import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import defaultContext from '../../../defaultContext';
import { getUserPlants } from '../../../services/plants';
import { map, values } from 'lodash';
import { useHistory } from 'react-router-dom';
import ModalComponent from '../modal';

export const MyPlants = () => {
	const { t } = useTranslation();
	const { plants, setPlants, user } = useContext(defaultContext);
	const [selectedPlant, setSelectedPlant] = useState();
	const history = useHistory();

	useEffect(() => {
		getUserPlants(user.userName)
			.then(res => {
				setPlants(res);
			})
			.catch(console.error);
	}, [setPlants, user.userName]);
	return (
		<>
			<div className="card">
				<div className="card-header">{t('my_plants_label')}</div>
				<ModalComponent open={!!selectedPlant} onClose={() => setSelectedPlant()}>
					{selectedPlant ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								flexDirection: 'column',
								alignItems: 'center',
								padding: '1rem',
							}}
						>
							{selectedPlant.image?<img style={{ width: '30rem' }} src={selectedPlant.image} alt="plant"></img>:<></>}
							<p style={{ fontWeight: 'bold', paddingTop: '3rem', margin: 0 }}>
								{selectedPlant.name || selectedPlant.canonical}
							</p>
							{selectedPlant.name !== selectedPlant.canonical ? (
								<p style={{ fontStyle: 'italic', margin: 0 }}>{selectedPlant.canonical}</p>
							) : (
								<></>
							)}
						</div>
					) : (
						<></>
					)}
				</ModalComponent>
				<div className="card-body">
					<table className="table table-hover">
						<thead>
							<tr>
								<th scope="col">{t('#_label')}</th>
								<th scope="col">{t('plant_name_label')}</th>
								<th scope="col">{t('image_label')}</th>
							</tr>
						</thead>
						<tbody>
							{map(values(plants), (p, i) => (
								<tr key={i} onClick={() => setSelectedPlant(p)}>
									<th scope="row">{i + 1}</th>
									<td>{p.name || p.canonical}</td>
									<td>{p.thumbnail ? <img style={{width:"10rem"}} src={p.thumbnail} alt={p.id} /> : <></>}</td>
								</tr>
							))}
						</tbody>
					</table>
					<button onClick={() => history.push('/plants')} className="btn btn-default">
						{t('add_more_label')}
					</button>
				</div>
			</div>
		</>
	);
};
