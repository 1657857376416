import React from 'react';
import { useTranslation } from 'react-i18next';
import RoomPanel from './room-panel';
import LocationPanel from './tune-location-panel';
import WindowsPanel from './windows-panel';
import BlocksPanel from './blocks-panel';
import TreesPanel from './trees-panel';
import WorkPlanesPanel from './workPlanes-panel';
import MiscObjectPanel from './misc-obj-panel';
import FurniturePanel from './furniture-panel';
import Add from './add-fab';
import expansionContext from './expansionContext';
import {
	updateCurrentSimulationBase,
	handleArrayEntityChangeBase,
	handleAddEntityBase,
	handleRemoveEntityBase,
} from '../utils';

const ModelerControls = ({
	setCurrentSimulation,
	currentSimulation,
	handleFinish,
	onMapClick,
	dirty,
	activeId,
	setActiveId,
}) => {
	const { t } = useTranslation();
	const updateCurrentSimulation = (key, value) => {
		updateCurrentSimulationBase({ [key]: value }, currentSimulation, setCurrentSimulation);
	};

	const handleArrayEntityChange = (arrayEntityName, index, prop, value) => {
		handleArrayEntityChangeBase(arrayEntityName, index, prop, value, currentSimulation, setCurrentSimulation);
	};

	const handleAddEntity = (arrayEntityName, value) => {
		const id = handleAddEntityBase(arrayEntityName, value, currentSimulation, setCurrentSimulation);
		setActiveId(arrayEntityName + '_' + id);
	};

	const handleRemoveEntity = (arrayEntityName, id) => {
		handleRemoveEntityBase(arrayEntityName, id, currentSimulation, setCurrentSimulation);
	};

	return (
		<expansionContext.Provider
			value={{
				activeId,
				setActiveId,
			}}
		>
			<Add
				add={handleAddEntity}
				onMapClick={onMapClick}
				setCurrentSimulation={setCurrentSimulation}
				currentSimulation={currentSimulation}
			/>
			{!activeId && dirty ? (
				<button
					className="btn btn-dark"
					style={{
						display: 'flex',
						flexDirection: 'row',
						maxWidth: '80vw',
						bottom: '5em',
						position: 'absolute',
						zIndex: 10,
						marginBottom: '0.5rem',
						marginLeft: '1rem',
						height: '3em',
					}}
					onClick={handleFinish}
				>
					{t('save_back_dashboard_button')}
				</button>
			) : (
				<></>
			)}
			<div
				className="controller-bar"
				style={{
					display: 'flex',
					flexDirection: 'row',
					maxWidth: '80vw',
					bottom: 0,
					position: 'absolute',
					zIndex: 10,
					marginBottom: '1rem',
				}}
			>
				<RoomPanel currentSimulation={currentSimulation} handleRoomChange={updateCurrentSimulation} />
				<WorkPlanesPanel
					workPlanes={currentSimulation.workPlanes}
					remove={handleRemoveEntity}
					handleChange={handleArrayEntityChange}
					width={currentSimulation.width}
					height={currentSimulation.height}
					depth={currentSimulation.depth}
				/>
				<LocationPanel currentSimulation={currentSimulation} handleRoomChange={updateCurrentSimulation} />
				{currentSimulation.hasRoom ? (
					<WindowsPanel
						depth={currentSimulation.depth}
						width={currentSimulation.width}
						height={currentSimulation.height}
						windows={currentSimulation.windows}
						remove={handleRemoveEntity}
						handleChange={handleArrayEntityChange}
					/>
				) : (
					<></>
				)}
				<BlocksPanel
					blocks={currentSimulation.blocks}
					remove={handleRemoveEntity}
					handleChange={handleArrayEntityChange}
					width={currentSimulation.width}
					height={currentSimulation.height}
					depth={currentSimulation.depth}
				/>
				<TreesPanel
					trees={currentSimulation.trees}
					remove={handleRemoveEntity}
					handleChange={handleArrayEntityChange}
				/>
				<FurniturePanel
					furniture={currentSimulation.furniture}
					remove={handleRemoveEntity}
					handleChange={handleArrayEntityChange}
				/>
				<MiscObjectPanel
					objects={currentSimulation.objects}
					remove={handleRemoveEntity}
					handleChange={handleArrayEntityChange}
				/>
			</div>
		</expansionContext.Provider>
	);
};

export default ModelerControls;
