import React from 'react';
import Window from './window-base';

export default props => {
	const splitNum = props.splitNum || 1;
	const width = props.width / splitNum;
	const height = props.height / splitNum;
	return props.splitDirection === 'V'
		? Array(splitNum)
				.fill()
				.map((_, index) => (
					<Window
						key={index}
						{...props}
						height={height}
						position={[props.position[0], props.position[1] + index * height]}
					/>
				))
		: Array(splitNum)
				.fill()
				.map((_, index) => (
					<Window
						key={index}
						{...props}
						width={width}
						position={[props.position[0] + index * width, props.position[1]]}
					/>
				));
};
