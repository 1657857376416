import React from 'react';
import { useTranslation } from 'react-i18next';
import { latLongToMeters } from '../utils';
import { NativeSelect } from '@material-ui/core';

export function ReportMeta({ currentSimulation, meta, irrigationMethod, setIrrigationMethod, setDirty }) {
	const { t } = useTranslation();
	const [stationLat, stationLong] = meta.distance.split('|').map(f => parseFloat(f));
	const style = { margin: 0 };
	const methods = ['best-plants-performance', 'baseline', 'best-water-saving'];

	return (
		<div style={{ marginLeft: '2em', marginTop: '2em' }}>
			<h2>{t('report_label')}</h2>
			<p style={style}>
				{t('project_name_label')}: {currentSimulation.id}
			</p>
			<p style={style}>
				{t('locationPanel_latitude_label')}: {currentSimulation.lat} {t('locationPanel_longitude_label')}:{' '}
				{currentSimulation.long}
			</p>
			<p style={style}>
				{t('report_meta_station')} {meta.station} {t('report_meta_distance')}{' '}
				{(latLongToMeters(currentSimulation.lat, currentSimulation.long, stationLat, stationLong) / 1000).toFixed(0)} Km
			</p>
			<NativeSelect
				value={irrigationMethod}
				onChange={event => {
					setIrrigationMethod(parseInt(event.target.value));
					setDirty(true);
				}}
				inputProps={{
					name: 'irrigation-method-select',
					id: 'irrigation-method-select',
				}}
			>
				{methods.map((w, i) => (
					<option key={w} value={i}>
						{t(w)}
					</option>
				))}
			</NativeSelect>
		</div>
	);
}
