import React from 'react';
import TextField from '@material-ui/core/TextField';

const PlantItem = ({ id, name, quantity, add, remove, onChange }) => {
	return (
		<div style={{ display: 'flex', alignItems: 'center' }}>
			<p style={{ paddingLeft: '.5rem', paddingRight: '.5rem', marginBottom: 0, width: '60%', maxWidth: '20rem' }}>
				{name}
			</p>
			<TextField
				style={{ width: '30%', height: '2rem', paddingRight: '.5rem', maxWidth: '4rem' }}
				type="number"
				value={quantity}
				onChange={e => onChange(id, e.target.value)}
			/>

			<button className="btn btn-dark-outline" style={{ height: '2rem', width: '5%' }} onClick={() => add(id)}>
				+
			</button>
			<button className="btn btn-dark-outline" style={{ height: '2rem', width: '5%' }} onClick={() => remove(id)}>
				-
			</button>
		</div>
	);
};

export default PlantItem;
