import React from 'react';
import { useTranslation } from 'react-i18next';
import defaultContext from '../../../defaultContext';
import { deleteCookie } from '../../../services/cookie';
import { canAccessApiToken, canAccessNotifications } from '../../../services/privileges';
import { deleteUser } from '../../../services/users';
import { ApiToken } from './ApiToken';
import { Notifications } from './Notifications';
import { useHistory } from 'react-router-dom';
import { usePrivileges } from '../../../hooks/priviledges';

export const General = () => {
	const { t } = useTranslation();
	const { user, setUser } = usePrivileges(defaultContext);
	const history = useHistory();

	const handleDelete = async () => {
		if (window.confirm(t('delete_account_notification'))) {
			await deleteUser(user.userName);
			deleteCookie('username');
			setUser();
			history.push('/login');
		}
	};
	
	return (
		<>
			<div className="card mb-2">
				<div className="card-header">{t('delete_account_label')}</div>
				<div className="card-body">
					<button className="btn btn-dark" onClick={handleDelete}>
						{t('delete_account_label')}
					</button>
				</div>
			</div>
			{canAccessNotifications(user) ? (
				<div className="card mb-2">
					<div className="card-header">{t('notifications_label')}</div>
					<div className="card-body">
						<Notifications />
					</div>
				</div>
			) : (
				<></>
			)}
			{canAccessApiToken(user) ? (
				<div className="card">
					<div className="card-header">{t('api_token')}</div>
					<div className="card-body">
						<ApiToken />{' '}
					</div>
				</div>
			) : (
				<></>
			)}
		</>
	);
};
