import React from 'react';
import { Typography, Select, MenuItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Expans from './expansion-panel';
import Slider from '../../ui-elements/slider';
import icon from '../../../assets/images/icons/windows.svg';
import { map } from 'lodash';

const WindowPanel = ({ windows, handleChange, add, remove, depth, width, height }) => {
	const { t } = useTranslation();
	const globalStep = 0.1;

	return (
		<Expans title={t('windows_label')} icon={icon} id="windows">
			{/* <Button onClick={() => add('windows',{ width: 1, height: 1, position: [0, 0], face: "F", splitNum: 1 })}>{t("windowsPanel_add_windows_label")}</Button> */}
			{map(windows, (window, index) => (
				<Expans
					key={index}
					title={t('windows_label') + index}
					icon={icon}
					id={'windows_' + window.id}
					remove={() => remove('windows', window.id)}
				>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Typography style={{ margin: '1em' }} gutterBottom>
							{t('windowsPanel_window_face_label')}
						</Typography>
						<Select
							style={{ flex: 1 }}
							labelId="label"
							id="select"
							value={window.face}
							onChange={e => handleChange('windows', index, 'face', e.target.value)}
						>
							<MenuItem value="F">{t('windowsPanel_window_face_front_label')}</MenuItem>
							<MenuItem value="B">{t('windowsPanel_window_face_back_label')}</MenuItem>
							<MenuItem value="L">{t('windowsPanel_window_face_left_label')}</MenuItem>
							<MenuItem value="R">{t('windowsPanel_window_face_right_label')}</MenuItem>
							<MenuItem value="T">{t('windowsPanel_window_face_top_label')}</MenuItem>
						</Select>
					</div>
					<Slider
						label={t('windowsPanel_window_width_label')}
						value={window.width}
						onChange={value => handleChange('windows', index, 'width', value)}
						min={0.1}
						max={(window.face === 'F' || window.face === 'B' ? width : depth) - window.position[0]}
						step={globalStep}
					/>
					<Slider
						label={t('windowsPanel_window_height_label')}
						value={window.height}
						onChange={value => handleChange('windows', index, 'height', value)}
						min={0.1}
						max={(window.face === 'T' ? width : height) - window.position[1]}
						step={globalStep}
					/>
					<Slider
						label={t('windowsPanel_window_x_label')}
						value={window.position[0]}
						onChange={value => handleChange('windows', index, 'position', [value, window.position[1]])}
						min={0}
						max={(window.face === 'F' || window.face === 'B' ? width : depth) - window.width}
						step={globalStep}
					/>
					<Slider
						label={t('windowsPanel_window_y_label')}
						value={window.position[1]}
						onChange={value => handleChange('windows', index, 'position', [window.position[0], value])}
						min={0}
						max={(window.face === 'T' ? width : height) - window.height}
						step={globalStep}
					/>
					<Slider
						label={t('windowsPanel_window_transmissivity_label')}
						value={window.transmissivity}
						onChange={value => handleChange('windows', index, 'transmissivity', value)}
						min={0}
						max={1}
						step={globalStep}
					/>
					{/* <TextFieldTypography label={t("windowsPanel_window_split_label")}
                    value={window.splitNum} onChange={(value => handleChange('windows',index, "splitNum", value))}
                />
                <Typography gutterBottom>{t("windowsPanel_window_split_direction_label")}</Typography>
                <Select labelId="label" id="select" value={window.splitDirection} onChange={(e) => handleChange('windows',index, "splitDirection", e.target.value)}>
                    <MenuItem value="V">{t("windowsPanel_window_vertical_label")}</MenuItem>
                    <MenuItem value="H">{t("windowsPanel_window_horizontal_label")}</MenuItem>

                </Select> */}
					{/* <TextFieldTypography label={t('windowsPanel_window_transmissivity_label')} value={window.transmissivity} onChange={value => handleChange('windows',index,'transmissivity',value)} /> */}
				</Expans>
			))}
		</Expans>
	);
};

export default WindowPanel;
