import React, { Suspense } from 'react';
import { useLoader, useFrame } from 'react-three-fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MeshBasicMaterial, DoubleSide } from 'three';

function GLB(props) {
	const gltf = useLoader(GLTFLoader, props.glb);

	const obj = gltf.scene.clone();
	const initialOpacity = Math.random() * Math.PI - Math.PI;
	var newMaterial = new MeshBasicMaterial({
		color: 0x002200,
		opacity: 0,
		transparent: true,
		side: DoubleSide,
		depthWrite: false,
	});

	obj.traverse(o => {
		if (o.isMesh) o.material = newMaterial;
	});
	let t = initialOpacity;
	useFrame(() => {
		obj.traverse(o => {
			if (o.isMesh) o.material.opacity = Math.max(Math.sin(t), 0);
		});
		t += Math.PI / 700;
	});

	return (
		<primitive
			object={obj}
			position={props.position}
			rotation={[-Math.PI / 2, 0, Math.PI]}
			scale={[props.scale, props.scale, props.scale]}
		/>
	);
}

function Box() {
	return (
		<mesh>
			<boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
			<meshStandardMaterial attach="material" transparent opacity={0.5} />
		</mesh>
	);
}

export default props => {
	return (
		<Suspense fallback={<Box />}>
			<GLB {...props} />
		</Suspense>
	);
};
