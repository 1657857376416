import React, { useContext } from 'react';
import location from '../../../assets/images/icons/locationIcon.png';
import AdvancedWizardSection from './advanced-wizard-section';
import { useTranslation } from 'react-i18next';
import Map from './map-selector';
import { notifyError } from '../../../services/toast';
import advancedWizardContext from './advance-wizard-context';
export const MapSection = () => {
	const { t } = useTranslation();
	const { currentSimulation, setCurrentSimulation } = useContext(advancedWizardContext);
	let { lat, long } = currentSimulation;
	return (
		<>
			<Map
				lat={lat}
				long={long}
				setLatLong={(lng, lat) => setCurrentSimulation({ ...currentSimulation, lat: lat, long: lng })}
			/>
			<AdvancedWizardSection style={{ position: 'absolute', bottom: '6em' }}>
				<div style={{ display: 'flex', flexDirection: 'row' }} image={location} title={t('location_label')}>
					<button
						className="btn btn-outline-dark mr-2"
						onClick={() => {
							if ('geolocation' in navigator) {
								navigator.geolocation.getCurrentPosition(function (position) {
									setCurrentSimulation({
										...currentSimulation,
										lat: position.coords.latitude,
										long: position.coords.longitude,
									});
								});
							} else {
								notifyError('Cannot Access Location');
							}
						}}
					>
						{t('get_current_location_label')}
					</button>
					<p className="ml-3">{t('map-select-comment')}</p>
				</div>
			</AdvancedWizardSection>
		</>
	);
};
