import React from 'react';
import { useTranslation } from 'react-i18next';
import PlayCircleFilledWhiteOutlinedIcon from '@material-ui/icons/PlayCircleFilledWhiteOutlined';
import roofTop from '../../../assets/images/home/roofTop.svg';
import landingPage from '../../../assets/images/home/landingPage.svg';
import { useHistory } from 'react-router-dom';
import HowItWorks from '../home/howItWorks';
import Carousel from '../home/carosoul';
import { isMobile } from 'react-device-detect';

const styles = {
	anchor: {
		display: 'block',
		height: '115px',
		marginTop: '-115px',
		visibility: 'hidden',
	},
	startButton: {
		fontSize: '15px',
		height: '40px',
		width: '10rem',
		alignItems: 'center',
		color: '#FFFFFF',
		marginBottom: isMobile ? '.5rem' : 0,
		marginRight: !isMobile ? '.5rem' : 0,
	},
	learnButton: {
		fontSize: '15px',
		height: '40px',
		width: '10rem',
	},

	businessIntroContainer: {
		display: 'flex',
		justifyContent: 'center',
		justifyItems: 'center',
	},
	disclaimerContainer: {
		display: 'flex',
		justifyContent: 'center',
		justifyItems: 'center',
		backgroundColor: 'grey',
		color: 'white',
		paddingBottom: '5.5rem',
		paddingTop: '2.5rem',
	},
	businessIntroTitle: {
		color: '#000',
		fontFamily: 'PT Sans',
		fontSize: '3.5rem',
		letterSpacing: 'none',
		marginTop: '2rem',
		marginBottom: '1rem',
		lineHeight: '2rem',
	},
	video: {
		position: 'absolute',
		top: '0',
		left: '0',
		width: '100%',
		height: '87%',
		marginBottom: '4em',
	},
	videoDiv: {
		position: 'relative',
		paddingTop: '56.25%',
	},
	articleContainer: {
		color: '#000',
		fontFamily: 'PT Sans',
		fontSize: '3.5rem',
		marginTop: '2rem',
		marginBottom: '1rem',
		lineHeight: '2rem',
	},
	title: {
		color: '#000',
		fontFamily: 'PT Sans',
		fontSize: '3.5rem',
		letterSpacing: 'none',
		marginTop: '2rem',
		marginBottom: '1rem',
		lineHeight: '2rem',
	},
};

const Home = () => {
	const { t } = useTranslation();
	const history = useHistory();
	return (
		<>
			<div className="container-fluid">
				<div className="row">
					{/* <div
						className="col-md-5 mb-md-6 mb-0 mt-md-0 mt-6"
						style={{
							backgroundColor: '#b0b0b0',
							padding: 0,
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<div
							style={{
								...(isMobile
									? {
											display: 'flex',
											alignContent: 'center',
											flexDirection: 'column',
											textAlign: 'center',
									  }
									: {}),
								...{ maxWidth: '75%', marginTop: '4rem', marginBottom: '2rem' },
							}}
						>
							<h1 style={{ fontSize: '2.5rem', color: '#FFF', fontWeight: 'bold' }}>
								{t('introduction_landing_page_heading')}
							</h1>
							<h2 style={{ fontSize: '1rem' }}>{t('introduction_landing_page_text')}</h2>
							<div
								style={{
									padding: 0,
								}}
							>
								<button
									className="btn btn-dark"
									aria-label={t('start_now_button_aria_label')}
									style={styles.startButton}
									onClick={() => history.push('/dashboard')}
								>
									{t('start_now_label')}
								</button>

								<a href="#howItWorks" className="btn btn-outline-dark" style={styles.learnButton}>
									<div style={{ display: 'flex' }}>
										<p>{t('learn_more_label')}</p>
										<PlayCircleFilledWhiteOutlinedIcon style={{ marginLeft: '0.1em' }} />
									</div>
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-7 mt-md-0 mb-0" style={{ backgroundColor: '#b0b0b0', padding: 0 }}>
						<img style={{ width: '100%' }} src={landingPage} alt={t('main_page_description')} />
					</div> */}
					<div
						className="col-md-12 mt-md-0 mb-0 landing-header"
						style={{
							minHeight: '90vh',
							backgroundAttachment: 'fixed',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							backgroundSize: 'cover',
							backgroundImage: `url(${landingPage})`,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							padding: 0,
						}}
					>
						<div
							className="backdrop-blur"
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								width: isMobile ? '100%' : '40%',
								padding: isMobile ? '2rem' : '6rem',
							}}
						>
							<h1
								style={{
									fontSize: '2.5rem',
									marginBottom: '3rem',
									marginTop: '1rem',
									fontWeight: 'bold',
									color: 'black',
								}}
							>
								{t('introduction_landing_page_heading')}
							</h1>
							<h2 style={{ fontSize: '1.5rem', marginBottom: '3rem' }}>{t('introduction_landing_page_text')}</h2>
							<div
								style={{
									padding: 0,
									display: 'flex',
									alignItems: 'flex-start',
								}}
							>
								<button
									className="btn btn-dark"
									aria-label={t('start_now_button_aria_label')}
									style={styles.startButton}
									onClick={() => history.push('/dashboard')}
								>
									{t('start_now_label')}
								</button>

								<a href="#howItWorks" className="btn btn-outline-dark" style={styles.learnButton}>
									<div style={{ display: 'flex', alignItems: 'flex-start', justifyContent: 'center' }}>
										<p>{t('learn_more_label')}</p>
										<PlayCircleFilledWhiteOutlinedIcon style={{ marginLeft: '0.1em' }} />
									</div>
								</a>
							</div>
						</div>
						{/* <img style={{  }} src={landingPage} alt={t('main_page_description')} /> */}
					</div>
				</div>
				<div className="row" style={styles.businessIntroContainer}>
					<div className="col-md-12" style={{ scrollBehavior: 'smooth' }}>
						<div id="howItWorks">
							<HowItWorks />
						</div>
					</div>
				</div>
				<div className="row" style={styles.businessIntroContainer}>
					<div className="col-md-2"></div>
					<div className="col-md-8 d-md-flex justify-content-md-center">
						<img className="mt-5 mw-100" src={roofTop} alt="business introdoction" />
					</div>
					<div className="col-md-2"></div>
				</div>
				<div className="row" style={styles.businessIntroContainer}>
					<div className="col-md-12">
						<Carousel />
					</div>
				</div>
				<span style={styles.anchor} id="inActionVideo"></span>

				<div className="row" style={styles.businessIntroContainer}>
					<div className="col-md-2"></div>
					<div className="col-md-8">
						<div style={styles.videoDiv}>
							<iframe
								style={styles.video}
								src="https://www.youtube.com/embed/vFMvx-XwpoI?controls=1"
								frameBorder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowFullScreen
								title="service introduction"
							></iframe>
						</div>
					</div>
					<div className="col-md-2"></div>
				</div>
			</div>
		</>
	);
};

export default Home;
