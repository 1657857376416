import React, { useContext } from 'react';
import AdvancedWizardSection from './advanced-wizard-section';
import { useTranslation } from 'react-i18next';
import { debounce, last } from 'lodash';
import RadioButtonMaker from '../radio-button';
import advancedWizardContext from './advance-wizard-context';
import Slider from '../../ui-elements/slider';

export const TreesAddSection = () => {
	const { t } = useTranslation();
	const {
		mapClickFunc,
		handleAddEntity,
		redrawEntity,
		handleNext,
		currentSimulation: { trees },
	} = useContext(advancedWizardContext);
	redrawEntity.current = 'trees';
	mapClickFunc.current = debounce((x, y) => {
		handleAddEntity('trees', { position: [x, -y], type: '4', scale: 1, transmissivity: 0 });
		handleNext.current();
	}, 150);

	return (
		<AdvancedWizardSection>
			<p>{trees && trees.length > 0 ? t('add_trees_section') : t('add_first_trees_section')}</p>
		</AdvancedWizardSection>
	);
};

export const TreesDetailsSection = () => {
	const { t } = useTranslation();
	const { currentSimulation, mapClickFunc, handleArrayEntityChange } = useContext(advancedWizardContext);
	mapClickFunc.current = () => {};
	let { trees } = currentSimulation;
	let tree = last(trees);
	let index = trees.length - 1;
	return (
		<AdvancedWizardSection>
			<div className="mb-3">
				<RadioButtonMaker
					inputsContent={[
						{
							id: 1,
							key: '0',
							label: 'trees_type_label_0',
						},
						{
							id: 2,
							key: '1',
							label: 'trees_type_label_1',
						},
						{
							id: 3,
							key: '2',
							label: 'trees_type_label_2',
						},
						{
							id: 4,
							key: '3',
							label: 'trees_type_label_3',
						},
						{
							id: 5,
							key: '4',
							label: 'trees_type_label_4',
						},
					]}
					style={{ marginLeft: '1.5em', display: 'flex', flexDirection: 'column' }}
					name="wizard-windows-faces"
					value={tree.type}
					onChange={v => handleArrayEntityChange('trees', index, 'type', v)}
				/>
			</div>
			<Slider
				label={t('trees_scale_label')}
				value={tree.scale}
				onChange={value => handleArrayEntityChange('trees', index, 'scale', value)}
				min={0.5}
				max={3}
				step={0.1}
			/>
			<Slider
				label={t('trees_transmissivity_label')}
				value={tree.transmissivity}
				onChange={value => handleArrayEntityChange('trees', index, 'transmissivity', value)}
				min={0}
				max={1}
				step={0.01}
			/>
		</AdvancedWizardSection>
	);
};
