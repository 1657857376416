import React, { useContext } from 'react';
import AdvancedWizardSection from './advanced-wizard-section';
import advancedWizardContext from './advance-wizard-context';
import { useTranslation } from 'react-i18next';
import Slider from '../../ui-elements/slider';
import { map } from 'lodash';

export const RoomSection = () => {
	const { t } = useTranslation();
	const { currentSimulation, updateCurrentSimulation, setCurrentSimulation, nextStep } =
		useContext(advancedWizardContext);

	let { width, depth, height, templateInputs } = currentSimulation;
	nextStep.current = templateInputs && templateInputs.length > 0 ? 'template-input' : 'window-add';

	const handleReflectivityChange = re => {
		setCurrentSimulation({
			...currentSimulation,
			floorReflectivity: re,
			ceilingReflectivity: re,
			wallReflectivity: re,
		});
	};

	return (
		<AdvancedWizardSection>
			<Slider
				value={width}
				onChange={v => updateCurrentSimulation('width', v)}
				name={'width'}
				label={t('advanced_wizard_width_label')}
				min={2}
				max={20}
			/>
			<Slider
				value={depth}
				onChange={v => updateCurrentSimulation('depth', v)}
				name={'depth'}
				label={t('advanced_wizard_depth_label')}
				min={2}
				max={20}
			/>
			<Slider
				value={height}
				onChange={v => updateCurrentSimulation('height', v)}
				name={'height'}
				label={t('advanced_wizard_height_label')}
				min={2}
				max={7}
			/>
			<Slider
				value={currentSimulation.floorReflectivity}
				onChange={v => handleReflectivityChange(v)}
				name={'reflectivity'}
				label={t('advanced_wizard_reflectivity_label')}
				min={0}
				max={1}
			/>
		</AdvancedWizardSection>
	);
};

export const TemplateSpecificSection = () => {
	const { t } = useTranslation();
	const { currentSimulation, handleArrayEntityChange, nextStep } = useContext(advancedWizardContext);

	let { templateInputs, hasRoom } = currentSimulation;
	nextStep.current = hasRoom ? 'window-add' : 'workplane-details';
	return (
		<AdvancedWizardSection>
			{map(templateInputs, (i, index) => (
				<Slider
					key={index + i.label}
					value={i.val}
					onChange={value => handleArrayEntityChange('templateInputs', index, 'val', value)}
					name={i.label}
					label={t(i.label)}
					min={i.min}
					max={i.max}
				/>
			))}
		</AdvancedWizardSection>
	);
};

export const FinishSection = () => {
	const { mapClickFunc } = useContext(advancedWizardContext);

	mapClickFunc.current = () => {};
	return <></>;
};
