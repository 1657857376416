import React from 'react';
import { Typography, Select, MenuItem } from '@material-ui/core';
import Expans from './expansion-panel';
import { useTranslation } from 'react-i18next';
import Slider from '../../ui-elements/slider';
import icon from '../../../assets/images/icons/trees.svg';
import { map } from 'lodash';

const TreesPanel = ({ trees, remove, handleChange }) => {
	const { t } = useTranslation();
	return (
		<Expans title={t('trees_label')} icon={icon} id="trees">
			{map(trees, (tree, index) => (
				<Expans
					key={index}
					title={t('trees_label') + index}
					icon={icon}
					id={'trees_' + tree.id}
					remove={() => remove('trees', tree.id)}
				>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Typography style={{ margin: '1em' }} gutterBottom>
							{t('trees_type_label')}
						</Typography>
						<Select
							style={{ flex: 1 }}
							labelId="label"
							id="select"
							value={tree.type}
							onChange={e => handleChange('trees', index, 'type', e.target.value)}
						>
							<MenuItem value="0">{t('trees_type_label_0')}</MenuItem>
							<MenuItem value="1">{t('trees_type_label_1')}</MenuItem>
							<MenuItem value="2">{t('trees_type_label_2')}</MenuItem>
							<MenuItem value="3">{t('trees_type_label_3')}</MenuItem>
							<MenuItem value="4">{t('trees_type_label_4')}</MenuItem>
						</Select>
					</div>
					<Slider
						label={t('trees_x_label')}
						value={tree.position[0]}
						onChange={value => handleChange('trees', index, 'position', [value, tree.position[1]])}
						min={-100}
						max={100}
						step={1}
					/>
					<Slider
						label={t('trees_y_label')}
						value={tree.position[1]}
						onChange={value => handleChange('trees', index, 'position', [tree.position[0], value])}
						min={-100}
						max={100}
						step={1}
					/>
					<Slider
						label={t('trees_scale_label')}
						value={tree.scale}
						onChange={value => handleChange('trees', index, 'scale', value)}
						min={0.5}
						max={3}
						step={0.1}
					/>
					<Slider
						label={t('trees_transmissivity_label')}
						value={tree.transmissivity}
						onChange={value => handleChange('trees', index, 'transmissivity', value)}
						min={0}
						max={1}
						step={0.01}
					/>
				</Expans>
			))}
		</Expans>
	);
};

export default TreesPanel;
