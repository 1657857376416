export function setCookie(cname, cvalue, exdays) {
	exdays = exdays || 365;
	var d = new Date();
	d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
	var expires = 'expires=' + d.toUTCString();
	document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

export function getCookie(cname) {
	var name = cname + '=';
	var ca = document.cookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) === ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) === 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

export function checkCookie() {
	var user = getCookie('username');
	if (user !== '') {
		console.error('Welcome again ' + user);
	} else {
		user = prompt('Please enter your name:', '');
		if (user !== '' && user !== null) {
			setCookie('username', user, 365);
		}
	}
}

export function deleteCookie(cname) {
	document.cookie = cname + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}
