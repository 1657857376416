import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useThree, extend, useFrame } from 'react-three-fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { Vector3, MOUSE } from 'three';
extend({ OrbitControls });

const OrbitControl = ({ alt, translateX, translateY, view, width, depth, centerMapFunc }) => {
	const {
		camera,
		gl: { domElement },
	} = useThree();
	const controls = useRef();
	let t = 0;
	const [stopRotation, setStopRotation] = useState(false);

	const resetCamera = useCallback(
		top => {
			camera.position.y = 40 + alt;
			if (top) {
				camera.position.x = 0;
				camera.position.z = 0;
			} else {
				camera.position.x = Math.max(width, depth) * 5;
				camera.position.z = Math.max(width, depth) * 5;
			}
			camera.lookAt(0, 0, 0);
		},
		[alt, camera, depth, width],
	);

	useEffect(() => {
		if (view === 'rotate') {
			controls.current.addEventListener(
				'start',
				() => {
					setStopRotation(true);
				},
				false,
			);
		}
		controls.current.mouseButtons = { LEFT: MOUSE.RIGHT, MIDDLE: MOUSE.MIDDLE, RIGHT: MOUSE.LEFT };
		controls.current.update();
	});
	if (centerMapFunc) {
		centerMapFunc.current = top => {
			resetCamera(top);
		};
	}
	useEffect(() => {
		controls.current.target = new Vector3(translateX, alt, translateY);
		resetCamera();
		controls.current.update();
	}, [alt, resetCamera, translateX, translateY]);

	useFrame(() => {
		if (view === 'rotate' && !stopRotation) {
			camera.position.y = 7 + alt;
			camera.position.z = Math.max(width, depth) * Math.cos(t);
			camera.position.x = Math.max(width, depth) * Math.sin(t);
			camera.lookAt(0, 0, 0);
			t += 0.005;
		}
	});
	return (
		<>
			<orbitControls ref={controls} args={[camera, domElement]} />
		</>
	);
};

export default OrbitControl;
