import React, { useEffect, useRef, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { Camera, WebGLRenderer, Vector2, TextureLoader, Matrix4, Vector3 } from 'three';
import { extend } from 'react-three-fiber';
// import { extend, useThree } from 'react-three-fiber';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer';
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass';
import { getXY } from '../utils';
import { isFunction } from 'lodash';

extend({ EffectComposer, ShaderPass, RenderPass });

const VERTEX = `
                            varying vec2 vUv;

                            void main() {
                                vec4 mvPosition = modelViewMatrix * vec4(position, 1.);
                                gl_Position = projectionMatrix * mvPosition;
                                vUv = uv;
                            }
                        `;
const FRAGMENT = `
                            uniform sampler2D tDiffuse;
                            uniform sampler2D tShadow;
                            uniform vec2 iResolution;

                            varying vec2 vUv;



                            #define Sensitivity (vec2(0.3, 1.5) * iResolution.y / 200.0)

                            float checkSame(vec4 center, vec4 samplef)
                            {
                                vec2 centerNormal = center.xy;
                                float centerDepth = center.z;
                                vec2 sampleNormal = samplef.xy;
                                float sampleDepth = samplef.z;

                                vec2 diffNormal = abs(centerNormal - sampleNormal) * Sensitivity.x;
                                bool isSameNormal = (diffNormal.x + diffNormal.y) < 0.1;
                                float diffDepth = abs(centerDepth - sampleDepth) * Sensitivity.y;
                                bool isSameDepth = diffDepth < 0.1;

                                return (isSameNormal && isSameDepth) ? 1.0 : 0.0;
                            }

                            void main( )
                            {
                                vec4 sample0 = texture2D(tDiffuse, vUv);
                                vec4 sample1 = texture2D(tDiffuse, vUv + (vec2(1.0, 1.0) / iResolution.xy));
                                vec4 sample2 = texture2D(tDiffuse, vUv + (vec2(-1.0, -1.0) / iResolution.xy));
                                vec4 sample3 = texture2D(tDiffuse, vUv + (vec2(-1.0, 1.0) / iResolution.xy));
                                vec4 sample4 = texture2D(tDiffuse, vUv + (vec2(1.0, -1.0) / iResolution.xy));

                                float edge = checkSame(sample1, sample2) * checkSame(sample3, sample4);

                                // gl_FragColor = vec4(edge, sample0.w, 1.0, 1.0);
                                float shadow = texture2D(tShadow, vUv).x;
                                gl_FragColor = vec4(edge, shadow, 1.0, 1.0);

                            }
                        `;
const FRAGMENT_FINAL = `
                        uniform sampler2D tDiffuse;
                        uniform sampler2D tNoise;
                        uniform float iTime;

                        varying vec2 vUv;

                        #define EdgeColor vec4(0.2, 0.2, 0.15, 1.0)
                        #define BackgroundColor vec4(1,0.95,0.85,1)
                        #define NoiseAmount 0.01
                        #define ErrorPeriod 30.0
                        #define ErrorRange 0.0003

                        // Reference: https://www.shadertoy.com/view/MsSGD1
                        float triangle(float x)
                        {
                            return abs(1.0 - mod(abs(x), 2.0)) * 2.0 - 1.0;
                        }

                        float rand(float x)
                        {
                            return fract(sin(x) * 43758.5453);
                        }

                        void main()
                        {
                            float time = floor(iTime * 16.0) / 16.0;
                            vec2 uv = vUv;
                            uv += vec2(triangle(uv.y * rand(time) * 1.0) * rand(time * 1.9) * 0.005,
                                    triangle(uv.x * rand(time * 3.4) * 1.0) * rand(time * 2.1) * 0.005);

                            float noise = (texture2D(tNoise, uv * 0.5).r - 0.5) * NoiseAmount;
                            vec2 uvs[3];
                            uvs[0] = uv + vec2(ErrorRange * sin(ErrorPeriod * uv.y + 0.0) + noise, ErrorRange * sin(ErrorPeriod * uv.x + 0.0) + noise);
                            uvs[1] = uv + vec2(ErrorRange * sin(ErrorPeriod * uv.y + 1.047) + noise, ErrorRange * sin(ErrorPeriod * uv.x + 3.142) + noise);
                            uvs[2] = uv + vec2(ErrorRange * sin(ErrorPeriod * uv.y + 2.094) + noise, ErrorRange * sin(ErrorPeriod * uv.x + 1.571) + noise);

                            float edge = texture2D(tDiffuse, uvs[0]).r;// * texture2D(tDiffuse, uvs[1]).r * texture2D(tDiffuse, uvs[2]).r;
                            float diffuse = texture2D(tDiffuse, uv).g;

                            float w = fwidth(diffuse) * 2.0;
                            vec4 mCol = mix(BackgroundColor * 0.5, BackgroundColor, mix(0.0, 1.0, smoothstep(-w, w, diffuse - 0.3)));
                            gl_FragColor = mix(EdgeColor, mCol, edge);
                        }
                        `;

const MapboxGLMap = ({
	className,
	lat,
	long,
	scene,
	view,
	show3dBuildings,
	rotation,
	elevation,
	translateX,
	translateY,
	onMapClick,
	centerMapFunc,
}) => {
	const [map, setMap] = useState(null);
	const mapContainer = useRef(null);

	useEffect(() => {
		mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;
		const initializeMap = ({ setMap, mapContainer }) => {
			const map = new mapboxgl.Map({
				container: mapContainer.current,
				style: 'mapbox://styles/mapbox/light-v10',
				zoom: 20,
				center: [long, lat],
				pitch: view === 'top' ? 0 : 60,
				bearing: view === 'top' ? -rotation : -17.5,
				antialias: true,
			});
			map.addControl(new mapboxgl.NavigationControl());

			map.on('load', function () {
				map.resize();

				map.addLayer(
					{
						id: '3d-model',
						type: 'custom',
						renderingMode: '3d',
						onAdd: function (map, gl) {
							this.camera = new Camera();

							this.renderer = new WebGLRenderer({
								canvas: map.getCanvas(),
								context: gl,
								antialias: true,
							});

							this.renderer.autoClear = false;

							if (scene) {
								const resolution = new Vector2(map.getCanvas().clientWidth, map.getCanvas().clientHeight);

								const drawShader = {
									uniforms: {
										tDiffuse: { type: 't', value: null },
										tShadow: { type: 't', value: null },
										iResolution: { type: 'v2', value: resolution },
									},
									vertexShader: VERTEX,
									fragmentShader: FRAGMENT,
								};

								this.composer = new EffectComposer(this.renderer);
								this.initPass = new RenderPass(scene, this.camera);
								this.composer.addPass(this.initPass);

								this.pass = new ShaderPass(drawShader);
								//pass.renderToScreen = true;
								this.composer.addPass(this.pass);

								const finalShader = {
									uniforms: {
										tDiffuse: { type: 't', value: null },
										iTime: { type: 'f', value: 0.0 },
										tNoise: { type: 't', value: new TextureLoader().load('noise.png') },
									},
									vertexShader: VERTEX,
									fragmentShader: FRAGMENT_FINAL,
								};

								this.passFinal = new ShaderPass(finalShader);
								this.passFinal.renderToScreen = true;
								this.passFinal.material.extensions.derivatives = true;
								this.composer.addPass(this.passFinal);
							}
						},
						render: function (gl, matrix) {
							if (scene && this.camera) {
								var modelOrigin = [long, lat];
								var modelAltitude = 0;
								var modelRotate = [Math.PI / 2, 0, 0];

								var modelAsMercatorCoordinate = mapboxgl.MercatorCoordinate.fromLngLat(modelOrigin, modelAltitude);

								var modelTransform = {
									translateX: modelAsMercatorCoordinate.x,
									translateY: modelAsMercatorCoordinate.y,
									translateZ: modelAsMercatorCoordinate.z,
									rotateX: modelRotate[0],
									rotateY: modelRotate[1],
									rotateZ: modelRotate[2],
									scale: modelAsMercatorCoordinate.meterInMercatorCoordinateUnits(),
								};

								var rotationX = new Matrix4().makeRotationAxis(new Vector3(1, 0, 0), modelTransform.rotateX);
								var rotationY = new Matrix4().makeRotationAxis(new Vector3(0, 1, 0), modelTransform.rotateY);
								var rotationZ = new Matrix4().makeRotationAxis(new Vector3(0, 0, 1), modelTransform.rotateZ);

								var m = new Matrix4().fromArray(matrix);
								var l = new Matrix4()
									.makeTranslation(modelTransform.translateX, modelTransform.translateY, modelTransform.translateZ)
									.scale(new Vector3(modelTransform.scale, -modelTransform.scale, modelTransform.scale))
									.multiply(rotationX)
									.multiply(rotationY)
									.multiply(rotationZ);

								this.camera.projectionMatrix = m.multiply(l);
								this.renderer.state.reset();
								this.renderer.render(scene, this.camera);
								//this.composer.render();

								map.triggerRepaint();
							}
						},
					},
					'waterway-label',
				);
			});

			setMap(map);
		};

		if (!map) initializeMap({ setMap, mapContainer });
	}, [map, scene, lat, long, rotation, view]);

	useEffect(() => {
		if (show3dBuildings && map) {
			var layers = map.getStyle().layers;

			var labelLayerId;
			for (var i = 0; i < layers.length; i++) {
				if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
					labelLayerId = layers[i].id;
					break;
				}
			}

			map.addLayer(
				{
					id: '3d-buildings',
					source: 'composite',
					'source-layer': 'building',
					filter: ['==', 'extrude', 'true'],
					type: 'fill-extrusion',
					minzoom: 15,
					paint: {
						'fill-extrusion-color': '#aaa',
						// use an 'interpolate' expression to add a smooth transition effect to the
						// buildings as the user zooms in
						'fill-extrusion-height': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'height']],
						'fill-extrusion-base': ['interpolate', ['linear'], ['zoom'], 15, 0, 15.05, ['get', 'min_height']],
						'fill-extrusion-opacity': 0.1,
					},
				},
				labelLayerId,
			);
		} else if (map) {
			try {
				map.removeLayer('3d-buildings');
			} catch {}
		}
	}, [show3dBuildings, map]);

	if (map) {
		map.on('click', function (point) {
			const center = { lat: lat, lng: long };

			let x = point.lngLat.distanceTo(new mapboxgl.LngLat(center.lng, point.lngLat.lat));
			let y = point.lngLat.distanceTo(new mapboxgl.LngLat(point.lngLat.lng, center.lat));
			x = x * (center.lng < point.lngLat.lng ? 1 : -1) - translateX;
			y = y * (center.lat < point.lngLat.lat ? 1 : -1) + translateY;

			const [rx, ry] = getXY(x, y, rotation);

			if (onMapClick && isFunction(onMapClick.current)) {
				onMapClick.current(rx, ry);
			}
		});

		if (centerMapFunc) {
			centerMapFunc.current = top => {
				let r_earth = 6378000;
				if (top || view === 'top') {
					map.setBearing(-rotation);
					map.setPitch(0);
				} else {
					map.setPitch(60);
				}
				let new_latitude = lat - (translateY / r_earth) * (180 / Math.PI);
				let new_longitude = long + ((translateX / r_earth) * (180 / Math.PI)) / Math.cos((lat * Math.PI) / 180);
				map.flyTo({ center: [new_longitude, new_latitude] });
			};
		}
	}
	return <div ref={el => (mapContainer.current = el)} className={className} />;
};

export default MapboxGLMap;
