import { post, get, put, del } from './request';

export async function addUser(id, pl) {
	const res = await post(`${process.env.REACT_APP_API}api/users/account/${id}`, pl);
	return res.result;
}

export async function updateUser(id, pl) {
	const res = await put(`${process.env.REACT_APP_API}api/users/account/${id}`, pl);
	return res.result;
}

export async function checkUser(id, pl) {
	const res = await post(`${process.env.REACT_APP_API}api/users/check/${id}`, pl);
	return res.result;
}

export async function getUser(id) {
	const res = await get(`${process.env.REACT_APP_API}api/users/account/${id}`);
	return res.result;
}

export async function deleteUser(id) {
	const res = await del(`${process.env.REACT_APP_API}api/users/account/${id}`);
	return res.result;
}

export async function getProjects(id) {
	const res = await get(`${process.env.REACT_APP_API}api/users/projects/${id}`);
	return res.result;
}

export async function getUserPrivileges(id) {
	const res = await get(`${process.env.REACT_APP_API}api/users/privileges/${id}`);
	return res.result;
}

export async function updateUserPrivilegesTime(id, time) {
	const res = await post(`${process.env.REACT_APP_API}api/users/privileges/${id}`, { time });
	return res.result;
}

export async function updateUserPrivilegesProjects(id, projects) {
	const res = await post(`${process.env.REACT_APP_API}api/users/privileges/${id}`, { projects });
	return res.result;
}

export async function updateUserPrivilegesCount(id, count) {
	const res = await post(`${process.env.REACT_APP_API}api/users/privileges/${id}`, { count });
	return res.result;
}

export async function createToken(id) {
	const res = await post(`${process.env.REACT_APP_API}api/users/token/${id}`);
	return res.result;
}

export async function getToken(id) {
	const res = await get(`${process.env.REACT_APP_API}api/users/token/${id}`);
	return res.result;
}

export async function getNotifications(id) {
	const res = await get(`${process.env.REACT_APP_API}api/notifications/${id}`);
	return res.result;
}

export async function upsertNotifications(id, pl) {
	const res = await post(`${process.env.REACT_APP_API}api/notifications/${id}`, pl);
	return res.result;
}
