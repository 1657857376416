import React, { useEffect, useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Skeleton } from '@material-ui/lab';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { map } from 'lodash';

export function PlantListRow({ plantOrPromise, i, deleteFunc, setSelectedPlantIndex }) {
	const [plant, setPlant] = useState();
	const [error, setError] = useState();
	useEffect(() => {
		if (plantOrPromise.p?.then) {
			plantOrPromise.p.then(setPlant).catch(e => {
				console.log(plantOrPromise.n);
				setError(true)
			});
		} else {
			setPlant(plantOrPromise);
		}
	}, [plantOrPromise]);

	if (!plant && !error) {
		return (
			<tr key={i}>
				<td></td>
				<td>
					<Skeleton />
				</td>
			</tr>
		);
	} else if (error) {
		return null;
	}

	return (
		<tr key={i}>
			<td style={{ cursor: 'pointer' }} onClick={setSelectedPlantIndex}>
				{i + 1}
			</td>
			{plant.quantity ? <td style={{ display: 'flex', justifyContent: 'center' }}>{'x ' + plant.quantity}</td> : <></>}

			<td style={{ cursor: 'pointer' }} onClick={setSelectedPlantIndex}>
				{' '}
				{plant.name ? (
					<div>
						<p style={{ fontWeight: 'bold', margin: 0 }}>{plant.name.split(',')[0]}</p>
						<p style={{ fontStyle: 'italic', margin: 0 }}>{plant.canonical}</p>
					</div>
				) : (
					<p style={{ fontWeight: 'bold', margin: 0 }}>{plant.canonical}</p>
				)}
			</td>
			{/* <td style={{ cursor: 'pointer' }} onClick={setSelectedPlantIndex}>
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div
						style={{
							height: '10px',
							width: (100 / 60) * plant.dli[0] + '%',
						}}
					></div>
					<div
						style={{
							height: '10px',
							width: (100 / 60) * (plant.dli[1] - plant.dli[0]) + '%',
							backgroundColor: '#588326',
							marginTop: '10px',
						}}
					></div>
					<div
						style={{
							height: '10px',
							width: (100 / 60) * (60 - plant.dli[1]) + '%',
						}}
					></div>
				</div>
			</td> */}
			{deleteFunc ? (
				<td style={{ width: '10%' }}>
					<IconButton onClick={() => deleteFunc(plant.id)}>
						<DeleteIcon />
					</IconButton>
				</td>
			) : (
				<></>
			)}
		</tr>
	);
}

export const PlantListRowDetail = ({ plantOrPromise, index, deleteFunc, onChange }) => {
	const { t } = useTranslation();
	const [plant, SetPlant] = useState();
	const [error, setError] = useState();
	useEffect(() => {
		if (plantOrPromise.p?.then) {
			plantOrPromise.p.then(SetPlant).catch(e => {
				console.log(plantOrPromise.n);
				setError(true)
			});
		} else {
			SetPlant(plantOrPromise);
		}
	}, [plantOrPromise]);

	if (!plant && !error) {
		return (
			<tr key={index}>
				<td></td>
				<td>
					<Skeleton />
				</td>
			</tr>
		);
	} else if (error) {
		return null;
	}
	// plant.quantity = 1;
	return (
		<tr key={index}>
			<td></td>
			<td style={{ width: '100%' }}>
				<div style={{ display: 'flex', flexDirection: 'column' }}>
					{plant.name ? (
						<div>
							<p style={{ fontWeight: 'bold', margin: 0 }}>{plant.name.split(',')}</p>
							<p style={{ fontStyle: 'italic', margin: 0 }}>{plant.canonical}</p>
						</div>
					) : (
						<p style={{ fontWeight: 'bold', margin: 0 }}>{plant.canonical}</p>
					)}
					<div style={{ marginTop: '2rem' }}>
						{plant.thumbnail ? <img style={{ width: '20rem' }} src={plant.thumbnail} alt={plant.id} /> : <></>}
					</div>
					<div style={{ display: 'flex', flexDirection: 'row', marginBottom: '2rem' }}>
						{onChange ? (
							<label htmlFor={'plant-quantity-' + index} style={{ margin: '1rem' }}>
								{'Quantity'}
							</label>
						) : (
							<></>
						)}
						{onChange ? (
							<TextField
								id={'plant-quantity-' + index}
								type="number"
								value={plant.quantity}
								onChange={e => onChange(plant.id, 'quantity', e.target.value)}
							/>
						) : (
							<></>
						)}
						{onChange ? (
							<label htmlFor={'plant-size-' + index} style={{ margin: '1rem' }}>
								{'Size'}
							</label>
						) : (
							<></>
						)}
						{onChange ? (
							<TextField
								id={'plant-size-' + index}
								type="number"
								value={plant.size}
								onChange={e => onChange(plant.id, 'size', e.target.value)}
							/>
						) : (
							<></>
						)}
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'row',
							justifyContent: 'space-between',
							width: '100%',
						}}
					>
						<p style={{ minWidth: '10rem', margin: 0 }}>{t('')}</p>
						<p style={{ minWidth: '15%', margin: 0, fontWeight: 'bold' }}>{t('plant_list_table_shaded')}</p>
						<p style={{ minWidth: '15%', margin: 0, fontWeight: 'bold' }}>{t('plant_list_table_Low')}</p>
						<p style={{ minWidth: '15%', margin: 0, fontWeight: 'bold' }}>{t('plant_list_table_bright')}</p>
						<p style={{ minWidth: '15%', margin: 0, fontWeight: 'bold' }}>{t('plant_list_table_direct')}</p>
					</div>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<div style={{ width: '10rem' }}>
							<p>Light Demand:</p>
						</div>
						{!plant.dli ? (
							<p>Unknown Light Demand</p>
						) : (
							<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
								<div
									style={{
										height: '10px',
										width: (100 / 60) * plant.dli[0] + '%',
									}}
								></div>
								<div
									style={{
										height: '10px',
										width: (100 / 60) * (plant.dli[1] - plant.dli[0]) + '%',
										backgroundColor: '#588326',
										marginTop: '10px',
									}}
								></div>
								<div
									style={{
										height: '10px',
										width: (100 / 60) * (60 - plant.dli[1]) + '%',
									}}
								></div>
							</div>
						)}
					</div>
				</div>

				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<div style={{ width: '10rem' }}>
						<p>Water Demand:</p>
					</div>
					{!plant.water ? (
						<p>Unknown Water Demand</p>
					) : (
						<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
							<div
								style={{
									height: '10px',
									width: plant.water[0] + '%',
								}}
							></div>
							<div
								style={{
									height: '10px',
									width: plant.water[1] - plant.water[0] + '%',
									backgroundColor: '#5883f6',
									marginTop: '10px',
								}}
							></div>
							<div
								style={{
									height: '10px',
									width: 100 - plant.water[1] + '%',
								}}
							></div>
						</div>
					)}
				</div>

				<div style={{ display: 'flex', flexDirection: 'column' }}>
					{map(plant.categories, (v, k) => {
						return (
							<p>
								<span style={{ fontWeight: 'bold', margin: 0 }}>{t(k)}:</span> {v}
							</p>
						);
					})}
				</div>
			</td>
			{deleteFunc ? (
				<td style={{ width: '10%' }}>
					<IconButton onClick={() => deleteFunc(plant.id)}>
						<DeleteIcon />
					</IconButton>
				</td>
			) : (
				<></>
			)}
		</tr>
	);
};
