import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import Logo from '../../assets/images/logo/logoAppBar.png';
// import Language from '../../assets/images/icons/language.svg';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import defaultContext from '../../defaultContext';
import { deleteCookie, getCookie } from '../../services/cookie';

const AppBar = () => {
	const { t } = useTranslation();
	const { user, setUser } = useContext(defaultContext);

	const userName = getCookie('username');

	return (
		<Navbar collapseOnSelect expand="lg" bg="white" style={{ zIndex: 1000 }}>
			<Navbar.Brand as={Link} to="/">
				{t('app_brand_label')}
			</Navbar.Brand>
			<Navbar.Brand as={Link} to="/">
				<img src={Logo} width="30" height="30" className="d-inline-block align-top" alt="" />
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="responsive-navbar-nav" />
			<Navbar.Collapse id="responsive-navbar-nav" style={{ backgroundColor: '#FFFFFF' }}>
				<Nav className="mr-auto">
					<Nav.Link as={Link} eventKey="1" to="/">
						{t('home_page_label')}
					</Nav.Link>
					{/* <Nav.Link as={Link} eventKey="2" to="/how-it-works">
						{t('how_it_works_page_label')}
					</Nav.Link> */}
					<Nav.Link as={Link} eventKey="2" to="/features">
						{t('features_page_label')}
					</Nav.Link>
					<Nav.Link eventKey="3" onClick={() => window.open('https://www.food4rhino.com/en/app/aiplantcare')}>
						{t('api_page_label')}
					</Nav.Link>
					<Nav.Link
						eventKey="4"
						onClick={() => window.open('https://fazel-ganji.gitbook.io/aiplantcare-documentation/')}
					>
						{t('docs_page_label')}
					</Nav.Link>
					<Nav.Link as={Link} eventKey="5" to="/pricing">
						{t('pricing_page_label')}
					</Nav.Link>
					<Nav.Link as={Link} eventKey="6" to="/contact">
						{t('contact_page_label')}
					</Nav.Link>
				</Nav>
				<Nav>
					<Nav.Link as={Link} eventKey="7" to="/dashboard">
						<NotificationsOutlinedIcon
							style={{
								width: '24px',
								height: '24px',
								justify: 'flex-end',
							}}
						/>
					</Nav.Link>
					<NavDropdown title={<PersonOutlineOutlinedIcon justify="flex-end" />} id="collasible-nav-dropdown">
						<NavDropdown.Item href="/dashboard">{t('Dashboard')}</NavDropdown.Item>
						<NavDropdown.Divider />
						<NavDropdown.Item
							onClick={() => {
								deleteCookie('username');
								setUser();
							}}
						>
							<Nav.Link as={Link} eventKey="8" to="/login">
								{t('sign_out')}
							</Nav.Link>
						</NavDropdown.Item>
					</NavDropdown>
					<Nav.Link as={Link} eventKey="9" to={user || userName ? '/dashboard' : 'login'}>
						{user ? user.userName : userName || 'login'}
					</Nav.Link>
					{/* <NavDropdown
						title={
							<img src={Language} style={{ width: '24px', height: '24px', justify: 'flex-end' }} alt="language"></img>
						}
						id="collasible-nav-dropdown"
					>
						<NavDropdown.Item href="#action/3.1">{t('en')}</NavDropdown.Item>
					</NavDropdown>
					<Navbar.Text>{t('nav_text')}</Navbar.Text> */}
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
};

export default AppBar;
