import React, { useEffect, useState, useContext } from 'react';
import defaultContext from '../../../defaultContext';
import PlantSet from './PlantSet';
import { isEmpty, map, values, find } from 'lodash';
import { useTranslation } from 'react-i18next';
import { getProject, upsertProject } from '../../../services/projects';
import { notify } from '../../../services/toast';
import { getUserPlants } from '../../../services/plants';
import { every } from 'lodash';

const PlantSetWrapper = ({ id, editing, setEditing }) => {
	const { t } = useTranslation();
	const { setCurrentSimulation, setCurrentSimulationName, currentSimulation, user, plants, setPlants } = useContext(
		defaultContext,
	);

	const [projectPlants, setProjectPlants] = useState([]);
	const handleFinish = async () => {
		const newState = { ...currentSimulation, plants: projectPlants.filter(p => p.quantity !== 0) };
		setCurrentSimulation(newState);
		await upsertProject(user.userName, newState.id, newState, true);
		setEditing(false);
	};

	const handleStartUpdate = async id => {
		if (isEmpty(plants)) {
			try {
				const res = await getUserPlants(user.userName);
				setPlants(res);
				if (isEmpty(res)) {
					notify(t('no_plants_notification_text'));
					return;
				}
			} catch (e) {
				console.error(e);
			}
		}
		try {
			const result = await getProject(user.userName, id);
			setCurrentSimulation(result);
			setCurrentSimulationName(result.id);
			setEditing(true);
		} catch (e) {
			console.error(e);
		}
	};

	useEffect(() => {
		if (editing) {
			const newState = map(values(plants), p => {
				const plantInProject = find(currentSimulation.plants, pl => pl.id === p.id);
				return {
					id: p.id,
					dli: p.dli,
					quantity: plantInProject ? plantInProject.quantity : 0,
					name: p.name || p.canonical,
				};
			});
			if (isEmpty(newState)) {
				setEditing(false);
			}

			setProjectPlants(newState);
		}
	}, [editing, setEditing, plants, currentSimulation]);
	return (
		<div>
			{editing ? (
				isEmpty(projectPlants) ? (
					<></>
				) : (
					<div>
						<PlantSet projectPlants={projectPlants} setProjectPlants={setProjectPlants} />

						<button style={{ marginTop: '2em' }} className="btn btn-dark mr-2" onClick={() => setEditing(false)}>
							{t('cancel_button_label')}
						</button>
						<button
							style={{ marginTop: '2em' }}
							disabled={every(projectPlants, f => f.quantity === 0) ? true : false}
							className="btn btn-dark"
							onClick={handleFinish}
						>
							{t('save_button_label')}
						</button>
					</div>
				)
			) : (
				<button className="btn btn-dark" onClick={() => handleStartUpdate(id)}>
					{t('edit_plantset_button')}
				</button>
			)}
		</div>
	);
};

export default PlantSetWrapper;
