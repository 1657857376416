import React, { useContext, useEffect, useState } from 'react';
import AdvancedWizardSection from './advanced-wizard-section';
import { useTranslation } from 'react-i18next';
import advancedWizardContext from './advance-wizard-context';
import Slider from '../../ui-elements/slider';
import { getXY } from '../utils';

export const LocationSection = () => {
	const { t } = useTranslation();

	const { currentSimulation, updateCurrentSimulation, updateCurrentSimulationKeyValues, centerMapFunc } = useContext(
		advancedWizardContext,
	);

	useEffect(() => {
		if (centerMapFunc.current) {
			centerMapFunc.current();
		}
	});

	const [xy, setXY] = useState(() =>
		getXY(currentSimulation.translateX, currentSimulation.translateY, -currentSimulation.orientation),
	);

	const onChange = xy => {
		setXY(xy);
		const [xx, yy] = getXY(xy[0], xy[1], currentSimulation.orientation);
		updateCurrentSimulationKeyValues({
			translateX: xx,
			translateY: yy,
		});
	};

	useEffect(() => {
		setXY(getXY(currentSimulation.translateX, currentSimulation.translateY, -currentSimulation.orientation));
	}, [currentSimulation.orientation, currentSimulation.translateX, currentSimulation.translateY]);

	return (
		<AdvancedWizardSection>
			<Slider
				value={currentSimulation.orientation}
				onChange={v => updateCurrentSimulation('orientation', v)}
				name={'orientation'}
				label={t('roomPanel_rotation_label')}
				min={-180}
				max={180}
			/>
			<Slider
				value={xy[0]}
				onChange={v => onChange([v, xy[1]])}
				name={'translateX'}
				label={t('roomPanel_tune_longitude_label')}
				min={-20}
				max={20}
			/>
			<Slider
				value={xy[1]}
				onChange={v => onChange([xy[0], v])}
				name={'translateY'}
				label={t('roomPanel_tune_latitude_label')}
				min={-20}
				max={20}
			/>
		</AdvancedWizardSection>
	);
};
