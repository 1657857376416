import React, { useState } from 'react';
import Context from './defaultContext';

const ContextMaker = ({ children }) => {
	const [currentSimulationName, setCurrentSimulationName] = useState();
	const [currentSimulation, setCurrentSimulation] = useState();
	const [projects, setProjects] = useState();
	const [privileges, setPrivileges] = useState(0);
	const [user, setUser] = useState();
	const [plants, setPlants] = useState([]);

	return (
		<Context.Provider
			value={{
				currentSimulationName,
				setCurrentSimulationName,
				currentSimulation,
				setCurrentSimulation,
				projects,
				setProjects,
				privileges,
				setPrivileges,
				user,
				setUser,
				plants,
				setPlants,
			}}
		>
			{children}
		</Context.Provider>
	);
};

export default ContextMaker;
