import React, { useContext, useState, useEffect } from 'react';
import defaultContext from '../../../defaultContext';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { Redirect } from 'react-router';
import { suggest, getUserPlants, savePlants, getPlant, recognizePlant } from '../../../services/plants';
import { TextField, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import plantsShortList from './plants-short-list';
import plantSets from './plant-sets';
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import { map, includes, isEmpty, each, values, filter, uniqBy, range, lowerCase } from 'lodash';
import { PlantList } from '../plant-list';
import { Accordion, Card, Button } from 'react-bootstrap';
import MyModal from '../modal';
import { RecognitionResults } from './recognition-results';
import { AddCustomPlant } from './add-custom-plant';

const PlantSpecies = () => {
	const { t } = useTranslation();

	const { plants, setPlants, user } = useContext(defaultContext);
	const [redirect, setRedirect] = useState(false);
	const options = plantsShortList;
	const [suggestions, setSuggestions] = useState([]);
	const [dataUri, setDataUri] = useState();
	const [plantPromises, setPlantPromises] = useState([]);
	const [cameraStarted, setCameraStarted] = useState(false);
	const [recognition, setRecognition] = useState();

	function handleTakePhotoAnimationDone(dataUri) {
		setRecognition(null);
		setCameraStarted(false);
		setDataUri(dataUri);
	}

	const recognize = async () => {
		const response = await recognizePlant(user.userName, dataUri);
		setRecognition(response.results);
	};

	useEffect(() => {
		getUserPlants(user.userName)
			.then(res => {
				setPlants(res);
			})
			.catch(console.error);
	}, [setPlants, user.userName]);

	const handleScientificNameSearch = async str => {
		let res = await suggest(str);
		setSuggestions(
			uniqBy(
				map(
					res,
					s => {
						return [s.key, s.canonicalName];
					},
					f => f[0],
				),
			),
		);
	};

	const handlePlantChange = async value => {
		setPlantPromises([...plantPromises, {p:getPlant(value),n:value}]);
	};

	const handleDelete = async value => {
		let { [value]: omit, ...res } = plants;
		setPlants(res);
		let count = 0;
		for (let p of plantPromises) {
			const r = await p;
			if (r.id === value) {
				break;
			}
			count++;
		}
		setPlantPromises(filter(plantPromises, (_, i) => i !== count));
	};

	const handleSave = async () => {
		const newPlants = await Promise.all(plantPromises);
		each(newPlants, p => (plants[p.id] = { ...p, name: p.name ? p.name.split(',')[0] : p.canonical }));
		await savePlants(user.userName, plants);
		setRedirect(true);
	};

	const handleRecognitionPlantSelect = async p => {
		setPlantPromises([...plantPromises, {p:getPlant(p.gbif.id),n:p}]);
		setRecognition();
		setDataUri();
	};

	const handlePlantSetChange = async p => {
		const set = plantSets.find(f => f.name === p);
		if (set) {
			setPlantPromises([...plantPromises, ...map(set.plants, p => ({p:getPlant(lowerCase(p[2])),n:p}))]);
		}
	};

	const getGenericPlants = () => {
		setPlantPromises([...plantPromises, ...map(range(1, 28), p => ({p:getPlant('generic-' + p),n:p}))]);
	};
	return redirect ? (
		<Redirect push to="/dashboard" />
	) : cameraStarted ? (
		<div style={{ position: 'absolute', top: 0 }}>
			<Camera
				onTakePhotoAnimationDone={handleTakePhotoAnimationDone}
				isFullscreen={true}
				idealFacingMode="environment"
				isImageMirror={false}
			/>
		</div>
	) : recognition && !isEmpty(recognition) ? (
		<MyModal open={!!recognition}>
			<RecognitionResults
				recognition={recognition}
				handleRecognitionPlantSelect={handleRecognitionPlantSelect}
				setRecognition={setRecognition}
			/>
		</MyModal>
	) : (
		<>
			<h1 style={{ display: 'flex', justifyContent: 'center', paddingBottom: '4rem', paddingTop: '2rem' }}>
				{t('my_plants_label')}
			</h1>
			<div className="container">
				<div className="row">
					<div className="col-md-4">
						<div className="mb-4">
							<button className="btn btn-dark" onClick={handleSave}>
								{t('save_back_dashboard_button')}
							</button>
						</div>
						<Accordion defaultActiveKey="0" activeKey={dataUri ? '4' : undefined}>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="0">
										{t('plant_set_label')}
									</Accordion.Toggle>
								</Card.Header>

								<Accordion.Collapse eventKey="0">
									<Card.Body>
										<div></div>
										<div style={{ display: 'flex', justifyContent: 'center' }}>
											<Autocomplete
												id="combo-box-plant-set"
												options={map(plantSets, s => s.name)}
												onChange={(event, value) => handlePlantSetChange(value)}
												getOptionLabel={option => t(option)}
												style={{ width: 300 }}
												renderInput={params => <TextField {...params} label="Name" variant="outlined" />}
											/>
										</div>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="1">
										{t('short_list_label')}
									</Accordion.Toggle>
								</Card.Header>

								<Accordion.Collapse eventKey="1">
									<Card.Body>
										<div></div>
										<div style={{ display: 'flex', justifyContent: 'center' }}>
											<Autocomplete
												id="combo-box-short-list"
												options={options}
												onChange={(event, value) => handlePlantChange(value[2])}
												getOptionSelected={(option, value) =>
													includes(option[0], value[0]) || includes(option[1], value[1])
												}
												getOptionLabel={option => (isEmpty(option[1]) ? option[0] : option[1])}
												style={{ width: 300 }}
												renderInput={params => <TextField {...params} label="Name" variant="outlined" />}
											/>
										</div>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="2">
										{t('search_scientific_name_label')}
									</Accordion.Toggle>
								</Card.Header>

								<Accordion.Collapse eventKey="2">
									<Card.Body>
										<div></div>
										<div style={{ display: 'flex', justifyContent: 'center' }}>
											<Autocomplete
												id="combo-box-search-scientific"
												options={suggestions}
												onChange={(event, value) => handlePlantChange(value[0])}
												getOptionSelected={(option, value) => (option = value)}
												getOptionLabel={option => option[1]}
												style={{ width: 300 }}
												renderInput={params => (
													<TextField
														{...params}
														label="Scientific Name"
														variant="outlined"
														onChange={e => handleScientificNameSearch(e.target.value)}
													/>
												)}
											/>
										</div>
									</Card.Body>
								</Accordion.Collapse>
							</Card>

							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="3">
										{t('add_custom_plant_label')}
									</Accordion.Toggle>
								</Card.Header>

								<Accordion.Collapse eventKey="3">
									<Card.Body>
										<AddCustomPlant plants={plants} setPlants={setPlants} />
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="4">
										{t('recognize_picture')}
									</Accordion.Toggle>
								</Card.Header>

								<Accordion.Collapse eventKey="4">
									<Card.Body>
										<div style={{ display: 'flex', justifyContent: 'center' }}>
											{dataUri ? (
												<div>
													<div style={{ display: 'flex', justifyContent: 'center' }}>
														<button className="btn btn-dark mr-2" onClick={() => setCameraStarted(true)}>
															{t('start_camera_again')}
														</button>
														<button className="btn btn-dark mr-2" onClick={() => recognize()}>
															{t('send_for_recognition')}
														</button>
														<IconButton onClick={() => setDataUri()}>
															<DeleteIcon />
														</IconButton>
													</div>
													<div style={{ width: '100%' }}>
														<img style={{ width: '100%' }} src={dataUri} alt="" />
													</div>
												</div>
											) : (
												<button className="btn btn-dark" onClick={() => setCameraStarted(true)}>
													{t('start_camera')}
												</button>
											)}
										</div>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<Card>
								<Card.Header>
									<Accordion.Toggle as={Button} variant="link" eventKey="5">
										{t('generic_plants')}
									</Accordion.Toggle>
								</Card.Header>

								<Accordion.Collapse eventKey="5">
									<Card.Body>
										<div style={{ display: 'flex', justifyContent: 'center' }}>
											<button className="btn btn-dark" onClick={() => getGenericPlants()}>
												{t('get_all_generic_plants')}
											</button>
											{/* {TODO: create a criteria for generating generic plants} */}
										</div>
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>
					</div>
					<div className="col-md-8">
						<PlantList plantsOrPromises={[...values(plants), ...plantPromises]} deleteFunc={handleDelete} />
					</div>
				</div>
			</div>
		</>
	);
};

export default PlantSpecies;
