import React, { useMemo, useCallback } from 'react';
import { Vector3 } from 'three';

function Line({ x1, y1, x2, y2 }) {
	const vertices = useMemo(
		() =>
			[
				[x1, 0, y1],
				[x2, 0, y2],
			].map(v => new Vector3(...v)),
		[x1, y1, x2, y2],
	);

	const update = useCallback(self => {
		self.verticesNeedUpdate = true;
		self.computeBoundingSphere();
	}, []);

	return (
		<>
			<line>
				<geometry attach="geometry" vertices={vertices} onUpdate={update} />
				<lineBasicMaterial attach="material" color="black" />
			</line>
		</>
	);
}

const Mark = ({ x, y }) => {
	return (
		<group>
			<Line x1={x - 0.25} y1={y - 0.25} x2={x + 0.25} y2={y + 0.25} />
			<Line x1={x - 0.25} y1={y + 0.25} x2={x + 0.25} y2={y - 0.25} />
		</group>
	);
};

export default Mark;
