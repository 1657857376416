import React from 'react';
import Expans from './expansion-panel';
import { useTranslation } from 'react-i18next';
import Slider from '../../ui-elements/slider';
import icon from '../../../assets/images/icons/general.svg';
import { map, sortBy, filter } from 'lodash';
import { NativeSelect } from '@material-ui/core';

const MiscObjPanel = ({ objects, remove, handleChange }) => {
	const { t } = useTranslation();
	return (
		<Expans title={t('objects_label')} icon={icon} id="objects">
			{map(objects, (object, index) => (
				<Expans
					key={index}
					title={t('objects_label') + index}
					icon={icon}
					id={'objects_' + object.id}
					remove={() => remove('objects', object.id)}
				>
					<Slider
						label={t('objects_x_label')}
						value={object.x}
						onChange={value => handleChange('objects', index, 'x', value)}
						min={-100}
						max={100}
						step={0.01}
					/>
					<Slider
						label={t('objects_y_label')}
						value={object.y}
						onChange={value => handleChange('objects', index, 'y', value)}
						min={-100}
						max={100}
						step={0.01}
					/>
					<Slider
						label={t('objects_z_label')}
						value={object.z}
						onChange={value => handleChange('objects', index, 'z', value)}
						min={-100}
						max={100}
						step={0.01}
					/>
					<Slider
						label={t('objects_scalex_label')}
						value={object.scalex}
						onChange={value => handleChange('objects', index, 'scalex', value)}
						min={0.001}
						max={5}
						step={0.001}
					/>
					<Slider
						label={t('objects_scaley_label')}
						value={object.scaley}
						onChange={value => handleChange('objects', index, 'scaley', value)}
						min={0.001}
						max={5}
						step={0.001}
					/>
					<Slider
						label={t('objects_scalez_label')}
						value={object.scalez}
						onChange={value => handleChange('objects', index, 'scalez', value)}
						min={0.001}
						max={5}
						step={0.001}
					/>
					<Slider
						label={t('objects_rotation_y_label')}
						value={object.rotation}
						onChange={value => handleChange('objects', index, 'rotation', value)}
						min={-180}
						max={180}
						step={0.01}
					/>
					<Slider
						label={t('objects_rotation_z_label')}
						value={object.rotation2}
						onChange={value => handleChange('objects', index, 'rotation2', value)}
						min={-180}
						max={180}
						step={0.01}
					/>
					<hr />
					<div style={{ maxHeight: '20rem', overflowY: 'scroll' }}>
						{map(
							sortBy(object.mat, mm => mm.id),
							m => {
								return (
									<div style={{}}>
										<NativeSelect
											value={m.surface}
											onChange={event =>
												handleChange('objects', index, 'mat', [
													...filter(object.mat, mm => mm.id !== m.id),
													{ id: m.id, surface: parseInt(event.target.value), value: m.value },
												])
											}
											inputProps={{
												name: 'dliType',
												id: 'dliType-select',
											}}
										>
											<option value={0}>{t('objects_window_label')}</option>
											<option value={1}>{t('objects_block_label')}</option>
											<option value={2}>{t('objects_work_plane_label')}</option>
										</NativeSelect>
										{m.surface !== 2 ? (
											<Slider
												label={(m.surface ? 'Reflectivity' : 'Transmissivity') + ' ' + m.id}
												value={m.value}
												onChange={value =>
													handleChange('objects', index, 'mat', [
														...filter(object.mat, mm => mm.id !== m.id),
														{ id: m.id, surface: m.surface, value: value },
													])
												}
												min={0}
												max={1}
												step={0.01}
											/>
										) : (
											<></>
										)}
									</div>
								);
							},
						)}
					</div>
				</Expans>
			))}
		</Expans>
	);
};

export default MiscObjPanel;
