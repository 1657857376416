import { filter } from 'lodash';

export function countOF(d, l, plantDli) {
	switch (l) {
		case 0:
			return filter(d, f => f < plantDli[0]).length;
		case 1:
			return filter(d, f => f >= plantDli[0] && f <= plantDli[1]).length;
		case 2:
			return filter(d, f => f > plantDli[1]).length;
		default:
			throw new Error('this line should not be reached');
	}
}
