import React, { useRef, useMemo } from 'react';
import { PlaneGeometry } from 'three';

export function Selection({ x, y, z, r, n, c, rotation }) {
	const mesh = useRef();
	const geom = useMemo(() => new PlaneGeometry(r, r), [r]);
	return (
		<mesh ref={mesh} position={[x + n[0] * 0.001, y + n[1] * 0.001, z + n[2] * 0.001]} rotation={rotation}>
			<lineSegments>
				<edgesGeometry attach="geometry" args={[geom]} />
				<lineBasicMaterial color={c} width={0.3} attach="material" />
			</lineSegments>
		</mesh>
	);
}
