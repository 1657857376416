import React from 'react';
import { map } from 'lodash';
import { PlantListRow, PlantListRowDetail } from './plant-list-row';
import { useTranslation } from 'react-i18next';
import { some } from 'lodash';

export function PlantList({ plantsOrPromises, deleteFunc, onChange }) {
	const { t } = useTranslation();
	const [selectedPlantIndex, setSelectedPlantIndex] = React.useState(null);

	return (
		<>
			<table className="table table-hover">
				<thead>
					<tr style={{ fontWeight: 'bold' }}>
						<th>
							<p style={{ margin: 0, minWidth: '4%' }}>#</p>
						</th>
						{some(plantsOrPromises, f => f.quantity) ? (
							<th style={{ minWidth: '12%' }}>
								<p style={{ margin: 0 }}>{t('plant_list_table_quantity')}</p>
							</th>
						) : (
							<></>
						)}

						<th style={{ minWidth: '74%' }}>
							<p style={{ margin: 0 }}>{t('plant_list_table_name')}</p>
						</th>

						{/* <th>
							{isMobile ? (
								<p>{t('plant_list_table_light')}</p>
							) : (
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										justifyContent: 'space-between',
									}}
								>
									<p style={{ minWidth: '15%', margin: 0 }}>{t('plant_list_table_shaded')}</p>
									<p style={{ minWidth: '15%', margin: 0 }}>{t('plant_list_table_Low')}</p>
									<p style={{ minWidth: '15%', margin: 0 }}>{t('plant_list_table_bright')}</p>
									<p style={{ minWidth: '15%', margin: 0 }}>{t('plant_list_table_direct')}</p>
								</div>
							)}
						</th> */}
						{deleteFunc ? <th style={{ width: '10%' }}></th> : <></>}
					</tr>
				</thead>
				<tbody>
					{map(plantsOrPromises, (plantOrPromise, i) => {
						if (i === selectedPlantIndex) {
							return (
								<PlantListRowDetail
									plantOrPromise={plantOrPromise}
									key={i}
									index={i}
									deleteFunc={deleteFunc}
									onChange={onChange}
								/>
							);
						}
						return (
							<PlantListRow
								i={i}
								plantOrPromise={plantOrPromise}
								deleteFunc={deleteFunc}
								key={i}
								setSelectedPlantIndex={() => setSelectedPlantIndex(i)}
							/>
						);
					})}
				</tbody>
			</table>
		</>
	);
}



