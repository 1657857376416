import React from 'react';

const styles = {
	image: {
		width: '80%',
	},
	content: {
		width: '80%',
	},
	container: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
	},
	paragraph: {
		minHeight: '121px',
		color: '#4F4F4F',
		fontFamily: 'PT Sans',
		fontSize: '16px',
		letterSpacing: '2px',
		lineHeight: '24px',
		textAlign: 'center',
	},
	title: {
		height: '31px',
		color: '#000',
		fontFamily: 'PT Sans',
		fontSize: '26px',
		letterSpacing: '2px',
		lineHeight: '31px',
		textAlign: 'center',
	},
};

const ImageTypography = ({ image, text, title, style, imageStyle, titleStyle, textStyle, contentStyle }) => {
	return (
		<div style={{ ...style, ...styles.container }}>
			<img src={image} style={{ ...styles.image, ...imageStyle }} alt="" />
			<h3 style={{ ...styles.title, ...titleStyle }}>{title}</h3>
			<div style={{ ...styles.content, ...contentStyle }}>
				<p style={{ ...styles.paragraph, ...textStyle }}>{text}</p>
			</div>
		</div>
	);
};

export default ImageTypography;
