import React from 'react';
import Expans from './expansion-panel';
import { useTranslation } from 'react-i18next';
import icon from '../../../assets/images/icons/pin.svg';
import TextFieldTypography from '../text-field';
import Slider from '../../ui-elements/slider';

const TuneLocationpanel = ({
	currentSimulation: { orientation, translateX, translateY, lat, long },
	handleRoomChange,
}) => {
	const { t } = useTranslation();
	return (
		<Expans title={t('location_label')} icon={icon} id="location">
			<Slider
				label={t('roomPanel_rotation_label')}
				value={orientation}
				onChange={value => handleRoomChange('orientation', value)}
				min={-180}
				max={180}
				step={0.01}
			/>
			<Slider
				label={t('roomPanel_tune_longitude_label')}
				value={translateX}
				onChange={value => handleRoomChange('translateX', value)}
				min={-40}
				max={40}
				step={0.1}
			/>
			<Slider
				label={t('roomPanel_tune_latitude_label')}
				value={translateY}
				onChange={value => handleRoomChange('translateY', value)}
				min={-40}
				max={40}
				step={0.1}
			/>
			<TextFieldTypography
				label={t('locationPanel_latitude_label')}
				value={lat}
				onChange={value => handleRoomChange('lat', value)}
			/>
			<TextFieldTypography
				label={t('locationPanel_longitude_label')}
				value={long}
				onChange={value => handleRoomChange('long', value)}
			/>
		</Expans>
	);
};

export default TuneLocationpanel;
