import React, { Suspense } from 'react';
import { useLoader } from 'react-three-fiber';
import { OBJLoader2 } from 'three/examples/jsm/loaders/OBJLoader2';
import { Mesh, MeshBasicMaterial, DoubleSide } from 'three';
import { rgbf, colorGradient, rotate } from '../utils';
import { map, join, chunk, uniqBy, forEach, find } from 'lodash';

const dataUri = str => 'data:text/plain;charset=utf-8;base64,' + Buffer.from(str || '').toString('base64');

function MiscObject({
	activeId,
	setActiveId,
	object: { id, data, x, y, z, rotation, rotation2, scalex, scaley, scalez, mat },
	sensors,
	showObjects = true,
}) {
	rotation = isNaN(rotation) ? 0 : rotation;
	rotation2 = isNaN(rotation2) ? 0 : rotation2;
	scalex = scalex ?? 1;
	scaley = scaley ?? 1;
	scalez = scalez ?? 1;
	x = x ?? 0;
	y = y ?? 0;
	z = z ?? 0;
	let myObj = useLoader(OBJLoader2, dataUri(data));
	const isActive = activeId === `objects_${id}`;
	myObj.traverse(function (child) {
		if (child instanceof Mesh) {
			var material = new MeshBasicMaterial({
				opacity: showObjects ? 0.7 : 0,
				//wireframe: true,
				transparent: true,
				side: DoubleSide,
				color: '#555555',
			});
			let onSelect = () => {};
			const m = mat.find(f => f.id === child.name);
			if (m) {
				if (m.surface === 0) {
					material = new MeshBasicMaterial({
						opacity: showObjects ? (m.value + 0.01) * 0.5 || 0.1 : 0,
						//wireframe: true,
						transparent: true,
						side: DoubleSide,
						color: isActive ? '#ff00ff' : '#000055',
					});
				} else if (m.surface === 1) {
					material = new MeshBasicMaterial({
						opacity: showObjects ? 0.7 : 0,
						wireframe: sensors ? true : false,
						transparent: true,
						side: DoubleSide,
						color: colorGradient(m.value, rgbf(0, 0, 0), rgbf(255, isActive ? 100 : 255, 255)),
					});
				} else if (m.surface === 2) {
					let color = '#74FF5B';

					if (sensors) {
						const arr = child.geometry.attributes.position.array;
						let s = [0, 0, 0];
						let chunked = chunk(arr, 3);
						let uni = uniqBy(chunked, f => join(f, '|'));
						forEach(uni, i => {
							s[0] += i[0];
							s[1] += i[1];
							s[2] += i[2];
						});
						let m = s.map(f => f / uni.length);
						let sc = m.map((f, i) => f * [scalex, scaley, scalez][i]);
						let ro = rotate(rotate(sc, -rotation, 1), -rotation2, 0);
						const s2 = ro.map((f, i) => f + [x, y, z][i]);
						const sensor = find(
							sensors,
							(v, k) => (v[0] - s2[0]) ** 2 + (v[1] - s2[1]) ** 2 + (v[2] - s2[2]) ** 2 < 0.01,
						);
						if (sensor) {
							color = sensor[3];
							onSelect = sensor[4];
						}
					}
					material = new MeshBasicMaterial({
						opacity: 0.7,
						//wireframe: true,
						transparent: true,
						side: DoubleSide,
						color: isActive ? '#ff0000' : color,
					});
				}
			}
			child.material = material;
			child.onPointerDown = onSelect;
		}
	});

	return (
		<Suspense fallback={<Box />}>
			{map(myObj.children, c => (
				<primitive
					onPointerDown={setActiveId ? e => (setActiveId ? setActiveId(`objects_${id}`) : {}) : c.onPointerDown}
					object={c.clone()}
					position={[x, y, z]}
					rotation={[(rotation2 * Math.PI) / 180, (rotation * Math.PI) / 180, 0]}
					scale={[scalex, scaley, scalez]}
				/>
			))}
		</Suspense>
	);
}

function Box() {
	return (
		<mesh>
			<boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
			<meshStandardMaterial attach="material" transparent opacity={0.5} />
		</mesh>
	);
}

export default MiscObject;
