import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageTypography from '../ui-elements/image-typography';
import computer from '../../assets/images/features/computer.png';
import monitor from '../../assets/images/features/monitor.png';
import plantSpeciesDB from '../../assets/images/features/plantSpeciesDB.png';
import interactiveResults from '../../assets/images/features/interactiveResults.png';
import plantCareService from '../../assets/images/features/plantCareService.png';
import validatedSimulationEngine from '../../assets/images/features/validatedSimulationEngine.png';
import { chunk } from 'lodash';

const styles = {
	header: {
		height: '64px',
		color: '#000',
		fontFamily: 'PT Sans',
		fontSize: '50px',
		letterSpacing: '2px',
		lineHeight: '64px',
		textAlign: 'center',
	},
	title: {
		marginTop: '50px',
		width: '100%',
		height: '170px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		color: 'green',
		fontFamily: 'PT Sans',
	},
};

const Features = () => {
	const { t } = useTranslation();

	const content = [
		{ id: 1, title: 'user_friendly modeler_title', text: 'user_friendly modeler_text', image: monitor },
		{ id: 2, title: 'plants_species_database_title', text: 'plants_species_database_text', image: plantSpeciesDB },
		{
			id: 3,
			title: 'validated_simulation_engine_title',
			text: 'validated_simulation_engine_text',
			image: validatedSimulationEngine,
		},
		{ id: 4, title: 'cloud_based_computing_title', text: 'cloud_based_computing_text', image: computer },
		{ id: 5, title: 'plants_care_service_title', text: 'plants_care_service_text', image: plantCareService },
		{ id: 6, title: 'interactive_results_title', text: 'interactive_results_text', image: interactiveResults },
	];
	const rowMaker = chunk(content, 3);
	return (
		<div className="container" style={{ backgroundColor: '#FFF', minHeight: '100vh' }}>
			<div className="row" style={styles.title}>
				<h2 style={styles.header}>{t('features_page_label')}</h2>
			</div>
			{rowMaker.map(row => (
				<div key={row.map(f => f.id).join('-')} className="row">
					{row.map(c => (
						<div key={c.id} className="col-md-4">
							<ImageTypography
								title={t(c.title)}
								titleStyle={{ color: '#398238', marginTop: '1rem' }}
								imageStyle={{ height: '100px', objectFit: 'scale-down' }}
								text={t(c.text)}
								textStyle={{ marginTop: '2rem', marginBottom: '7rem' }}
								image={c.image}
							/>
						</div>
					))}
				</div>
			))}
		</div>
	);
};

export default Features;
