export default [
	{
		id: 0,
		maxNumberOfProjectRun: 0,
		maxNumberOfProjectRunPerMonth: 0,
		maxNumberOfProjects: 0,
		maxNumberOfPlantRecognition: 0,
		expiry: 365,
	},
	{
		id: 1,
		maxNumberOfProjectRun: 14,
		maxNumberOfProjectRunPerMonth: 2,
		maxNumberOfProjects: 5,
		maxNumberOfPlants: 25,
		maxNumberOfPlantRecognition: 1,
		expiry: 365,
	},
	{
		id: 2,
		maxNumberOfProjectRun: 40,
		maxNumberOfProjectRunPerMonth: 3,
		maxNumberOfProjects: 10,
		maxNumberOfPlants: 50,
		maxNumberOfPlantRecognition: 10,
		expiry: 365,
	},
	{
		id: 3,
		maxNumberOfProjectRun: null,
		maxNumberOfProjectRunPerMonth: null,
		maxNumberOfProjects: null,
		maxNumberOfPlants: null,
		maxNumberOfPlantRecognition: 30,
		expiry: 365,
	},
	{
		id: 4,
		maxNumberOfProjectRun: null,
		maxNumberOfProjectRunPerMonth: null,
		maxNumberOfProjects: null,
		maxNumberOfPlants: 2,
		maxNumberOfPlantRecognition: 2,
		expiry: 1,
	},
	{
		id: 5,
		maxNumberOfProjectRun: null,
		maxNumberOfProjectRunPerMonth: null,
		maxNumberOfProjects: null,
		maxNumberOfPlants: null,
		maxNumberOfPlantRecognition: null,
		expiry: 1000,
	},
];
