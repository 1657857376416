import React, { Suspense } from 'react';
import { useLoader } from 'react-three-fiber';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MeshBasicMaterial, DoubleSide } from 'three';
import type0 from '../../../assets/three/glb/0.glb';
import type1 from '../../../assets/three/glb/1.glb';
import type2 from '../../../assets/three/glb/2.glb';
import type3 from '../../../assets/three/glb/3.glb';
import type4 from '../../../assets/three/glb/4.glb';

function Tree(props) {
	const isActive = props.activeId === `trees_${props.id}`;

	const gltf = useLoader(GLTFLoader, { 0: type0, 1: type1, 2: type2, 3: type3, 4: type4 }[props.type]);

	const obj = gltf.scene.clone();

	if (isActive) {
		var newMaterial = new MeshBasicMaterial({
			color: 0x990011,
			opacity: 1,
			side: DoubleSide,
		});

		obj.traverse(o => {
			if (o.isMesh) o.material = newMaterial;
		});
	}

	return (
		<primitive
			onPointerDown={e => props.setActiveId(`trees_${props.id}`)}
			object={obj}
			position={props.position}
			scale={[props.scale, props.scale, props.scale]}
		/>
	);
}

function Box() {
	return (
		<mesh>
			<boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
			<meshStandardMaterial attach="material" transparent opacity={0.5} />
		</mesh>
	);
}

export default props => {
	return (
		<Suspense fallback={<Box />}>
			<Tree {...props} position={[props.position[0], 0, props.position[1]]} />
		</Suspense>
	);
};
