import React from 'react';
import Expans from './expansion-panel';
import { useTranslation } from 'react-i18next';
import icon from '../../../assets/images/icons/blocks.svg';
import Slider from '../../ui-elements/slider';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { map } from 'lodash';

const BlocksPanel = ({ blocks, setBlocks, handleChange, add, remove, width, height, depth }) => {
	const { t } = useTranslation();
	const globalStep = 0.01;

	return (
		<Expans title={t('blocks_label')} icon={icon} id="blocks">
			{/* <Button onClick={() => add('blocks', { width: 1, height: 1, distance: 1, position: [0, 0], face: "F" })}>{t("blocksPanel_add_block_label")}</Button> */}
			{map(blocks, (block, index) => (
				<Expans
					key={index}
					title={t('blocks_label') + index}
					icon={icon}
					id={'blocks_' + block.id}
					remove={() => remove('blocks', block.id)}
				>
					<FormControlLabel
						control={
							<Checkbox
								checked={block.top}
								color="primary"
								onChange={() => handleChange('blocks', index, 'top', !block.top)}
							/>
						}
						label="Horizontal Block"
					/>

					<Slider
						label={t('blocksPanel_height_label')}
						value={block.height}
						onChange={value => handleChange('blocks', index, 'height', value)}
						min={0.1}
						max={height * 10}
						step={globalStep}
					/>
					<Slider
						label={t('blocksPanel_start_x_label')}
						value={block.position1[0]}
						onChange={value => handleChange('blocks', index, 'position1', [value, block.position1[1]])}
						min={-100}
						max={100}
						step={globalStep}
					/>
					<Slider
						label={t('blocksPanel_start_y_label')}
						value={block.position1[1]}
						onChange={value => handleChange('blocks', index, 'position1', [block.position1[0], value])}
						min={-100}
						max={100}
						step={globalStep}
					/>
					<Slider
						label={t('blocksPanel_end_x_label')}
						value={block.position2[0]}
						onChange={value => handleChange('blocks', index, 'position2', [value, block.position2[1]])}
						min={-100}
						max={100}
						step={globalStep}
					/>
					<Slider
						label={t('blocksPanel_end_y_label')}
						value={block.position2[1]}
						onChange={value => handleChange('blocks', index, 'position2', [block.position2[0], value])}
						min={-100}
						max={100}
						step={globalStep}
					/>

					<Slider
						label={t('blocksPanel_angle_label')}
						value={block.incline}
						onChange={value => handleChange('blocks', index, 'incline', value)}
						min={-90}
						max={90}
						step={globalStep}
					/>
					{block.top ? (
						<Slider
							label={t('blocksPanel_angle_label')}
							value={block.incline2}
							onChange={value => handleChange('blocks', index, 'incline2', value)}
							min={-90}
							max={90}
							step={globalStep}
						/>
					) : (
						<></>
					)}
					<Slider
						label={t('blocksPanel_reflectivity_label')}
						value={block.reflectivity}
						onChange={value => handleChange('blocks', index, 'reflectivity', value)}
						min={0}
						max={1}
						step={0.01}
					/>
				</Expans>
			))}
		</Expans>
	);
};

export default BlocksPanel;
