import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import loading from '../../assets/images/reports/loading.gif';
import defaultContext from '../../defaultContext';
import { Redirect, useHistory } from 'react-router';
import { calculate, calculateOrchestrationStart, calculateOrchestrationStatus } from '../../services/projects';
import Report from '../ui-elements/report-page/report';
import { isEmpty, range, each, map, includes } from 'lodash';
import { notifyError } from '../../services/toast';
import { useParams } from 'react-router-dom';

const ReportPreLoad = () => {
	const { t } = useTranslation();
	const { currentSimulationName, currentSimulation, setCurrentSimulation, user, plants } = useContext(defaultContext);
	const history = useHistory();
	const [sensors, setSensors] = useState();
	const [distance, setDistance] = useState();
	const [station, setStation] = useState();
	const [status, setStatus] = useState();
	const [statusResult, setStatusResult] = useState();

	const { id } = useParams();

	const handleSimChange = useCallback(
		async sim => {
			try {
				const getResults = async () => {
					if (user && user.userName && user.tier !== 0) {
						const id = user.userName;
						const resultOrOrchestrationId = await calculateOrchestrationStart(id, sim);
						if (resultOrOrchestrationId && resultOrOrchestrationId.status === 'Completed') {
							return resultOrOrchestrationId.result;
						}
						//  eslint-disable-next-line
						for (let i of range(200)) {
							try {
								const response = await calculateOrchestrationStatus(id, resultOrOrchestrationId);
								if (response.status === 'Failed') {
									const e = response.result;
									if (includes(e, 'NoCredit|')) {
										history.push('/dashboard');
										const error = e.split('|');
										notifyError(t(error[1], { value: error[2] }));
										return;
									}
									throw new Error(e);
								}

								setStatus(response.status);
								if (response.status === 'Completed') {
									return response.result;
								}
								setStatusResult(response.result);

								await new Promise(res => setTimeout(res, 5000));
							} catch (e) {
								throw new Error(e);
							}
						}
						history.push('/dashboard');
						notifyError(t('error_no_calculation_received'));
					} else {
						const result = await calculate('.guest', currentSimulation);
						return result;
					}
				};
				const response = await getResults();
				if (!response) {
					return;
				}
				const ptsResult = response.pts;
				const dlisResult = response.dlis;
				const parsResult = response.pars;
				const illsResult = response.ills;
				setCurrentSimulation(response.project);
				setDistance(response.distance);
				setStation(response.station);
				let _sensors = {};
				let minx = Infinity,
					miny = Infinity,
					maxx = -Infinity,
					maxy = -Infinity;

				each(ptsResult, (sensor, index) => {
					sensor = sensor.map(f => parseFloat(f));
					let wpId = sensor.splice(0, 1)[0];
					let pos = sensor.splice(0, 3);
					minx = Math.min(pos[0], minx);
					miny = Math.min(pos[1], miny);
					maxx = Math.max(pos[0], maxx);
					maxy = Math.max(pos[1], maxy);
					let i = pos.join('|');
					_sensors[i] = _sensors[i] || {};
					_sensors[i].wpId = wpId;

					_sensors[i].position = pos;
					_sensors[i].normal = sensor.splice(0, 3).map(f => Math.round(f * 100) / 100);

					_sensors[i].rotation = [
						(-_sensors[i].normal[1] * Math.PI) / 2,
						(_sensors[i].normal[0] * Math.PI) / 2 +
							(_sensors[i].normal[2] !== 0 ? ((_sensors[i].normal[2] + 1) * Math.PI) / 2 : 0), //TODO what the hell is going on here? can't we come up with simpler conversion?
						0,
					];

					for (let win in dlisResult) {
						let dlis = dlisResult[win][index];

						_sensors[i].dlis = _sensors[i].dlis || {};
						_sensors[i].dlisAll = _sensors[i].dlisAll || [];

						_sensors[i].dlis[win] = dlis;
						_sensors[i].dlisAll = map(dlis, (f, ii) => f + (_sensors[i].dlisAll[ii] || 0));
					}
					if (!isEmpty(parsResult)) {
						for (let win in parsResult) {
							let pars = parsResult[win][index];

							_sensors[i].pars = _sensors[i].pars || {};
							_sensors[i].parsAll = _sensors[i].parsAll || [];

							_sensors[i].pars[win] = pars;
							_sensors[i].parsAll = map(pars, (f, ii) => f + (_sensors[i].parsAll[ii] || 0));
						}
					}
					if (!isEmpty(illsResult)) {
						for (let win in illsResult) {
							let ills = illsResult[win][index];

							_sensors[i].ills = _sensors[i].ills || {};
							_sensors[i].illsAll = _sensors[i].illsAll || [];

							_sensors[i].pars[win] = ills;
							_sensors[i].parsAll = map(ills, (f, ii) => f + (_sensors[i].illsAll[ii] || 0));
						}
					}
				});

				setSensors({ sensors: _sensors, gridCameraPosition: [(maxx - minx) / 2, (maxy - miny) / 2] });
				//setGridCameraPosition([(maxx - minx) / 2, (maxy - miny) / 2]);
			} catch (e) {
				console.error(e);
				history.push('/dashboard');
				notifyError(t('error_no_calculation_received'));
			}
		},
		[user, history, setCurrentSimulation, currentSimulation, t],
	);

	useEffect(() => {
		if (user && (id || currentSimulationName)) {
			handleSimChange(id ?? currentSimulationName);
		} else if (!user && currentSimulationName) {
			handleSimChange(currentSimulationName);
		}
		//  eslint-disable-next-line
	}, [user, currentSimulationName, id]);

	if (!currentSimulationName && !id) {
		return <Redirect push to="/dashboard" />;
	}

	if (!sensors || !currentSimulation) {
		return (
			<div className="full-screen" style={{ display: 'flex', justifyContent: 'center' }}>
				{' '}
				<img src={loading} style={{ height: '10rem', alignSelf: 'center' }} alt="loading report"></img>
				{status ? (
					<StatusDisplay id={id ?? currentSimulationName} status={status} statusResult={statusResult} />
				) : (
					<></>
				)}
			</div>
		);
	}
	return (
		<Report sensors={sensors} meta={{ distance, station }} currentSimulation={currentSimulation} plants={plants} />
	);
};

const StatusDisplay = ({ id, status, statusResult }) => {
	const { setProjects, projects } = useContext(defaultContext);

	useEffect(() => {
		if(projects){
			setProjects(projects.map(p => (p.id === id ? { ...p, status } : p)));
		}
	}, [projects, setProjects, id, status]);

	return (
		<div style={{ backgroundColor: 'grey', color: 'white', bottom: 0, position: 'fixed', width: '100%' }}>
			<p>
				{status} : {statusResult}
			</p>
		</div>
	);
};

export default ReportPreLoad;
