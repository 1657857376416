import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from './charts/grid';
import Bar from './charts/bar';
import Line from './charts/line';
import SpatialDliChart from './charts/spatialDliChart';
import { values, forEach, slice, isEmpty, debounce, find, map } from 'lodash';
import { NativeSelect, Slider, Select, MenuItem } from '@material-ui/core';
import { countOF } from './helpers';
import { ArtificialLight } from './ArtificialLight';
import { Shading } from './Shading';
import { ReportMeta } from './ReportMeta';
import { isMobile } from 'react-device-detect';

const ReportDetails = ({ sensors: { sensors, gridCameraPosition }, currentSimulation, setDetails, meta }) => {
	const { t } = useTranslation();

	const plants = currentSimulation.plants;
	const [sensor, setSensor] = useState(0);
	const [pickedSensor, setPickedSensor] = useState();
	const [plant, setPlant] = useState(!isEmpty(plants) ? plants[0] : null);
	const [dliType, setDliType] = useState(1);
	const [window, setWindow] = useState();
	const [rangeSliderValue, setRangeSliderValue] = useState([0, 365]);

	const pickSensor = useCallback(
		(dli, range) => {
			let sensorValues = {};
			let max = -Infinity;
			forEach(values(sensors), s => {
				const totalEnough = countOF(slice(window ? s.dlis[window] : s.dlisAll, range[0], range[1]), 1, dli);
				if (totalEnough >= max) {
					max = totalEnough;
					sensorValues = s;
				}
			});
			setPickedSensor(sensorValues);
		},
		[sensors, window],
	);

	useEffect(() => {
		if (!isEmpty(plants)) pickSensor(plants[0].dli, [0, 365]);
	}, [pickSensor, plants, window]);

	const handlePlantChange = async value => {
		const t = find(plants, p => p.id === value);
		setPlant(t);
		if (!isEmpty(t)) {
			pickSensor(t.dli, rangeSliderValue);
		}
	};

	const onRangeSliderChange = (event, newValue) => {
		setRangeSliderValue(newValue);
		debounce(() => pickSensor(plant.dli, newValue), 150);
	};

	if (!currentSimulation) {
		return <></>;
	}
	return (
		<div className="container-fluid">
			<div className="row mb-4">
				<div className="col-md-12">
					<ReportMeta currentSimulation={currentSimulation} meta={meta} />
					<button
						className="btn btn-dark"
						style={{ marginLeft: '2em', marginTop: '1em' }}
						onClick={() => setDetails(false)}
					>
						{t('report_simple_button_label')}
					</button>
				</div>
			</div>
			<div className="row mb-4 pt-3 pb-3" style={{ backgroundColor: '#eeeeee' }}>
				<div className="col-md-5" style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
					<div
						className="d-flex justify-content-center"
						style={{ width: 300, display: 'flex', flexDirection: 'column' }}
					>
						<p style={{ marginBottom: '0.5rem' }}>{t('selected_plant_label')}</p>
						<Select labelId="label" id="select" value={plant.id} onChange={e => handlePlantChange(e.target.value)}>
							{map(plants, p => (
								<MenuItem key={p.id} value={p.id}>
									{p.name}
								</MenuItem>
							))}
						</Select>
					</div>
				</div>
				<div className="col-md-2">
					<div className="d-flex justify-content-center">
						{false && currentSimulation.individualWin && !isEmpty(currentSimulation.windows) ? (
							<div className="form-select mt-1">
								<label htmlFor={'report-windows-name'}>{t('windows')}</label>
								<select
									style={{ width: '10em' }}
									className="custom-select ml-1"
									id="report-windows-name"
									onChange={e => {
										const val = parseInt(e.target.value);
										val === 0 ? setWindow() : setWindow(val - 1);
									}}
								>
									{map([{ id: 'all' }, ...currentSimulation.windows], (window, index) => (
										<option key={window.id} value={index} selected={window ? window + 1 : 0}>
											{t('window ' + window.id)}
										</option>
									))}
								</select>
							</div>
						) : (
							<></>
						)}
					</div>
				</div>
				<div className="col-md-5">
					<div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
						<p>{t('range_label')}</p>
						<Slider
							style={{ color: 'green' }}
							value={rangeSliderValue}
							onChange={onRangeSliderChange}
							max={365}
							min={0}
							valueLabelDisplay="auto"
							aria-labelledby="range-slider"
						/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-6 d-flex justify-content-center" style={{position:'relative'}}>
					<div
						style={{
							width: '100%',
							maxWidth: isMobile ? '85%' : '100%',
						}}
					>
						<div className="d-flex justify-content-center">
							<NativeSelect
								value={dliType}
								onChange={event => setDliType(parseInt(event.target.value))}
								inputProps={{
									name: 'dliType',
									id: 'dliType-select',
								}}
							>
								<option value={0}>{t('show_underlit_sensors_label')}</option>
								<option value={1}>{t('show_sensors_with_enough_light_label')}</option>
								<option value={2}>{t('show_overlit_sensors')}</option>
							</NativeSelect>
						</div>
						<Grid
							sensors={sensors}
							gridCameraPosition={gridCameraPosition}
							pickedSensor={pickedSensor}
							selectedSensor={sensor}
							currentSimulation={currentSimulation}
							onselect={sensor => setSensor(sensor)}
							plantDli={(plant || {}).dli}
							dliType={dliType}
							range={rangeSliderValue}
							style={{
								height: '450px',
								border: '1px solid',
							}}
							window={window}
						/>
					</div>
					<div style={{ position: 'absolute', right: '40px', top:'80px' }}>
						<SpatialDliChart
							range={rangeSliderValue}
							sensors={
								window
									? values(sensors).map(sensor => sensor.dlis[window])
									: values(sensors).map(sensor => sensor.dlisAll)
							}
							plantDli={plant.dli}
							dliType={dliType}
							chartLabel={t('spatial_dlia')}
						/>
					</div>
				</div>

				<div className="col-md-6 d-flex justify-content-center mt-4 mt-md-0">
					<Line
						series1={window ? (pickedSensor || {}).dlis[window] || [] : (pickedSensor || {}).dlisAll || []}
						label1="Optimal Point"
						series2={window ? (sensor || {}).dlis[window] || [] : (sensor || {}).dlisAll || []}
						label2="Selected Point"
						chartLabel={'Annual Daily Light Integral (DLI) Values'}
						yLabel="mol/m²day"
						dli={(plant || {}).dli}
						min={0}
						max={70}
						range={rangeSliderValue}
						style={{ width: '100%', height: '450px', maxWidth: isMobile ? '90%' : '100%' }}
					/>
				</div>
			</div>

			{(sensor || pickedSensor) && plant && plant.dli ? (
				<>
					<div className="row mt-5">
						<div className="col-md-6">
							{sensor ? (
								<Bar
									range={rangeSliderValue}
									sensor={window ? sensor.dlis[window] : sensor.dlisAll}
									plantDli={plant.dli}
									chartLabel={t('selected_point_label')}
								/>
							) : (
								<></>
							)}
						</div>
						<div className="col-md-6">
							<Bar
								range={rangeSliderValue}
								sensor={window ? pickedSensor.dlis[window] : pickedSensor.dlisAll}
								plantDli={plant.dli}
								chartLabel={t('optimal_point_label')}
							/>
						</div>
					</div>

					<ArtificialLight
						pickedSensor={pickedSensor}
						plant={plant}
						sensor={sensor}
						rangeSliderValue={rangeSliderValue}
						window={window}
					/>
					{!isEmpty((sensor || pickedSensor || {}).parsAll) ? (
						<Shading
							rangeSliderValue={rangeSliderValue}
							sensor={(sensor || {}).parsAll}
							pickedSensor={(pickedSensor || {}).parsAll}
							plant={plant}
						/>
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}
		</div>
	);
};

export default ReportDetails;
