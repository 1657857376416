import React from 'react';
import { useTranslation } from 'react-i18next';
import { TreesAddSection, TreesDetailsSection } from './trees-section-utils';
import { MapSection } from './map-section-utils';
import { LocationSection } from './location-section-utils';
import { WindowFaceSection, WindowSizeSection, WindowDetailsSection, WindowAddSection } from './windows-section-utils';
import { RoomSection, TemplateSpecificSection, FinishSection } from './room-section-utils';
import { WorkplaneAddSectionOne, WorkplaneAddSectionTwo, WorkplaneDetailsSection } from './workplane-section-utils';
import { BlockAddSectionOne, BlockAddSectionTwo, BlocksSizeSection, BlocksDetailsSection } from './block-section-utils';
import {
	BlocksTopAddSectionOne,
	BlocksTopAddSectionTwo,
	BlocksTopSizeSection,
	BlocksTopDetailsSection,
} from './top-block-section-utils';
import { TemplateSection } from './template-section-utils';
import Stepper from './stepper';
import { startsWith } from 'lodash';
import addHorizontalWorkplaneInRoom from '../../../assets/images/helpGif/addHorizontalWorkplaneInRoom.gif';
import addTree from '../../../assets/images/helpGif/addTree.gif';
import addVerticalBlock from '../../../assets/images/helpGif/addVerticalBlock.gif';
import addWindow from '../../../assets/images/helpGif/addWindow.gif';

const styles = {
	gif: {
		width: '100%',
	},
};

export const CurrentSection = ({ setStep, hasRoom, id }) => {
	const { t } = useTranslation();
	return (
		<Stepper
			setStep={setStep}
			allSteps={[
				{
					id: 'template',
					component: <TemplateSection />,
					index: 0,
					disableRedraw: () => true,
					main: true,
					label: t('template_label'),
					active: true,
					nextStepIdFunc: () => 1,
				},
				{
					id: 'map',
					component: <MapSection />,
					index: 1,
					disableRedraw: () => true,
					main: true,
					label: t('location_label'),
					active: true,
					nextStepIdFunc: () => 2,
				},

				{
					id: 'location',
					index: 2,
					main: true,
					disableRedraw: () => true,
					component: <LocationSection />,
					label: t('orientation_label'),
					active: true,
					nextStepIdFunc: () => 3,
				},
				{
					id: 'room',
					index: 3,
					main: true,
					disableRedraw: () => true,
					component: <RoomSection />,
					label: t('room_label_wizard'),
					active: true,
					nextStepIdFunc: p => (p.templateInputs ? 4 : p.hasRoom && p.windows.length < 5 ? 5 : 11),
				},
				{
					id: 'template-input',
					index: 4,
					main: false,
					component: <TemplateSpecificSection />,
					disableRedraw: () => true,
					active: true,
					nextStepIdFunc: p => (p.hasRoom && p.windows.length < 5 ? 5 : 11),
				},
				{
					id: 'window-add',
					category: 'windows',
					index: 5,
					main: false,
					next: t('add_button'),
					component: <WindowAddSection />,
					label: t('windows_label'),
					nextStepIdFunc: () => 6,
					skipStepIdFunc: () => 11,
					disableRedraw: p => p.windows.length < 1,
					active: true,
					hasCustomNextFunc: true,
					help: (
						<>
							<img src={addWindow} style={styles.gif} alt={t('add_window_gif')}></img>
						</>
					),
				},
				{
					id: 'window-face',
					category: 'windows',
					index: 6,
					label: t('windows_label'),
					main: true,
					component: <WindowFaceSection />,
					active: true,
					nextStepIdFunc: () => 7,
				},
				{
					id: 'window-size',
					category: 'windows',
					index: 7,
					label: t('windows_label'),
					main: false,
					component: <WindowSizeSection />,
					active: true,
					nextStepIdFunc: () => 8,
				},
				{
					id: 'window-details',
					category: 'windows',
					index: 8,
					label: t('windows_label'),
					main: false,
					component: <WindowDetailsSection />,
					active: true,
					nextStepIdFunc: () => 11,
				},
				{
					id: 'workplane-add-1',
					category: 'workplane',
					index: 9,
					main: false,
					component: <WorkplaneAddSectionOne />,
					label: t('workplane_label'),
					disableNext: true,
					active: true,
					disableRedraw: p => p.workPlanes.length < (p.templateInputs ? 2 : 1),
					nextStepIdFunc: () => 10,
					help: (
						<>
							<img src={addHorizontalWorkplaneInRoom} style={styles.gif} alt={t('add_horizontal_workplane_gif')}></img>
						</>
					),
				},
				{
					id: 'workplane-add-2',
					category: 'workplane',
					index: 10,
					label: t('workplane_label'),
					main: false,
					component: <WorkplaneAddSectionTwo />,
					disableRedraw: () => true,
					disableNext: true,
					active: true,
					nextStepIdFunc: () => 11,
				},
				{
					id: 'workplane-details',
					category: 'workplane',
					index: 11,
					label: t('workplane_label'),
					main: true,
					component: <WorkplaneDetailsSection />,
					nextStepIdFunc: () => 12,
					active: true,
					disableRedraw: p => p.workPlanes.length < (p.templateInputs ? 2 : 1),
				},
				{
					id: 'blocks-add-1',
					category: 'blocks',
					index: 12,
					main: false,
					component: <BlockAddSectionOne />,
					label: t('blocks_label'),
					disableNext: true,
					disableRedraw: p => p.blocks.filter(f => !f.top).length < 1,
					active: true,
					nextStepIdFunc: () => 13,
					skipStepIdFunc: () => 22, //directly to finish
					help: (
						<>
							<img src={addVerticalBlock} style={styles.gif} alt={t('add_vertical_block_gif')}></img>
						</>
					),
				},
				{
					id: 'blocks-add-2',
					category: 'blocks',
					index: 13,
					main: false,
					component: <BlockAddSectionTwo />,
					label: t('blocks_label'),
					disableRedraw: () => true,
					disableNext: true,
					active: true,
					nextStepIdFunc: () => 14,
				},
				{
					id: 'blocks-size',
					category: 'blocks',
					index: 14,
					label: t('blocks_label'),
					main: true,
					component: <BlocksSizeSection />,
					active: true,
					nextStepIdFunc: () => 15,
				},
				{
					id: 'blocks-details',
					category: 'blocks',
					index: 15,
					label: t('blocks_label'),
					main: false,
					component: <BlocksDetailsSection />,
					active: true,
					nextStepIdFunc: () => 22, //directly to finish
				},
				{
					id: 'blocks-top-add-1',
					category: 'blocks-top',
					index: 16,
					main: true,
					disableRedraw: p => p.blocks.filter(f => f.top).length < 1,
					component: <BlocksTopAddSectionOne />,
					label: t('horizontal_blocks_label'),
					disableNext: true,
					active: false,
					nextStepIdFunc: () => 17,
				},
				{
					id: 'blocks-top-add-2',
					category: 'blocks-top',
					index: 17,
					main: false,
					label: t('horizontal_blocks_label'),
					component: <BlocksTopAddSectionTwo />,
					disableRedraw: () => true,
					disableNext: true,
					active: false,
					nextStepIdFunc: () => 18,
				},
				{
					id: 'blocks-top-size',
					category: 'blocks-top',
					index: 18,
					main: false,
					label: t('horizontal_blocks_label'),
					component: <BlocksTopSizeSection />,
					active: false,
					nextStepIdFunc: () => 19,
				},
				{
					id: 'blocks-top-details',
					category: 'blocks-top',
					index: 19,
					main: false,
					label: t('horizontal_blocks_label'),
					component: <BlocksTopDetailsSection />,
					active: false,
					nextStepIdFunc: () => 20,
				},
				{
					id: 'trees-add',
					category: 'trees',
					index: 20,
					main: true,
					disableRedraw: p => p.trees.length < 1,
					component: <TreesAddSection />,
					label: t('trees_label'),
					disableNext: true,
					active: false,
					nextStepIdFunc: () => 21,
					help: (
						<>
							<img src={addTree} style={styles.gif} alt={t('add_tree_gif')}></img>
						</>
					),
				},
				{
					id: 'trees-details',
					category: 'trees',
					index: 21,
					main: false,
					label: t('trees_label'),
					active: true,
					component: <TreesDetailsSection />,
					nextStepIdFunc: () => 22,
				},
				{ id: 'finish', index: 22, main: true, component: <FinishSection />, active: true, label: t('finish_label') },
			].filter(f => (hasRoom && id !== 'greenhouse' ? true : !startsWith(f.id, 'window-')))}
		></Stepper>
	);
};
