import React from 'react';
import { TextField } from '@material-ui/core';

const TextFieldTypography = ({ label, value, onChange }) => {
	const handleChange = value => {
		value = parseFloat(value) || 0;
		return value;
	};
	return (
		<TextField
			value={value}
			style={{ marginBottom: '1em', marginLeft: '0.5em' }}
			onChange={event => onChange(handleChange(event.target.value))}
			label={label}
		/>
	);
};

export default TextFieldTypography;
