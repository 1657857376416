import { isEmpty, max, map, reduce, values, findIndex, findLastIndex, slice, keys, replace } from 'lodash';

export const runTemplateModifications = currentSimulation => {
	if (currentSimulation.func) {
		//  eslint-disable-next-line
		const hydrated = eval(
			`(r)=>{
				let room = _.cloneDeep(r); 
				
				const get=(key,i)=>(room[key]||[]).find(f=>f.id===i)||{}; 
				
				try{${currentSimulation.func}}
				catch(e){console.error(e)} 
				return room
			}`,
		)(currentSimulation);

		return hydrated;
	} else {
		return currentSimulation;
	}
};

export const updateCurrentSimulationBase = (keyValues, currentSimulation, setCurrentSimulation) => {
	let updatedCurrentSimulation = {
		...currentSimulation,
		...keyValues,
	};
	updatedCurrentSimulation = {
		...updatedCurrentSimulation,
		windows: updatedCurrentSimulation.hasRoom ? updatedCurrentSimulation.windows : [],
	};
	const hydrated = runTemplateModifications(updatedCurrentSimulation);
	setCurrentSimulation(hydrated);
};

export const handleArrayEntityChangeBase = (
	arrayEntityName,
	index,
	prop,
	value,
	currentSimulation,
	setCurrentSimulation,
) => {
	const arrayEntities = currentSimulation[arrayEntityName];
	updateCurrentSimulationBase(
		{
			[arrayEntityName]: arrayEntities.map((arrayEntity, i) =>
				index !== i ? arrayEntity : { ...arrayEntity, [prop]: value },
			),
		},
		currentSimulation,
		setCurrentSimulation,
	);
};

export const handleAddEntityBase = (arrayEntityName, value, currentSimulation, setCurrentSimulation) => {
	const arrayEntities = currentSimulation[arrayEntityName];
	const addedId = isEmpty(arrayEntities) ? 0 : max(map(arrayEntities, f => f.id)) + 1;
	updateCurrentSimulationBase(
		{ [arrayEntityName]: [...arrayEntities, { ...value, id: addedId }] },
		currentSimulation,
		setCurrentSimulation,
	);
	return addedId;
};

export const handleRemoveEntityBase = (arrayEntityName, id, currentSimulation, setCurrentSimulation) => {
	const arrayEntities = currentSimulation[arrayEntityName];
	updateCurrentSimulationBase(
		{ [arrayEntityName]: arrayEntities.filter(entity => entity.id !== id) },
		currentSimulation,
		setCurrentSimulation,
	);
};

export const getXY = (x, y, rot) => {
	return [
		x * Math.cos((rot * Math.PI) / 180) + y * Math.sin((rot * Math.PI) / 180),
		y * Math.cos((rot * Math.PI) / 180) - x * Math.sin((rot * Math.PI) / 180),
	];
};

const months = {
	Jan: 31,
	Feb: 28,
	Mar: 31,
	Apr: 30,
	May: 31,
	Jun: 30,
	Jul: 31,
	Aug: 31,
	Sep: 30,
	Oct: 31,
	Nov: 30,
	Dec: 31,
};

export const getMonthNames = ([start, end]) => {
	start = start || 0;
	end = end || 365;
	const accumulativeDays = reduce(values(months), (a, d, i) => [...a, (i > 1 ? a[i - 1] : 0) + d], []);
	const indexStart = findIndex(accumulativeDays, f => f >= start);
	const indexEnd = findLastIndex(accumulativeDays, f => f < end) + 1;
	return slice(keys(months), indexStart, indexEnd);
};

export const getMonthDays = ([start, end]) => {
	start = start || 0;
	end = end || 365;
	const accumulativeDays = reduce(values(months), (a, d, i) => [...a, (i > 1 ? a[i - 1] : 0) + d], []);
	const indexStart = findIndex(accumulativeDays, f => f >= start);
	const indexEnd = findLastIndex(accumulativeDays, f => f < end) + 1;
	let result = slice(values(months), indexStart, indexEnd);
	result[0] = accumulativeDays[indexStart] - start;
	result[result.length] = end - accumulativeDays[indexEnd - 1];
	return result;
};

export function rgbf(r, g, b) {
	return {
		red: r,
		green: g,
		blue: b,
	};
}
export function colorGradient(fadeFraction, rgbColor1, rgbColor2, rgbColor3) {
	var color1 = rgbColor1;
	var color2 = rgbColor2;
	var fade = fadeFraction;

	if (rgbColor3) {
		fade = fade * 2;

		if (fade >= 1) {
			fade -= 1;
			color1 = rgbColor2;
			color2 = rgbColor3;
		}
	}

	var diffRed = color2.red - color1.red;
	var diffGreen = color2.green - color1.green;
	var diffBlue = color2.blue - color1.blue;

	var gradient = {
		red: parseInt(Math.floor(color1.red + diffRed * fade), 10),
		green: parseInt(Math.floor(color1.green + diffGreen * fade), 10),
		blue: parseInt(Math.floor(color1.blue + diffBlue * fade), 10),
	};

	return 'rgb(' + gradient.red + ',' + gradient.green + ',' + gradient.blue + ')';
}

export function latLongToMeters(lat1, lon1, lat2, lon2) {
	const R = 6371e3; // metres
	const φ1 = (lat1 * Math.PI) / 180; // φ, λ in radians
	const φ2 = (lat2 * Math.PI) / 180;
	const Δφ = ((lat2 - lat1) * Math.PI) / 180;
	const Δλ = ((lon2 - lon1) * Math.PI) / 180;

	const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) + Math.cos(φ1) * Math.cos(φ2) * Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
	const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

	const d = R * c; // in metres
	return d;
}

export function rotate(arr, rad, axis) {
	const degree = (rad * Math.PI) / 180;
	const rotationMatrix = [
		[1, 0, 0],
		[0, 1, 0],
		[0, 0, 1],
	];
	rotationMatrix[(axis + 1) % 3][(axis + 1) % 3] = Math.cos(degree);
	rotationMatrix[(axis + 1) % 3][(axis + 2) % 3] = -Math.sin(degree);
	rotationMatrix[(axis + 2) % 3][(axis + 1) % 3] = Math.sin(degree);
	rotationMatrix[(axis + 2) % 3][(axis + 2) % 3] = Math.cos(degree);
	let res = [0, 0, 0];
	for (let i = 0; i < 3; i++) {
		for (let j = 0; j < 3; j++) {
			res[i] += arr[j] * rotationMatrix[j][i];
		}
	}
	return res;
}

export function validateEmailFormat(str) {
	const regex = new RegExp(
		//  eslint-disable-next-line
		/(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
	);
	return regex.test(str);
}

export function correctProjectName(str) {
	//  eslint-disable-next-line
	const regex = new RegExp(/[^a-zA-Z0-9-_\ \.]*/g);
	return replace(str, regex, '');
}
