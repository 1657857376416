import React from 'react';
import toast from 'toasted-notes';

export const notify = str => {
	toast.notify(
		() => {
			return (
				<p style={{ color: 'white', backgroundColor: 'orange', padding: '1rem 2rem', borderRadius: '5px' }}>{str}</p>
			);
		},
		{
			position: 'bottom',
		},
	);
};

export const notifyError = str => {
	toast.notify(
		() => {
			return <p style={{ color: 'white', backgroundColor: 'red', padding: '1rem 2rem', borderRadius: '5px' }}>{str}</p>;
		},
		{
			position: 'bottom',
		},
	);
};
