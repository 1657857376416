import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Card, CardMedia, CardContent, CardActions, Checkbox, FormControlLabel } from '@material-ui/core';
import { usePricingFeatures } from '../../../hooks/pricingFeatures';

const PricingFeatures = () => {
	const priceFeaturesContent = usePricingFeatures();

	const [selected, setSelected] = useState(2);
	const [support, setSupport] = useState(false);
	const [notification, setNotification] = useState(false);
	const [workshop, setWorkshop] = useState(false);
	const [contact, SetContact] = useState(false);
	const selectedCard = () => priceFeaturesContent.find(f => f.id === selected);

	useEffect(() => {
		SetContact(support || notification || workshop);
	}, [support, notification, workshop]);

	const handleSupportClicked = event => {
		setSupport(!support);
	};

	const handleCardClicked = id => {
		if (id !== selected) {
			setSupport(false);
		}
		setSelected(id);
	};
	return (
		<div className="row mt-5">
			<div className="col-6">
				<div className="container-fluid">
					<div className="row">
						{priceFeaturesContent.map(c => {
							return (
								<Card
									key={c.id}
									onClick={() => handleCardClicked(c.id)}
									style={{
										width: '100%',
										margin: c.id === selected ? '1rem' : '0.5rem',
										cursor: 'pointer',
									}}
								>
									{/* <CardMedia component="img" image={c.image} height="250" /> */}
									<CardContent>
										<h3>{c.title}</h3>

										<p style={{ whiteSpace: 'pre-line' }}>{c.id === selected ? c.description : <></>}</p>
									</CardContent>
									{c.id === selected ? (
										<CardActions>
											<Link
												className="btn btn-dark-outline mr-2"
												role="button"
												to={contact ? '/contact' : '/login'}
												style={{ color: '#005c12' }}
											>
												{contact ? 'Contact Us' : 'Sign Up and Start!'}
											</Link>
											{/* <Link size="small">
												<button className="btn btn">{support ? 'Contact Us' : 'Sign Up and Start!'}</button>
											</Link> */}
										</CardActions>
									) : (
										<></>
									)}
								</Card>
							);
						})}
					</div>
				</div>
			</div>
			<div className="col-6">
				<div className="container-fluid">
					<Card style={{ width: '100%', margin: '0.5rem', display: 'flex', flexDirection: 'column' }}>
						<CardMedia component="img" image={selectedCard().image} style={{ width: '50%', alignSelf: 'center' }} />
						<CardContent>
							{selectedCard().id === 0 ? (
								<></>
							) : (
								<>
									{contact ? (
										<h3>Contact Us</h3>
									) : (
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											<div style={{ display: 'flex', flexDirection: 'column' }}>
												<h3
													style={{
														marginRight: '1rem',
														color: '#bbbbbb',
														textDecoration: 'line-through',
														marginLeft: '1rem',
													}}
												>
													{selectedCard().price}
												</h3>
												<h6
													style={{
														marginRight: '1rem',
														color: '#bbbbbb',
														textDecoration: 'line-through',
														marginLeft: '1rem',
													}}
												>
													{selectedCard().priceYearly}
												</h6>
											</div>
											<h3 style={{ marginRight: '1rem', color: '#005c12' }}>Free for early access!</h3>
										</div>
									)}
								</>
							)}
							<div style={{ display: 'flex', flexDirection: 'column' }}>
								{selectedCard().id === 2 || selectedCard().id === 3 ? (
									<FormControlLabel
										control={<Checkbox onChange={handleSupportClicked} checked={support} />}
										label="Support Included"
									/>
								) : (
									<></>
								)}
								{selectedCard().id === 3 ? (
									<>
										<FormControlLabel
											control={<Checkbox onChange={() => setNotification(!notification)} checked={notification} />}
											label="IoT Notification Included"
										/>
										<FormControlLabel
											control={<Checkbox onChange={() => setWorkshop(!workshop)} checked={workshop} />}
											label="Workshop Included"
										/>
									</>
								) : (
									<></>
								)}
								<ul>
									{selectedCard().features.map(f => (
										<li variant="body2" color="text.secondary" style={{ margin: '0.5rem' }}>
											{f}
										</li>
									))}
								</ul>
							</div>
						</CardContent>
					</Card>
				</div>
			</div>
		</div>
	);
};

export default PricingFeatures;
