export default [
	['Adiantum fragrans', 'Maidenhair Fern', 3750120],
	['Aechmea', '', 2697812],
	['Aeschynanthus', 'Lipstick Plant', 6365037],
	['Aeschynanthus longicaulis', 'Zebra Basket Vine', 3825597],
	['Aeschynanthus radicans', 'Lipstick plant Mona Lisa', 3824774],
	['Agapetes serpens', '', 4175061],
	['Aglaonema commutatum', 'Chinese evergreen', 2866467],
	['Aglaonema modestum', ' Variegatum', 8562689],
	['Aglaonema pictum', '', 2866460],
	['Alocasia cuprea', 'Mirror Plant or Jewel Alocasia', 5329958],
	['Aloe Vera', 'Aloe Vera', 2777724],
	['Amydrium zippelianum', '', 2870249],
	['Ananas', '', 2699430],
	['Anthurium', '', 2872218],
	['Anthurium andraeanum', ' Rainbow Champion', 7768208],
	['Anthurium bonplandii', '', 2872628],
	['Anthurium coriaceum', '', 2872307],
	['Anthurium friedrichsthalii', '', 2872380],
	['Anthurium pedatoradiatum', '', 2873561],
	['Anthurium plowmanii', 'Fruffles', 2873240],
	['Anthurium polyschistum', '', 2873067],
	['Anthurium schlechtendalii', '', 2872288],
	['Araucaria cunninghamii', 'Hoop Pine Bonsai', 7847859],
	['Araucaria heterophylla', 'Norfolk Island Pine', 2684969],
	['Ardisia crenata', ' Coral berry', 7726689],
	['Aristolochia leuconeura', ' Pipe Flower', 3589506],
	['Aspidistra elatior', ' Milky Way Cast Iron Plant', 2769937],
	['Asparagus retrofractus', ' Asparagus fern', 8192491],
	['Asparagus setaceus', '', 2768686],
	['Asplenium', 'Birds Nest Fern  Leslie', 2650583],
	['Asplenium antiquum', 'Japanese Birds Nest Fern', 4257299],
	['Asplenium nidus', '', 7889959],
	['Asplenium kenzoi', 'Asplenium x kenzoi', 4257884],
	['Astrophytum myriostigma', ' var. Tricostatum', 7282451],
	['Beaucarnea recurvata', 'Pony Tail Palm', 2768035],
	['Begonia acetosa', 'Begonia', 4157591],
	['Begonia dregei', ' Tweedle Dee', 4157290],
	['Begonia grisea', '', 4157680],
	['Begonia incarnata', ' B. metallica', 8254689],
	['Begonia lanceolata', '', 4157822],
	['Begonia maculata', 'Spotted begonia  Wightii', 7303475],
	['Begonia masoniana', ' Iron Cross Begonia', 8423065],
	['Begonia ulmifolia', '', 2874713],
	['Begonia venosa', '', 4157897],
	['Bertolonia nymphaeifolia', '', 5600649],
	['Bowiea volubilis', ' Blooming Onion', 2768051],
	['Bryophyllum daigremontianum', 'Mother of Thousands', 5362080],
	['Calathea lancifolia', 'Rattlesnake plant', 2761750],
	['Calathea orbifolia', 'Peacock Plant', 2761903],
	['Calathea roseopicta', 'Rose painted Calathea', 2761756],
	['Calathea rufibarba', 'Velvet Calathea', 2761622],
	['Calathea sanderiana', 'Pin Stripe Calathea', 2761575],
	['Callisia navicularis', '', 5303419],
	['Caputia', ' medley-woodii', 9376781],
	['Caputia scaposa', '', 9288299],
	['Caputia tomentosa', 'Cocoon Plant', 9390174],
	['Ceiba speciosa', ' Silk floss tree', 8688929],
	['Ceodes umbellifera', '', 3086267],
	['Cereus forbesii', ' Spiralis', 9298301],
	['Ceropegia rothii', 'Ceropegia x rothii', 3573589],
	['Ceropegia africana', '', 7150893],
	['Ceropegia ampliata', '', 7150889],
	['Ceropegia crassifolia', '', 3575744],
	['Ceropegia linearis', 'String of Hearts', 7150667],
	['Ceropegia sandersonii', '', 7837855],
	['Ceropegia simoneae', '', 3573358],
	['Ceropegia woodii', 'String of Hearts', 3572767],
	['chamaedorea cataractarum', 'Cat Palm', 9096139],
	['Chamaedorea elegans', 'Parlour palm', 2734661],
	['Chamaedorea metallica', 'Metallic Palm', 7627648],
	['Chamaedorea seifrizii', 'Bamboo Palm  - broadleaf lady palm - Rhapis excelsa', 2734708],
	['Chlorophytum', '', 9388590],
	['Chlorophytum comosum', 'Spider Plant', 7742047],
	['Chrysalidocarpus lutescens', 'Areca Palm', 2735936],
	['Cissus quadrangularis', '', 9041729],
	['Cissus rhombifolia', 'Grape Ivy', 3039161],
	['Clivia miniata', '', 7388745],
	['Clusia lanceolata', '', 7330314],
	['Cnidoscolus aconitifolius', 'Chaya', 8966977],
	['Codiaeum', '', 3071464],
	['Codiaeum variegatum', 'Croton Petra', 3071476],
	['Copiapoa hypogaea', '', 7284202],
	['Crassothonna capensis', 'String of Pickles Ruby Necklace', 9476539],
	['Crassula', '', 2985823],
	['Crassula cotyledonis', '', 5688998],
	['Crassula mesembryanthemoides', '', 7334026],
	['Crassula minor', 'Mini Jade Plant', 5688061],
	['Crassula muscosa', 'Watch Chain Crassula', 7396425],
	['Crassula ovata', 'Golden Jade tree Hummels Sunset', 5362063],
	['Crassula ovata', ' Crosbys Compact Jade Plant', 5362063],
	['Crassula perforata', ' String of buttons', 7883418],
	['Crassula rupestris', ' subsp. Marnieriana', 7557934],
	['Crassula socialis', '', 5689989],
	['Crassula', ' sp. Buddhass Temple', 2985823],
	['Crassula tecta', ' Lizard Skin Crassula', 5689795],
	['Crassula tetragona', ' Miniature Pine Tree', 7172747],
	['Ctenanthe', 'fishbone prayer plant', 9747580],
	['Ctenanthe lubbersiana', ' Golden Mosaic Plant', 2762435],
	['Curio herreanus', ' String of Tears', 3149664],
	['Curio radicans', ' String of bananas', 3107396],
	['Curio rowleyanus', ' String of Pearls', 3149649],
	['Cyanotis beddomei', ' Teddy Bear Vine', 5303397],
	['Cycas revoluta', ' Sago Palm', 2683217],
	['Cynanchum marnierianum', 'Bundle of sticks', 3577734],
	['Cyphostemma pachypus', '', 4053919],
	['Davallia canariensis', 'Hares Foot Fern', 4204692],
	['Davallia griffithiana', ' Rabbits Foot Fern', 7911152],
	['Desmidorchis retrospiciens', '', 3571921],
	['Dieffenbachia', 'Dumb Cane', 2869330],
	['Dieffenbachia oerstedii', ' Dumb cane', 2869429],
	['Dieffenbachia', ' sp. Reflector', 2869330],
	['Dioscorea elephantipes', '', 2755523],
	['Dischidia hirsuta', '', 3582490],
	['Dischidia imbricata', '', 3582441],
	['Dischidia nummularia', 'String of nickels', 7439694],
	['Dischidia nummularia', ' String of Nickles', 7439694],
	['Dischidia nummularia', ' Ideaminubu', 7439694],
	['Dischidia ovata', ' Watermelon Dischidia', 3582092],
	['Dischidia ruscifolia', '', 8464824],
	['Dischidia', ' sp. Geri', 7150312],
	['Dracaena', '', 2752062],
	['Dracaena compacta', 'Compact Dragon tree', 8152409],
	['Dracaena deremensis', 'Dragon Tree Janet Craig', 7925681],
	['Dracaena fragrans', 'fragrans Cintho', 5304583],
	['Dracaena fragrans', ' Rikki', 5304583],
	['Dracaena marginata', 'Madagascar Dragon Tree', 7592779],
	['Dracaena Massangeana', '', 8547612],
	['Dracaena reflexa', 'Pleomele Anita', 5304436],
	['Drymonia chiribogana', '', 5591196],
	['Echeveria', 'Mexican Hens and Chicks', 5362052],
	['Echeveria agavoides', ' f. Cristata', 7333993],
	['Edithcolea grandis', ' Persian Carpet Flower', 3577469],
	['Elaphoglossum metallicum', '', 4012304],
	['Epiphyllum anguliger', 'Fishbone cactus', 7673570],
	['Epiphyllum oxypetalum', ' Queen-of-the-Night Cactus', 5384031],
	['Epipremnum aureum', 'Devils Ivy', 2868323],
	['Epipremnum aureum', 'Devils Ivy / Pothos', 2868323],
	['Epipremnum pinnatum', '', 2868272],
	['Episcia cupreata', ' Flame Flower', 3172881],
	['Episcia', ' sp. Silver Skies', 3172880],
	['Espostoa melanostele', '', 7984839],
	['Eulophia petersii', '', 5311779],
	['Euphorbia ambovombensis', '', 3065816],
	['Euphorbia bongolavensis', '', 3064981],
	['Euphorbia bupleurifolia', '', 3065392],
	['Euphorbia cylindrifolia', '', 3070377],
	['Euphorbia labatii', ' Red Leaf', 3070043],
	['Euphorbia Lactea', 'mottled spurge', 7739152],
	['Euphorbia milii', ' Crown of Thorns', 7713085],
	['Euphorbia neorubella', ' Red Monadenium', 3069578],
	['Euphorbia obesa', ' hyb. Baseball Plant', 4930719],
	['Euphorbia platyclada', '', 3068268],
	['Euphorbia polygona', ' African Milk Barrel', 3065721],
	['Euphorbia spiralis', ' Spiraling Euphorbia', 3065802],
	['Euphorbia tirucalli', ' Pencil cactus', 8130960],
	['Euphorbia tithymaloides', ' Devils Backbone', 3069168],
	['Euphorbia xylophylloides', '', 3063852],
	['Faucaria tigrina', ' Tiger Jaws', 3703460],
	['Fenestraria rhopalophylla', ' Baby Toes Plant', 7329187],
	['Ficus benghalensis', 'Bengal Fig', 5361919],
	['Ficus benjamina', 'Weeping fig', 5361932],
	['Ficus binnendijkii', 'Narrow Leaf Fig', 7262699],
	['Ficus cyathistipula', 'African fig tree', 7262354],
	['Ficus deltoidea', ' Mistletoe Fig', 7262889],
	['Ficus elastica', 'Rubber fig', 7835333],
	['Ficus elastica', ' Variegata Rubber Tree', 7835333],
	['Ficus lyrata', 'Fiddle leaf fig', 5361899],
	['Ficus lyrata', 'Fiddle leaf Fig', 5361899],
	['Ficus microcarpa', 'Chinese Banyan', 7551458],
	['Ficus nitida', 'Indian laurel', 5361933],
	['Ficus triangularis', 'Triangle Fig', 5361922],
	['Fittonia albivenis', ' Nerve Plant', 5574508],
	['Gasteria brachyphylla', ' var. bayeri', 2778670],
	['Gasteria glomerata', ' Ox Tongue', 2778566],
	['Gasteria rawlinsonii', '', 2778666],
	['Gerrardanthus macrorhizus', '', 8638005],
	['Gibbaeum dispar', '', 3708649],
	['Gloriosa rothschildiana', 'Flame Lily', 2740054],
	['Goeppertia warszewiczii', '', 9467672],
	['Goeppertia elliptica', ' Vittata', 7473602],
	['Calathea', ' Rattlesnake Calathea', 8394666],
	['Goeppertia orbifolia', ' Calathea orbifolia', 7476815],
	['Grusonia clavata', ' Club cholla', 3956653],
	['Guzmania', ' Bromeliaceae', 2698853],
	['Gymnocalycium', '', 8421939],
	['Gymnocalycium mihanovichii', 'v. friedrichii Gymnocalycium stenopleurum', 7282762],
	['Gymnocalycium platense', '', 7282503],
	['Gymnocalycium schroederianum', '', 7282283],
	['Haworthia cooperi', ' var. truncata', 2780103],
	['Haworthia truncata', ' var. truncata', 2779989],
	['Haworthiopsis glauca', '', 9620716],
	['Haworthiopsis limifolia', ' Variegata', 9509404],
	['Hedera helix', ' Variegata English Ivy', 8351737],
	['Hibiscus', 'Rose of China', 3152542],
	['Homalomena', ' Adelonema sp. Selby', 2868531],
	['Hoodia gordonii', '', 5525637],
	['Howea forsteriana', 'Kentia Palm', 7857942],
	['Hoya affinis', '', 3573982],
	['Hoya australis', '', 7150750],
	['Hoya bella', '', 3575282],
	['Hoya brevialata', '', 3575198],
	['Hoya calycina', '', 7312041],
	['Hoya carnosa', 'Wax Flower', 8658195],
	['Hoya carnosa', ' Wax Plant', 8658195],
	['Hoya carnosa', ' Compacta Hindu rope', 8658195],
	['Hoya caudata', '', 3575051],
	['Hoya crassicaulis', '', 3574816],
	['Hoya cumingiana', '', 3574778],
	['Hoya curtisii', ' Tiny-leaf porcelain flower', 3574763],
	['Hoya densifolia', '', 3574702],
	['Hoya diptera', '', 3574660],
	['Hoya fitchii', '', 8151286],
	['Hoya imperialis', '', 3574085],
	['Hoya kentiana', '', 3573967],
	['Hoya kerrii', ' Sweetheart Hoya', 3573959],
	['Hoya krohniana', ' Eskimo', 7356720],
	['Hoya lacunosa', '', 3573874],
	['Hoya linearis', '', 3573613],
	['Hoya macrophylla', ' Variegata', 7451212],
	['Hoya multiflora', ' Shooting star hoya', 3573230],
	['Hoya nummularioides', '', 3573122],
	['Hoya obovata', '', 3573084],
	['Hoya odorata', '', 3573019],
	['Hoya pachyclada', '', 3572856],
	['Hoya pubicalyx', ' Pink Silver', 3572445],
	['Hoya retusa', ' Grass-leaved Hoya', 3572304],
	['Hoya revolubilis', '', 3572299],
	['Hoya rotundiflora', '', 8125423],
	['Hoya surigaoensis', '', 8058265],
	['Hoya wayetii', ' Variegata', 3571594],
	['Hypoestes phyllostachya', ' Polka Dot Plant', 3173196],
	['Ischnolepis natalensis', ' Petopentia natalensis', 7840494],
	['Kalanchoe', '', 2985928],
	['Kalanchoe beauverdii', '', 2985929],
	['Kalanchoe fedtschenkoi', '', 2985934],
	['Kalanchoe marnieriana', '', 4199416],
	['Kalanchoe orgyalis', ' Copper spoon Kalanchoe', 4199216],
	['Kalanchoe pumila', ' Flower dust plant or Ghost plant', 7334193],
	['Kalanchoe tomentosa', ' Panda plant', 4198346],
	['Kalanchoe uniflora', '', 4198292],
	['Kleinia barbertonica', '', 5694160],
	['Kleinia fulgens', ' Scarlet Kleinia', 5405633],
	['Kleinia petraea', ' Senecio jacobensii', 5405661],
	['Kleinia stapeliiformis', '', 5405653],
	['Lepismium cruciforme', ' Hurricane Cactus', 7279966],
	['Lithops', ' sp. Living Stones', 3231652],
	['Ludisia discolor', ' Jewel Orchid', 2784179],
	['Macodes petola', ' Lightning Jewel Orchid', 2804641],
	['Mammillaria longimamma', ' Nipple Cactus', 7284614],
	['Manihot esculenta', ' Cassava', 3060998],
	['Maranta kerchoveana', 'Prayer Plant', 7350315],
	['Maranta leuconeura', 'Herringbone Plant', 2761337],
	['Maranta leuconeura', ' Silver Band Prayer Plant', 2761337],
	['Matelea cyclophylla', '', 3573131],
	['Medinilla magnifica', 'Medinilla', 3188507],
	['Microsorum diversifolium', 'Kangaroo Fern', 4025472],
	['Monstera adansonii', 'Five Holes Plant', 2868136],
	['Monstera adansonii', ' Swiss cheese plant', 2868136],
	['Monstera deliciosa', 'Swiss Cheese Plant', 2868241],
	['Muehlenbeckia Complexa', 'IKEA Himalayamix Plant', 2888859],
	['Musa acuminata', 'Dwarf Cavendish banana', 2762680],
	['Myrtillocactus geometrizans', ' FUKUROKURYUZINBOKO Booby Cactus', 7279778],
	['Narcissus', 'IKEA LÃ¶karyd', 2858200],
	['Neoalsomitra sarcophylla', '', 3623331],
	['Neoregelia doeringiana', ' Fireball', 2694008],
	['Neoregelia fluminensis', '', 2694103],
	['Neoregelia pauciflora', '', 2694064],
	['Neoregelia', ' sp. Mo Peppa Please', 2693967],
	['Nephrolepis exaltata', 'Boston Fern or Sword Fern', 2650927],
	['Oeceoclades calcarata', '', 2840174],
	['Oeceoclades', 'sp. O decaryana x O maculata', 2840107],
	['Opuntia', 'Prickly pear', 3084187],
	['Opuntia elata', '', 7388512],
	['Opuntia humifusa', ' Eastern prickly pear', 5384047],
	['Opuntia microdasys', ' Bunny Ear Cactus', 9819787],
	['Oxalis triangularis', 'False Shamrock', 7156971],
	['Oxalis triangularis', ' Burgundy Shamrock', 7156971],
	['Pachypodium densiflorum', ' var. Nova', 5535493],
	['Pachypodium lamerei', 'Madagascar Palm', 5535474],
	['Pandanus amaryllifolius', '', 9296315],
	['Pelargonium alternans', '', 3826480],
	['Pellionia repens', ' Polynesian Ivy', 4100799],
	['Peperomia albovittata', 'Piccolo Banda', 4190750],
	['Peperomia albovittata', ' Piccolo Banda', 4190750],
	['Peperomia argyreia', ' Watermelon peperomia', 4189296],
	['Peperomia asperula', '', 4189039],
	['Peperomia caperata', 'Radiator plant', 4186167],
	['Peperomia columella', '', 4184077],
	['Peperomia congesta', '', 4183827],
	['Peperomia cubensis', '', 7305967],
	['Peperomia dahlstedtii', 'IKEA Himalayamix Plant', 9165862],
	['Peperomia dahlstedtii', ' Vining Pepper Plant', 9165862],
	['Peperomia dolabriformis', '', 7305648],
	['Peperomia elongata', '', 7648262],
	['Peperomia ferreyrae', ' Green Beans Peperomia', 4186501],
	['Peperomia graveolens', '', 4183917],
	['Peperomia hoffmannii', '', 4190091],
	['Peperomia incana', '', 8049505],
	['Peperomia kimnachii', '', 4188556],
	['Peperomia metallica', '', 4184843],
	['Peperomia obtusifolia', 'Baby Rubber Plant', 3086423],
	['Peperomia obtusifolia', ' Variegata Baby Rubber Plant', 3086423],
	['Peperomia orba', '', 4189505],
	['Peperomia pereskiifolia', '', 3086394],
	['Peperomia polybotrya', ' Raindrop Peperomia', 4186205],
	['Peperomia prostrata', ' String of Turtles', 9596201],
	['Peperomia quadrangularis', '', 4183834],
	['Peperomia Rotundifolia', 'IKEA Himalayamix Plant', 7646851],
	['Peperomia', ' sp. Hope', 3086367],
	['Peperomia tetragona', ' Parallel Peperomia', 4183682],
	['Peperomia Verticillata', 'IKEA Himalayamix Plant', 8151601],
	['Peperomia verticillata', ' Belly button Peperomia', 8151601],
	['Pereskia aculeata', ' Godseffiana Barbados Gooseberry', 5384215],
	['Pereskia weberiana', '', 5620408],
	['Pfeiffera monacantha', '', 7280143],
	['Phalaenopsis', ' sp. Orchid', 2804680],
	['philodendron', 'Green Congo', 2861312],
	['Philodendron atabapoense', '', 2871146],
	['Philodendron billietiae', '', 2870818],
	['Philodendron brevispathum', '', 2871304],
	['Philodendron callosum', '', 2871096],
	['Philodendron erubescens', ' Red leaf Philodendron', 2871297],
	['Philodendron', ' Florida Alba Beauty', 2861312],
	['Philodendron guttiferum', '', 2871247],
	['Philodendron hastatum', '', 7548805],
	['Philodendron hederaceum', ' Velvet Leaf Philodendron', 8335693],
	['Philodendron hederaceum', ' Brasil', 8335693],
	['Philodendron martianum', '', 2871359],
	['Philodendron mayoi', '', 2871293],
	['Philodendron pedatum', 'Oak Leaf Philodendron', 2870914],
	['Philodendron scandens', 'Sweetheart Plant', 9171301],
	['Philodendron', ' sp. Jungle Boogie', 2861312],
	['Philodendron tripartitum', '', 2870748],
	['Philodendron xanadu', '', 2870723],
	['Phlebodium aureum', ' Blue Star Fern', 2650479],
	['Pilea', 'IKEA Himalayamix Plant', 2984417],
	['Pilea cadierei', 'Variegata Aluminum Plant', 5671157],
	['Pilea peperomioides', 'Chinese Money Plant', 5669632],
	['Pilea peperomioides', ' Pancake Plant', 5669632],
	['Pilea spruceana', '', 5670354],
	['Platycerium bifurcatum', 'Staghorn fern', 7126767],
	['Platycerium bifurcatum', ' Staghorn Fern', 7126767],
	['Plectranthus ernstii', '', 3904846],
	['Plectranthus verticillatus', 'Swedish Ivy', 2926638],
	['Pleiospilos Nelii', 'IKEA Himalayamix Plant', 3706668],
	['Dracaena thalioides', ' Dracaena aubryana', 5304632],
	['Primulina', ' sp. Hotei', 6365114],
	['Pseudorhipsalis amazonica', '', 7283573],
	['Pseudorhipsalis ramulosa', 'Red Mistletoe Cactus', 7283547],
	['Quesnelia marmorata', ' Tim Plowman', 2696565],
	['Ravenea', '', 2739028],
	['Rhaphidophora decursiva', ' actually this has been confirmed E. pinnatum', 5329707],
	['Rhaphidophora tetrasperma', ' Mini Monstera', 5329745],
	['Rhipsalis', 'Mistletoe cacti', 3084035],
	['Rhipsalis salicornioides', 'Dancing Bones Cactus', 7280609],
	['Rhipsalis agudoensis', '', 3947944],
	['Rhipsalis baccifera', ' Mistletoe Cactus', 3084036],
	['Rhipsalis burchellii', 'Mistletoe Cactus', 3947598],
	['Rhipsalis cereoides', '', 8282664],
	['Rhipsalis cereuscula', '', 7659514],
	['Rhipsalis crispata', '', 3947212],
	['Rhipsalis cuneata', '', 3947167],
	['Rhipsalis elliptica', '', 7281035],
	['Rhipsalis ewaldiana', '', 3946970],
	['Rhipsalis mesembryanthemoides', ' Clumpy mistletoe cactus', 7484915],
	['Rhipsalis micrantha', '', 8615111],
	['Rhipsalis oblonga', '', 3945922],
	['Rhipsalis occidentalis', '', 3945910],
	['Rhipsalis pachyptera', '', 7280748],
	['Rhipsalis paradoxa', '', 7280743],
	['Rhipsalis pentaptera', '', 3945739],
	['Rhipsalis pilocarpa', ' Hairy stemmed rhipsalis', 7280718],
	['Ruschia uncinata', '', 5555045],
	['Sansevieria', '', 2770498],
	['Sansevieria kirkii', 'Star Sansevieria', 2770599],
	['Sansevieria metallica', '', 2770604],
	['Sansevieria trifasciata', 'Snake Plant', 2770610],
	['Sansevieria volkensii', 'Sansevieria volkensii', 2770653],
	['Sansevieria zeylanica', 'Mother-in-laws Tongue or Snake Plant', 7870689],
	['Saxifraga stolonifera', ' Creeping Saxifrage', 8062061],
	['Schefflera actinophylla', ' Nova Umbrella Tree', 3038546],
	['Schefflera arboricola', 'Umbrella plant', 3038723],
	['Schlumbergera gaertneri', 'Easter Cactus', 8506587],
	['Schlumbergera truncata', 'Thanksgiving Cactus', 5383983],
	['Scindapsus pictus', 'Satin Pothos', 2873745],
	['Scindapsus pictus', ' Jade Satin Silver Pothos', 2873745],
	['Scindapsus treubii', ' Moonlight', 2873707],
	['Sedum burrito', ' Burros Tail', 5690519],
	['Sedum Makinoi', 'IKEA Himalayamix Plant', 5690711],
	['Selenicereus grandiflorus', 'Monstrose', 3084466],
	['Sempervivum arachnoideum', '', 4201709],
	['Senecio Barbertonicus', 'IKEA Himalayamix Plant', 3106999],
	['Senecio crassissimus', ' Propeller Plant', 4238247],
	['Senecio herreianus', 'String of Beads', 3107117],
	['Senecio macroglossus', ' Variegata Wax Ivy', 3107720],
	['Siderasis fuscata', ' Brown Spiderwort', 2764920],
	['Sinocrassula yunnanensis', ' f. Cristata', 4197675],
	['Spathiphyllum', '', 2869649],
	['Spathiphyllum', ' Silver Streak Peace Lily', 2869649],
	['Spathiphyllum wallisii', 'Peace Lily', 2869680],
	['Spathiphyllum wallisii', ' Vareigata Peace Lily', 2869680],
	['Stapelia grandiflora', ' Carrion plant', 7312155],
	['Stenocactus crispatus', ' Brain Cactus', 3941347],
	['Strelitzia', '', 2763100],
	['Strelitzia nicolai', 'Giant white Bird of Paradise', 2763112],
	['Strelitzia reginae', 'Bird of Paradise', 7944285],
	['Streptocarpus ionanthus', 'Saintpaulia ionantha African violets', 6710927],
	['Stromanthe thalia', 'Triostar', 2761259],
	['Stromanthe thalia', ' Triostar', 2761259],
	['Syngonium chiapense', '', 2867846],
	['Syngonium erythrophyllum', ' Llano-Carti Road', 2867864],
	['Syngonium podophyllum', 'Variegated Arrowhead Vine', 2867866],
	['Tephrocactus articulatus', ' Paper spine or Pinecone Cactus', 7283993],
	['Thaumatophyllum spruceanum', '', 2870763],
	['Tillandsia bulbosa', '', 2695645],
	['Tillandsia ionantha', '', 2695006],
	['Tillandsia streptophylla', '', 8901607],
	['Tillandsia tectorum', '', 2695240],
	['Tillandsia xerographica', ' Air Plant', 2695364],
	['Tradescantia pallida', ' Purple Spiderwort', 2765221],
	['Tradescantia sillamontana', ' Cobweb Spiderwort', 2765120],
	['Tradescantia zebrina', 'Wandering jew', 9301419],
	['Vriesea fosteriana', ' Red Chestnut', 5289387],
	['Vriesea gigantea', 'Giant Vriesea  Nova', 5288894],
	['Wallisia cyanea', ' Pink Quill', 9556126],
	['Xerosicyos danguyi', '', 3625571],
	['Xerosicyos perrieri', '', 3625553],
	['Yucca', 'Tropisk', 2775496],
	['Yucca elephantipes', 'Spineless Yucca', 7722879],
	['Zamia furfuracea', ' Cardboard Palm', 5284125],
	['Zamioculcas', '', 2869013],
	['Zamioculcas zamiifolia', 'ZZ Plant', 2869014],
	['STEPHANOTIS', 'Madagascar jasmine or Wedding vine', 7311880],
	['Dianthus caryophyllus', 'Carnation ', 3085420],
	['GARDENIA', 'Gardenia', 2894320],
	['HYDRANGEA', 'Hydrangea', 2985983],
	['Helianthus', 'Sunflower', 3119134],
	['Viola tricolor', 'Pansy', 5331347],
	['Narcissus', 'Daffodils', 2858200],
	['Myosotis', 'Forget-me-not', 2925668],
	['Tagetes', 'Marigold', 3088468],
	['Paeonia', 'Peony', 3083483],
	['PETUNIA', 'Petunia', 2928858],
	['Matthiola incana', 'Stock', 5377378],
	['Viola', 'Violet', 2874237],
	['Rosa', 'rose', 8395064],
	['Bellis perennis', 'Daisy', 3117424],
	['Tulipa', 'Tulip', 2750913],
	['Syringa', 'Lilac', 3172235],
	['Caladium', 'heart of Jesus', 2868340],
	['Coleus scutellarioides', 'coleus', 8594529],
];
