import React, { useEffect, useState, useContext } from 'react';
import ReportSimple from './report-simple';
import ReportDetails from './report-details';
import { isEmpty, values } from 'lodash';
import { notify } from '../../../services/toast';
import { useTranslation } from 'react-i18next';
import { getUserPlants } from '../../../services/plants';
import defaultContext from '../../../defaultContext';

const ReportWrapper = ({ sensors: { sensors, gridCameraPosition }, currentSimulation, meta, plants }) => {
	const { t } = useTranslation();
	const { user } = useContext(defaultContext);
	const [details, setDetails] = useState(false);
	const [sim, setSim] = useState();
	const [dirty, setDirty] = useState(false);
	const [luminaries, setLuminaries] = useState(currentSimulation.luminaries??[]);
	const [luminarySensorMap, SetLuminarySensorMap] = useState({ luminaryMap: {}, getTempLuminary: () => {} });

	useEffect(() => {
		const lsmap = Object.entries(sensors || {}).reduce((a, [k, s]) => {
			const ls = luminaries.filter(luminary => {
				let pos = s.position.filter((f, i) => Math.round(s.normal[i]) === 0);

				return (
					luminary.wpId === s.wpId &&
					Math.sqrt(Math.pow(pos[0] - luminary.position[0], 2) + Math.pow(pos[1] - luminary.position[1], 2)) <
						luminary.diameter
				);
			});

			a[k] = ls;

			return a;
		}, {});
		SetLuminarySensorMap({
			luminaryMap: lsmap,
			getTempLuminary: () =>
				luminaries.reduce((a, i) => {
					a[i.id] = Array(365).fill(0);
					return a;
				}, {}),
		});
	}, [sensors, luminaries]);

	const handleSetLuminaries = (luminaries) => {
		setLuminaries(luminaries);
		setDirty(true);
	}

	useEffect(() => {
		if (isEmpty(currentSimulation.plants)) {
			if (isEmpty(plants)) {//TODO add generic plants here
				getUserPlants(user.userName)
					.then(res => {
						if (isEmpty(res)) {
							notify(t('no_plants_notification_text'));
							setSim({ ...currentSimulation, plants: [{ id: -1, dli: [10, 20], name: t('sample_plant') }] });
						} else {
							setSim({ ...currentSimulation, plants: values(res) });
						}
					})
					.catch(e => {
						console.error(e);
					});
			} else {
				setSim({ ...currentSimulation, plants: values(plants) });
			}
		} else {
			setSim(currentSimulation);
		}
	}, [currentSimulation, plants, t, user]);

	if (!sim || isEmpty(sim.plants)) {
		return <></>;
	}

	return details ? (
		<ReportDetails
			sensors={{ sensors, gridCameraPosition }}
			meta={meta}
			currentSimulation={sim}
			setDetails={setDetails}
		/>
	) : (
		<ReportSimple
			sensors={{ sensors, gridCameraPosition }}
			meta={meta}
			currentSimulation={sim}
			setCurrentSimulation={setSim}
			setDetails={setDetails}
			luminaries={luminaries}
			setLuminaries={handleSetLuminaries}
			luminarySensorMap={luminarySensorMap}
			user={user}
			dirty={dirty}
			setDirty={setDirty}
		/>
	);
};

export default ReportWrapper;
