import React from 'react';
import { useTranslation } from 'react-i18next';
import ImageTypography from '../image-typography';
import roomModeler from '../../../assets/images/howItWorks/roomModeler.svg';
import climateCondition from '../../../assets/images/howItWorks/climateCondition.svg';
import plantSelection from '../../../assets/images/howItWorks/plantSelection.svg';
import { useHistory } from 'react-router-dom';

const styles = {
	button: {
		height: '40px',
		width: '165px',
	},
	header: {
		height: '64px',
		color: '#000',
		fontFamily: 'PT Sans',
		fontSize: '50px',
		letterSpacing: '2px',
		lineHeight: '64px',
		textAlign: 'center',
	},
	title: {
		marginTop: '4em',
		width: '100%',
		height: '170px',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	learn: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	body: {},
};

const HowItWorks = () => {
	const { t } = useTranslation();
	const history = useHistory();

	return (
		<div className="container" style={{ backgroundColor: '#FFF', marginBottom: '4em' }}>
			<div className="row" style={styles.title}>
				<h2 style={styles.header}>{t('how_it_works_page_label')}</h2>
			</div>
			<div className="row" style={styles.body}>
				<div className="col-md-4 mb-2">
					<ImageTypography title={t('room_modeler_title')} text={t('room_modeler_text')} image={roomModeler} />
				</div>
				<div className="col-md-4 mb-2">
					<ImageTypography
						title={t('climate_condition_title')}
						text={t('climate_condition_text')}
						image={climateCondition}
					/>
				</div>
				<div className="col-md-4">
					<ImageTypography title={t('plant_selection_title')} text={t('plant_selection_text')} image={plantSelection} />
				</div>
			</div>
			<div className="row mt-md-5 mt-3" style={styles.learn}>
				<button
					className="btn btn-dark m-1"
					aria-label={t('try_button_label')}
					style={styles.button}
					onClick={() => history.push('/pricing')}
				>
					{t('try_button_label')}
				</button>
				{/* eslint-disable-next-line */}
				<a href="#inActionVideo" className="btn btn-dark  mx-1" style={styles.button}>
					{t('watch_button_label')}
				</a>
			</div>
		</div>
	);
};

export default HowItWorks;
