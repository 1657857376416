import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'toasted-notes/src/styles.css';

import { I18nextProvider } from 'react-i18next';
import i18next from './i18n';

const Loader = () => <div>loading...</div>;

ReactDOM.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18next}>
			<Suspense fallback={<Loader />}>
				<App />
			</Suspense>
		</I18nextProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);
