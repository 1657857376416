import React, { useState, useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import defaultContext from '../../../defaultContext';
import { Accordion, Card, Button, Collapse } from 'react-bootstrap';
import { includes, indexOf, isEmpty, map } from 'lodash';
import { deleteProject, getProject, upsertProject } from '../../../services/projects';
import { getProjects } from '../../../services/users';
import { Skeleton } from '@material-ui/lab';
import { UpdateCard } from './UpdateCard';
import PlantSetWrapper from './plant-set-wrapper';
import { isMobile } from 'react-device-detect';
import { correctProjectName } from '../utils';
import { usePrivileges } from '../../../hooks/priviledges';

export const Simulations = () => {
	const { t } = useTranslation();

	const { currentSimulationName, setCurrentSimulationName, setCurrentSimulation, projects, setProjects } =
		useContext(defaultContext);
	const { user, waiting: getUserPrivilegesWaiting } = usePrivileges();
	const [newSimName, setNewSimName] = useState('');
	const [editingPlants, setEditingPlants] = useState(false);
	const [editingMeta, setEditingMeta] = useState(false);
	const [waiting, setWaiting] = useState(false);
	const [editingProject, setEditingProject] = useState();

	useEffect(() => {
		if (projects) {
			setWaiting(false);
			return;
		}
		setWaiting(true);
		getProjects(user.userName)
			.then(result => {
				setWaiting(false);
				setProjects(result);
			})
			.catch(e => console.error(e));
	}, [user.userName, setProjects, projects]);

	function getNewProjectName(projects, val) {
		if (val && val !== '') {
			if (indexOf(projects, val) < 0) return val;
			return getNewProjectName(projects, 'New ' + val);
		}
		return 'Project ' + (projects.length + 1);
	}

	const handleStart = () => {
		setCurrentSimulationName(getNewProjectName(projects, newSimName));
		setCurrentSimulation();
	};

	const handleDuplicate = async id => {
		let p = await getProject(user.userName, id);
		p.id = 'Copy of ' + p.id;
		while (includes(projects, p.id)) {
			p.id = 'Copy of ' + p.id;
		}
		await upsertProject(user.userName, p.id, p);
		setProjects([...projects, { id: p.id }]);
	};

	const handleNameChange = name => {
		name = correctProjectName(name);
		setNewSimName(name);
	};

	return (
		<>
			{!getUserPrivilegesWaiting && user.tier === 0 ? (
				<div style={{ backgroundColor: '#eeeeee', padding: '1rem', margin: '1rem' }}>
					{/* <p style={{ whiteSpace: 'pre-line' }}>{t('free_plan_indication_html', { component: { b: '<br>' } })}</p> */}
					<Trans i18nKey="free_plan_indication_html" components={{ italic: <i />, bold: <strong /> }} />
				</div>
			) : (
				<></>
			)}
			<Accordion
				activeKey={
					isEmpty(projects) || !currentSimulationName || currentSimulationName === ''
						? '0'
						: indexOf(
								map(projects, f => f.id),
								currentSimulationName,
						  ) + 1
				}
			>
				<Card>
					<Card.Header>
						<Accordion.Toggle
							as={Button}
							variant="link"
							eventKey="0"
							onClick={() => {
								setCurrentSimulationName();
							}}
						>
							{t('new_project_label')}
						</Accordion.Toggle>
					</Card.Header>

					<Accordion.Collapse eventKey="0">
						<Card.Body>
							<div>
								<label>{t('new_project_name_label')}</label>
								<input
									className="form-control"
									type="text"
									disabled={user.tier === 0}
									value={newSimName}
									onChange={e => handleNameChange(e.target.value)}
								/>
							</div>
							<div className="mt-4">
								{user.tier === 0 ? (
									<>
										<Link className="btn btn-dark mr-2" role="button" to={'/wizard'}>
											{t('start_wizard_button')}
										</Link>
									</>
								) : (
									<></>
								)}
								<Link
									className="btn btn-dark mr-2"
									style={{ pointerEvents: user.tier === 0 ? 'none' : 'auto', opacity: user.tier === 0 ? '0.5' : '1' }}
									role="button"
									to="/modeler"
									onClick={handleStart}
								>
									{t('start_modeler_button')}
								</Link>

								<Link
									className="btn btn-dark"
									style={{ pointerEvents: user.tier === 0 ? 'none' : 'auto', opacity: user.tier === 0 ? '0.5' : '1' }}
									role="button"
									to="/advance-wizard"
									onClick={handleStart}
								>
									{t('start_advance_wizard_button')}
								</Link>
							</div>
						</Card.Body>
					</Accordion.Collapse>
				</Card>
				{waiting ? (
					<Card key={0}>
						<Card.Header>
							<Accordion.Toggle as={Button} variant="link" eventKey={0}>
								<Skeleton width={'8em'} />
							</Accordion.Toggle>
						</Card.Header>
					</Card>
				) : (
					(projects || []).map(({ id, status }, index) => (
						<Card key={id}>
							<Card.Header>
								<Accordion.Toggle
									as={Button}
									variant="link"
									eventKey={index + 1}
									style={{ width: '100%' }}
									onClick={() => {
										setEditingPlants(false);
										setEditingMeta(false);
										setCurrentSimulationName(id);
										setEditingProject();
									}}
								>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between',
											alignItems: 'center',
											width: '100%',
										}}
									>
										<h6 style={{ margin: 0 }}>{id}</h6>
										<p style={{ margin: 0 }}>{status}</p>
									</div>
								</Accordion.Toggle>
							</Card.Header>
							<Accordion.Collapse eventKey={index + 1}>
								<Card.Body>
									<div>
										<Link
											className="btn btn-dark mr-2"
											style={{ marginBottom: isMobile ? '1rem' : '' }}
											role="button"
											to="/report"
											onClick={() => setCurrentSimulationName(id)}
										>
											{t('report_label')}
										</Link>
										<button
											className="btn btn-dark mr-2"
											style={{ marginBottom: isMobile ? '1rem' : '' }}
											onClick={() => handleDuplicate(id)}
										>
											{t('duplicate_button_label')}
										</button>
										<button
											className="btn btn-dark mr-2"
											style={{ marginBottom: isMobile ? '1rem' : '' }}
											onClick={() => {
												deleteProject(user.userName, id)
													.then(() => {
														setProjects(projects.filter(f => f.id !== id));
													})
													.catch(console.error);
											}}
										>
											{t('delete_button_label')}
										</button>
										{editingProject === index ? (
											<></>
										) : (
											<button
												className="btn btn-dark"
												style={{ marginBottom: isMobile ? '1rem' : '' }}
												onClick={() => setEditingProject(index)}
											>
												{t('edit')}
											</button>
										)}
									</div>

									<Collapse in={editingProject === index}>
										<div id={'editing-buttons-' + index}>
											<hr></hr>
											<div style={{ display: 'inline-block' }}>
												<div id={'editing-buttons-' + index} style={{ display: 'flex', flexDirection: 'row' }}>
													<hr></hr>
													{editingPlants || editingMeta ? (
														<></>
													) : (
														<Link
															className="btn btn-dark mr-2"
															role="button"
															to="/modeler"
															style={{ minHeight: '2.35rem', marginBottom: isMobile ? '1rem' : '' }}
														>
															{t('edit_room_label')}
														</Link>
													)}
													{!editingPlants ? (
														<UpdateCard
															id={id}
															setProjects={setProjects}
															setEditing={setEditingMeta}
															editing={editingMeta}
														/>
													) : (
														<></>
													)}

													{!editingMeta ? (
														<PlantSetWrapper id={id} setEditing={setEditingPlants} editing={editingPlants} />
													) : (
														<></>
													)}
												</div>
											</div>
										</div>
									</Collapse>
								</Card.Body>
							</Accordion.Collapse>
						</Card>
					))
				)}
			</Accordion>
		</>
	);
};
