import React, { useState, Suspense } from 'react';
import { isEmpty, map, sum, max, values, filter, flatten } from 'lodash';
import { Canvas } from 'react-three-fiber';
import Room from '../../room-builder/room';
import Window from '../../room-builder/window';
import Block from '../../room-builder/block';
import MiscObject from '../../room-builder/misc-object';
import WorkPlane from '../../room-builder/workplane';
import { rgbf, colorGradient } from '../../utils';
import { Plane } from './Plane';
import { CameraController } from './CameraController';

import { Text } from './Text';

const GridSimple = ({
	sensors,
	luminaryPositions,
	luminaries,
	luminarySensorMap,
	gridCameraPosition,
	onSelect,
	currentSimulation: { width, depth, height, elevation, windows, resolution, hasRoom, blocks, objects, workPlanes },
	pickedSensor,
	className,
}) => {
	const [showObjects, setShowObjects] = useState(true);
	let averages = {};
	let maxIll = 1;
	if (!isEmpty(sensors)) {
		map(sensors, (sensor, key) => {
			if (luminarySensorMap[key] && luminarySensorMap[key].length > 0) {
				averages[key] =
					(sum(sensor.dlisAll) +
						sum(flatten(luminarySensorMap[key].map(f => map(luminaries[f.id], g => g * f.ppfd * 0.0864))))) /
					365;
			} else {
				averages[key] = sum(sensor.dlisAll) / 365;
			}
		});
		maxIll = max(values(averages));
	}
	const [x, y] = gridCameraPosition;

	return (
		<div className={className}>
			{isEmpty(sensors) ? (
				<></>
			) : (
				<>
					<Canvas camera={{ position: [0, 10, 0] }} onCreated={({ camera }) => camera.lookAt(0, -1, 0)}>
						<CameraController x={x} y={y} />
						<ambientLight intensity={2} />
						<pointLight position={[40, 40, 40]} />
						<Suspense fallback={null}>
							<group>
								{hasRoom ? <Room width={width} depth={depth} height={height} alt={elevation} /> : <></>}
								{map(windows, (window, index) => (
									<Window
										key={index}
										alt={elevation}
										roomWidth={width}
										roomDepth={depth}
										roomHeight={height}
										width={window.width}
										height={window.height}
										position={window.position}
										face={window.face}
										splitDirection={window.splitDirection}
										splitNum={window.splitNum}
									/>
								))}
								{map(workPlanes, (workPlane, index) => (
									<WorkPlane
										key={index}
										alt={elevation}
										workPlane={workPlane}
										resolution={resolution}
										lamps={map(
											filter(values(luminaryPositions), f => f.wpId === workPlane.id),
											g => [...g.position, g.ppfd],
										)}
									/>
								))}
								{map(blocks, (block, index) => (
									<Block key={index} alt={elevation} block={block} />
								))}
								{map(objects, (object, index) => (
									<MiscObject
										key={index}
										showObjects={showObjects}
										object={object}
										sensors={filter(
											map(sensors, (s, k) =>
												s.wpId > 1000
													? [
															...s.position,
															colorGradient(
																averages[k] / maxIll,
																rgbf(55, 94, 197),
																rgbf(85, 161, 87),
																rgbf(255, 83, 83),
															),
													  ]
													: null,
											),
										)}
									/>
								))}
								{map(sensors, (sensor, key) =>
									sensor.wpId > 1000 ? (
										<></>
									) : (
										<Plane
											key={key}
											x={sensor.position[0]}
											y={sensor.position[1]}
											z={sensor.position[2]}
											r={resolution}
											n={sensor.normal}
											rotation={sensor.rotation}
											c={colorGradient(averages[key] / maxIll, rgbf(55, 94, 197), rgbf(85, 161, 87), rgbf(255, 83, 83))}
											onClick={() => onSelect(sensor)}
										/>
									),
								)}
								{/* {map(sensors, (sensor, key) =>
									sensor.wpId > 1000 ? (
										<></>
									) : (
										<Text
											key={key}
											size={0.025}
											position={[sensor.position[0] + 0.5, sensor.position[1], sensor.position[2] - 0.5]}
											rotation={sensor.rotation}
											children={averages[key].toFixed(0).toString()}
										/>
									),
								)} */}
								{map(pickedSensor, (v, k) => {
									return (
										<Text
											key={k}
											position={v.position}
											rotation={v.rotation}
											children={v.plantIndex.toString()}
											color={v.fixed ? '#ff00ff' : '#000000'}
										/>
										// <Text
										// 	size={0.025}
										// 	color={'#00aa00'}
										// 	position={[v.position[0] - 0.5, v.position[1], v.position[2] - 0.25]}
										// 	rotation={v.rotation}
										// 	children={v.plantDli[0]}
										// />
									);
								})}
							</group>
						</Suspense>
					</Canvas>

					{objects && objects.length > 0 ? (
						<button
							className="btn btn-dark"
							style={{ position: 'absolute', bottom: '1rem', right: '2rem', zIndex: 1000 }}
							onClick={() => setShowObjects(!showObjects)}
						>
							{showObjects ? 'Hide Objects' : 'Show Objects'}
						</button>
					) : (
						<></>
					)}
				</>
			)}
		</div>
	);
};

export default GridSimple;
