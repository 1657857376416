import React, { useContext } from 'react';
import AdvancedWizardSection from './advanced-wizard-section';
import { useTranslation } from 'react-i18next';
import { debounce, last, filter } from 'lodash';
import advancedWizardContext from './advance-wizard-context';
import Slider from '../../ui-elements/slider';

export const BlocksTopAddSectionOne = () => {
	const { t } = useTranslation();
	const {
		mapClickFunc,
		setMarks,
		redrawEntity,
		handleNext,
		currentSimulation: { blocks },
	} = useContext(advancedWizardContext);
	redrawEntity.current = 'blocks';
	mapClickFunc.current = debounce((x, y) => {
		setMarks([{ id: 0, x, y: -y }]);
		handleNext.current();
	}, 150);

	return (
		<AdvancedWizardSection>
			<p>
				{filter(blocks, b => b.top).length > 0
					? t('start_top_block_section_guide')
					: t('start_first_top_block_section_guide')}
			</p>
		</AdvancedWizardSection>
	);
};
export const BlocksTopAddSectionTwo = () => {
	const { t } = useTranslation();
	const { marks, handleAddEntity, mapClickFunc, setMarks, handleNext } = useContext(advancedWizardContext);

	mapClickFunc.current = debounce((x2, y2) => {
		y2 = -y2;

		const x1 = marks[0].x;
		const y1 = marks[0].y;

		handleAddEntity('blocks', {
			height: 0,
			position1: [x1, y1],
			position2: [x2, y2],
			incline: 0,
			incline2: 0,
			top: true,
			reflectivity: 0.5,
		});
		setMarks([...marks, { id: 1, x: x2, y: y2 }]);
		handleNext.current();
	}, 150);

	return (
		<AdvancedWizardSection>
			<p>{t('end_top_block_section_guide')}</p>
		</AdvancedWizardSection>
	);
};
export const BlocksTopSizeSection = () => {
	const { t } = useTranslation();
	const { currentSimulation, mapClickFunc, globalStep, handleArrayEntityChange } = useContext(advancedWizardContext);
	mapClickFunc.current = () => {};

	let { blocks, height } = currentSimulation;

	let block = last(blocks);
	return (
		<AdvancedWizardSection>
			<Slider
				label={t('blocksPanel_height_label')}
				value={block.height}
				onChange={value => handleArrayEntityChange('blocks', blocks.length - 1, 'height', value)}
				min={0.1}
				max={height * 10}
				step={globalStep}
			/>
		</AdvancedWizardSection>
	);
};
export const BlocksTopDetailsSection = () => {
	const { t } = useTranslation();
	const { currentSimulation, handleArrayEntityChange } = useContext(advancedWizardContext);
	let { blocks } = currentSimulation;
	let block = last(blocks);
	return (
		<AdvancedWizardSection>
			<Slider
				label={t('blocksPanel_reflectivity_label')}
				value={block.reflectivity}
				onChange={value => handleArrayEntityChange('blocks', blocks.length - 1, 'reflectivity', value)}
				min={0}
				max={1}
				step={0.01}
			/>
		</AdvancedWizardSection>
	);
};
