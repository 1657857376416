import React, { useMemo } from 'react';
import { FontLoader, Vector3, DoubleSide } from 'three';
import { useLoader, useUpdate } from 'react-three-fiber';
import bold from '../../../../assets/bold.blob';

export function Text({ children, vAlign = 'center', hAlign = 'center', size = 0.05, color = '#000000', ...props }) {
	const font = useLoader(FontLoader, bold);
	const config = useMemo(
		() => ({
			font,
			// size: 40,
			height: 1,
			// curveSegments: 32,
			// bevelEnabled: true,
			// bevelThickness: 6,
			// bevelSize: 2.5,
			// bevelOffset: 0,
			// bevelSegments: 8,
		}),
		[font],
	);
	const mesh = useUpdate(
		self => {
			const size = new Vector3();
			self.geometry.computeBoundingBox();
			self.geometry.boundingBox.getSize(size);
			self.position.x = hAlign === 'center' ? -size.x / 2 : hAlign === 'right' ? 0 : -size.x;
			self.position.y = vAlign === 'center' ? -size.y / 2 : vAlign === 'top' ? 0 : -size.y;
		},
		[children],
	);
	return (
		<group {...props} scale={[0.1 * size, 0.1 * size, 0.1]}>
			<mesh ref={mesh}>
				<textGeometry attach="geometry" args={[children, config]} />
				<meshBasicMaterial
					attach="material"
					opacity={1}
					reflectivity={1}
					transparent={true}
					side={DoubleSide}
					color={color}
				/>
			</mesh>
		</group>
	);
}
