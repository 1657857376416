export function getTotalProjects(user) {
	if (!user.tier) {
		return 0;
	}
	return (
		{
			0: 0,
			1: 2,
			2: 5,
			3: 10,
			4: Infinity,
			5: 1,
		}[user.tier] || 0
	);
}

export function canAccessModeler(user) {
	return !!user.tier && user.tier > 0;
}

export function canAccessAdvanceWizard(user) {
	return !!user.tier && user.tier > 0;
}

export function canAccessSimpleWizard(user) {
	return !user.tier || user.tier < 1;
}

export function canAccessApiToken(user) {
	return !!user.tier && (user.tier === 3 || user.tier === 4);
}

export function canAccessNotifications(user) {
	return !!user.tier && (user.tier === 4 || user.tier === 3);
}
