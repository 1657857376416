import React, { useEffect, useRef, useMemo } from 'react';
import { PlaneGeometry, DoubleSide } from 'three';
import GLB from './glb';
import Lamp from './lamp';
import { range } from 'lodash';
import { map } from 'lodash';
import plant1 from '../../../assets/three/glb/plant1.glb';
import plant2 from '../../../assets/three/glb/plant2.glb';
import plant3 from '../../../assets/three/glb/plant3.glb';
import plant4 from '../../../assets/three/glb/plant4.glb';

const Box = ({
	alt,
	activeId,
	setActiveId,
	resolution,
	lamps,
	showPlants,
	workPlane: {
		id,
		face,
		distance,
		position1: [x1, y1],
		position2: [x2, y2],
	},
}) => {
	const mesh = useRef();
	const ref = useRef();
	const isActive = activeId === `workPlanes_${id}`;
	useEffect(() => {
		if (ref.current) {
			ref.current.computeLineDistances();
		}
	});

	const traverse = (x1, x2, y1, y2, face, alt, distance) => {
		let rotation, pos;
		if (face === 'H') {
			rotation = [Math.PI / 2, 0, 0];
			pos = [(x1 + x2) / 2, alt + distance, (y1 + y2) / 2];
		} else if (face === 'F' || face === 'B') {
			rotation = [0, Math.PI / 2, 0];
			pos = [distance, alt + (y1 + y2) / 2, (x1 + x2) / 2];
		} else if (face === 'R' || face === 'L') {
			rotation = [0, 0, Math.PI / 2];
			pos = [(y1 + y2) / 2, alt + (x1 + x2) / 2, distance];
		}
		const width = Math.abs(x2 - x1);
		const height = Math.abs(y2 - y1);

		return [width, height, rotation, pos];
	};

	const [width, height, rotation, pos] = traverse(x1, x2, y1, y2, face, alt, distance);

	const geom = useMemo(() => new PlaneGeometry(width, height), [width, height]);
	if (width === 0 || height === 0) {
		return <></>;
	}

	return (
		<group>
			<mesh
				ref={mesh}
				position={pos}
				rotation={rotation}
				onPointerDown={setActiveId ? e => setActiveId(`workPlanes_${id}`) : () => {}}
			>
				{lamps ? (
					<></>
				) : (
					<>
						<lineSegments ref={ref}>
							<edgesGeometry attach="geometry" args={[geom]} />
							<lineDashedMaterial color="#10a11a" width={0.1} attach="material" dashSize={0.2} gapSize={0.2} />
						</lineSegments>
						<planeGeometry attach="geometry" args={[width, height]} />
						<meshBasicMaterial
							attach="material"
							opacity={0.3}
							transparent={true}
							side={DoubleSide}
							color={isActive ? '#ff0000' : '#74FF5B'}
						/>
					</>
				)}
			</mesh>
			{lamps ? (
				<>
					{map(lamps, (f,i) => {
						if (f && f.length === 3) {
							const position = traverse(
								face === 'H' ? f[0] * 2 : f[1] * 2, //TODO what's going on here
								0,
								face === 'H' ? f[1] * 2 : f[0] * 2,
								0,
								face,
								alt,
								distance,
							)[3];
							return <Lamp key={i} position={position} ppfd={(f[2] - 100) / 500} />;
						} else {
							return <></>;
						}
					})}
				</>
			) : (
				<></>
			)}
			{showPlants ? (
				range(pos[0] - width + resolution / 2, pos[0] + width - resolution / 2, resolution * 2).map(x =>
					range(pos[1] - height + resolution / 2, pos[1] + height - resolution / 2, resolution * 2).map(y => {
						const i = Math.floor(Math.random() * 4);
						const pos = traverse(x + resolution / 2, 0, y - resolution / 2, 0, face, alt, distance)[3];
						if (face === 'F') {
							pos[0] -= 0.25;
						} else if (face === 'B') {
							pos[0] += 0.25;
						} else if (face === 'R') {
							pos[2] += 0.25;
							pos[0] += 0.25;
						} else if (face === 'L') {
							pos[2] -= 0.25;
							pos[0] += 0.25;
						}
						return (
							<GLB
								glb={{ 0: plant1, 1: plant2, 2: plant3, 3: plant4 }[i]}
								key={'plant' + x + '-' + y}
								position={pos}
								scale={{ 0: 1, 1: 0.0002, 2: 0.005, 3: 0.04 }[i]}
							/>
						);
					}),
				)
			) : (
				<></>
			)}
		</group>
	);
};

export default Box;
