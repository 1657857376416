import React from 'react';
import { useEffect, useRef } from 'react';
import { useThree, useFrame } from 'react-three-fiber';

export default function Camera(props) {
	const ref = useRef();
	const { size, setDefaultCamera, scene } = useThree();
	props.setScene(scene);
	// Make the camera known to the system
	useEffect(() => {
		void setDefaultCamera(ref.current);
	}, [setDefaultCamera]);
	// Update it every frame
	useFrame(() => ref.current.updateMatrixWorld());
	return (
		<perspectiveCamera
			ref={ref}
			aspect={size.width / size.height}
			radius={(size.width + size.height) / 4}
			fov={10}
			position={[25, 40, 65]}
			{...props}
		/>
	);
}
