import React, { useState } from 'react';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import wpicon from '../../../assets/images/icons/workplanes.svg';
import objicon from '../../../assets/images/icons/obj.svg';
import windowicon from '../../../assets/images/icons/windows.svg';
import blockicon from '../../../assets/images/icons/blocks.svg';
import blocksHoriz from '../../../assets/images/icons/blocksHoriz.svg';
import furniture from '../../../assets/images/icons/furniture.svg';
import treeicon from '../../../assets/images/icons/trees.svg';
import { findIndex, includes, debounce, map, uniq, filter, join } from 'lodash';
import { notify } from '../../../services/toast';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	tooltip: {
		fontSize: '1rem',
	},
}));

const AddFab = ({ add, currentSimulation, onMapClick }) => {
	const [open, setOpen] = useState(false);

	const actions = [
		{
			icon: <img src={wpicon} style={{ width: '2rem' }} alt="workplanes icon" />,
			name: 'Work Plane',
			func: () => {
				const { depth, width } = currentSimulation;

				add('workPlanes', {
					distance: 0.5,
					position1: [depth / 2, width / 2],
					position2: [-depth / 2, -width / 2],
					face: 'H',
				});
			},
		},
		{
			icon: <img src={windowicon} style={{ width: '2rem' }} alt="window icon" />,
			name: 'Window',
			func: () => {
				const { height, width, windows } = currentSimulation;
				const windowsFaces = ['F', 'B', 'L', 'R', 'T'];
				const index = findIndex(
					windowsFaces,
					f =>
						!includes(
							windows.map(g => g.face),
							f,
						),
				);
				const face = index < 0 ? 'F' : windowsFaces[index];

				const winWidth = Math.min(width, height);
				add('windows', {
					width: winWidth / 2,
					height: height / 2,
					position: [winWidth / 4, height / 4],
					face,
					splitNum: 1,
					transmissivity: 1,
				});
			},
		},
		{
			icon: <img src={blockicon} style={{ width: '2rem' }} alt="block icon" />,
			name: 'Block',
			func: () => {
				notify('Please Select Where Your block starts and ends');
				onMapClick.current = debounce((x1, y1) => {
					onMapClick.current = debounce((x2, y2) => {
						onMapClick.current = () => {};
						add('blocks', {
							height: 10,
							position1: [x1, -y1],
							position2: [x2, -y2],
							top: false,
							incline: 0,
							incline2: 0,
							reflectivity: 0.5,
						});
					}, 150);
				}, 150);
			},
		},
		{
			icon: <img src={blocksHoriz} style={{ width: '2rem' }} alt="block icon" />,
			name: 'Horizontal Block',
			func: () => {
				notify('Please Select Where Your block starts and ends');
				onMapClick.current = debounce((x1, y1) => {
					onMapClick.current = debounce((x2, y2) => {
						onMapClick.current = () => {};
						add('blocks', {
							height: 0,
							position1: [x1, -y1],
							position2: [x2, -y2],
							top: true,
							incline: 0,
							incline2: 0,
							reflectivity: 0.5,
						});
					}, 150);
				}, 150);
			},
		},
		{
			icon: <img src={treeicon} style={{ width: '2rem' }} alt="tree icon" />,
			name: 'Tree',
			func: () => {
				notify('Please Select Where You want to place a tree');
				onMapClick.current = debounce((x, y) => {
					onMapClick.current = () => {};
					add('trees', { position: [x, -y], type: '0', scale: 1, transmissivity: 0 });
				}, 150);
			},
		},
		{
			icon: <img src={furniture} style={{ width: '2rem' }} alt="tree icon" />,
			name: 'Furniture',
			func: () => {
				notify('Please Select Where You want to place a tree');
				onMapClick.current = debounce((x, y) => {
					onMapClick.current = () => {};
					add('furniture', { position: [x, 0.5, -y], width: 1, depth: 1, height: 1, reflectivity: 0.5 });
				}, 150);
			},
		},
		{
			icon: <img src={objicon} style={{ width: '2rem' }} alt="object icon" />,
			name: 'Object',
			func: () => {
				let inp = document.getElementById('file-input');
				inp.click();
			},
		},
	];
	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	const onFileLoaded = (e, cb) => {
		const match = /^data:(.*);base64,(.*)$/.exec(e.target.result);
		if (match == null) {
			throw new Error('Could not parse result');
		}

		const content = new Buffer(match[2], 'base64').toString('ascii');
		cb(content);
	};

	const onFileChanged = e => {
		var files = Array.from(e.target.files);
		if (files.length < 1 || files.length > 1) {
			return;
		}
		var file = files.find(f => f.name.includes('.obj'));
		if (file) {
			var reader = new FileReader();
			reader.onload = e =>
				onFileLoaded(e, content => {
					const raw = content.split('\r\n');
					const mat = map(uniq(filter(raw, f => f[0] === 'g')), g => {
						return {
							id: g.split(' ')[1],
							surface: 1,
							value: 0.5,
						};
					});
					const fixedContent = map(
						filter(raw, f => f[0] !== 'o'),
						(g, i) => {
							if (g[0] !== 'f') {
								return g;
							}
							return `o object_${i}\r\n` + g;
						},
					);
					let currentGroup = '';
					let groupAppendedContent = [];
					for (let line of fixedContent) {
						if (line.length < 1) {
							continue;
						}
						if (line[0] === 'g') {
							currentGroup = line;
							continue;
						}
						if (line[0] === 'o') {
							line = currentGroup + '\r\n' + line;
						}
						groupAppendedContent.push(line);
					}
					add('objects', {
						data: join(groupAppendedContent, '\r\n'),
						mat,
						x: 0,
						y: 0,
						z: 0,
						rotation: 0,
						rotation2: 0,
						scalex: 1,
						scaley: 1,
						scalez: 1,
					});
				});
			reader.readAsDataURL(file);
		}
	};

	const classes = useStyles();

	return (
		<div>
			<input id="file-input" type="file" accept=".obj" style={{ display: 'none' }} onChange={onFileChanged} />
			<SpeedDial
				FabProps={{ style: { backgroundColor: '#116f0e' } }}
				ariaLabel="add entity"
				style={{ position: 'absolute', bottom: '1rem', right: '1rem' }}
				icon={<SpeedDialIcon />}
				onClose={handleClose}
				onOpen={handleOpen}
				open={open}
			>
				{actions
					.filter(f => (!currentSimulation.hasRoom ? f.name !== 'Window' : true))
					.map(action => (
						<SpeedDialAction
							key={action.name}
							icon={action.icon}
							tooltipTitle={action.name}
							TooltipClasses={classes}
							onClick={action.func}
						/>
					))}
			</SpeedDial>
		</div>
	);
};

export default AddFab;
