import React, { useEffect, useContext } from 'react';
import Home from './pages/home';
import RoomBuilder from './pages/room-builder';
import Features from './pages/features';
import Pricing from './pages/pricing';
import About from './pages/about';
import HowItWorksPage from './pages/howItWorks';
import Contact from './pages/contact';
import Documentation from './pages/documentation';
import Login from './pages/login';
import Dashboard from './pages/dashboard';
import Wizard from './pages/wizard';
import AdvanceWizard from './pages/advance-wizard';
import Templates from './pages/templates';
import WeatherCondition from './pages/weatherCondition';
import Plants from './pages/plants';
import Report from './pages/report';
import AppBar from './ui-elements/app-bar';
import Footer from './ui-elements/footer';
import defaultContext from '../defaultContext';
import { getCookie } from '../services/cookie';
import usePageTracking from '../hooks/pageTracking';
import { BrowserRouter as Router, Switch, Route, useHistory } from 'react-router-dom';

const LoggedInOnly = ({ children }) => {
	const { user, setUser } = useContext(defaultContext);
	const history = useHistory();

	useEffect(() => {
		const userName = getCookie('username');
		const tier = parseInt(getCookie('tier'));
		if (!user && userName !== '') {
			setUser({ userName, tier });
		} else if (!user) {
			history.push('/login');
		}
	}, [history, setUser, user]);

	return user ? <>{children}</> : <></>;
};

const Content = () => {
	usePageTracking();

	return (
		<>
			<AppBar />
			<Switch>
				<Route exact path="/">
					<Home />
					<Footer />
				</Route>

				<Route exact path="/features">
					<Features />
					<Footer />
				</Route>
				<Route exact path="/pricing">
					<Pricing />
					<Footer />
				</Route>
				<Route exact path="/how-it-works">
					<HowItWorksPage />
					<Footer />
				</Route>
				<Route exact path="/about">
					<About />
					<Footer />
				</Route>
				<Route exact path="/contact">
					<Contact />
					<Footer />
				</Route>
				<Route exact path="/documentation">
					<Documentation />
					<Footer />
				</Route>
				<Route exact path="/login">
					<Login />
					<Footer />
				</Route>
				<Route exact path="/templates">
					<Templates />
				</Route>
				<Route exact path="/wizard">
					<Wizard />
				</Route>
				<Route exact path="/free-report">
					<Report />
				</Route>
				<LoggedInOnly>
					<Switch>
						<Route exact path="/modeler">
							<RoomBuilder />
						</Route>
						<Route exact path="/dashboard">
							<Dashboard />
						</Route>

						<Route exact path="/advance-wizard">
							<AdvanceWizard />
						</Route>
						<Route exact path="/Weather-condition">
							<WeatherCondition />
						</Route>
						<Route exact path="/plants">
							<Plants />
						</Route>
						<Route exact path="/report">
							<Report />
						</Route>
						<Route path="/report/:id">
							<Report />
						</Route>
					</Switch>
				</LoggedInOnly>
			</Switch>
		</>
	);
};
const Root = () => {
	return (
		<Router>
			<Content />
		</Router>
	);
};

export default Root;
