import { includes } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { Email, LocalPhone, LocationOn } from '@material-ui/icons';
import { email } from '../../services/general';
import { getCookie } from '../../services/cookie';
import { Card } from '@material-ui/core';

const Contact = () => {
	const { t } = useTranslation();
	const userName = getCookie('username');

	const subjects = [
		{ id: 1, title: t('subject_a') },
		{ id: 2, title: t('subject_b') },
		{ id: 3, title: t('subject_c') },
		{ id: 4, title: t('subject_d') },
	];

	const [subject, setSubject] = useState(subjects[0]);
	const [text, setText] = useState('');
	const [from, setFrom] = useState();
	const [info, setInfo] = useState();

	const handleSubjectChange = value => {
		setSubject(value.title);
	};
	const handleOnsubmit = e => {
		console.log({ subject, body: text });
		email({ from: from ?? userName, subject, body: text }).then(() => {
			setInfo(t('contact_submitted_text'));
		});
	};

	// const handleSubjectSearch = () => {};
	// const handleServiceSearch = () => {};
	return (
		<div className="container-fluid">
			<div className="row">
				<div className="col-mb-12" style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
					<p style={{ fontFamily: 'PT Sans', fontSize: '3em' }}>{t('contact_intro')}</p>
				</div>
			</div>
			<div className="row">
				<div className="col-md-7" style={{ padding: '8rem' }}>
					<h3 style={{ marginBottom: '2rem' }}> Send us an email</h3>
					<Card style={{ backgroundColor: '#D8EAD7', padding: '3em', width: '100%', height: '100%' }}>
						<Autocomplete
							id="combp-box-subject"
							options={subjects}
							onChange={(event, value) => handleSubjectChange(value)}
							getOptionSelected={(option, value) => includes(option.title, value)}
							getOptionLabel={option => t(option.title)}
							style={{ width: '16rem', marginBottom: '1rem', marginTop: '2rem' }}
							renderInput={params => <TextField {...params} label={t('subject_tittle')} variant="outlined" />}
						/>
						<TextField
							id="outlined-multiline-static"
							label={'email'}
							defaultValue={userName}
							variant="outlined"
							onChange={e => setFrom(e.target.value)}
							style={{ width: '80%', marginBottom: '1rem' }}
						/>
						<TextField
							id="outlined-multiline-static"
							label={t('text_us')}
							multiline
							rows={5}
							defaultValue=""
							variant="outlined"
							onChange={e => setText(e.target.value)}
							style={{ width: '80%', marginBottom: '1rem' }}
						/>
						{info ? <p>{info}</p> : <></>}
						<div className="d-*-flex justify-content-end" style={{ display: 'flex', flexDirection: 'flex-end' }}>
							<button className="btn btn-light" onClick={handleOnsubmit} alt={t('submit_button')}>
								{t('submit_button')}
							</button>
						</div>
					</Card>
				</div>
				<div className="col-md-5" style={{ padding: '8rem' }}>
					<h3 style={{ marginBottom: '2rem' }}>Or visit our discord server</h3>
					<iframe
						src="https://discord.com/widget?id=891358917412655204&theme=dark"
						// width="350"
						// height="500"
						title="discord"
						style={{ width: '100%', height: '100%' }}
						allowtransparency="true"
						frameborder="0"
						sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
					></iframe>
				</div>
			</div>
			<div className="row" style={{ display: 'flex', marginTop: '3rem', padding: '3rem' }}>
				<div className="col-md-2"></div>
				<div className="col-md-3">
					<div style={{ display: 'flex' }}>
						<Email />
						<p>{t('email_address')}</p>
					</div>
				</div>
				<div className="col-md-3">
					<div style={{ display: 'flex' }}>
						<LocationOn />
						<p>{t('headquarter_address')}</p>
					</div>
				</div>
				<div className="col-md-3">
					<div style={{ display: 'flex' }}>
						<LocalPhone />
						<p>{t('phone_number')}</p>
					</div>
				</div>
				<div style={{ marginBottom: '8rem' }}></div>
			</div>
		</div>
	);
};

export default Contact;
