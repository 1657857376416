import React, { useContext } from 'react';
import templates from '../../pages/templatesList';
import { BareTemplates } from '../wizard-templates';
import AdvancedWizardSection from './advanced-wizard-section';
import advancedWizardContext from './advance-wizard-context';
import { cloneDeep } from 'lodash';

export const TemplateSection = () => {
	const { setCurrentSimulation, template, setTemplate, currentSimulationName } = useContext(advancedWizardContext);
	return (
		<AdvancedWizardSection>
			<BareTemplates
				optionId={template}
				setOptionId={v => {
					setTemplate(v);
					setCurrentSimulation({ ...cloneDeep(templates.find(t => t.id === v)), id: currentSimulationName });
				}}
			/>
		</AdvancedWizardSection>
	);
};
