import React from 'react';
import { useTranslation } from 'react-i18next';
import { map } from 'lodash';
import { Carousel } from 'react-bootstrap';

export function RecognitionResults({ recognition, handleRecognitionPlantSelect, setRecognition }) {
	const { t } = useTranslation();
	return (
		<div>
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					textAlign: 'center',
					overflow: 'auto',
				}}
			>
				<div
					style={{
						maxWidth: '100%',
						height: '80vh',
					}}
				>
					{map(recognition, (r, index) => {
						return (
							<React.Fragment key={index}>
								<Carousel interval={null}>
									{map(r.images, i => {
										return (
											<Carousel.Item>
												<img style={{ width: '100%' }} src={i.url.m || i.url.s} alt={r.species.commonNames[0]} />
												<Carousel.Caption></Carousel.Caption>
											</Carousel.Item>
										);
									})}
								</Carousel>
								{r.species.commonNames.length > 0 ? (
									<p style={{ fontWeight: 'bold', marginTop: '1rem', marginBottom: '1rem' }}>
										{r.species.commonNames.join(', ')}
									</p>
								) : (
									''
								)}

								<p style={{ fontStyle: 'italic' }}>{r.species.scientificNameWithoutAuthor}</p>
								<div style={{ marginBottom: '1rem' }}>
									<button className="btn btn-outline-dark mr-2" onClick={() => setRecognition()}>
										{t('cancel_button_label')}
									</button>
									<button className="btn btn-dark mr-2" onClick={() => handleRecognitionPlantSelect(r)}>
										{t('select')}
									</button>
								</div>
							</React.Fragment>
						);
					})}
				</div>
			</div>
		</div>
	);
}
