import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { createToken, getToken } from '../../../services/users';
import defaultContext from '../../../defaultContext';
import { Skeleton } from '@material-ui/lab';

export function ApiToken() {
	const { t } = useTranslation();
	const { user } = useContext(defaultContext);
	const [token, setToken] = useState();
	const [ready, setReady] = useState(false);

	useEffect(() => {
		const f = async () => {
			try {
				const t = await getToken(user.userName);
				setToken(t);
			} catch (e) {
				console.error(e);
			} finally {
				setReady(true);
			}
		};
		f();
	}, [user.userName]);

	const handleCreateToken = async () => {
		try {
			setReady(false);
			const t = await createToken(user.userName);
			setToken(t);
		} catch (e) {
			console.error(e);
		} finally {
			setReady(true);
		}
	};
	return (
		<div style={{ display: 'flex', flexDirection: 'row' }}>
			<p style={{ marginRight: '1rem' }}>{t('api_token')}:</p>
			{ready ? (
				token ? (
					<div>
						<p>{token}</p>
					</div>
				) : (
					<div className="form-check">
						<button className="btn btn-dark" onClick={handleCreateToken}>
							{t('request_api_token_button')}
						</button>
					</div>
				)
			) : (
				<Skeleton width={'8em'} />
			)}
		</div>
	);
}
