import React, { useRef, useMemo } from 'react';
import { rgbf, colorGradient } from '../utils';
import { PlaneGeometry, DoubleSide } from 'three';

const Box = ({
	alt,
	activeId,
	setActiveId,
	block: {
		id,
		height,
		top,
		incline,
		incline2,
		position1: [x1, y1],
		position2: [x2, y2],
		reflectivity,
	},
}) => {
	const isActive = activeId === `blocks_${id}`;
	const mesh = useRef();

	let pos, rotation, incl, geom, width, actualHeight;
	if (top) {
		width = Math.abs(x2 - x1);
		actualHeight = Math.abs(y2 - y1);
		incl = [(incline * Math.PI) / 180, 0, 0];
		pos = [(x1 + x2) / 2, alt + height, (y1 + y2) / 2];
		rotation = [Math.PI / 2, (incline2 * Math.PI) / 180, 0];
	} else {
		width = Math.sqrt((x2 - x1) ** 2 + (y2 - y1) ** 2);
		actualHeight = height;
		const theta = Math.atan((x2 - x1) / (y2 - y1)) + Math.PI / 2;

		incl = [(incline * Math.PI) / 180, 0, 0];
		pos = [(x1 + x2) / 2, alt + height / 2, (y1 + y2) / 2];
		rotation = [0, theta, 0];
	}

	geom = useMemo(() => new PlaneGeometry(width, actualHeight), [width, actualHeight]);

	if (width === 0 || actualHeight === 0) {
		return <></>;
	}

	return (
		<group rotation={rotation} position={pos}>
			<mesh
				ref={mesh}
				rotation={incl}
				onPointerDown={e => {
					if (setActiveId) setActiveId(`blocks_${id}`);
				}}
			>
				<lineSegments>
					<edgesGeometry attach="geometry" args={[geom]} />
					<lineBasicMaterial color={isActive ? '#ff0000' : '#000000'} width={0.1} attach="material" />
				</lineSegments>
				<planeGeometry attach="geometry" args={[width, actualHeight]} />
				<meshBasicMaterial
					attach="material"
					opacity={0.5}
					reflectivity={1}
					transparent={true}
					side={DoubleSide}
					color={colorGradient(reflectivity, rgbf(0, 0, 0), rgbf(255, 255, 255))}
				/>
			</mesh>
		</group>
	);
};

export default Box;

// import React, { useRef, useState, useMemo, useCallback } from 'react'
// import {
//     Shape,
//     MeshPhongMaterial,
//     ExtrudeGeometry,
//     Vector3,
//     DoubleSide
//   } from 'three'

// function Box({ alt, block:{height, top, incline, position1:[x1,y1], position2:[x2,y2]}}) {
//     const points = [[x1,y1,0],[x2,y2,0],[x2,y2,height],[x1,y1,height]].map(v=>new Vector3(...v))
//     const shape = Shape(points)
//     const extrudeSettings = { steps: 1, depth: 1, bevelEnabled: false };
//     const edgeSideGeometry = new ExtrudeGeometry(shape, extrudeSettings);
//     const edgeMaterial = new MeshPhongMaterial( { side: DoubleSide} )
//   return (
//       <object3D>
//     <mesh
//         args={[edgeSideGeometry, edgeMaterial]}
//         />
//         </object3D>
//   )
// }

// export default Box;
