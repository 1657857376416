import React, { Suspense } from 'react';
import { useLoader } from 'react-three-fiber';
import { SVGLoader } from 'three/examples/jsm/loaders/SVGLoader';
import { Group, MeshBasicMaterial, Color, DoubleSide, ShapeBufferGeometry, Mesh } from 'three';
import arrow from '../../../assets/three/img/arrow.svg';
import human from '../../../assets/three/img/human.svg';

function groupAddFillMaterial(group, path, material) {
	var shapes = path.toShapes(true);
	for (var j = 0; j < shapes.length; j++) {
		var geometry = new ShapeBufferGeometry(shapes[j]);
		var mesh = new Mesh(geometry, material);
		group.add(mesh);
	}
}

function groupAddStrokeMaterial(group, path, material) {
	for (var j = 0; j < path.subPaths.length; j++) {
		var geometry = SVGLoader.pointsToStroke(path.subPaths[j].getPoints(), path.userData.style);
		if (geometry) {
			var mesh = new Mesh(geometry, material);
			group.add(mesh);
		}
	}
}

function assignMaterial(group, path, fill = true) {
	var color = fill ? path.userData.style.fill : path.userData.style.stroke;
	if (color !== undefined && color !== 'none') {
		var material = new MeshBasicMaterial({
			color: new Color().setStyle(color),
			opacity: fill ? path.userData.style.fillOpacity : path.userData.style.strokeOpacity,
			transparent: fill ? path.userData.style.fillOpacity < 1 : path.userData.style.strokeOpacity < 1,
			side: DoubleSide,
			depthWrite: false,
		});

		if (fill) {
			groupAddFillMaterial(group, path, material);
		} else {
			groupAddStrokeMaterial(group, path, material);
		}
	}
}

function assignGeneralMaterial(group, path) {
	assignMaterial(group, path, true);
	assignMaterial(group, path, false);
}

function Arrow(props) {
	const data = useLoader(SVGLoader, arrow);

	var paths = data.paths;

	var group = new Group();
	group.scale.multiplyScalar(0.0025);
	group.rotation.y = Math.PI;
	group.rotation.x = Math.PI / 2;
	group.rotation.z = -Math.PI / 2;
	group.position.x = props.depth - 1.2;
	group.position.z = 0.25;
	group.position.y = 0;
	group.scale.y *= -1;

	paths.forEach(path => {
		assignGeneralMaterial(group, path);
	});

	return <primitive object={group.clone()} {...props} />;
}

function Human(props) {
	const data = useLoader(SVGLoader, human);

	var paths = data.paths;

	var group = new Group();
	group.scale.multiplyScalar(0.0025);
	group.rotation.y = Math.PI / 2;
	group.rotation.x = 0;
	group.position.x = props.depth;
	group.position.y = 1.7;
	group.scale.y *= -1;

	paths.forEach(path => {
		assignGeneralMaterial(group, path);
	});

	return <primitive object={group.clone()} {...props} />;
}

function Box() {
	return (
		<mesh>
			<boxBufferGeometry attach="geometry" args={[1, 1, 1]} />
			<meshStandardMaterial attach="material" transparent opacity={0.5} />
		</mesh>
	);
}

export default props => {
	return (
		<Suspense fallback={<Box />}>
			<Arrow {...props} />
			<Human {...props} />
		</Suspense>
	);
};
