import React from 'react';
import { useTranslation } from 'react-i18next';
import facebook from '../../assets/images/icons/facebook.svg';
import twitter from '../../assets/images/icons/twitter.svg';
import instagram from '../../assets/images/icons/instagram.svg';
import youtube from '../../assets/images/icons/youtube.svg';
import linkedin from '../../assets/images/icons/linkedin.svg';

const styles = {
	container: {
		backgroundColor: '#FFF',
		color: '#4F4F4F',
		textAlign: 'left',
		borderTop: '1px solid #7ABB4E',
	},
	img: {
		width: '1rem',
		height: '1rem',
		marginLeft: '0.5rem',
	},
	copyRight: {
		display: 'flex',
		justifyContent: 'flex-end',
		height: '100%',
		marginBottom: 0,
		alignItems: 'center',
		color: '#4F4F4F',
		fontFamily: 'PT Sans',
		fontSize: '14px',
		lineHeight: '21px',
		textAlign: 'center',
	},
	footerStyle: {
		backgroundColor: '#FFF',
		color: '#4F4F4F',
		textAlign: 'left',
		borderTop: '1px solid #7ABB4E',
		padding: '20px',
		position: 'fixed',
		left: '0',
		bottom: '0',
		height: '60px',
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},

	phantomStyle: {
		display: 'block',
		width: '100%',
	},
	handles: {
		maxWidth: '50%',
		display: 'flex',
		justifyContent: 'start',
	},
};

function Footer() {
	const { t } = useTranslation();
	return (
		<div style={{ zIndex: 1000 }}>
			<div style={styles.phantomStyle} />
			<div style={styles.footerStyle}>
				<div style={styles.handles}>
					<a href="https://twitter.com/aiplantcare">
						<img style={styles.img} src={twitter} alt="twitter" />
					</a>
					<a href="https://www.facebook.com/ai.plantcare">
						<img style={styles.img} src={facebook} alt={t('facebook')} />
					</a>
					<a href="https://www.instagram.com/aiplantcare">
						<img style={styles.img} src={instagram} alt={t('instagram')} />
					</a>
					<a href="https://www.linkedin.com/in/ai-plantcare-23b35a201">
						<img style={styles.img} src={linkedin} alt={t('linkedin')} />
					</a>
					<a href="https://www.youtube.com/channel/UCsbBXoW_OfhNMGnbL1ZkUqA?view_as=subscriber">
						<img style={styles.img} src={youtube} alt={t('youtube')} />
					</a>
				</div>
				<div>
					<p style={styles.copyRight}>{t('copy_right_label')}</p>
				</div>
			</div>
		</div>
	);
}

export default Footer;
