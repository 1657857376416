const azureHeaders = {
	Accept: 'application/json',
	'x-functions-key': process.env.REACT_APP_CODE,
	'Content-Type': 'application/json',
	'Access-Control-Allow-Origin': '*',
};

export async function post(url = '', data = {}) {
	const response = await fetch(url, {
		method: 'POST',
		headers: azureHeaders,
		body: JSON.stringify(data),
	});
	if (response.status !== 200) {
		const error = await response.text();
		throw error;
	}
	const result = await response.json();
	return result;
}

export async function put(url = '', data = {}) {
	const response = await fetch(url, {
		method: 'PUT',
		headers: azureHeaders,
		body: JSON.stringify(data),
	});
	if (response.status !== 200) {
		const error = await response.text();
		throw error;
	}
	const result = await response.json();
	return result;
}

export async function del(url = '') {
	const response = await fetch(url, {
		method: 'DELETE',
		headers: azureHeaders,
	});
	if (response.status !== 200) {
		const error = await response.text();
		throw error;
	}
	const result = await response.json();
	return result;
}

export async function get(url = '') {
	const response = await fetch(url, {
		method: 'GET',
		headers: azureHeaders,
	});
	if (response.status !== 200) {
		const error = await response.text();
		throw error;
	}
	const result = await response.json();
	return result;
}

export async function getPublic(url = '') {
	const response = await fetch(url, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
	});
	const result = await response.json();
	return result;
}

export async function postPublic(url = '', data = {}) {
	const response = await fetch(url, {
		method: 'POST',
		mode: 'no-cors',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(data),
	});
	const result = await response.json();
	return result;
}
