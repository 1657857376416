import React from 'react';
import { rgbf, colorGradient } from '../utils';

const Lamp = ({ position, ppfd }) => {
	return (
		<mesh position={position}>
			<sphereBufferGeometry args={[0.2, 30, 30]} attach="geometry" />
			<meshBasicMaterial color={colorGradient(ppfd, rgbf(255, 237, 186), rgbf(255, 188, 0))} attach="material" />
		</mesh>
	);
};

export default Lamp;
