import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import defaultContext from '../../../defaultContext';
import { endsWith, isEmpty, range, toLower } from 'lodash';
import { getProject, upsertProject } from '../../../services/projects';
import { getProjects } from '../../../services/users';

export const UpdateCard = ({ id, setProjects, editing, setEditing }) => {
	const { t } = useTranslation();
	const { setCurrentSimulationName, setCurrentSimulation, currentSimulation, currentSimulationName, user } =
		useContext(defaultContext);
	const [localCurrentSimulation, setLocalCurrentSimulation] = useState();
	const [error, setError] = useState();
	const [dirty, setDirty] = useState(false);

	useEffect(() => {
		if (editing) {
			const { id, resolution, individualWin, maxPar, skyMode, rayTracingQuality, epw } = currentSimulation;
			const temp = { id, resolution, individualWin, maxPar, skyMode, rayTracingQuality, epw };

			setLocalCurrentSimulation(temp);
		}
	}, [editing, currentSimulation]);

	const handleStartUpdate = id => {
		getProject(user.userName, id)
			.then(result => {
				setCurrentSimulation(result);
				setCurrentSimulationName(result.id);
				setEditing(true);
			})
			.catch(console.error);
	};

	const handleFinishUpdate = async id => {
		const temp = { ...currentSimulation, ...localCurrentSimulation };

		try {
			await upsertProject(user.userName, currentSimulationName, temp);
			setCurrentSimulation(temp);
			setCurrentSimulationName(temp.id);
			const updatedProjects = await getProjects(user.userName);
			setEditing(false);
			setProjects(updatedProjects);
		} catch (e) {
			console.error(e);
		}
	};

	const updateSim = (key, value) => {
		setDirty(true);
		setLocalCurrentSimulation({ ...localCurrentSimulation, [key]: value });
	};

	const styles = {
		textInputStyle: {},
	};
	const handleEwpInput = e => {
		const value = e.target.value;
		if (isEmpty(value)) {
			setError();
			updateSim('epw', e.target.value);
			return;
		}
		try {
			new URL(value);
		} catch (err) {
			setError(t('ewp_valid_url_error'));
			return;
		}
		if (!isEmpty(value) && !(endsWith(toLower(value), '.epw') || endsWith(toLower(value), '.zip'))) {
			setError(t('ewp_suffix_error'));
			return;
		}
		setError();
		updateSim('epw', e.target.value);
	};
	return editing ? (
		!localCurrentSimulation ? (
			<></>
		) : (
			<div style={{ display: 'flex', flexDirection: 'column' }}>
				<div className="form">
					<div className="form-group mb-2">
						<label htmlFor={'update-project-name' + id}>{t('project_name_label')}</label>
						<input
							style={styles.textInputStyle}
							id={'update-project-name' + id}
							className="form-control"
							type="text"
							value={localCurrentSimulation.id}
							onChange={e => updateSim('id', e.target.value)}
						/>
					</div>
					<div className="form-group mb-2">
						<label htmlFor={'update-resolution-name' + id}>{t('resolution_label')}</label>

						{localCurrentSimulation.resolution < 0.1 || localCurrentSimulation.resolution > 10 ? (
							<span style={{ color: 'red', marginLeft: '1em' }}>{t('resolution_guide_text')}</span>
						) : (
							<></>
						)}
						<input
							style={{ width: '10em' }}
							id={'update-resolution-name' + id}
							className="form-control"
							type="number"
							value={localCurrentSimulation.resolution}
							onChange={e => updateSim('resolution', parseFloat(e.target.value))}
						/>
						{localCurrentSimulation.windows && localCurrentSimulation.windows.length > 0 ? (
							<div className="form-check mt-1">
								<input
									id={'update-individualWin-name' + id}
									className="form-check-input"
									type="checkbox"
									checked={localCurrentSimulation.individualWin || false}
									onClick={() => updateSim('individualWin', !localCurrentSimulation.individualWin)}
								/>
								<label className="form-check-label" htmlFor={'update-individualWin-name' + id}>
									{t('generate_result_text')}
								</label>
							</div>
						) : (
							<></>
						)}
						<div className="form-check mt-1">
							<input
								id={'update-maxPar-name' + id}
								className="form-check-input"
								type="checkbox"
								checked={localCurrentSimulation.maxPar || false}
								onClick={() => updateSim('maxPar', !localCurrentSimulation.maxPar)}
							/>
							<label className="form-check-label" htmlFor={'update-maxPar-name' + id}>
								{t('calculate_maximum_par_text')}
							</label>
						</div>

						{/* <div className="form-check mt-1">
							<input
								id={'update-skyMode-name' + id}
								className="form-check-input"
								type="checkbox"
								checked={localCurrentSimulation.skyMode === 2}
								onClick={() =>
									updateSim('skyMode', !localCurrentSimulation.skyMode || localCurrentSimulation.skyMode === 1 ? 2 : 1)
								}
							/>
							<label className="form-check-label" htmlFor={'update-skyMode-name' + id}>
								{t('clear_sky')}
							</label>
						</div> */}
						<div className="form-select mt-1">
							<label htmlFor={'update-rayTracingQuality-name' + id}>{t('ray_tracing_quality_text')}</label>
							<select
								style={{ width: '10em' }}
								className="custom-select ml-1"
								id="update-rayTracingQuality-name"
								onChange={e => updateSim('rayTracingQuality', parseInt(e.target.value))}
							>
								{range(1, 4, 1).map(i => (
									<option key={i} value={i} selected={localCurrentSimulation.rayTracingQuality === i ? 'selected' : ''}>
										{t('ray_tracing_quality_options_' + i)}
									</option>
								))}
							</select>
						</div>
					</div>
					<div className="form-group mb-2">
						<label htmlFor={'ewp' + id}>{t('epw')}</label>
						<input
							style={styles.textInputStyle}
							id={'ewp' + id}
							className="form-control"
							type="text"
							value={localCurrentSimulation.epw}
							onChange={handleEwpInput}
						/>
						{error ? <p style={{ color: 'red' }}>{error}</p> : <></>}
					</div>
				</div>
				<div>
					<button style={{ marginTop: '2em' }} className="btn btn-dark mr-2" onClick={() => setEditing(false)}>
						{t('cancel_button_label')}
					</button>
					<button
						style={{ marginTop: '2em' }}
						className="btn btn-dark mr-2"
						disabled={!dirty}
						onClick={() => handleFinishUpdate(id)}
					>
						{t('save_button_label')}
					</button>
				</div>
			</div>
		)
	) : (
		<div>
			<button className="btn btn-dark mr-2" onClick={() => handleStartUpdate(id)}>
				{t('update_button_label')}
			</button>
		</div>
	);
};
