import React, { useRef, useMemo } from 'react';
import { BoxBufferGeometry } from 'three';

const Box = ({ width, depth, alt }) => {
	const mesh = useRef();

	const geom = useMemo(() => new BoxBufferGeometry(depth, alt, width), [depth, alt, width]);
	return alt > 0 ? (
		<mesh ref={mesh} position={[0, alt / 2, 0]}>
			{/* <boxBufferGeometry attach="geometry" args={[depth, alt, width]} /> */}
			<lineSegments>
				<edgesGeometry attach="geometry" args={[geom]} />
				<lineBasicMaterial color="#aaaaaa" width={0.1} attach="material" />
			</lineSegments>
		</mesh>
	) : (
		<></>
	);
};

export default Box;
