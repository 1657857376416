import React, { useState, useContext, useRef, useEffect } from 'react';
import defaultContext from '../../../defaultContext';
import Scene from '../room-builder/scene';
import templates from '../../pages/templatesList';
import { Redirect } from 'react-router';
import { upsertProject } from '../../../services/projects';

import advancedWizardContext from './advance-wizard-context';
import { CurrentSection } from './current-section';
import {
	updateCurrentSimulationBase,
	handleArrayEntityChangeBase,
	handleAddEntityBase,
	handleRemoveEntityBase,
} from '../utils';
import { last } from 'lodash';
import ModalCarousel from '../modal-carousel';

const AdvancedWizard = () => {
	const { currentSimulation, setCurrentSimulation, currentSimulationName, user, projects, setProjects } =
		useContext(defaultContext);
	const [template, setTemplate] = useState(templates[0].id);
	const [index, setIndex] = useState(0);
	const [redirect, setRedirect] = useState(false);
	const [marks, setMarks] = useState([]);
	const [step, setStep] = useState('template');
	const mapClickFunc = useRef();
	const centerMapFunc = useRef();
	const nextFunc = useRef();
	const nextStep = useRef();
	const redrawEntity = useRef();
	const skipStep = useRef();
	const handleNext = useRef();

	useEffect(() => {
		if (!currentSimulation) {
			setCurrentSimulation({ ...templates[0], id: currentSimulationName });
		}
	});

	const updateCurrentSimulation = (key, value) => {
		updateCurrentSimulationBase({ [key]: value }, currentSimulation, setCurrentSimulation);
	};

	const updateCurrentSimulationKeyValues = keyValues => {
		updateCurrentSimulationBase(keyValues, currentSimulation, setCurrentSimulation);
	};

	const handleArrayEntityChange = (arrayEntityName, index, prop, value) => {
		handleArrayEntityChangeBase(arrayEntityName, index, prop, value, currentSimulation, setCurrentSimulation);
	};

	const handleAddEntity = (arrayEntityName, value) => {
		handleAddEntityBase(arrayEntityName, value, currentSimulation, setCurrentSimulation);
	};

	const handleRemoveEntity = (arrayEntityName, id) => {
		id = id || last(currentSimulation[arrayEntityName]).id;
		handleRemoveEntityBase(arrayEntityName, id, currentSimulation, setCurrentSimulation);
	};

	const handleFinish = () => {
		const { func, templateInputs, templateId, ...rest } = currentSimulation;
		upsertProject(user.userName, currentSimulation.id, rest)
			.then(() => {
				setCurrentSimulation(rest);
				setRedirect(true);
				const exist = projects?.find(f => f.id === currentSimulation.id);
				if (!exist) setProjects([...projects, { id: currentSimulation.id, status: null }]);
			})
			.catch(console.error);
	};

	if (!currentSimulation) {
		return <></>;
	}

	if (redirect) {
		return <Redirect push to="/dashboard" />;
	}

	return (
		<>
			<ModalCarousel />
			<advancedWizardContext.Provider
				value={{
					step,
					setStep,
					index,
					setIndex,
					handleNext,
					nextStep,
					redrawEntity,
					skipStep,
					nextFunc,
					mapClickFunc,
					currentSimulation,
					setCurrentSimulation,
					template,
					setTemplate,
					setRedirect,
					marks,
					setMarks,
					updateCurrentSimulation,
					handleRemoveEntity,
					handleAddEntity,
					handleArrayEntityChange,
					updateCurrentSimulationKeyValues,
					centerMapFunc,
					handleFinish,
					currentSimulationName,
				}}
			>
				<CurrentSection
					setStep={setStep}
					hasRoom={currentSimulation && currentSimulation.hasRoom}
					id={currentSimulation.templateId}
				/>
				{step === 'map' ? (
					<></>
				) : (
					<Scene
						onMapClick={mapClickFunc}
						centerMapFunc={centerMapFunc}
						view={step === 'template' ? 'rotate' : 'top'}
						showPlants={step === 'template' || step?.startsWith('workplane-')}
						className="full-screen"
						currentSimulation={{ ...currentSimulation, marks }}
					/>
				)}
			</advancedWizardContext.Provider>
		</>
	);
};

export default AdvancedWizard;
