import React, { useState, useEffect, useContext } from 'react';
import defaultContext from '../../../defaultContext';
import { getUserPrivileges } from '../../../services/users';
import { Tab, Tabs } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { makeStyles } from '@material-ui/core/styles';
import { MyPlants } from './MyPlants';
import { General } from './General';
import { Simulations } from './Simulations';
import { Payments } from './Payments';
import { Disclaimer } from './Disclaimer';

const useStyles = makeStyles({
	root: {
		background: '#116f0e',
	},
});

const Dashboard = () => {
	const [active, setActive] = useState(0);
	const [waiting, setWaiting] = useState(true);
	const [showDisclaimer, setShowDisclaimer] = useState(true);
	const classes = useStyles();
	const labels = ['Projects', 'Plants', 'General Settings', 'Payments'];

	const { user, privileges, setPrivileges } = useContext(defaultContext);

	useEffect(() => {
		if (privileges) {
			setWaiting(false);
			return;
		}
		setWaiting(true);
		getUserPrivileges(user.userName)
			.then(r => {
				setPrivileges(r);
				setWaiting(false);
			})
			.catch(e => console.error(e));
	}, [user.userName, privileges, setPrivileges]);

	return waiting ? (
		<></>
	) : (
		<div className="container">
			<div className="row">
				<div className="col-md-3">
					<Tabs
						orientation={isMobile ? 'horizontal' : 'vertical'}
						classes={{ indicator: classes.root }}
						className={isMobile ? 'fixed-bottom' : ''}
						variant="scrollable"
						value={active}
						onChange={(event, newValue) => setActive(newValue)}
						aria-label="tabs"
					>
						{labels.map((l, i) => (
							<Tab label={l} key={i} />
						))}
					</Tabs>
				</div>
				<div className="col-md-9">
					{
						{
							0: <Simulations />,
							1: <MyPlants />,
							2: <General />,
							3: <Payments />,
						}[active]
					}
					{isMobile ? <div style={{ height: '4rem' }}></div> : <></>}
				</div>
				<Disclaimer show={showDisclaimer} setShow={setShowDisclaimer} />
			</div>
		</div>
	);
};

export default Dashboard;

