import React, { useContext } from 'react';
import AdvancedWizardSection from './advanced-wizard-section';
import { useTranslation } from 'react-i18next';
import { debounce, last, filter, each } from 'lodash';
import advancedWizardContext from './advance-wizard-context';
import Slider from '../../ui-elements/slider';

const snapToGrid = (currentSimulation, x, y) => {
	const tolerance = 1;
	const dist = (x1, y1, x2, y2) => Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);

	let [xr, yr] = [x, y];
	let minDis = Infinity;
	each(
		filter(currentSimulation.blocks, f => !f.top),
		b => {
			let d = dist(b.position1[0], b.position1[1], x, y);
			if (d < minDis && d < tolerance) {
				minDis = d;
				[xr, yr] = b.position1;
			}
			d = dist(b.position2[0], b.position2[1], x, y);

			if (d < minDis && d < tolerance) {
				minDis = d;
				[xr, yr] = b.position2;
			}
		},
	);
	return [xr, yr];
};

export const BlockAddSectionOne = () => {
	const { t } = useTranslation();
	const { mapClickFunc, setMarks, redrawEntity, handleNext, currentSimulation } = useContext(advancedWizardContext);

	redrawEntity.current = 'blocks';

	mapClickFunc.current = debounce((x, y) => {
		[x, y] = snapToGrid(currentSimulation, x, -y);
		setMarks([{ id: 0, x, y }]);
		handleNext.current();
	}, 150);
	return (
		<AdvancedWizardSection>
			<p>
				{filter(currentSimulation.blocks, b => !b.top).length > 0
					? t('start_block_section_guide')
					: t('start_first_block_section_guide')}
			</p>
		</AdvancedWizardSection>
	);
};
export const BlockAddSectionTwo = () => {
	const { t } = useTranslation();
	const { marks, setMarks, handleAddEntity, mapClickFunc, handleNext, currentSimulation } = useContext(
		advancedWizardContext,
	);
	mapClickFunc.current = debounce((x2, y2) => {
		[x2, y2] = snapToGrid(currentSimulation, x2, -y2);
		const x1 = marks[0].x;
		const y1 = marks[0].y;

		handleAddEntity('blocks', {
			height: 10,
			position1: [x1, y1],
			position2: [x2, y2],
			incline: 0,
			incline2: 0,
			top: false,
			reflectivity: 0.5,
		});
		setMarks([...marks, { id: 1, x: x2, y: y2 }]);
		handleNext.current();
	}, 150);

	return (
		<AdvancedWizardSection>
			<p>{t('end_block_section_guide')}</p>
		</AdvancedWizardSection>
	);
};
export const BlocksSizeSection = () => {
	const { t } = useTranslation();
	const { currentSimulation, handleArrayEntityChange, globalStep, mapClickFunc } = useContext(advancedWizardContext);

	mapClickFunc.current = () => {};
	let { blocks, height } = currentSimulation;

	let block = last(blocks);
	return (
		<AdvancedWizardSection>
			<Slider
				label={t('blocksPanel_height_label')}
				value={block.height}
				onChange={value => handleArrayEntityChange('blocks', blocks.length - 1, 'height', value)}
				min={0.1}
				max={height * 10}
				step={globalStep}
			/>
		</AdvancedWizardSection>
	);
};
export const BlocksDetailsSection = () => {
	const { t } = useTranslation();
	const { currentSimulation, handleArrayEntityChange } = useContext(advancedWizardContext);
	let { blocks } = currentSimulation;
	let block = last(blocks);

	return (
		<AdvancedWizardSection>
			<Slider
				label={t('blocksPanel_reflectivity_label')}
				value={block.reflectivity}
				onChange={value => handleArrayEntityChange('blocks', blocks.length - 1, 'reflectivity', value)}
				min={0}
				max={1}
				step={0.01}
			/>
		</AdvancedWizardSection>
	);
};
