import React from 'react';

const RadioImage = ({ options, style, name, checked, onClick }) => {
	return (
		<div>
			{options.map(option => (
				<React.Fragment key={option.id}>
					<input
						type="radio"
						name={name}
						disabled={option.disabled}
						id={name + '-' + option.id}
						value={option.id}
						className="input-hidden"
						defaultChecked={option.id === checked}
						onClick={() => onClick(option.id)}
					/>
					<label htmlFor={name + '-' + option.id} style={{opacity:option.disabled?0.1:1}}>
						<img src={option.image} style={style} alt="option" />
						{option.sub ? <h4 style={{display:'flex', justifyContent:'center'}}>{option.sub}</h4>:<></>}
					</label>
				</React.Fragment>
			))}
		</div>
	);
};

export default RadioImage;
