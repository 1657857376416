import React, { useContext } from 'react';
import AdvancedWizardSection from './advanced-wizard-section';
import { useTranslation } from 'react-i18next';
import RadioButtonMaker from '../radio-button';
import advancedWizardContext from './advance-wizard-context';
import Slider from '../../ui-elements/slider';
import { findIndex, includes } from 'lodash';

export const WindowFaceSection = () => {
	const { currentSimulation, index, handleArrayEntityChange } = useContext(advancedWizardContext);
	let { windows } = currentSimulation;
	let window = windows[index];
	return (
		<AdvancedWizardSection>
			<RadioButtonMaker
				inputsContent={[
					{
						id: 1,
						key: 'F',
						label: 'windowsPanel_window_face_front_label',
					},
					{
						id: 2,
						key: 'B',
						label: 'windowsPanel_window_face_back_label',
					},
					{
						id: 3,
						key: 'L',
						label: 'windowsPanel_window_face_left_label',
					},
					{
						id: 4,
						key: 'R',
						label: 'windowsPanel_window_face_right_label',
					},
					{
						id: 5,
						key: 'T',
						label: 'windowsPanel_window_face_top_label',
					},
				]}
				style={{ marginLeft: '1.5em', display: 'flex', flexDirection: 'column' }}
				name="wizard-windows-faces"
				value={window.face}
				onChange={v => handleArrayEntityChange('windows', index, 'face', v)}
			/>
		</AdvancedWizardSection>
	);
};
export const WindowSizeSection = () => {
	const { t } = useTranslation();
	const { currentSimulation, index, handleArrayEntityChange, globalStep } = useContext(advancedWizardContext);
	let { width, depth, height, windows } = currentSimulation;
	let window = windows[index];
	return (
		<AdvancedWizardSection>
			<Slider
				label={t('windowsPanel_window_width_label')}
				value={window.width}
				onChange={value => handleArrayEntityChange('windows', index, 'width', value)}
				min={0.1}
				max={(window.face === 'F' || window.face === 'B' ? width : depth) - window.position[0]}
				step={globalStep}
			/>
			<Slider
				label={t('windowsPanel_window_height_label')}
				value={window.height}
				onChange={value => handleArrayEntityChange('windows', index, 'height', value)}
				min={0.1}
				max={(window.face === 'T' ? width : height) - window.position[1]}
				step={globalStep}
			/>
			<Slider
				label={t('windowsPanel_window_x_label')}
				value={window.position[0]}
				onChange={value => handleArrayEntityChange('windows', index, 'position', [value, window.position[1]])}
				min={0}
				max={(window.face === 'F' || window.face === 'B' ? width : depth) - window.width}
				step={globalStep}
			/>
			<Slider
				label={t('windowsPanel_window_y_label')}
				value={window.position[1]}
				onChange={value => handleArrayEntityChange('windows', index, 'position', [window.position[0], value])}
				min={0}
				max={(window.face === 'T' ? width : height) - window.height}
				step={globalStep}
			/>
		</AdvancedWizardSection>
	);
};
export const WindowDetailsSection = () => {
	const { t } = useTranslation();
	const { currentSimulation, index, handleArrayEntityChange } = useContext(advancedWizardContext);
	let { windows } = currentSimulation;
	return (
		<AdvancedWizardSection>
			<Slider
				label={t('windowsPanel_window_transmissivity_label')}
				value={windows[index].transmissivity}
				onChange={value => handleArrayEntityChange('windows', index, 'transmissivity', value)}
				min={0}
				max={1}
				step={0.01}
			/>
		</AdvancedWizardSection>
	);
};
export const WindowAddSection = () => {
	const { t } = useTranslation();
	const { currentSimulation, nextStep, handleAddEntity, setIndex, redrawEntity, skipStep, nextFunc } = useContext(
		advancedWizardContext,
	);
	let { width, depth, height, windows, workPlanes } = currentSimulation;

	skipStep.current = workPlanes && workPlanes.length > 0 ? 'workplane-details' : 'workplane-add-1';
	nextStep.current = 'window-face';
	redrawEntity.current = 'windows';

	nextFunc.current = () => {
		const windowsFaces = ['F', 'B', 'L', 'R', 'T'];
		const index = findIndex(
			windowsFaces,
			f =>
				!includes(
					windows.map(g => g.face),
					f,
				),
		);
		const face = index < 0 ? 'F' : windowsFaces[index];

		handleAddEntity('windows', {
			width: winWidth / 2,
			height: height / 2,
			position: [winWidth / 4, height / 4],
			face,
			transmissivity: 1,
		});
		setIndex((windows || []).length || 0);
	};
	const winWidth = Math.min(width, depth);
	return (
		<AdvancedWizardSection>
			<p>{windows && windows.length > 0 ? t('add_another_window_section_guide') : t('add_a_window_section_guide')}</p>
		</AdvancedWizardSection>
	);
};
