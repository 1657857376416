import React from 'react';
import { Typography, Select, MenuItem } from '@material-ui/core';
import Expans from './expansion-panel';
import { useTranslation } from 'react-i18next';
import Slider from '../../ui-elements/slider';
import icon from '../../../assets/images/icons/workplanes.svg';
import { map } from 'lodash';

const WorkPlanesPanel = ({ workPlanes, handleChange, remove }) => {
	const { t } = useTranslation();
	const globalStep = 0.001;

	return (
		<Expans title={t('workPlanes_label')} icon={icon} id="workPlanes">
			{map(workPlanes, (workPlane, index) => (
				<Expans
					key={index}
					title={t('workPlanes_label') + index}
					icon={icon}
					id={'workPlanes_' + workPlane.id}
					remove={() => remove('workPlanes', workPlane.id)}
				>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Typography style={{ margin: '1em' }} gutterBottom>
							{t('windowsPanel_window_face_label')}
						</Typography>
						<Select
							style={{ flex: 1 }}
							labelId="label"
							id="select"
							value={workPlane.face}
							onChange={e => handleChange('workPlanes', index, 'face', e.target.value)}
						>
							<MenuItem value="F"> {t('workPlanesPanel_workPlane_front_label')}</MenuItem>
							<MenuItem value="B">{t('workPlanesPanel_workPlane_back_label')}</MenuItem>
							<MenuItem value="L">{t('workPlanesPanel_workPlane_left_label')}</MenuItem>
							<MenuItem value="R">{t('workPlanesPanel_workPlane_right_label')}</MenuItem>
							<MenuItem value="H">{t('workPlanesPanel_workPlane_ceiling_label')}</MenuItem>
						</Select>
					</div>
					<Slider
						label={t('workPlanesPanel_distance_label')}
						value={workPlane.distance}
						onChange={value => handleChange('workPlanes', index, 'distance', value)}
						min={-100}
						max={100}
						step={globalStep}
					/>
					<Slider
						label={t('workPlanesPanel_x_label')}
						value={workPlane.position1[0]}
						onChange={value => handleChange('workPlanes', index, 'position1', [value, workPlane.position1[1]])}
						min={-100}
						max={100}
						step={globalStep}
					/>
					<Slider
						label={t('workPlanesPanel_y_label')}
						value={workPlane.position1[1]}
						onChange={value => handleChange('workPlanes', index, 'position1', [workPlane.position1[0], value])}
						min={-100}
						max={100}
						step={globalStep}
					/>
					<Slider
						label={t('workPlanesPanel_x_label')}
						value={workPlane.position2[0]}
						onChange={value => handleChange('workPlanes', index, 'position2', [value, workPlane.position2[1]])}
						min={-100}
						max={100}
						step={globalStep}
					/>
					<Slider
						label={t('workPlanesPanel_y_label')}
						value={workPlane.position2[1]}
						onChange={value => handleChange('workPlanes', index, 'position2', [workPlane.position2[0], value])}
						min={-100}
						max={100}
						step={globalStep}
					/>
				</Expans>
			))}
		</Expans>
	);
};
export default WorkPlanesPanel;
