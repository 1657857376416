import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import GridSimple from './charts/grid-simple';
import {
	isEmpty,
	map,
	sortBy,
	reduce,
	find,
	sum,
	max,
	some,
	filter,
	mean,
	values,
	head,
	range,
	keys,
	slice,
	times,
	forEach,
} from 'lodash';
import { CircularProgress, NativeSelect } from '@material-ui/core';
import { ReportMeta } from './ReportMeta';
import Luminary from './luminary';
import { notify } from '../../../services/toast';
import { TextField } from '@material-ui/core';

import { Legend } from './charts/Legend';
import { getUserPlants } from '../../../services/plants';
import { upsertProject } from '../../../services/projects';
import { getMonthNames } from '../utils';
import { Delete, Add } from '@material-ui/icons';

const styles = {
	top: {
		position: 'fixed',
		top: '60px',
		left: 'calc(50% - 100px)',
	},
	bottom: {
		marginLeft: '2rem',
		position: 'fixed',
		bottom: '60px',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
	},
};
const ReportSimple = ({
	luminaries,
	setLuminaries,
	luminarySensorMap,
	sensors: { sensors, gridCameraPosition },
	currentSimulation,
	setCurrentSimulation,
	setDetails,
	meta,
	user,
	dirty,
	setDirty,
}) => {
	const { t } = useTranslation();

	const [pickedSensor, setPickedSensor] = useState();

	const [show, setShow] = useState('plants');
	const [selectedLuminary, setSelectedLuminary] = useState(head(luminaries)?.id);
	const [luminariesWorkingHours, SetLuminariesWorkingHours] = useState({});
	const [luminariesWorking, SetLuminariesWorking] = useState({});
	//const [averageUnderLit, setAverageUnderLit] = useState(0);
	const [plants, setPlants] = useState([]);
	const [autoPlaceMent, setAutoPlaceMent] = useState(!currentSimulation.disableAutoPlacement);
	const [selectedSensor, setSelectedSensor] = useState(null);
	const [waiting, setWaiting] = useState(false);
	const [irrigationMethod, setIrrigationMethod] = useState(currentSimulation.irrigationMethod ?? 0);
	const [irrigation, setIrrigation] = useState();

	const dliToNumberOfHours = (dli, ppfd) => {
		return Math.round((dli / (ppfd * 0.0864)) * 10) / 10;
	};

	const handleGetPlants = () => {
		getUserPlants(user.userName)
			.then(res => {
				if (isEmpty(res)) {
					notify(t('no_plants_notification_text'));
				} else {
					setPlants(values(res));
				}
			})
			.catch(e => {
				console.error(e);
			});
	};
	const pickSensor = useCallback(() => {
		if (!currentSimulation.plants || !currentSimulation.plants.length === 0) {
			return;
		}
		let tempLuminariesHours = luminarySensorMap.getTempLuminary();
		let tempLuminaries = luminarySensorMap.getTempLuminary();
		let underlit = {};

		const maxHoursOfArtificialLightOperation = 16;
		const sortedPlants = sortBy(
			map(currentSimulation.plants, (f, i) => (f.index ? f : { ...f, index: i + 1 })),
			f => -(f.dli[0] + f.dli[1]),
		);
		const sortedSensors = sortBy(
			map(Object.values(sensors), g => {
				let artificial = 0;
				const key = g.position.join('|');
				if (luminarySensorMap.luminaryMap[key] && luminarySensorMap.luminaryMap[key].length > 0) {
					for (const luminary of luminarySensorMap.luminaryMap[key]) {
						artificial += Math.round(luminary.ppfd * maxHoursOfArtificialLightOperation * 0.0864);
					}
				}
				return {
					...g,
					artificial,
					key,
					sensors: reduce(
						g.sensors,
						(a, s, i) => {
							a[i] = s;
							return a;
						},
						{},
					),
					meanDli: mean(g.dlisAll),
				};
			}),
			f => -(f.meanDli + f.artificial),
		);

		let sensorsMap = {};
		let takenSensors = {};
		let alreadyPlacedPlants = {};
		if (!autoPlaceMent) {
			for (const plant of sortedPlants) {
				if (!plant.sensors) {
					continue;
				}
				for (let i = 0; i < (plant.quantity ?? 1); i++) {
					const key = plant.index + '--' + i;
					const sensor = plant.sensors[i];
					if (!autoPlaceMent && plant.sensors && plant.sensors.length > 0 && sensor) {
						sensorsMap[key] = {
							...sensor,
							fixed: true,
							plantIndex: plant.index,
							plantDli: plant.dli,
							plantId: plant.id,
						};
						takenSensors[sensor.key] = true;
						alreadyPlacedPlants[key] = true;
						underlit[key] = handleLuminaryOperationHours(sensor, plant, key);
					}
				}
			}
		}
		for (const plant of sortedPlants) {
			for (let i = 0; i < (plant.quantity ?? 1); i++) {
				const key = plant.index + '--' + i;

				if (alreadyPlacedPlants[key]) {
					continue;
				}
				let distance = Infinity;
				let foundKey = null;
				for (const sensor of sortedSensors) {
					if (takenSensors[sensor.key]) continue;

					let current = Math.abs(mean(plant.dli) - (sensor.meanDli + sensor.artificial));
					if (current < distance) {
						sensorsMap[key] = {
							...sensor,
							plantIndex: plant.index,
							plantDli: plant.dli,
							plantId: plant.id,
						};
						foundKey = sensor.key;
						distance = current;

						underlit[key] = handleLuminaryOperationHours(sensor, plant, key);
					}
				}

				takenSensors[foundKey] = true;
			}
		}
		setPickedSensor(sensorsMap);
		SetLuminariesWorkingHours(tempLuminariesHours);
		SetLuminariesWorking(tempLuminaries);
		//setAverageUnderLit(mean(values(underlit)));
		setIrrigation(calculateIrrigation(currentSimulation, sensorsMap, irrigationMethod));
		function handleLuminaryOperationHours(sensor, plant, key) {
			const totalEnough = sensor.dlisAll.reduce(
				(a, i, day) => {
					const d = i - plant.dli[0];
					let sl = 0;
					if (
						d < 0 &&
						luminarySensorMap.luminaryMap[sensor.key] &&
						luminarySensorMap.luminaryMap[sensor.key].length > 0
					) {
						for (let lum of luminarySensorMap.luminaryMap[sensor.key]) {
							const nh = dliToNumberOfHours(-d, lum.ppfd);
							let slt = -d;
							if (nh > maxHoursOfArtificialLightOperation) {
								slt *= maxHoursOfArtificialLightOperation / nh;
							}
							const nhm = Math.min(nh, maxHoursOfArtificialLightOperation);
							tempLuminariesHours[lum.id][day] = Math.max(tempLuminariesHours[lum.id][day], nhm);
							tempLuminaries[lum.id][day] = Math.max(tempLuminaries[lum.id][day], slt);
							sl += slt;
							if (nh <= maxHoursOfArtificialLightOperation) break;
						}
					}
					if (d + sl < 0) {
						a.ul++;
					}
					return a;
				},
				{ ul: 0 },
			);
			return totalEnough.ul;
		}
	}, [sensors, currentSimulation, luminarySensorMap, autoPlaceMent, irrigationMethod]);

	useEffect(() => {
		pickSensor();
	}, [autoPlaceMent, pickSensor]);

	const onSelectSensor = sensor => {
		if (selectedSensor) {
			const prevSensor = find(values(pickedSensor), s => s.position.join('|') === selectedSensor);
			const currentSensorTaken = find(values(pickedSensor), s => s.position.join('|') === sensor.position.join('|'));
			if (currentSensorTaken) {
				notify('Please select a free spot');
				return;
			}
			//setPickedSensor({ ...pickedSensor, [prevSensor.plantId]: { ...prevSensor, ...sensor } });
			setCurrentSimulation({
				...currentSimulation,
				plants: currentSimulation.plants.map(p =>
					p.id === prevSensor.plantId
						? { ...p, sensors: [...filter(p.sensors, g => g.key !== prevSensor.key), sensor] }
						: p,
				),
			});
			setSelectedSensor();
			setAutoPlaceMent(false);
			setDirty(true);
		} else {
			setSelectedSensor(sensor.position.join('|'));
			notify('Select a free place to move the plant');
		}
	};

	const handleSave = async () => {
		let tempSim = { ...currentSimulation, luminaries: luminaries, irrigationMethod };
		tempSim.plants = tempSim.plants.map(p => {
			return { ...p, sensors: filter(pickedSensor, s => s.plantId === p.id) };
		});
		tempSim.disableAutoPlacement = !autoPlaceMent;
		if (luminaries && luminaries.length > 0) {
			tempSim.luminaries = luminaries;
		}

		setWaiting(true);
		try {
			await upsertProject(user.userName, tempSim.id, tempSim, true);
			setCurrentSimulation(tempSim);
			setWaiting(false);
			notify('Simulation saved');
			setDirty(false);
		} catch (e) {
			setWaiting(false);
			notify('Error saving simulation');
		}

		setWaiting(false);
	};

	const handleDownloadCalender = () => {
		console.log(irrigation);
	};

	const handleSetAutoPlaceMent = () => {
		setAutoPlaceMent(!autoPlaceMent);
		setCurrentSimulation({
			...currentSimulation,
			disableAutoPlacement: !autoPlaceMent,
			plants: map(currentSimulation.plants, p => ({ ...p, sensors: undefined })),
		});
		setDirty(true);
	};

	const handlePlantAdd = plant => {
		setCurrentSimulation({
			...currentSimulation,
			plants: [...currentSimulation.plants, { ...plant, index: currentSimulation.plants.length + 1, sensors: [] }],
		});
		setDirty(true);
	};
	const handlePlantDelete = plantId => {
		setCurrentSimulation({
			...currentSimulation,
			plants: map(
				sortBy(
					filter(currentSimulation.plants, (p, i) => i !== plantId),
					f => f.plantIndex,
				),
				(p, i) => ({ ...p, index: i + 1 }),
			),
		});
		setDirty(true);
	};
	const handlePlantOnChange = (plantId, prop, val) => {
		setCurrentSimulation({
			...currentSimulation,
			plants: map(currentSimulation.plants, (f, i) => (i === plantId ? { ...f, [prop]: val } : f)),
		});

		setDirty(true);
	};

	if (!currentSimulation) {
		return <></>;
	}

	return (
		<div style={{ width: '100%' }}>
			<GridSimple
				sensors={sensors}
				luminaryPositions={luminaries}
				luminaries={luminariesWorking}
				luminarySensorMap={luminarySensorMap.luminaryMap}
				gridCameraPosition={gridCameraPosition}
				pickedSensor={pickedSensor}
				currentSimulation={currentSimulation}
				onSelect={onSelectSensor}
				className="full-screen"
			/>
			<div style={styles.top}>
				<Legend
					labels={[t('simple_grid_low'), t('simple_grid_mid'), t('simple_grid_high')]}
					colors={[
						[55, 94, 197],
						[85, 161, 87],
						[255, 83, 83],
					]}
					overrideStyle={true}
				/>
			</div>
			<div style={styles.bottom}>
				<div
					style={
						show
							? {
									backgroundColor: 'rgba(0, 0, 0, .1) ',
									zIndex: 1000,
									marginBottom: '3rem',
									padding: '2rem',
									maxHeight: '40rem',
									overflowY: 'scroll',
							  }
							: {}
					}
				>
					{{
						meta: (
							<ReportMeta
								currentSimulation={currentSimulation}
								meta={meta}
								irrigationMethod={irrigationMethod}
								setIrrigationMethod={setIrrigationMethod}
								setDirty={setDirty}
							/>
						),
						environment: (
							<ReportEnvironmentParameters
								currentSimulation={currentSimulation}
								setCurrentSimulation={setCurrentSimulation}
								setDirty={setDirty}
							/>
						),
						plants: (
							<div>
								<PlantList
									plants={[...currentSimulation.plants, ...plants]}
									onChange={handlePlantOnChange}
									onDelete={handlePlantDelete}
									onAdd={handlePlantAdd}
								/>
								{plants.length === 0 ? (
									<button className="btn btn-dark" onClick={handleGetPlants}>
										{t('get-all-of-my-plants')}
									</button>
								) : null}
							</div>
						),
						lights: (
							<div>
								<button
									className="btn btn-dark"
									style={{ marginLeft: '2em', marginTop: '1em', width: '10rem' }}
									onClick={() => {
										const id = luminaries.length === 0 ? 0 : max(map(luminaries, f => f.id)) + 1;
										const wp = find(currentSimulation.workPlanes, f => f.id < 1000);
										setLuminaries([
											...luminaries,
											{
												id,
												wpId: wp.id,
												position: [0, 0],
												ppfd: 135,
												diameter: 1.77,
											},
										]);
										setSelectedLuminary(id);
										setDirty(true);
									}}
								>
									{t('report_add_luminary_button_label')}
								</button>

								{selectedLuminary !== undefined ? (
									<p style={{ marginTop: '2rem' }}>
										{t('average-working-hours')} {Math.round(sum(luminariesWorkingHours[selectedLuminary]) / 365)}
									</p>
								) : (
									<></>
								)}
								{luminaries.length > 1 ? (
									<div style={{ display: 'flex', flexDirection: 'row', padding: '2rem' }}>
										<label htmlFor="luminary-select" style={{ marginRight: '2rem' }}>
											{t('report_select_luminary_button_label')}
										</label>
										<NativeSelect
											value={selectedLuminary}
											onChange={event => setSelectedLuminary(parseInt(event.target.value))}
											inputProps={{
												name: 'luminary-select',
												id: 'luminary-select',
											}}
										>
											{map(luminaries, l => (
												<option key={l.id} value={l.id}>
													{l.id}
												</option>
											))}
										</NativeSelect>
									</div>
								) : (
									<></>
								)}
								<Luminary
									luminaries={luminaries}
									setLuminaries={setLuminaries}
									selectedId={selectedLuminary}
									setSelectedId={setSelectedLuminary}
									workPlanes={filter(currentSimulation.workPlanes, f => f.id < 1000)}
								/>
							</div>
						),
					}[show] ?? <></>}
				</div>
				<div style={styles.row}>
					<button className="btn btn-dark" style={{ marginLeft: '1em' }} onClick={() => setDetails(true)}>
						{t('report_details_button_label')}
					</button>
					<button
						style={{ marginLeft: '1rem' }}
						className="btn btn-dark"
						onClick={() => setShow(show === 'meta' ? null : 'meta')}
					>
						{t('meta-data')}
					</button>
					<button
						style={{ marginLeft: '1rem' }}
						className="btn btn-dark"
						onClick={() => setShow(show === 'plants' ? null : 'plants')}
					>
						{t('plants')}
					</button>
					{some(currentSimulation.workPlanes, f => f.id < 1000) ? (
						<button
							style={{ marginLeft: '1rem' }}
							className="btn btn-dark"
							onClick={() => setShow(show === 'lights' ? null : 'lights')}
						>
							{t('artificial-lights')}
						</button>
					) : null}
					{currentSimulation.environmentParameters ? (
						<button
							style={{ marginLeft: '1rem' }}
							className="btn btn-dark"
							onClick={() => setShow(show === 'environment' ? null : 'environment')}
						>
							{t('environment-parameters')}
						</button>
					) : null}
					{!autoPlaceMent ? (
						<button className="btn btn-dark" style={{ marginLeft: '1em' }} onClick={() => handleSetAutoPlaceMent()}>
							{t('auto-placement')}
						</button>
					) : null}
					{dirty ? (
						<button className="btn btn-dark" style={{ marginLeft: '1em' }} onClick={() => handleSave()}>
							{t('save')}
						</button>
					) : null}
					<button className="btn btn-dark" style={{ marginLeft: '1em' }} onClick={() => handleDownloadCalender()}>
						{t('download-calender')}
					</button>
					{waiting ? <CircularProgress style={{ position: 'absolute', top: '50%', left: '40%' }} /> : <></>}
				</div>
			</div>
		</div>
	);
};

export default ReportSimple;

const PlantList = ({ plants, onChange, onDelete, onAdd }) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'left' }}>
			{map(plants, (p, i) => (
				<PlantRow key={p.id + i} id={i} plant={p} onChange={onChange} onDelete={onDelete} onAdd={onAdd} />
			))}
		</div>
	);
};

const PlantRow = ({ id, plant, onChange, onDelete, onAdd }) => {
	const { t } = useTranslation();
	return plant.quantity ? (
		<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
			<p style={{ margin: '0', padding: '0', fontSize: '1.2rem', fontWeight: 'bold' }}>{id + 1}.</p>
			{plant.image ? (
				<img
					src={plant.image}
					alt={plant.name}
					style={{ width: '5rem', height: '5rem', paddingTop: '1rem', marginLeft: '1rem' }}
				/>
			) : (
				<div style={{ width: '6rem' }}></div>
			)}
			<p style={{ paddingTop: '3rem', width: '10rem', marginLeft: '1rem' }}>{plant.name}</p>
			<TextField
				label={t('report_simple_plant_nickname_label')}
				value={plant.nickname}
				style={{ width: '10rem', marginLeft: '1rem' }}
				onChange={event => onChange(id, 'nickname', event.target.value)}
				type="text"
			/>
			<TextField
				label={t('report_simple_plant_quantity_label')}
				value={plant.quantity}
				style={{ width: '3rem', marginLeft: '1rem' }}
				onChange={event => onChange(id, 'quantity', parseInt(event.target.value))}
				type="number"
			/>
			<TextField
				label={t('report_simple_plant_size_label')}
				value={plant.size || getEffectiveArea(plant)}
				style={{ width: '3rem', marginLeft: '1rem' }}
				onChange={event => onChange(id, 'size', parseFloat(event.target.value))}
				type="number"
			/>
			<button className="btn btn-danger" style={{ marginLeft: '1rem' }} onClick={() => onDelete(id)}>
				<Delete />
			</button>
		</div>
	) : (
		<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
			<p style={{ margin: '0', padding: '0', fontSize: '1.2rem', fontWeight: 'bold' }}>{id + 1}.</p>
			{plant.image ? (
				<img src={plant.image} alt={plant.name} style={{ width: '5rem', height: '5rem', paddingTop: '1rem' }} />
			) : (
				<div style={{ width: '6rem' }}></div>
			)}
			<p style={{ paddingTop: '3rem', width: '10rem', marginLeft: '1rem' }}>{plant.name}</p>
			<button
				className="btn btn-dark"
				style={{ marginLeft: '1rem' }}
				onClick={() => onAdd({ ...plant, quantity: 1, size: getEffectiveArea(plant) })}
			>
				<Add />
			</button>
		</div>
	);
};
const ReportEnvironmentParameters = ({ currentSimulation, setCurrentSimulation, setDirty }) => {
	const handleChange = (key, month, value) => {
		if (month === undefined) {
			setCurrentSimulation({
				...currentSimulation,
				environment: {
					...currentSimulation.environment,
					[key]: value,
				},
			});
		} else {
			setCurrentSimulation({
				...currentSimulation,
				environmentParameters: {
					...currentSimulation.environmentParameters,
					[key]: map(currentSimulation.environmentParameters[key], (v, k) => (k === month ? value : v)),
				},
			});
		}
		setDirty(true);
	};
	const months = getMonthNames([]);
	return (
		<div>
			<table className="table table-sm table-striped">
				<thead>
					<td></td>
					{range(12)
						.map(m => months[m])
						.map(m => (
							<td>{m}</td>
						))}
				</thead>
				<tbody>
					{map(
						filter(
							keys(currentSimulation.environmentParameters),
							k =>
								currentSimulation.environmentParameters[k].length &&
								k !== 'fileName' &&
								k !== 'stationName' &&
								k !== 'latitude' &&
								k !== 'longitude',
						),
						key => (
							<tr key={key}>
								<td>{key}</td>
								{range(12).map(m => (
									<td key={m}>
										<TextField
											style={{ width: '3rem' }}
											type="number"
											value={currentSimulation.environmentParameters[key][m]}
											onChange={event => handleChange(key, m, parseFloat(event.target.value))}
										/>
									</td>
								))}
							</tr>
						),
					)}
				</tbody>
			</table>
			{map(
				filter(
					keys(currentSimulation.environmentParameters),
					k =>
						!currentSimulation.environmentParameters[k].length &&
						k !== 'fileName' &&
						k !== 'stationName' &&
						k !== 'latitude' &&
						k !== 'longitude',
				),
				key => (
					<TextField
						key={key}
						label={key}
						value={currentSimulation.environmentParameters[key]}
						onChange={event => handleChange(key, undefined, parseFloat(event.target.value))}
					/>
				),
			)}
		</div>
	);
};

const calculateEtos = (currentSimulation, sensorMap) => {
	const etos = reduce(
		keys(sensorMap),
		(acc, key) => {
			const sensor = sensorMap[key];
			const sensorEtos = calculateEToSensor(currentSimulation, sensor);
			acc[key] = { eto: sensorEtos, plantId: sensor.plantId, plantIndex: sensor.plantIndex };
			return acc;
		},
		{},
	);
	return etos;
};

const calculateEToSensor = (currentSimulation, sensor) => {
	const DOY = [16, 45, 75, 105, 136, 166, 197, 228, 258, 289, 319, 350]; // Day of year integer between 1 and 365 or 366
	const Tmin = currentSimulation.environmentParameters?.tempMin ?? times(12, 18);
	const Tmax = currentSimulation.environmentParameters?.tempMax ?? times(12, 26);
	const WindSpd_meanMonthlylst = currentSimulation.environmentParameters?.wind ?? times(12, 0.1);
	const RHmin = currentSimulation.environmentParameters?.humidityMin ?? times(12, 50);
	const RHmax = currentSimulation.environmentParameters?.humidityMax ?? times(12, 60);
	const elevation = currentSimulation.environmentParameters?.elevation ?? 0;
	const latitude = currentSimulation.lat;
	const z = 10; // Height of wind measurement above ground surface [m]
	const wind2 = map(WindSpd_meanMonthlylst, ws => ws * (4.87 / Math.log(67.8 * z - 5.42))); // Wind speed at 2 m height [m s-1] #ws * (4.87 / math.log((67.8 * z) - 5.42)) #ws: Measured wind speed [m s-1]

	const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; //TODO getMonthDays([]);
	let DLImean_lst = [];
	let alreadyGot = 0;
	for (let i = 0; i < monthDays.length; i++) {
		DLImean_lst.push(mean(slice(sensor.dlisAll, alreadyGot, monthDays[i] + alreadyGot)));
		alreadyGot = alreadyGot + monthDays[i];
	}
	//DLImean_lst = [30]*12 #monthly mean DLI (mol/m².day)
	const swRad = map(DLImean_lst, dli => ((dli * 24) / 0.0864 / 0.09290304000008 / 0.2 / 179) * 0.0036); //Net incoming shortwave radiation [MJ m-2 day-1] is the net shortwave radiation resulting from the balance between incoming and reflected solar radiation.

	return range(12).map(i => {
		let t_month_prev = 0;
		if (i === 0) {
			t_month_prev = 0.5 * (Tmin[11] + Tmax[11]);
		} else {
			t_month_prev = 0.5 * (Tmin[i - 1] + Tmax[i - 1]);
		}
		let next = 0;
		if (i === 11) {
			next = 0;
		} else {
			next = i + 1;
		}
		const t_month_next = 0.5 * (Tmin[next] + Tmax[next]);

		let psychrometer = 0;
		if (wind2[i] >= 4.5) {
			//ventilated (Asmann or aspirated type) psychrometer with an air movement of approximately 5 m/s
			psychrometer = 1;
		} else if (4.5 > wind2[i] && wind2[i] >= 0.5) {
			//natural ventilated psychrometer with an air movement of approximately 1 m/s
			psychrometer = 2;
		} else {
			psychrometer = 3; //non ventilated psychrometer installed indoors
		}

		const ETo_val = calculateEToForMonth(
			elevation,
			latitude,
			DOY[i],
			Tmin[i],
			Tmax[i],
			RHmax[i],
			RHmin[i],
			swRad[i],
			t_month_prev,
			t_month_next,
			wind2[i],
			psychrometer,
		);
		return Math.round(ETo_val * 1000) / 1000;
	});
};

const calculateEToForMonth = (
	Elevation,
	latitude_deg,
	day_of_year,
	tmin,
	tmax,
	rh_max,
	rh_min,
	ni_sw_rad,
	t_month_prev,
	t_month_next,
	wind_2,
	psychrometer,
) => {
	const SOLAR_CONSTANT = 0.082; // Solar constant [ MJ m-2 min-1]
	const STEFAN_BOLTZMANN_CONSTANT = 0.000000004903; // Stefan Boltzmann constant [MJ K-4 m-2 day-1]

	const sol_dec = 0.409 * Math.sin(((2.0 * Math.PI) / 365.0) * day_of_year - 1.39); //Solar declination [radians]
	const latitude = latitude_deg * (Math.PI / 180.0); //Latitude [radians]
	const cos_sha = -Math.tan(latitude) * Math.tan(sol_dec);
	const sha = Math.acos(Math.min(Math.max(cos_sha, -1.0), 1.0)); // Sunset hour angle [radians]
	const ird = 1 + 0.033 * Math.cos(((2.0 * Math.PI) / 365.0) * day_of_year); //Inverse relative distance earth-sun from day of the year [dimensionless]
	let tmp1 = (24.0 * 60.0) / Math.PI;
	let tmp2 = sha * Math.sin(latitude) * Math.sin(sol_dec);
	let tmp3 = Math.cos(latitude) * Math.cos(sol_dec) * Math.sin(sha);
	let et_rad = tmp1 * SOLAR_CONSTANT * ird * (tmp2 + tmp3); // Daily extraterrestrial radiation [MJ m-2 day-1]
	const cs_rad = (0.00002 * Elevation + 0.75) * et_rad; //Clear sky radiation [MJ m-2 day-1].

	// Temperature
	const svp_tmin = 0.6108 * Math.exp((17.27 * tmin) / (tmin + 237.15)); //Saturation vapour pressure at daily minimum temperature [kPa]
	const svp_tmax = 0.6108 * Math.exp((17.27 * tmax) / (tmax + 237.15)); //Saturation vapour pressure at daily minimum temperature [kPa]
	const avp = (svp_tmin * (rh_max / 100.0) + svp_tmax * (rh_min / 100.0)) / 2.0; //Actual vapour pressure [kPa] avp_from_rhmin_rhmax //Estimate actual vapour pressure (*ea*) from saturation vapour pressure and relative humidity

	tmp1 = STEFAN_BOLTZMANN_CONSTANT * ((Math.pow(tmax + 237.15, 4) + Math.pow(tmin + 237.15, 4)) / 2);
	tmp2 = 0.34 - 0.14 * Math.sqrt(avp);
	tmp3 = 1.35 * (ni_sw_rad / cs_rad) - 0.35;
	const no_lw_rad = tmp1 * tmp2 * tmp3; // Net outgoing longwave radiation [MJ m-2 day-1] Based on FAO equation 39 in Allen et al (1998).

	const net_rad = ni_sw_rad - no_lw_rad; // Net radiation at crop surface [MJ m-2 day-1]
	const t_deg = 0.5 * (tmax + tmin);
	const t_kelvin = t_deg + 237.15; // the mean air temperature at 2 m height [deg Kelvin]
	const svp = 1; // Saturation vapour pressure [kPa]
	const delta_svp = (4098 * (0.6108 * Math.exp((17.27 * t_deg) / t_kelvin))) / Math.pow(t_kelvin, 2); // Slope of saturation vapour pressure curve [kPa degC-1] Based on equation 13 in Allen et al (1998).

	// Select coefficient based on type of ventilation of the wet bulb
	let psy_coeff = 0;
	if (psychrometer === 1) psy_coeff = 0.000662;
	else if (psychrometer === 2) psy_coeff = 0.0008;
	else if (psychrometer === 3) psy_coeff = 0.0012;
	else {
		console.log(`psychrometer should be in range 1 to 3: ${psychrometer}`);
	}

	const atmos_pres = Math.pow((293.0 - 0.0065 * Elevation) / 293.0, 5.26) * 101.3; //Atmospheric pressure [kPa]
	const psy = psy_coeff * atmos_pres; // Psychrometric constant [kPa degC-1] Based on FAO equation 16 in Allen et al (1998)

	const shf = 0.07 * (t_month_next - t_month_prev); // Soil heat flux (G) [MJ m-2 day-1] (default is 0.0, which is reasonable for a daily or 10-day time steps)

	const a1 = (0.408 * (net_rad - shf) * delta_svp) / (delta_svp + psy * (1 + 0.34 * wind_2));
	const a2 = (((900 * wind_2) / t_kelvin) * (svp - avp) * psy) / (delta_svp + psy * (1 + 0.34 * wind_2));
	const eto = a1 + a2; //Reference evapotranspiration (ETo) from a hypothetical grass reference surface [mm day-1] Based on FAO-56 Penman-Monteith equation (equ. 6) in Allen et al (1998)
	return eto;
};
const calculateIrrigation = (currentSimulation, sensorMap, irrigationMethod) => {
	console.log(irrigationMethod);
	let etos = calculateEtos(currentSimulation, sensorMap);

	const methodAffectedDemand = (method, water) => {
		if (!water) return 0;
		if (method === 0) {
			return water[1] / 100;
		} else if (method === 1) {
			return (water[1] * 0.8) / 100;
		} else if (method === 2) {
			return water[0] / 100;
		} else {
			return water[1] / 100;
		}
	};
	const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; //TODO getMonthDays([]);

	forEach(keys(etos), currentKey => {
		const plant = find(currentSimulation.plants, p => p.id === etos[currentKey].plantId);
		etos[currentKey].effectiveArea = getEffectiveArea(plant);
		etos[currentKey].water = methodAffectedDemand(irrigationMethod, plant.water);
	});

	return reduce(
		keys(etos),
		(acc, key) => {
			acc[etos[key].plantIndex] = map(
				etos[key].eto,
				(eto, i) => eto * etos[key].water * etos[key].effectiveArea * monthDays[i],
			);
			return acc;
		},
		{},
	);
};

const getEffectiveArea = plant => {
	if (!plant) return 0;
	if (plant.size) return plant.size;
	if (!plant.type) {
		return 1;
	}
	return {
		fern: 0.5,
		tree: 2,
		'shrub/tree': 1,
		shrub: 1,
		herb: 0.5,
		vine: 0.5,
		'herb/shrub': 1.5,
		graminoid: 1,
		moss: 1,
		cactus: 0.5,
		'herb/shrub/tree': 1,
	}[plant.type];
};
