import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	updateUser,
	updateUserPrivilegesTime,
	updateUserPrivilegesCount,
	updateUserPrivilegesProjects,
} from '../../../services/users';
import ea from '../../../assets/images/pricing/earlyaccess.png';
import RadioImage from '../../ui-elements/radio-image';

import privileges from '../../../assets/privileges';
import { setCookie } from '../../../services/cookie';
import { usePrivileges } from '../../../hooks/priviledges';
import { usePricingFeatures } from '../../../hooks/pricingFeatures';

const styles = {
	imageRadioButton: {
		marginRight: '1rem',
		width: '12rem',
	},
	seclectionsIcons: {
		height: '2.75rem',
		width: '2.75rem',
	},
};

export const Payments = () => {
	const { t } = useTranslation();
	const [showPay, setShowPay] = useState(false);
	const {
		user,
		tier,
		setTier,
		time,
		maxTime,
		setMaxTime,
		maxProjects,
		setMaxProjects,
		count,
		monthlyCount,
		maxCount,
		setMaxCount,
		expiry,
		setExpiry,
		privilege,
		setPrivilege,
		waiting,
	} = usePrivileges();

	useEffect(() => {
		if (
			(maxProjects !== user.maxProjects ||
				maxCount !== user.maxCount ||
				maxTime !== Math.round(user.maxTime / 60) ||
				tier !== user.tier) &&
			!waiting
		) {
			setShowPay(true);
		} else {
			setShowPay(false);
		}
		setPrivilege(privileges.find(f => f.id === tier));
		setCookie('tier', tier);
	}, [
		maxCount,
		maxTime,
		maxProjects,
		tier,
		user.maxProjects,
		user.maxCount,
		user.maxTime,
		user.tier,
		setPrivilege,
		waiting,
	]);

	const pay = async () => {
		if (tier !== user.tier) {
			let pl = { tier };
			if (expiry !== user.expiry) {
				pl.expiry = (expiry.getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24);
			}
			await updateUser(user.userName, pl);
		}
		if (maxCount !== user.maxCount) {
			await updateUserPrivilegesCount(user.userName, parseInt(maxCount));
		}
		if (maxTime !== user.maxTime) {
			await updateUserPrivilegesTime(user.userName, parseInt(maxTime * 60));
		}
		if (maxProjects !== user.maxProjects) {
			await updateUserPrivilegesProjects(user.userName, maxProjects);
			setCookie('maxProjects', maxProjects);
		}
		setShowPay(false);
	};
	const options = [
		...usePricingFeatures(), //after this line should be removed after early access
		{
			id: 5,
			image: ea,
			title: t('early_access'),
		},
	].map((f, i) => (i === 0 || i === 5 ? f : { ...f, disabled: true }));

	return waiting ? (
		<></>
	) : (
		<div className="container pt-2 pb-2">
			<div className="row">
				<div className="col-md-12">
					<p>
						<b>{t('current_plan')}</b>: {options.find(f => f.id === user.tier)?.title}
					</p>
				</div>
			</div>
			<div className="row mb-3">
				<div className="col-md-12">
					<p>
						<b>{t('exp_date_time')}</b>: {new Date(expiry).toLocaleString()}
					</p>
				</div>
			</div>
			<div className="row mb-5">
				<div className="col-md-12">
					<RadioImage
						onClick={e => {
							if (e === 5) {
								setTier(4);
								setMaxCount(20);
								setMaxTime(60);
								let now = new Date();
								let then = new Date(now.getTime() + 1000 * 60 * 60 * 24 * 90);
								setExpiry(then);
							} else {
								setTier(e);
							}
						}}
						//onChange ={e =>setOptionId(e)}
						checked={tier}
						name="tiers"
						options={options}
						style={styles.imageRadioButton}
					/>
				</div>
			</div>

			{tier === 1 || tier === 2 ? (
				<>
					<div className="row">
						<div className="col-md-6">
							<p>
								<b>{t('num_saved_projects')}</b>: {user.projects?.length ?? 0} / {privilege.maxNumberOfProjects}
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<p>
								<b>{t('monthly_num_project_analysis')}</b>: {monthlyCount} / {privilege.maxNumberOfProjectRunPerMonth}
							</p>
						</div>
					</div>
					<div className="row">
						<div className="col-md-6">
							<p>
								<b>{t('num_project_analysis')}</b>: {count} / {privilege.maxNumberOfProjectRun}
							</p>
						</div>
					</div>
				</>
			) : (
				<></>
			)}
			{tier === 3 || tier === 4 ? (
				<>
					{tier === 3 ? (
						<>
							<div className="row">
								<div className="col-md-12" style={{ display: 'flex', flexDirection: 'row' }}>
									<p className="mr-2">
										<b>{t('num_saved_projects')}</b>: {user.projects?.length ?? 0} /
									</p>
									<input
										className="form-control"
										type="number"
										style={{ width: '4rem', height: '2rem', marginTop: '-0.2rem' }}
										value={maxProjects}
										id="total-time"
										onChange={e => setMaxProjects(parseInt(e.target.value))}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-md-12">
									<p>
										<b>{t('monthly_num_project_analysis')}</b>: {monthlyCount}
									</p>
								</div>
							</div>
						</>
					) : (
						<></>
					)}
					<div className="row">
						<div className="col-md-12" style={{ display: 'flex', flexDirection: 'row' }}>
							<p className="mr-2">
								<b>{t('cal_time')}</b>: {time} {t('mins')} /
							</p>
							<input
								className="form-control"
								type="number"
								disabled={true} //Early Access
								style={{ width: '4rem', height: '2rem', marginTop: '-0.2rem' }}
								value={maxTime}
								id="total-time"
								onChange={e => setMaxTime(e.target.value)}
							/>
							<p className="ml-1">{t('mins')}</p>
						</div>
					</div>
					{tier === 4 ? (
						<div className="row">
							<div className="col-md-12" style={{ display: 'flex', flexDirection: 'row' }}>
								<p className="mr-2">
									<b>{t('num_project_analysis')}</b>: {count} /
								</p>
								<input
									className="form-control"
									type="number"
									disabled={true} //Early Access
									style={{ width: '4rem', height: '2rem', marginTop: '-0.2rem' }}
									value={maxCount}
									id="total-count"
									onChange={e => setMaxCount(e.target.value)}
								/>
							</div>
						</div>
					) : (
						<></>
					)}
				</>
			) : (
				<></>
			)}
			{showPay ? (
				<div className="row mt-4">
					<div className="col-md-12">
						<button className="btn btn-dark" onClick={pay}>
							{t('payment_pay_early_access')}
						</button>
					</div>
				</div>
			) : (
				<></>
			)}
		</div>
	);
};
