import React, { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Canvas } from 'react-three-fiber';
import Room from './room';
import Camera from './camera';
import CameraController from './orbitControl';
import Grid from './grid';
import Furniture from './furniture';
import Window from './window';
import Block from './block';
import Tree from './tree';
import Map from './map';
import NI from './ni';
import Mark from './mark';
import MiscObject from './misc-object';
import WorkPlane from './workplane';
import FrontArrow from './front-arrow';
import threeDmap from '../../../assets/images/icons/threeDmap.svg';
import hideThreeDmap from '../../../assets/images/icons/hideThreeDmap.svg';
import mapView from '../../../assets/images/icons/mapView.svg';
import mapNotView from '../../../assets/images/icons/mapNotView.svg';
import top from '../../../assets/images/icons/top.svg';
import center from '../../../assets/images/icons/center.svg';
import { isMobile } from 'react-device-detect';
import threeDrotate from '../../../assets/images/icons/threeDrotate.svg';
import fingersZoom from '../../../assets/images/icons/fingersZoom.svg';
import fingerSwipeHhorizontally from '../../../assets/images/icons/fingerSwipeHhorizontally.svg';
import fingersRotate from '../../../assets/images/icons/fingersRotate.svg';
import pan from '../../../assets/images/icons/pan.svg';
import zoomIn from '../../../assets/images/icons/zoomIn.svg';
import { map } from 'lodash';

import { Euler } from 'three';

const styles = {
	image: {
		zIndex: 10,
		height: '2rem',
		width: '2rem',
		cursor: 'pointer',
		margin: '.5rem',
	},
	actionImage: {
		zIndex: 10,
		height: '2rem',
		width: '2rem',
		margin: '.5rem',
	},
	iconsDiv: {
		display: 'flex',
		flexDirection: 'column',
		position: 'absolute',
		zIndex: 100,
		right: 0,
		alignItems: 'flex-end',
		top: '10rem',
	},
};

function Lights() {
	return (
		<>
			<ambientLight intensity={2} />
			{/* <pointLight position={[40, 40, 40]} /> */}
			{/* <directionalLight
				intensity={0.6}
				position={[5, 5, 5]}
				shadow-mapSize-width={2048}
				shadow-mapSize-height={2048}
				castShadow
			/> */}
		</>
	);
}

const Scene = ({
	className,
	style,
	view,
	showPlants,
	onMapClick,
	showMap,
	centerMapFunc,
	activeId,
	setActiveId,
	currentSimulation: {
		hasRoom,
		orientation,
		width,
		depth,
		height,
		resolution,
		elevation,
		furniture,
		windows,
		blocks,
		workPlanes,
		trees,
		marks,
		objects,
		lat,
		long,
		translateX,
		translateY,
	},
}) => {
	const { t } = useTranslation();
	const [scene, setScene] = useState();
	const [show3dBuildings, setShow3dBuildings] = useState(false);
	const [showMapInternal, setShowMapInternal] = useState(view !== 'rotate');

	useEffect(() => {
		if (view !== 'rotate') {
			setShowMapInternal(true);
		}
	}, [view]);
	const threeDMapTitle = threeDmap => (threeDmap ? t('three_d_map') : t('hide_three_d_map'));
	const hideShowDMapTitle = threeDmap => (threeDmap ? t('hide_map') : t('show_map'));
	const topTitle = () => t('top_label');
	const centerTitle = () => t('center_label');
	const threeDRotateTitle = () => t('three_d_rotate_label');
	const panTitle = () => t('pan_label');
	const zoomTitle = () => t('zoom_in_label');

	return (
		<div style={style} className={className}>
			{view !== 'rotate' ? (
				<>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							position: 'absolute',
						}}
					>
						<img
							style={styles.image}
							title={centerTitle()}
							alt={centerTitle()}
							src={center}
							onClick={() => {
								centerMapFunc.current();
							}}
						/>

						<img
							style={styles.image}
							src={showMap || showMapInternal ? mapNotView : mapView}
							title={hideShowDMapTitle()}
							alt={hideShowDMapTitle()}
							onClick={() => {
								setShowMapInternal(!showMapInternal);
							}}
						/>

						<img
							style={styles.image}
							src={top}
							alt={topTitle()}
							title={topTitle()}
							onClick={() => {
								centerMapFunc.current(true);
							}}
						/>

						{showMap || showMapInternal ? (
							<img
								style={styles.image}
								src={show3dBuildings ? hideThreeDmap : threeDmap}
								alt={threeDMapTitle()}
								title={threeDMapTitle()}
								onClick={() => {
									setShow3dBuildings(!show3dBuildings);
								}}
							/>
						) : (
							<></>
						)}
					</div>
					<div>
						{isMobile ? (
							!(showMap || showMapInternal) ? (
								<div style={{ ...styles.iconsDiv, top: '3.5rem' }}>
									<img
										style={{ ...styles.actionImage, width: '2rem', height: '2rem' }}
										alt={t('swipe_label')}
										src={fingerSwipeHhorizontally}
									/>
									<img style={styles.actionImage} alt={t('zoom_in_label')} src={fingersZoom} />
								</div>
							) : (
								<div style={styles.iconsDiv}>
									<img
										style={{ ...styles.actionImage, width: '2rem', height: '2rem' }}
										alt={t('swipe_label')}
										src={fingerSwipeHhorizontally}
									/>
									<img style={styles.actionImage} alt={t('zoom_in_label')} src={fingersZoom} />

									<img style={styles.actionImage} src={fingersRotate} alt={t('Two finger rotate')} />
								</div>
							)
						) : (
							<div style={styles.iconsDiv}>
								<img style={{ ...styles.actionImage, width: '1.5', height: '1.5rem' }} alt={panTitle()} src={pan} />
								<img style={styles.actionImage} alt={zoomTitle()} src={zoomIn} />

								<img style={styles.actionImage} alt={threeDRotateTitle()} src={threeDrotate} />
							</div>
						)}
					</div>
				</>
			) : (
				<></>
			)}
			{(showMap || showMapInternal) && scene ? (
				<Map
					lat={lat}
					long={long}
					scene={scene}
					view={view}
					show3dBuildings={show3dBuildings}
					elevation={elevation}
					rotation={orientation}
					centerMapFunc={centerMapFunc}
					translateX={translateX} //{getTranslateX(translateX,translateY)}
					translateY={translateY} //{getTranslateY(translateX,translateY)}
					onMapClick={onMapClick}
					className={className}
				/>
			) : (
				<></>
			)}
			<Canvas
				//shadowMap
				gl2
				style={{ position: 'absolute', top: '0', zIndex: showMap || showMapInternal ? -1 : 1 }}
			>
				{!(showMap || showMapInternal) ? (
					<>
						<CameraController
							alt={elevation}
							translateX={translateX}
							translateY={translateY}
							centerMapFunc={centerMapFunc}
							view={view}
							width={width}
							depth={depth}
						/>
						{view !== 'rotate' ? <Grid onMapClick={onMapClick} /> : <></>}
					</>
				) : (
					<Camera setScene={setScene} />
				)}
				<Lights />
				<Suspense fallback={null}>
					<group
						position={[translateX, 0, translateY]} //{[getTranslateX(translateX,translateY),0,getTranslateY(translateX,translateY)]}
					>
						<group rotation={new Euler(0, (orientation * Math.PI) / 180, 0)}>
							{hasRoom ? <Room width={width} depth={depth} height={height} alt={elevation} /> : <></>}
							{hasRoom ? (
								map(windows, (window, index) => (
									<Window
										key={index}
										id={window.id}
										alt={elevation}
										roomWidth={width}
										roomDepth={depth}
										roomHeight={height}
										width={window.width}
										height={window.height}
										position={window.position}
										face={window.face}
										splitDirection={window.splitDirection}
										splitNum={window.splitNum}
										transmissivity={window.transmissivity}
										activeId={activeId}
										setActiveId={setActiveId}
									/>
								))
							) : (
								<></>
							)}
							{map(blocks, (block, index) => (
								<Block key={index} alt={elevation} block={block} activeId={activeId} setActiveId={setActiveId} />
							))}
							{map(workPlanes, (workPlane, index) => (
								<WorkPlane
									key={index}
									alt={elevation}
									workPlane={workPlane}
									activeId={activeId}
									setActiveId={setActiveId}
									resolution={resolution}
									showPlants={showPlants}
								/>
							))}
							{map(trees, (tree, index) => (
								<Tree
									key={index}
									id={tree.id}
									position={tree.position}
									type={tree.type}
									scale={tree.scale}
									activeId={activeId}
									setActiveId={setActiveId}
								/>
							))}
							{map(furniture, (furn, index) => (
								<Furniture
									key={index}
									id={furn.id}
									position={furn.position}
									width={furn.width}
									depth={furn.depth}
									height={furn.height}
									reflectivity={furn.reflectivity}
									alt={elevation}
									activeId={activeId}
									setActiveId={setActiveId}
								/>
							))}
							{[...(marks || []), { id: 10000, x: 0, y: 0 }].map((mark, index) => (
								<Mark key={index} x={mark.x} y={mark.y} />
							))}
							{map(objects, (object, index) => (
								<MiscObject key={index} object={object} activeId={activeId} setActiveId={setActiveId} />
							))}
							<NI width={width} depth={depth} alt={elevation} />
							{view !== 'rotate' ? <FrontArrow depth={depth} orientation={orientation} /> : <></>}
						</group>
					</group>
				</Suspense>
			</Canvas>
		</div>
	);
};

export default Scene;
