import { post, get, getPublic } from './request';
import { filter, toLower } from 'lodash';

export async function suggest(name) {
	const res = await getPublic('https://api.gbif.org/v1/species/suggest?limit=20&q=' + name);
	return filter(res, s => s.kingdom === 'Plantae');
}

export async function getUserPlants(user) {
	const res = await get(`${process.env.REACT_APP_API}api/users/plants/${user}`);
	return res.result;
}

export async function savePlants(user, plants) {
	const res = await post(`${process.env.REACT_APP_API}api/users/plants/${user}`, plants);
	return res;
}

export async function getPlant(plant) {
	const res = await get(`${process.env.REACT_APP_API}api/plants/info/${toLower(plant)}`);
	return res.result;
}

export async function recognizePlant(user, image) {
	const res = await post(`${process.env.REACT_APP_API}api/plants/recognize/${user}`, { images: [image] });
	return res.result;
}
