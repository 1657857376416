import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Line from './charts/line';
import Bar from './charts/simple-bar';
import { slice, sum, map } from 'lodash';

export function ArtificialLight({ pickedSensor, plant, sensor, window, rangeSliderValue }) {
	const { t } = useTranslation();
	const [ppfd, setPpfd] = useState(200);
	const [watt, setWatt] = useState(50);
	const [totalKwh, setTotalKwh] = useState(0);
	const [totalKwhSelected, setTotalKwhSelected] = useState(0);
	const [annualUnderLit, setAnnualUnderLit] = useState([]);
	const [annualUnderLitSelected, setAnnualUnderLitSelected] = useState([]);

	const getUnderlit = useCallback(
		(sensor, plant) => {
			const dliToNumberOfHours = dli => {
				return Math.round((dli / 0.0036 / ppfd) * 10) / 10;
			};

			const minDli = (plant || {}).dli[0] || 0;
			const illsAll = window ? (sensor || {}).dlis[window] || [] : (sensor || {}).dlisAll || [];
			const result = map(slice(illsAll, rangeSliderValue[0], rangeSliderValue[1]), f =>
				f > minDli ? 0 : dliToNumberOfHours(minDli - f),
			);
			return result;
		},
		[rangeSliderValue, ppfd, window],
	);

	useEffect(() => {
		const result = getUnderlit(pickedSensor, plant);
		setAnnualUnderLit(result);
		setTotalKwh((sum(result) * watt) / 1000);
	}, [pickedSensor, ppfd, watt, rangeSliderValue, getUnderlit, plant]);

	useEffect(() => {
		const result = getUnderlit(sensor, plant);
		setAnnualUnderLitSelected(result);
		setTotalKwhSelected((sum(result) * watt) / 1000);
	}, [sensor, ppfd, watt, rangeSliderValue, getUnderlit, plant]);

	return (
		<div className="row mt-5">
			<div className="col-md-6">
				<div className="form-group">
					<label htmlFor="report-artificial-light-ppfd">{t('report-artificial-light-ppfd')}</label>
					<div>
						<input
							style={{ maxWidth: '10em', float: 'left' }}
							className="form-control"
							type="number"
							id="report-artificial-light-ppfd"
							value={ppfd}
							onChange={e => setPpfd(parseFloat(e.target.value))}
						/>
						<span className="ml-2"> {t('µmol/m²s')}</span>
					</div>
				</div>
				<div className="form-group">
					<label htmlFor="report-artificial-light-watt">{t('power_label')}</label>
					<div>
						<input
							style={{ maxWidth: '10em', float: 'left' }}
							className="form-control"
							type="number"
							id="report-artificial-light-watt"
							value={watt}
							onChange={e => setWatt(parseFloat(e.target.value))}
						/>
						<span className="ml-2">{t('watt_label')}</span>
					</div>
				</div>

				<p style={{ fontSize: '1.5em', marginTop: '2em' }}>
					{t('annual_electricity_for_plants_text')}
					<span style={{ fontWeight: 'bold', color: 'rgb(255, 83, 83)' }}>{totalKwh.toFixed(2)}</span>
					{t('kWh/_label')}
					/(
					{rangeSliderValue[1] - rangeSliderValue[0]} {t('days_label')})
				</p>
				{sensor ? (
					<p style={{ fontSize: '1.5em' }}>
						{t('annual_electricity_for_plants_selected_text')}
						<span style={{ fontWeight: 'bold', color: 'rgb(255, 83, 83)' }}>{totalKwhSelected.toFixed(2)}</span>{' '}
						{t('kWh/_label')}/(
						{rangeSliderValue[1] - rangeSliderValue[0]} {t('days_label')})
					</p>
				) : (
					<></>
				)}

				<Bar
					name={t('electricity_consumption_comparison')}
					valueA={Math.round(totalKwh)}
					labelA={t('artificial_light_line_chart_label_optimum')}
					valueB={Math.round(totalKwhSelected)}
					labelB={t('artificial_light_line_chart_label_selected')}
					style={{ width: '100%', height: '150px' }}
				/>
			</div>
			<div className="col-md-6 mt-4 mt-md-0">
				<Line
					series1={annualUnderLit}
					label1={t('artificial_light_line_chart_label_optimum')}
					series2={annualUnderLitSelected}
					label2={t('artificial_light_line_chart_label_selected')}
					chartLabel={t('artificial_light_line_chart_label')}
					yLabel={t('artificial_light_line_chart_y_label')}
					shoWRangeOnly={true}
					range={rangeSliderValue}
					style={{
						width: '100%',
						maxWidth: '600px',
						height: '450px',
						margin: 'auto',
					}}
				/>
			</div>
		</div>
	);
}
