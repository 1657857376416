import React from 'react';
import Expans from './expansion-panel';
import { useTranslation } from 'react-i18next';
import Slider from '../slider';
import icon from '../../../assets/images/icons/furniture.svg';
import { map } from 'lodash';

const FurniturePanel = ({ furniture, remove, handleChange }) => {
	const { t } = useTranslation();
	return (
		<Expans title={t('furniture_label')} icon={icon} id="furniture">
			{map(furniture, (furn, index) => (
				<Expans
					key={index}
					title={t('furniture_label') + index}
					icon={icon}
					id={'furniture_' + furn.id}
					remove={() => remove('furniture', furn.id)}
				>
					<Slider
						label={t('furniture_x_label')}
						value={furn.position[0]}
						onChange={value =>
							handleChange('furniture', index, 'position', [value, furn.position[1], furn.position[2]])
						}
						min={-100}
						max={100}
						step={1}
					/>
					<Slider
						label={t('furniture_y_label')}
						value={furn.position[1]}
						onChange={value =>
							handleChange('furniture', index, 'position', [furn.position[0], value, furn.position[2]])
						}
						min={-100}
						max={100}
						step={1}
					/>
					<Slider
						label={t('furniture_z_label')}
						value={furn.position[2]}
						onChange={value =>
							handleChange('furniture', index, 'position', [furn.position[0], furn.position[1], value])
						}
						min={-100}
						max={100}
						step={1}
					/>
					<Slider
						label={t('furniture_width_label')}
						value={furn.width}
						onChange={value => handleChange('furniture', index, 'width', value)}
						min={0.05}
						max={5}
						step={0.1}
					/>
					<Slider
						label={t('furniture_depth_label')}
						value={furn.depth}
						onChange={value => handleChange('furniture', index, 'depth', value)}
						min={0.05}
						max={5}
						step={0.1}
					/>
					<Slider
						label={t('furniture_height_label')}
						value={furn.height}
						onChange={value => handleChange('furniture', index, 'height', value)}
						min={0.05}
						max={5}
						step={0.1}
					/>
					<Slider
						label={t('furniture_reflectivity_label')}
						value={furn.reflectivity}
						onChange={value => handleChange('furniture', index, 'reflectivity', value)}
						min={0}
						max={1}
						step={0.1}
					/>
				</Expans>
			))}
		</Expans>
	);
};

export default FurniturePanel;
