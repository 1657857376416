import React from 'react';
import { DoubleSide } from 'three';
import { rgbf, colorGradient } from '../utils';

const Box = ({ id, width, depth, height, position, alt, reflectivity, activeId, setActiveId }) => {
	const isActive = activeId === `furniture_${id}`;
	if (width === 0 || height === 0 || depth === 0) {
		return <></>;
	}
	return (
		<mesh position={[position[0], alt + position[1], position[2]]} onPointerDown={e => setActiveId(`furniture_${id}`)}>
			<boxBufferGeometry attach="geometry" args={[width, height, depth]} />
			<meshBasicMaterial
				attach="material"
				opacity={0.5}
				reflectivity={1}
				transparent={true}
				side={DoubleSide}
				color={colorGradient(reflectivity, rgbf(0, 0, 0), rgbf(200, isActive ? 100 : 200, 200))}
			/>
		</mesh>
	);
};

export default Box;
