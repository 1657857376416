import React from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ResponsiveContainer,
	Label,
	LabelList,
} from 'recharts';
import { getMonthNames, getMonthDays } from '../../utils';
import { slice } from 'lodash';
import { countOF } from '../helpers';

const MyBarChart = ({ sensor, plantDli, range, chartLabel }) => {
	const monthDays = getMonthDays(range);
	const slicedData = slice(sensor, range[0], range[1]);
	const data = getMonthNames(range).map((m, i) => {
		const sdata = slicedData.splice(0, monthDays[i]);
		return {
			name: m,
			UnderLit: countOF(sdata, 0, plantDli),
			Enough: countOF(sdata, 1, plantDli),
			OverLit: countOF(sdata, 2, plantDli),
		};
	});
	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1em' }}>
				<h3>{chartLabel}</h3>
			</div>
			<ResponsiveContainer height={450} width={'100%'}>
				<BarChart layout="vertical" stackOffset="expand" data={data}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis type="number" tick={false} />
					<YAxis type="category" dataKey="name" />
					<Tooltip />
					<Legend />
					<Bar dataKey="UnderLit" stackId="a" fill="#375ec5">
						<LabelList dataKey="UnderLit" position="center" content={CustomizedLabel} />
					</Bar>
					<Bar dataKey="Enough" stackId="a" fill="#55a157">
						<LabelList dataKey="Enough" position="center" content={CustomizedLabel} />
					</Bar>
					<Bar dataKey="OverLit" stackId="a" fill="#ff5353">
						<LabelList dataKey="OverLit" position="center" content={CustomizedLabel} />
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</>
	);
};

const CustomizedLabel = props => {
	const { content, ...rest } = props;
	rest.value = rest.value === 0 ? "" : rest.value;
	return <Label {...rest} fontSize="12" fill="#FFFFFF" fontWeight="bold" />;
};

export default MyBarChart;
