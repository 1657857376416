import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Bar from './charts/simple-bar';
import Line from './charts/line';
import { slice, sum, map, chunk, filter } from 'lodash';

export function Shading({ pickedSensor, sensor, rangeSliderValue }) {
	const { t } = useTranslation();
	const [parMax, setParMax] = useState(500); //TODO par max
	const [annualShading, setAnnualSahding] = useState([]);
	const [annualShadingSelected, setAnnualShadingSelected] = useState([]);
	const [days, setDays] = useState(0);
	const [daysSelected, setDaysSelected] = useState(0);

	useEffect(() => {
		const result = map(
			chunk(slice(pickedSensor || [], rangeSliderValue[0] * 24, rangeSliderValue[1] * 24), 24),
			f => filter(f, g => g > parMax).length,
		);
		setAnnualSahding(result);
		setDays(sum(result));
	}, [pickedSensor, parMax, rangeSliderValue]);

	useEffect(() => {
		const result = map(
			chunk(slice(sensor || [], rangeSliderValue[0] * 24, rangeSliderValue[1] * 24), 24),
			f => filter(f, g => g > parMax).length,
		);
		setAnnualShadingSelected(result);
		setDaysSelected(sum(result));
	}, [sensor, parMax, rangeSliderValue]);

	return (
		<div className="row mt-5">
			<div className="col-md-6">
				<div className="form-group">
					<label htmlFor="report-artificial-light-ppfd">{t('par_max_label')}</label>
					<div>
						<input
							style={{ maxWidth: '10em', float: 'left' }}
							className="form-control"
							type="number"
							id="report-artificial-light-ppfd"
							value={parMax}
							onChange={e => setParMax(parseFloat(e.target.value))}
						/>
						<span className="ml-2"> {t('µmol/m²s')}</span>
					</div>
				</div>

				<p style={{ fontSize: '1.5em', marginTop: '2em' }}>
					{t('close_shading_text')}
					<span style={{ fontWeight: 'bold', color: 'rgb(255, 83, 83)' }}>{days}</span>{' '}
					{t('days_for_your_optimal_sensor_text')}
				</p>
				{sensor ? (
					<p style={{ fontSize: '1.5em' }}>
						{t('close_shading_text')}{' '}
						<span style={{ fontWeight: 'bold', color: 'rgb(255, 83, 83)' }}>{daysSelected}</span>{' '}
						{t('days_for_your_selected_sensor_text')}
					</p>
				) : (
					<></>
				)}

				<Bar
					name={t('electricity_consumption_comparison')}
					valueA={Math.round(days)}
					labelA={t('artificial_light_line_chart_label_optimum')}
					valueB={Math.round(daysSelected)}
					labelB={t('artificial_light_line_chart_label_selected')}
					style={{ width: '100%', height: '150px' }}
				/>
			</div>
			<div className="col-md-6  mt-4 mt-md-0">
				<Line
					series1={annualShading}
					label1={t('shading_line_chart_label_optimum')}
					series2={annualShadingSelected}
					label2={t('shading_line_chart_label_selected')}
					chartLabel={t('shading_line_chart_label')}
					yLabel={t('shading_line_chart_y_label')}
					range={rangeSliderValue}
					shoWRangeOnly={true}
					step={true}
					style={{
						width: '100%',
						maxWidth: '600px',
						height: '450px',
						margin: 'auto',
					}}
				/>
			</div>
		</div>
	);
}
