import React from 'react';
import { useTranslation } from 'react-i18next';

const RadioButtonMaker = ({ inputsContent, style, name, value, onChange }) => {
	const { t } = useTranslation();
	return (
		<div>
			{inputsContent.map(({ id, label, key }) => (
				<React.Fragment key={id}>
					<div className="from-group" style={style}>
						<input
							className="form-check-input"
							type="radio"
							name={name}
							id={name + id}
							checked={value === key}
							onChange={e => onChange(key)}
						/>
						<label className="form-check-label" htmlFor={name + id}>
							{t(label)}
						</label>
					</div>
				</React.Fragment>
			))}
		</div>
	);
};

export default RadioButtonMaker;
