import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '@material-ui/core';
import { map, min } from 'lodash';

export const AddCustomPlant = ({ plants, setPlants }) => {
	const { t } = useTranslation();
	const [addCustomPlant, setAddCustomPlant] = useState(false);
	const [customPlant, setCustomPlant] = useState({ id: '', dli: [null, null] });
	return (
		<>
			{addCustomPlant ? (
				<div style={{ margin: '1rem' }}>
					<TextField
						onChange={e => setCustomPlant({ ...customPlant, name: e.target.value })}
						placeholder={t('custom_plant_name_placeholder')}
					/>
					<TextField
						onChange={e => setCustomPlant({ ...customPlant, canonical: e.target.value })}
						placeholder={t('custom_plant_canonical_name_placeholder')}
					/>
					<TextField
						type="number"
						value={customPlant.id}
						placeholder={t('custom_plant_taxon_id_placeholder')}
						onChange={e => setCustomPlant({ ...customPlant, id: e.target.value })}
					/>

					<TextField
						type="number"
						value={customPlant.dli[0]}
						placeholder={t('custom_plant_dli_min_placeholder')}
						onChange={e => setCustomPlant({ ...customPlant, dli: [e.target.value, customPlant.dli[1] || 0] })}
					/>

					<TextField
						placeholder={t('custom_plant_dli_max_placeholder')}
						type="number"
						value={customPlant.dli[1]}
						onChange={e => setCustomPlant({ ...customPlant, dli: [customPlant.dli[0] || 0, e.target.value] })}
					/>
				</div>
			) : (
				<></>
			)}
			<div>
				<button
					className="btn btn-dark ml-2"
					onClick={async () => {
						if (addCustomPlant) {
							try {
								if (!customPlant.id) {
									const m = min(map(plants, p => p.id));
									customPlant.id = m < 0 ? m - 1 : -1;
								}
								const pl = { ...plants, [customPlant.id]: customPlant };
								setPlants(pl);
								setAddCustomPlant(false);
							} catch (e) {
								console.error(e);
							}
						} else {
							setAddCustomPlant(true);
						}
					}}
				>
					{addCustomPlant ? t('save_button_label') : t('add_custom_plant_label')}
				</button>
			</div>
		</>
	);
};
