import { post, get, del } from './request';

export async function getProject(id, sim) {
	const res = await get(`${process.env.REACT_APP_API}api/projects/info/${id}/${sim}`);
	return res.result;
}

export async function calculate(id, data) {
	const res = await post(`${process.env.REACT_APP_API}api/projects/calculate/${id}`, data);
	return res.result;
}

export async function deleteProject(id, sim) {
	const res = await del(`${process.env.REACT_APP_API}api/projects/info/${id}/${sim}`);
	return res.result;
}

export async function upsertProject(id, sim, data, softUpdate = false) {
	const res = await post(`${process.env.REACT_APP_API}api/projects/info/${id}/${sim}`, { ...data, softUpdate });
	return res.result;
}

export async function calculateOrchestrationStart(id, simId) {
	const res = await post(`${process.env.REACT_APP_API}api/v1/calc/startweb/${id}/${simId}`);
	return res.result;
}

export async function calculateOrchestrationStatus(id, orchestrationId) {
	const res = await post(`${process.env.REACT_APP_API}api/v1/calc/statusweb/${id}/${orchestrationId}`);
	return res.result;
}
