import React, { useContext } from 'react';
import AdvancedWizardSection from './advanced-wizard-section';
import { useTranslation } from 'react-i18next';
import { debounce, last } from 'lodash';
import advancedWizardContext from './advance-wizard-context';
import { isMobile } from 'react-device-detect';
import Slider from '../../ui-elements/slider';

export const WorkplaneAddSectionOne = () => {
	const { t } = useTranslation();
	const { mapClickFunc, setMarks, redrawEntity, handleNext, nextFunc } = useContext(advancedWizardContext);

	redrawEntity.current = 'workPlanes';
	nextFunc.current = () => {};
	mapClickFunc.current = debounce((x, y) => {
		setMarks([{ id: 0, x, y: -y }]);
		handleNext.current();
	}, 150);
	return (
		<AdvancedWizardSection>
			<p>{t('start_workplane_section_guide')}</p>
		</AdvancedWizardSection>
	);
};
export const WorkplaneAddSectionTwo = () => {
	const { t } = useTranslation();
	const { marks, handleAddEntity, handleNext, mapClickFunc, setMarks } = useContext(advancedWizardContext);
	mapClickFunc.current = debounce((x2, y2) => {
		y2 = -y2;

		const x1 = marks[0].x;
		const y1 = marks[0].y;

		handleAddEntity('workPlanes', { distance: 0, position1: [x1, y1], position2: [x2, y2], face: 'H' });
		setMarks([...marks, { id: 1, x: x2, y: y2 }]);
		handleNext.current();
	}, 150);
	return (
		<AdvancedWizardSection>
			<p>{t('end_workplane_section_guide')}</p>
		</AdvancedWizardSection>
	);
};
export const WorkplaneDetailsSection = () => {
	const { t } = useTranslation();
	const { currentSimulation, handleArrayEntityChange, globalStep, mapClickFunc, nextFunc } = useContext(
		advancedWizardContext,
	);
	nextFunc.current = () => {};
	mapClickFunc.current = () => {};

	let {
		workPlanes,
		// blocks
	} = currentSimulation;

	let workplane = last(workPlanes);
	return (
		<AdvancedWizardSection>
			<p style={{ width: isMobile ? '95%' : '45%' }}>{t('workplane_slider_section_guide')}</p>
			<Slider
				label={t('blocksPanel_height_label')}
				value={workplane.distance}
				onChange={value => handleArrayEntityChange('workPlanes', workPlanes.length - 1, 'distance', value)}
				min={0}
				max={10}
				step={globalStep}
			/>
		</AdvancedWizardSection>
	);
};
