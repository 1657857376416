import { useState, useEffect, useContext } from 'react';
import defaultContext from '../defaultContext';
import privileges from '../assets/privileges';
import { setCookie } from '../services/cookie';
import { getUser } from '../services/users';

export function usePrivileges() {
	const { user, setUser } = useContext(defaultContext);
	const [tier, setTier] = useState(user.tier);
	const [time, setTime] = useState(user.time);
	const [maxTime, setMaxTime] = useState(user.maxTime);
	const [maxProjects, setMaxProjects] = useState(user.maxProjects);
	const [count, setCount] = useState(user.count);
	const [monthlyCount, setMonthlyCount] = useState(user.monthlyCount);
	const [maxCount, setMaxCount] = useState(user.maxCount);
	const [expiry, setExpiry] = useState(user.expiry);
	const [privilege, setPrivilege] = useState(privileges.find(f => f.id === user.tier));
	const [waiting, setWaiting] = useState(false);

	const getUserData = async () => {
		setWaiting(true);
		const data = await getUser(user.userName);

		setUser({ ...data, userName: data.id });
		setMaxCount(data.maxCount);
		setMaxTime(Math.round(data.maxTime / 60));
		setMaxProjects(data.maxProjects);
		setTime(Math.round(data.time / 60));
		setCount(data.totalCount);
		setTier(data.tier);
		setMonthlyCount(data.monthlyCount);
		setExpiry(data.expiry);
		setWaiting(false);
	};

	useEffect(() => {
		getUserData()
			.then(() => {
				setCookie('tier', tier);
			})
			.catch(console.error);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setUser, user.userName]);

	return {
		user,
		setUser,
		tier,
		setTier,
		time,
		setTime,
		maxTime,
		setMaxTime,
		maxProjects,
		setMaxProjects,
		count,
		setCount,
		monthlyCount,
		setMonthlyCount,
		maxCount,
		setMaxCount,
		expiry,
		setExpiry,
		privilege,
		setPrivilege,
		waiting,
	};
}
