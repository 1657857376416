import React, { useRef } from 'react';
import { DoubleSide } from 'three';

export function Plane({ x, y, z, c, r, n, rotation, onClick }) {
	const mesh = useRef();

	return (
		<mesh position={[x, y, z]} ref={mesh} rotation={rotation} onPointerDown={onClick}>
			<planeGeometry attach="geometry" args={[r, r]} />
			<meshBasicMaterial attach="material" opacity={0.8} transparent={true} side={DoubleSide} color={c} />
		</mesh>
	);
}
