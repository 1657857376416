import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { getNotifications, upsertNotifications } from '../../../services/users';
import defaultContext from '../../../defaultContext';
import { Skeleton } from '@material-ui/lab';
import { uniq, includes, some } from 'lodash';
import { validateEmailFormat } from '../utils';

const availableNotificationTypes = ['email'];

const MultiCheckBox = ({ label, vals, available, onChange }) => {
	const { t } = useTranslation();

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			{available.map(v => (
				<div key={label + v} style={{ display: 'flex', flexDirection: 'row' }}>
					<label htmlFor={label + v}>{t(label + '_' + v)}</label>
					<input
						className="form-control"
						style={{ width: '1rem', height: '1rem', marginLeft: '1rem', marginTop: '0.4rem' }}
						id={label + v}
						type="checkbox"
						onChange={e => onChange(e.target.checked ? [...vals, v] : vals.filter(f => f !== v))}
						checked={some(vals, vv => vv === v)}
					/>
				</div>
			))}
		</div>
	);
};

const MultiInput = ({ label, vals, onChange, dirty }) => {
	const { t } = useTranslation();

	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<label htmlFor={label}>{t(label)}</label>
			{vals?.map((v, i) => (
				<div key={label + i} style={{ display: 'flex', flexDirection: 'column' }}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<input
							className="form-control"
							style={{ width: '20rem' }}
							id={label}
							type="text"
							onChange={e => onChange(vals.map((f, ii) => (ii === i ? e.target.value : f)))}
							value={v}
						/>
						{vals?.length > 1 ? (
							<button
								className="btn btn-dark-outline"
								style={{ height: '2rem', width: '5%' }}
								onClick={() => onChange(vals.filter((f, ii) => i !== ii))}
							>
								-
							</button>
						) : (
							<></>
						)}
					</div>
					{!validateEmailFormat(v) && dirty ? (
						<p style={{ color: 'red' }}>{t('error_login_invalid_email_address')}</p>
					) : (
						<></>
					)}
				</div>
			))}
			<button
				className="btn btn-dark-outline"
				style={{ height: '2rem', width: '5%' }}
				onClick={() => onChange([...vals, ''])}
			>
				+
			</button>
		</div>
	);
};

const NotificationForm = ({ type, props, onChange, dirty }) => {
	const { t } = useTranslation();

	switch (type) {
		case 'email':
			return (
				<div>
					<h6>{t('notification_type_' + type)}</h6>
					<MultiInput
						label={'notification_addresses_' + type}
						vals={props?.addresses ?? []}
						dirty={dirty}
						onChange={e => onChange(type, 'addresses', e)}
					/>
					<MultiCheckBox
						label={'notification_events_' + type}
						vals={props?.events ?? []}
						available={['project_completed', 'project_failed']}
						onChange={e => onChange(type, 'events', e)}
					/>
				</div>
			);
		default:
			return <></>;
	}
};

export function Notifications() {
	const { t } = useTranslation();
	const { user } = useContext(defaultContext);
	const [notifications, setNotifications] = useState();
	const [ready, setReady] = useState(false);
	const [dirty, setDirty] = useState(false);

	useEffect(() => {
		const f = async () => {
			try {
				const n = await getNotifications(user.userName);
				setNotifications(n);
			} catch (e) {
				console.error(e);
			} finally {
				setReady(true);
			}
		};
		f();
	}, [user.userName]);

	const handleChange = (type, attr, value) => {
		setNotifications({
			types: uniq([...(notifications.types ?? []), type]),
			list: some(notifications.list, f => f.type === type)
				? notifications.list.map(f => (f.type !== type ? f : { ...f, [attr]: value }))
				: [...notifications.list, { type, [attr]: value }],
		});
		setDirty(true);
	};

	const handleCancel = async () => {
		const n = await getNotifications(user.userName);
		setNotifications(n);
		setDirty(false);
	};

	const handleUpdateNotifications = async () => {
		try {
			setReady(false);
			await upsertNotifications(user.userName, notifications);
		} catch (e) {
			console.error(e);
		} finally {
			setReady(true);
		}
	};
	if (!notifications) {
		return <></>;
	}
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			{ready ? (
				<>
					{availableNotificationTypes
						.filter(f => !includes(notifications.types, f))
						.map((f, i) => (
							<NotificationForm key={f + i} type={f} onChange={handleChange} dirty={dirty} />
						))}
					{notifications.list.map((f, i) => (
						<NotificationForm key={f + i} type={f.type} props={f} onChange={handleChange} dirty={dirty} />
					))}
					{dirty ? (
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							<button style={{ width: '10rem' }} className="btn btn-dark" onClick={handleUpdateNotifications}>
								{t('save_button_label')}
							</button>
							<button style={{ width: '10rem', marginLeft: '1rem' }} className="btn btn-dark" onClick={handleCancel}>
								{t('cancel_button_label')}
							</button>
						</div>
					) : (
						<></>
					)}
				</>
			) : (
				<Skeleton width={'8em'} />
			)}
		</div>
	);
}
