import React from 'react';
import {
	BarChart,
	Bar,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	// Legend,
	ResponsiveContainer,
	Label,
	LabelList,
} from 'recharts';
import { slice, filter, map, values } from 'lodash';
import { countOF } from '../helpers';
import { useTranslation } from 'react-i18next';

const MyBarChart = ({ sensors, plantDli, range, chartLabel }) => {

	const {t} = useTranslation();
	let percentage = {};
	map(sensors, (sensor, key) => {
		percentage[key] = countOF(slice(sensor, range[0], range[1]), 1, plantDli) / (range[1] - range[0]);
	});
	
	const b3 = filter(values(percentage), f => f >= 0.75).length / sensors.length;
	const b2 = filter(values(percentage), f => f >= 0.5 && f < 0.75).length / sensors.length;
	const b1 = filter(values(percentage), f => f >= 0.25 && f < 0.5).length / sensors.length;
	const b0 = filter(values(percentage), f => f < 0.25).length / sensors.length;
	const data = [
		{
			b0: Math.round(b0 * 100),
			b1: Math.round(b1 * 100),
			b2: Math.round(b2 * 100),
			b3: Math.round(b3 * 100),
		},
	];

	return (
		<>
			<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1em' }}>
				<h6 style={{paddingLeft:'40px'}}>{chartLabel}</h6>
			</div>
			<ResponsiveContainer height={330} width={120}>
				<BarChart stackOffset="expand" data={data}>
					<CartesianGrid strokeDasharray="3 3" />
					<YAxis type="number" tick={false} />
					<XAxis type="category" dataKey="name" />
					<Tooltip />
					{/* <Legend /> */}
					<Bar name={t('spatial_poor')} dataKey="b0" stackId="a" fill="#fe8181">
						<LabelList dataKey="b0" position="center" content={CustomizedLabel} />
					</Bar>
					<Bar name={t('spatial_fair')} dataKey="b1" stackId="a" fill="#cde7ca">
						<LabelList dataKey="b1" position="center" content={CustomizedLabel} />
					</Bar>
					<Bar name={t('spatial_good')} dataKey="b2" stackId="a" fill="#94dd9d">
						<LabelList dataKey="b2" position="center" content={CustomizedLabel} />
					</Bar>
					<Bar name={t('spatial_excellent')} dataKey="b3" stackId="a" fill="#3ca156">
						<LabelList dataKey="b3" position="center" content={CustomizedLabel} />
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</>
	);
};

const CustomizedLabel = props => {
	const { content, ...rest } = props;
	rest.value = rest.value === 0 ? "" : rest.value+"%";
	return <Label {...rest} fontSize="12" fill="#FFFFFF" fontWeight="bold" />;
};

export default MyBarChart;
