import React from 'react';
import PlantItem from './plantItem';
import { useTranslation } from 'react-i18next';
const styles = {
	text: {
		paddingLeft: '.5rem',
		paddingRight: '.5rem',
		marginBottom: 0,
		width: '60%',
		maxWidth: '20rem',
		fontWeight: 'bold',
	},
};

const PlantSet = ({ projectPlants, setProjectPlants }) => {
	const { t } = useTranslation();

	const add = plantId => {
		const newState = projectPlants.map(plant =>
			plant.id === plantId ? { ...plant, quantity: Math.max(plant.quantity + 1, 0) } : plant,
		);
		setProjectPlants(newState);
	};
	const remove = plantId => {
		const newState = projectPlants.map(plant =>
			plant.id === plantId ? { ...plant, quantity: Math.max(plant.quantity - 1, 0) } : plant,
		);

		setProjectPlants(newState);
	};

	const onChange = (plantId, value) => {
		const newState = projectPlants.map(plant =>
			plant.id === plantId ? { ...plant, quantity: Math.max(parseInt(value), 0) } : plant,
		);
		setProjectPlants(newState);
	};
	return (
		<div>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<p style={styles.text}>{t('plant_name_label')}</p>
				<p style={styles.text}>{t('quantity_label')}</p>
			</div>
			<hr />
			{projectPlants.map(p => (
				<PlantItem add={add} remove={remove} onChange={onChange} id={p.id} name={p.name} quantity={p.quantity} />
			))}
		</div>
	);
};

export default PlantSet;
