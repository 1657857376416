import React from 'react';
const styles = {
	inputText: {
		height: '2.06rem',
		width: '3.68 rem',
		color: '#4F4F4F',
		fontFamily: 'PT Sans',
		marginRight: '2.06rem',
		marginBottom: '3rem',
	},
	icons: {
		marginRight: '1rem',
	},
	seclectionsIcons: {
		height: '2.75rem',
		width: '2.75rem',
	},
	titleText: {
		color: '#000',
		fontFamily: 'PT Sans',
		fontSize: '1.125rem',
		lineHeight: '1.5rem',
	},
};

const WizardSection = ({ image, title, style, children }) => {
	return (
		<div className="container border-top pt-2 pb-2" style={style}>
			<div className="row">
				<div className="col-md-1">
					<img src={image} style={{ width: '2rem' }} alt="wizard section" />
				</div>
				<div className="col-md-2">
					<p style={styles.titleText}>{title}</p>
				</div>
				<div className="col-md-9">{children}</div>
			</div>
		</div>
	);
};

export default WizardSection;
