import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';

const SimpleBarChart = ({ name, valueA, labelA, valueB, labelB, style }) => {
	const data = [
		{
			name,
			[labelA]: valueA,
			[labelB]: valueB,
		},
	];

	return (
		<div style={style}>
			<ResponsiveContainer>
				<BarChart layout="vertical" data={data}>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis type="number" tick={false} />
					<YAxis type="category" label={{ value: name, angle: -90, position: 'center' }} tick={false} />
					<Tooltip />
					<Legend />
					<Bar dataKey={labelA} fill="#696969">
						<LabelList dataKey={labelA} />
					</Bar>
					<Bar dataKey={labelB} fill="#ff5353">
						<LabelList dataKey={labelB} />
					</Bar>
				</BarChart>
			</ResponsiveContainer>
		</div>
	);
};

export default SimpleBarChart;
