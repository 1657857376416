import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import signinCouples from '../../assets/images/login/signinCouples.svg';
import { TextField } from '@material-ui/core';
import { useHistory } from 'react-router';
import Checkbox from '@material-ui/core/Checkbox';
import defaultContext from '../../defaultContext';
import { addUser, checkUser } from '../../services/users';
import { setCookie } from '../../services/cookie';
import { CircularProgress } from '@material-ui/core';
import { includes } from 'lodash';
import { validateEmailFormat } from '../ui-elements/utils';

const styles = {
	login: {
		height: 'fit-content',
		backgroundColor: '#D8EAD7',
		padding: ' 2rem 2rem 2rem 2rem',
		margin: '2rem 2rem 2rem 2rem',
	},
	register: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		fontFamily: 'PT Sans',
	},
	rememberMe: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'center',
		height: '24px',
		color: '#828282',
		fontFamily: 'PT Sans',
		marginBottom: '1.5rem',
	},
	loginButton: {
		backgroundColor: '#E0E0E0',
		fontFamily: 'PT Sans',
		width: '6em',
	},
};

const Login = () => {
	const { t } = useTranslation();
	const [remember, setRemember] = useState(false);
	const [userName, setUserName] = useState();
	const [error, setError] = useState();
	const [pass, setPass] = useState();
	const [passConfirm, setPassConfirm] = useState();
	const [login, setLogin] = useState(true);
	const [waiting, setWaiting] = useState(false);
	const { user, setUser } = useContext(defaultContext);
	const history = useHistory();

	useEffect(() => {
		if (user) {
			history.push('/dashboard');
		}
	}, [user, history]);

	const handleLogin = async () => {
		try {
			if (!userName || userName === '' || !pass || pass === '') {
				setError(t('error_login_empty'));
				return;
			}

			setWaiting(true);
			const res = await checkUser(userName, { pass });
			if (res === true) {
				if (remember) {
					setCookie('username', userName);
				}
				setError();
				setUser({ userName });
			} else {
				setError(t('error_login_error'));
			}
		} catch (e) {
			if (includes(e, 'Exception: Cannot find email')) {
				setError(t('error_login_cannot_find'));
			} else {
				setError(t('error_login_unknown'));
			}
		} finally {
			setWaiting(false);
		}
	};

	const handleSignUp = async () => {
		try {
			if (!userName || userName === '' || !pass || pass === '' || !passConfirm || passConfirm === '') {
				setError(t('error_login_empty'));
				return;
			}
			if (pass !== passConfirm) {
				setError(t('error_login_invalid_password_match'));
				return;
			}
			if (!validateEmailFormat(userName)) {
				setError(t('error_login_invalid_email_address'));
				return;
			}
			setWaiting(true);
			await addUser(userName, { pass });
			if (remember) {
				setCookie('username', userName);
			}
			setError();
			setUser({ userName });
		} catch (e) {
			if (includes(e, 'Exception: Email already exist')) {
				setError(t('error_login_exist'));
			} else {
				setError(t('error_login_unknown'));
			}
		} finally {
			setWaiting(false);
		}
	};

	return (
		<div className="container full-screen" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
			<div className="row" style={{ paddingTop: '9rem' }}>
				<div
					className="col-md-4 mb-md-5 mb-0 mt-md-0 mt-5"
					style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
				>
					<div style={styles.login}>
						<TextField
							id="login-text-box"
							label={t('email_label_login')}
							variant="outlined"
							onChange={e => setUserName(e.target.value)}
							style={{ backgroundColor: '#FFF', marginTop: '2rem', marginBottom: '2rem' }}
						/>
						<TextField
							id="password-text-box"
							label={t('password_label_login')}
							variant="outlined"
							type="password"
							onChange={e => setPass(e.target.value)}
							style={{ backgroundColor: '#FFF', marginBottom: '0.5rem' }}
						/>
						{!login ? (
							<TextField
								id="password-text-box"
								label={t('confirm_password_label_login')}
								variant="outlined"
								type="password"
								onChange={e => setPassConfirm(e.target.value)}
								style={{ backgroundColor: '#FFF', marginBottom: '0.5rem' }}
							/>
						) : (
							<></>
						)}
						{error ? <p style={{ color: 'red' }}>{error}</p> : <></>}
						<div style={styles.rememberMe}>
							<Checkbox
								disableRipple={true}
								style={{ color: '#C2D1D9' }}
								id="remember-me"
								checked={remember}
								onChange={() => setRemember(!remember)}
							/>
							<label htmlFor="remember-me" style={{ margin: 0 }}>
								{t('remember_me_label_login')}
							</label>
						</div>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
							{!login ? (
								<button
									className="btn btn-light"
									onClick={() => handleSignUp()}
									style={{
										...styles.loginButton,
									}}
								>
									{t('sign_up_button')}
								</button>
							) : (
								<></>
							)}
							{login ? (
								<button
									className="btn btn-light"
									variant="outlined"
									onClick={handleLogin}
									style={{
										...styles.loginButton,
									}}
								>
									{t('login_button')}
								</button>
							) : (
								<></>
							)}
							<button className="btn btn-link" onClick={() => setLogin(!login)}>
								{login ? t('sign_up_button') : t('login_button')}
							</button>
						</div>

						{/* <a style={{ marginTop: '1rem' }}>{t('forget_password_label')}</a> */}
						{waiting ? <CircularProgress style={{ position: 'absolute', top: '50%', left: '40%' }} /> : <></>}
					</div>
				</div>
				<div className="col-md-8 mt-md-0 mb-5">
					<img src={signinCouples} style={{ maxWidth: '100%' }} alt="login room" />
				</div>
			</div>
		</div>
	);
};

export default Login;
