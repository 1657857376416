import React from 'react';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

const useStyles = makeStyles(theme => ({
	paper: {
		position: 'absolute',
		width: 600,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
}));
const ModalComponent = ({ open, onClose, children }) => {
	const classes = useStyles();
	return (
		<Modal
			open={open}
			onClose={() => onClose()}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			{
				<div
					style={{
						top: `50%`,
						left: `50%`,
						transform: `translate(-50%, -50%)`,
						width: isMobile ? 'fit-content' : '',
					}}
					className={classes.paper}
				>
					{children}
				</div>
			}
		</Modal>
	);
};

export default ModalComponent;
