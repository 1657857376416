import React from 'react';
import { useTranslation } from 'react-i18next';
import RadioImage from '../ui-elements/radio-image';
import optionListPicture from '../pages/templatesList';
import optionOne from '../../assets/images/icons/icon_T1.svg';
import optionTwo from '../../assets/images/icons/icon_T2.svg';
import optionThree from '../../assets/images/icons/icon_T3.svg';
import optionFour from '../../assets/images/icons/icon_T4.svg';
import optionFive from '../../assets/images/icons/scratch.svg';
import templates from '../../assets/images/icons/templates.svg';
import { isMobile } from 'react-device-detect';

const styles = {
	imageRadioButton: {
		marginRight: '1rem',
		width: '6rem',
	},
	templateText: {
		height: '1.5rem',
		width: '4.56rem',
		color: '#000',
		fontFamily: 'PT Sans',
		fontSize: '1.125rem',
		lineHeight: '1.5rem',
	},
	seclectionsIcons: {
		height: '2.75rem',
		width: '2.75rem',
	},
};

optionListPicture[0].image = optionOne;
optionListPicture[1].image = optionTwo;
optionListPicture[2].image = optionThree;
optionListPicture[3].image = optionFour;
optionListPicture[4].image = optionFive;

export const WizardTemplateSetter = ({ optionId, setOptionId, style, free }) => {
	const { t } = useTranslation();
	return (
		<div className="container border-top pt-2 pb-2" style={style}>
			<div className="row">
				<div className="col-md-1">
					<img src={templates} style={styles.seclectionsIcons} alt="template" />
				</div>
				<div className="col-md-2">
					<p style={styles.templateText}>{t('template_label') + ':'}</p>
				</div>
				<div className="col-md-9">
					<RadioImage
						onClick={e => setOptionId(e)}
						//onChange ={e =>setOptionId(e)}
						checked={optionId}
						name="templates"
						options={free ? optionListPicture.filter(f => f.id !== 'bare') : optionListPicture}
						style={styles.imageRadioButton}
					/>
				</div>
			</div>
		</div>
	);
};

export const BareTemplates = ({ optionId, setOptionId }) => {
	const { t } = useTranslation();

	return (
		<>
			<p style={{ width: isMobile ? '100%' : '45%' }}>{t('template-select-comment')}</p>
			<RadioImage
				onClick={e => setOptionId(e)}
				//onChange ={e =>setOptionId(e)}
				checked={optionId}
				name="templates"
				options={optionListPicture}
				style={styles.imageRadioButton}
			/>
		</>
	);
};
