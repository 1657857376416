import React, { useRef } from 'react';
import Scene from '../ui-elements/room-builder/scene';
import { useState, useContext, useEffect, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import defaultContext from '../../defaultContext';
import { Redirect } from 'react-router';
import ModelerControls from '../ui-elements/modeler-controls/modeler-controls';
import { getProject, upsertProject } from '../../services/projects';

const RoomBuilder = () => {
	const { t } = useTranslation();
	const { currentSimulationName, currentSimulation, setCurrentSimulation, user, projects, setProjects } =
		useContext(defaultContext);
	const centerMapFunc = useRef();
	const onMapClick = useRef();
	const [localCurrentSimulation, setLocalCurrentSimulation] = useState();
	const [dirty, setDirty] = useState(false);
	useEffect(() => {
		if (!currentSimulationName) {
			return;
		}
		if (currentSimulation && currentSimulation.id === currentSimulationName) {
			setLocalCurrentSimulation(currentSimulation);
		}
		if (currentSimulationName && !projects.some(f => f.id === currentSimulationName)) {
			setLocalCurrentSimulation({
				id: currentSimulationName,
				hasRoom: true,
				translateX: 0,
				translateY: 0,
				lat: 55.6652759,
				long: 12.5815248,
				resolution: 1,
				orientation: 0,
				width: 5,
				depth: 7,
				height: 3,
				wallReflectivity: 0.5,
				ceilingReflectivity: 0.8,
				floorReflectivity: 0.5,
				elevation: 0,
				workPlanes: [],
				windows: [],
				blocks: [],
				trees: [],
				furniture: [],
				marks: [],
				objects: [],
			});
			setDirty(true);
			return;
		}

		getProject(user.userName, currentSimulationName)
			.then(result => {
				setLocalCurrentSimulation(result);
			})
			.catch(e => console.error(e));
	}, [currentSimulationName, currentSimulation, setCurrentSimulation, user.userName, projects, setDirty]);
	const [redirect, setRedirect] = useState(false);
	const [activeId, setActiveId] = useState();

	const handleLocalCurrentSimulation = v => {
		setLocalCurrentSimulation(v);
		setDirty(true);
	};
	const handleFinish = () => {
		upsertProject(user.userName, localCurrentSimulation.id, localCurrentSimulation)
			.then(() => {
				setCurrentSimulation(localCurrentSimulation);
				setRedirect(true);
				const exist = projects?.find(f => f.id === localCurrentSimulation.id);
				if (!exist) setProjects([...projects, { id: localCurrentSimulation.id, status: null }]);
			})
			.catch(console.error);
	};

	if (!currentSimulationName && !localCurrentSimulation) {
		return <Redirect push to={'/dashboard'} />;
	}
	if (!localCurrentSimulation) {
		return <></>;
	}

	return redirect ? (
		<Redirect push to="/dashboard" />
	) : (
		<Suspense fallback={<p>{t('loading_label')}</p>}>
			<div>
				<ModelerControls
					setCurrentSimulation={handleLocalCurrentSimulation}
					currentSimulation={localCurrentSimulation}
					handleFinish={handleFinish}
					onMapClick={onMapClick}
					activeId={activeId}
					dirty={dirty}
					setActiveId={setActiveId}
				/>
				<Scene
					centerMapFunc={centerMapFunc}
					onMapClick={onMapClick}
					currentSimulation={localCurrentSimulation}
					activeId={activeId}
					setActiveId={setActiveId}
					className="full-screen"
					showPlants={false}
				/>
			</div>
		</Suspense>
	);
};

export default RoomBuilder;
