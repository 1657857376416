import React, { useContext } from 'react';
import expansionContext from './expansionContext';
import { Delete, Close } from '@material-ui/icons';

const Expanse = ({ title, icon, id, remove, children }) => {
	const { activeId, setActiveId } = useContext(expansionContext);

	const top =
		React.Children.map(children, f => f.type.displayName).some(f => f === 'Expanse') ||
		id === 'room' ||
		id === 'location';

	const bottom = React.Children.map(children, f => f.type.displayName).every(f => f !== 'Expanse');

	const handleClose = () => {
		if (top) {
			setActiveId();
		} else {
			setActiveId(activeId.split('_')[0]);
		}
	};
	const handleClick = a => {
		setActiveId(id);
	};

	const handleRemove = () => {
		remove();
		setActiveId();
	};
	if (children.length === 0) {
		return <></>;
	}

	return (
		<>
			<button
				onClick={() => handleClick(true)}
				className="btn btn-outline-dark rounded-pill"
				style={{
					display: (!activeId && top) || (activeId && id.indexOf(activeId + '_') === 0) ? 'flex' : 'none',
					flexDirection: 'row',
					marginLeft: '0.5rem',
					height: '3em',
				}}
			>
				<img src={icon} style={{ width: '2rem', marginRight: '0.5rem' }} alt="icon" />
				<h4>{title}</h4>
			</button>
			{activeId && id === activeId ? (
				<>
					<div
						style={{
							display: 'flex',
							marginTop: '0',
							marginLeft: '0.5rem',
							flexDirection: bottom ? 'column' : 'row',
						}}
					>
						<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
							<button
								className="btn btn-outline-dark rounded-pill ml-2"
								style={{
									height: '3em',
									maxWidth: '3em',
								}}
								onClick={() => handleClose()}
							>
								<Close />
							</button>
							{remove ? (
								<button
									className="btn btn-outline-dark rounded-pill"
									style={{
										height: '3em',
										maxWidth: '3em',
									}}
									onClick={handleRemove}
								>
									<Delete />
								</button>
							) : (
								<></>
							)}
						</div>
						{children}
					</div>
				</>
			) : (
				<></>
			)}
			{activeId && activeId.indexOf('_') > 0 && activeId.indexOf(id + '_') === 0 ? (
				<>
					<div>{children}</div>
				</>
			) : (
				<></>
			)}
		</>
	);
};

Expanse.displayName = 'Expanse';

export default Expanse;
