export default [
	{
		id: 'room',
		templateId: 'room',
		hasRoom: true,
		templateInputs: [
			{ label: 'wwr', val: 0.3, min: 0.25, max: 0.85, step: 0.5 },
			{ label: 'baluster_bottom', val: 0.05, min: 0.05, max: 2, step: 0.5 },
			{ label: 'baluster_top', val: 0.05, min: 0.05, max: 1, step: 0.5 },
			{ label: 'overhang', val: 1, min: 0.5, max: 3, step: 0.5 },
			{ label: 'transmissivity', val: 0.8, min: 0, max: 1, step: 0.01 },
		],
		translateX: 0,
		translateY: 0,
		lat: 55.6652759,
		long: 12.5815248,
		resolution: 1,
		orientation: 0,
		width: 5,
		depth: 7,
		height: 3,
		wallReflectivity: 0.5,
		ceilingReflectivity: 0.5,
		floorReflectivity: 0.5,
		elevation: 0,
		workPlanes: [{ id: 0, distance: 1, position1: [-3.5, -2.5], position2: [3.5, 2.5], face: 'H' }],
		windows: [{ id: 0, width: 1.55, height: 2.9, position: [1.72, 0.05], face: 'F', transmissivity: 0.8 }],
		blocks: [
			{
				id: 0,
				height: 3,
				position1: [4.5, -2.5],
				position2: [3.5, 2.5],
				incline: 0,
				incline2: 0,
				top: true,
				reflectivity: 0.5,
			},
		],
		trees: [],
		furniture: [],
		marks: [],
		objects: [],
		func: `
	  get('workPlanes',0).position1[0]=(room.depth)/-2;
	  get('workPlanes',0).position2[0]=(room.depth)/2;
	  get('workPlanes',0).position1[1]=(room.width)/-2;
	  get('workPlanes',0).position2[1]=(room.width)/2;
	  
	  get('windows',0).height=room.height-room.templateInputs[1].val-room.templateInputs[2].val
	  get('windows',0).width=(room.height*room.width*room.templateInputs[0].val)/get('windows',0).height;
	  get('windows',0).position[0]= (room.width-get('windows',0).width)/2;
	  get('windows',0).position[1]= room.templateInputs[1].val;
	  get('windows',0).transmissivity = room.templateInputs[4].val;

	  get('blocks',0).position1[0]=room.templateInputs[3].val+((room.depth)/2); 
	  get('blocks',0).position1[1]=(room.width)/-2;
	  get('blocks',0).position2[0]=(room.depth)/2; 
	  get('blocks',0).position2[1]=(room.width)/2;
      get('blocks',0).height=room.height;
	  `,
	},

	{
		id: 'balcony',
		templateId: 'balcony',
		hasRoom: false,
		templateInputs: [
			{ label: 'Front Wall Height', val: 1, min: 0, max: 10, step: 0.5 },
			{ label: 'Left Wall Height', val: 1.5, min: 0, max: 3, step: 0.5 },
			{ label: 'Right Wall Height', val: 1.5, min: 0, max: 3, step: 0.5 },
			{ label: 'Over Hang Length', val: 3, min: 0, max: 12, step: 0.5 },
		],
		translateX: 0,
		translateY: 0,
		lat: 55.6652759,
		long: 12.5815248,
		resolution: 1,
		orientation: 0,
		width: 4,
		depth: 6,
		height: 3,
		wallReflectivity: 0.5,
		ceilingReflectivity: 0.5,
		floorReflectivity: 0.5,
		elevation: 0,
		workPlanes: [{ id: 0, distance: 0.5, position1: [-3, -2], position2: [3, 2], face: 'H' }],
		windows: [],
		blocks: [
			{
				id: 0,
				height: 1.5,
				position1: [3, -2],
				position2: [3, 2],
				incline: 0,
				incline2: 0,
				top: false,
				reflectivity: 0.5,
			},
			{
				id: 1,
				height: 3,
				position1: [-3, -2],
				position2: [-3, 2],
				incline: 0,
				incline2: 0,
				top: false,
				reflectivity: 0.5,
			},
			{
				id: 2,
				height: 1.5,
				position1: [-3, 2],
				position2: [3, 2],
				incline: 0,
				incline2: 0,
				top: false,
				reflectivity: 0.5,
			},
			{
				id: 3,
				height: 1.5,
				position1: [-3, -2],
				position2: [3, -2],
				incline: 0,
				incline2: 0,
				top: false,
				reflectivity: 0.5,
			},
			{
				id: 4,
				height: 3,
				position1: [-3, -2],
				position2: [0, 2],
				incline: 0,
				incline2: 0,
				top: true,
				reflectivity: 0.5,
			},
		],
		trees: [],
		furniture: [],
		marks: [],
		objects: [],
		func: `
	  get('workPlanes',0).position1[0]=(room.depth)/-2;
	  get('workPlanes',0).position2[0]=(room.depth)/2;
	  get('workPlanes',0).position1[1]=(room.width)/-2;
	  get('workPlanes',0).position2[1]=(room.width)/2;

      get('blocks',0).position1[0]=(room.depth)/2; 
      get('blocks',0).position1[1]=(room.width)/-2;
      get('blocks',0).position2[0]=(room.depth)/2; 
	  get('blocks',0).position2[1]=(room.width)/2;
	  get('blocks',0).height=room.templateInputs[0].val;

      get('blocks',1).position1[0]=(room.depth)/-2; 
      get('blocks',1).position1[1]=(room.width)/-2;
      get('blocks',1).position2[0]=(room.depth)/-2; 
	  get('blocks',1).position2[1]=(room.width)/2;	  
	  get('blocks',1).height=room.height;

      get('blocks',2).position1[0]=(room.depth)/-2; 
      get('blocks',2).position1[1]=(room.width)/2;
      get('blocks',2).position2[0]=(room.depth)/2; 
	  get('blocks',2).position2[1]=(room.width)/2;
	  get('blocks',2).height=room.templateInputs[1].val;

	  get('blocks',3).position1[0]=(room.depth)/-2; 
      get('blocks',3).position1[1]=(room.width)/-2;
      get('blocks',3).position2[0]=(room.depth)/2; 
	  get('blocks',3).position2[1]=(room.width)/-2;
	  get('blocks',3).height=room.templateInputs[2].val;

	  get('blocks',4).position1[0]=(room.depth)/-2; 
      get('blocks',4).position1[1]=(room.width)/-2;
      get('blocks',4).position2[0]=((room.depth)/-2)+room.templateInputs[3].val; 
	  get('blocks',4).position2[1]=(room.width)/2;
	  get('blocks',4).height=room.height;
	  `,
	},

	{
		id: 'yard',
		templateId: 'yard',
		hasRoom: false,
		templateInputs: [
			{ label: 'h1', val: 1.5, min: 0.5, max: 10, step: 0.5 },
			{ label: 'h2', val: 3, min: 0.5, max: 10, step: 0.5 },
			{ label: 'h3', val: 3, min: 0.5, max: 10, step: 0.5 },
			{ label: 'h4', val: 1.5, min: 0.5, max: 10, step: 0.5 },
		],
		translateX: 0,
		translateY: 0,
		lat: 55.6652759,
		long: 12.5815248,
		resolution: 1,
		orientation: 0,
		width: 4,
		depth: 6,
		height: 3,
		wallReflectivity: 0.5,
		ceilingReflectivity: 0.5,
		floorReflectivity: 0.5,
		elevation: 0,
		workPlanes: [{ id: 0, distance: 1, position1: [-3, -2], position2: [3, 2], face: 'H' }],
		windows: [],
		blocks: [
			{
				id: 0,
				height: 1.5,
				position1: [3, -2],
				position2: [3, 2],
				incline: 0,
				incline2: 0,
				top: false,
				reflectivity: 0.5,
			},
			{
				id: 1,
				height: 3,
				position1: [-3, -2],
				position2: [-3, 2],
				incline: 0,
				incline2: 0,
				top: false,
				reflectivity: 0.5,
			},
			{
				id: 2,
				height: 3,
				position1: [-3, 2],
				position2: [3, 2],
				incline: 0,
				incline2: 0,
				top: false,
				reflectivity: 0.5,
			},
			{
				id: 3,
				height: 1.5,
				position1: [-3, -2],
				position2: [3, -2],
				incline: 0,
				incline2: 0,
				top: false,
				reflectivity: 0.5,
			},
		],
		trees: [],
		furniture: [],
		marks: [],
		objects: [],
		func: `
	  get('workPlanes',0).position1[0]=(room.depth)/-2;
	  get('workPlanes',0).position2[0]=(room.depth)/2;
	  get('workPlanes',0).position1[1]=(room.width)/-2;
	  get('workPlanes',0).position2[1]=(room.width)/2;

      get('blocks',0).position1[0]=(room.depth)/2; 
      get('blocks',0).position1[1]=(room.width)/-2;
      get('blocks',0).position2[0]=(room.depth)/2; 
	  get('blocks',0).position2[1]=(room.width)/2;
	  get('blocks',0).height=room.templateInputs[0].val;

      get('blocks',1).position1[0]=(room.depth)/-2; 
      get('blocks',1).position1[1]=(room.width)/-2;
      get('blocks',1).position2[0]=(room.depth)/-2; 
	  get('blocks',1).position2[1]=(room.width)/2;	  
	  get('blocks',1).height=room.templateInputs[1].val;

      get('blocks',2).position1[0]=(room.depth)/-2; 
      get('blocks',2).position1[1]=(room.width)/2;
      get('blocks',2).position2[0]=(room.depth)/2; 
	  get('blocks',2).position2[1]=(room.width)/2;
	  get('blocks',2).height=room.templateInputs[2].val;

	  get('blocks',3).position1[0]=(room.depth)/-2; 
      get('blocks',3).position1[1]=(room.width)/-2;
      get('blocks',3).position2[0]=(room.depth)/2; 
	  get('blocks',3).position2[1]=(room.width)/-2;
	  get('blocks',3).height=room.templateInputs[3].val;
      `,
	},

	{
		id: 'greenhouse',
		templateId: 'greenhouse',
		hasRoom: true,
		templateInputs: [{ label: 'height', val: 1, min: 1, max: 4, step: 0.5 }],
		translateX: 0,
		translateY: 0,
		lat: 55.6652759,
		long: 12.5815248,
		resolution: 1,
		orientation: 0,
		width: 8,
		depth: 20,
		height: 5,
		wallReflectivity: 0.5,
		ceilingReflectivity: 0.5,
		floorReflectivity: 0.5,
		elevation: 0,
		workPlanes: [{ id: 0, distance: 1, position1: [-10, -4], position2: [10, 4], face: 'H' }],
		windows: [
			{ id: 0, width: 8, height: 4, position: [0, 1], face: 'F', transmissivity: 0.8 },
			{ id: 1, width: 8, height: 4, position: [0, 1], face: 'B', transmissivity: 0.8 },
			{ id: 2, width: 20, height: 4, position: [0, 1], face: 'R', transmissivity: 0.8 },
			{ id: 3, width: 20, height: 4, position: [0, 1], face: 'L', transmissivity: 0.8 },
			{ id: 4, width: 20, height: 8, position: [0, 0], face: 'T', transmissivity: 0.8 },
		],
		func: `
	  get('workPlanes',0).position1[0]=(room.depth)/-2;
	  get('workPlanes',0).position2[0]=(room.depth)/2;
	  get('workPlanes',0).position1[1]=(room.width)/-2;
	  get('workPlanes',0).position2[1]=(room.width)/2;

      get('windows',0).width=room.width; 
	  get('windows',0).height=room.height-room.templateInputs[0].val;
	  get('windows',0).position[1]=room.templateInputs[0].val;

      get('windows',1).width=room.width; 
	  get('windows',1).height=room.height-room.templateInputs[0].val;
	  get('windows',1).position[1]=room.templateInputs[0].val;

      get('windows',2).width=room.depth; 
	  get('windows',2).height=room.height-room.templateInputs[0].val;
	  get('windows',2).position[1]=room.templateInputs[0].val;

      get('windows',3).width=room.depth; 
	  get('windows',3).height=room.height-room.templateInputs[0].val;
	  get('windows',3).position[1]=room.templateInputs[0].val;

	  get('windows',4).width=room.depth;
	  get('windows',4).height=room.width;
      `,
		blocks: [],
		trees: [],
		furniture: [],
		marks: [],
		objects: [],
	},
	{
		id: 'bare',
		templateId: 'bare',
		hasRoom: true,
		translateX: 0,
		translateY: 0,
		lat: 55.6652759,
		long: 12.5815248,
		resolution: 1,
		orientation: 0,
		width: 5,
		depth: 7,
		height: 3,
		wallReflectivity: 0.5,
		ceilingReflectivity: 0.5,
		floorReflectivity: 0.5,
		elevation: 0,
		workPlanes: [{ id: 0, distance: 1, position1: [-3.5, -2.5], position2: [3.5, 2.5], face: 'H' }],
		windows: [],
		blocks: [],
		trees: [],
		furniture: [],
		marks: [],
		objects: [],
		func: `
	  get('workPlanes',0).position1[0]=(room.depth)/-2;
	  get('workPlanes',0).position2[0]=(room.depth)/2;
	  get('workPlanes',0).position1[1]=(room.width)/-2;
	  get('workPlanes',0).position2[1]=(room.width)/2;`,
	},
];
