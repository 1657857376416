import React from 'react';
import { useTranslation } from 'react-i18next';
import lightImportance from '../../assets/images/howItWorks/lightImportance.png';
import parCurve from '../../assets/images/howItWorks/parCurve.png';
import daylightModeling from '../../assets/images/howItWorks/daylightModeling.png';
import energy from '../../assets/images/howItWorks/energy.png';
import database from '../../assets/images/howItWorks/database.png';
import dliValues from '../../assets/images/howItWorks/dliValues.png';
import visualization from '../../assets/images/howItWorks/visualization.png';
import dailyYearSunlight from '../../assets/images/howItWorks/dailyYearSunlight.png';
import map from '../../assets/images/howItWorks/map.png';

const styles = {
	image: {
		maxWidth: '100%',
	},
	figureCaption: { fontStyle: 'italic', color: '#00BFFF', textAlign: 'center', marginTop: '0.5em' },
	summary: {
		border: '1px solid #aaa',
		borderRadius: '4px',
		marginBottom: '.7rem',
	},
	title: {
		fontSize: '1.5rem',
	},
	details: {},
};

const contents = [
	{
		title: t => <h3 style={styles.title}>{t('how_it_works_heading_one')}</h3>,
		content: t => (
			<>
				<p>
					Light (particularly natural light) is the most important yet the most undetermined element for parenting a
					healthy plant. All plants require light for
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://en.wikipedia.org/wiki/Photosynthesis"
					>
						photosynthesis
					</a>{' '}
					and convert Co2 and water into sugar and O2. Without adequate light plants die.
				</p>
				<p>
					In general, the vital needs of a plant are light, water, air, nutrients, and proper temperature. While the
					importance of each of these needs is mostly equal to the plants, sunlight is the most complex factor to
					control. Generally speaking, people are more confused about light requirement than any other plants
					requirements.
				</p>
				<figure>
					<img src={lightImportance} alt={t('how_it_works_figure_one')} style={styles.image} />
					<figcaption style={styles.figureCaption}>
						The importance of light in plants requirement and its dynamic nature which makes it hard to control.
					</figcaption>
				</figure>
				<p>
					The complexity lies on the dynamic behavior of the sun which is based on the location
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://en.wikipedia.org/wiki/Latitude"
					>
						(latitude)
					</a>{' '}
					and varies over the course of a day/season. Besides, the sky condition and cloud cover may easily alter the
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://en.wikipedia.org/wiki/Solar_access"
					>
						solar access
					</a>{' '}
					on the ground. But the good news is that we can predict the amount of available sunlight in your space by
					using advanced techniques.
				</p>
				<p>
					From cost saving perspective, energy consumption is 60% of the overall annual operation cost in a greenhouse.
					About 30% of this fraction is for running the artificial lightings, and the other 30% should be used for air
					condition systems to mainly remove the overheating caused by the lightings
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://youtu.be/D-uLwapyCUo?t=255"
					>
						[Dr. Marc Iersel]
					</a>{' '}
					. This is particularly important for commercial growers when electrical consumption of lighting increases the
					production cost and CO2 emission. In this case, by predicting the available daily sunlight, more
					energy-efficient lighting strategies must be applied to provide plants with the optimal level of light (no
					more, no less).
				</p>
			</>
		),
	},
	{
		title: t => <h3 style={styles.title}>{t('how_it_works_heading_two')}</h3>,
		content: t => (
			<>
				<p>
					The source of solar energy for photosynthesis is expressed
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://en.wikipedia.org/wiki/Photosynthetically_active_radiation"
					>
						photosynthetically active radiation
					</a>{' '}
					(PAR) in μmol/m²∙s. While the PAR radiation approximately corresponds to the visible spectrum (to the human
					eye 400–700 nm), light absorption by photosynthetic pigments occurs especially in the blue (400–500 nm) and
					red (600–700 nm).
				</p>
				<div style={{ display: 'flex', justifyContent: 'center' }}>
					<figure>
						<img src={parCurve} alt={t('how_it_works_figure_two')} style={styles.image} />
						<figcaption style={styles.figureCaption}>
							Photosynthesis yield photon flux spectral weighting
							<a
								style={{ textDecoration: 'underline', color: 'blue' }}
								href="https://www.sciencedirect.com/science/article/abs/pii/0002157171900227"
							>
								(McCree curve);
							</a>{' '}
							<br />
							The energy-weighted curve is for weighting PAR expressed in watts or joules
							<a
								style={{ textDecoration: 'underline', color: 'blue' }}
								href="https://en.wikipedia.org/wiki/Photosynthetically_active_radiation#/media/File:Photosynthesis_yield_photon_flux_spectral_weighting.svg"
							>
								[credit]
							</a>{' '}
						</figcaption>
					</figure>
				</div>
				<p>
					<a
						style={{ textDecoration: 'underline', color: 'blue' }}
						href="https://en.wikipedia.org/wiki/Daily_light_integral"
					>
						Daily Light Integral
					</a>{' '}
					(DLI) in mol/m².d is recommended by scientist as the best quantum metric which explains the required light for
					each plant species. DLI is the amount of photosynthetically active radiation (PAR) delivered to the plant
					canopy over 24-hour
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://en.wikipedia.org/wiki/Photoperiodism"
					>
						photoperiod.
					</a>{' '}
					Providing the required DLI we can ensure the plants growth and optimal crop yield.
				</p>
				<p>
					To date, researchers have provided worldwide DLI maps to help farmers to select proper plant species or
					estimate the required electricity for supplemental lighting based on the available sunlight at their location.
					However, these annual or monthly averaged DLI maps or online DLI calculators are very limited and may only
					work for farmers in plain fields but not for the indoor growers.
				</p>
				<figure>
					<img src={map} alt={t('how_it_works_figure_three')} style={styles.image} />
					<figcaption style={styles.figureCaption}>
						{t('how_it_works_figure_img_three')}
						<a
							style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
							href="https://journals.ashs.org/hortsci/view/journals/hortsci/53/9/article-p1250.xml"
						>
							[Faust, J. 2018]
						</a>{' '}
					</figcaption>
				</figure>
			</>
		),
	},
	{
		title: t => <h3 style={styles.title}>{t('how_it_works_heading_three')}</h3>,
		content: t => (
			<>
				<p>
					For predicting the annual available
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://en.wikipedia.org/wiki/Daylight"
					>
						natural light
					</a>{' '}
					in an indoor (or semi-indoor) environment, it is necessary to survey the daily and seasonal change of the sun
					and sky condition in your exact location. In a dense urban neighborhood, the contextual obstructions and
					surrounding buildings are also importance, since they play a significant role on the sunlight beam reaching to
					the plants canopy. If the light beam travels through a glazing or bouncing back from a reflecting surface in
					front of your window, the size and the property of the
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://en.wikipedia.org/wiki/Glazing_(window)"
					>
						glazing
					</a>{' '}
					(window) and
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://en.wikipedia.org/wiki/Opacity_(optics)"
					>
						opaque
					</a>{' '}
					objects (
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://en.wikipedia.org/wiki/Transmittance"
					>
						transmittance
					</a>{' '}
					and
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://en.wikipedia.org/wiki/Transmittance"
					>
						reflectance
					</a>{' '}
					) need to be considered in the calculations.
				</p>
				<p>
					Using advance
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="http://climate-based-daylighting.com/doku.php?id=academic:climate-based-daylight-modelling"
					>
						climate-based daylighting
					</a>{' '}
					simulations in
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://floyd.lbl.gov/radiance/index.html"
					>
						Radiance
					</a>{' '}
					, all the geometrical information of your room and the surrounding buildings with optical properties are built
					up in a scene. Besides an artificial sky (
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://doi.org/10.1016/0038-092X(93)90017-I"
					>
						Perez all-weather sky model
					</a>{' '}
					) is modeled for calculations based on the closest available climatic data (TMY) for your location.
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://en.wikipedia.org/wiki/Typical_meteorological_year"
					>
						Typical meteorological year (TMY)
					</a>{' '}
					includes historical solar radiation for a year-round period based on the recorded hourly values over a very
					long period (more than 12 years). These data sets are the information when it comes to design your garden
					orientation, opening size and orientations in room, and making a right decision for growing the right plant
					and finding the right place for your desired plants.
				</p>
				<figure>
					<img src={daylightModeling} alt={t('how_it_works_figure_three')} style={styles.image} />

					<figcaption style={styles.figureCaption}>
						Advance climate-based daylighting modeling and simulation considering the room and all surrounding buildings
					</figcaption>
				</figure>
				<p>
					Even though these physically based lighting simulations techniques have been used for more than three decades
					in lighting, building industry and horticulture; to date, general users could not benefit from these
					complicated and expensive simulations. Now, thanks to{' '}
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://en.wikipedia.org/wiki/https://en.wikipedia.org/wiki/Cloud_computing"
					>
						cloud computing
					</a>{' '}
					services it is time to make these calculations affordable for everyone without any background in modeling,
					lighting or design.
				</p>
			</>
		),
	},
	{
		title: t => <h3 style={styles.title}>{t('how_it_works_heading_four')}</h3>,
		content: t => (
			<>
				<p>
					Required lighting for plants is a cumulative value (we need to know how much light is delivered to the plant
					over a day or week). Therefore, it needs continuous measurement for long periods (month, year, …). The quantum
					sensors are required for measurements which comes with extra costs for calibration, maintenance, and data
					logging over a long period.{' '}
				</p>
				<p>
					{' '}
					If you are not a commercial grower and just want to parent your plants carefully, here what you get simply.
					You don’t need to buy any expensive quantum sensor to find out which part of your room or backyard or balcony
					gets enough direct sunlight. You also get informed if your desired plants can survive dark winter season on
					the same spot or do you need to move it to another place or provide it supplemental light to makes it happy to
					bloom. Besides, you get notification if the spot may get too much direct sun and you better activate your
					shades or just water it more often.
				</p>
				<figure>
					<img src={dliValues} alt={t('how_it_works_figure_img_five')} style={styles.image} />
					<figcaption style={styles.figureCaption}>
						A detailed monthly breakdown for each point inside the room to illustrate when the lighting situation is not
						suitable (under-lit or over-lit) over a year
					</figcaption>
				</figure>
				<figure>
					<img src={visualization} alt={t('how_it_works_figure_img_six')} style={styles.image} />
					<figcaption style={styles.figureCaption}>
						A spatial visualization which shows the distribution of sunlight in an indoor environment and the percentage
						of the times each spot inside the room needs supplemental lighting or solar protection.
					</figcaption>
				</figure>
				<p>
					If you are a commercial grower, you know the importance of predicting available natural light and its
					distribution inside the growing space. You know that this is a very crucial piece of information for
					estimating the electrical power consumption and shading requirements over a year. Predicting these values will
					inform you about a profitable crops and leads to a more efficient operating energy management.{' '}
				</p>
				<figure>
					<img src={dailyYearSunlight} alt={t('how_it_works_figure_img_seven')} style={styles.image} />
					<figcaption style={styles.figureCaption}>
						A detailed daily calculation of the use of supplemental lighting based on the available sunlight over a year
					</figcaption>
				</figure>
				<p>
					The graph below shows the breakdown of total energy consumption in a commercial greenhouse. The percentage of
					energy for supplemental lighting is significant from 19% to 39% of total energy consumption for a 85.5 m² (920
					ft2) growing area in Denver, USA [
					<a
						style={{ paddingLeft: '4px', textDecoration: 'underline', color: 'blue' }}
						href="https://cerescann.com/calculate-energy-savings/"
					>
						ref.
					</a>{' '}
					]. Our services help you to have a more beneficial plan for your greenhouse and cut part of this energy by
					applying an adaptive lighting strategy.
				</p>
				<div>
					<figure style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
						<img src={energy} alt={t('how_it_works_figure_eight')} style={styles.image} />
						<figcaption style={styles.figureCaption}>
							The breakdown of total energy consumption in different types of greenhouses and the percentage of
							supplemental lighting
						</figcaption>
					</figure>
				</div>
			</>
		),
	},
	{
		title: t => <h3 style={styles.title}>{t('how_it_works_heading_five')}</h3>,
		content: t => (
			<>
				<p>
					For various plant species, it is known how much light is required during a day. This is the point at which the
					process of photosynthesis is active and if the level of light is less, growth is reduced or stopped. Many
					lighting requirements for individual plant species have been published, but they are scattered throughout the
					enormous number of scientific literature and too specific to the experimental conditions. There are also some
					data available on different websites for caring plants. However, in all of these resources required light is
					expressed only in relative terms, such as high and low light, which is somehow confusing.{' '}
				</p>
				<p>
					Finding the optimal amount of DLI is the fundamental question for having healthy plants. For example, tomatoes
					require at least 20 mol/m².d. DLI values below this level decline the productivity and DLI values more than 35
					mol/m².d are not favorable and may cause excessive heat. Here we offer a complete database for various
					cultivars (over 200,000 species), which helps you to find the right plant.{' '}
				</p>
				<p>
					If you want to have more alternatives to design, you can select our selected plant catalogues (house-plants,
					air purifiers, green-wall, edible, and flowering sets) and let our algorithm find the best place in your space
					for each plant automatically.
				</p>
				<figure>
					<img src={database} alt={t('how_it_works_figure_nine')} style={styles.image} />
					<figcaption style={styles.figureCaption}>
						The database for various cultivars and the automatic selection algorithm for finding the right place for the
						right
					</figcaption>
				</figure>
				<p></p>
			</>
		),
	},
	{
		title: t => <h3 style={styles.title}>{t('how_it_works_heading_six')}</h3>,
		content: t => (
			<>
				<p>
					If you are a plant lover and want to parent your healthy house plants, this is your tool. Have you ever
					thought what are the right places for my plants? You can do fast and easy what experts have been spending
					hours to do. You don’t need to learn how to use this and that software and understand all the scientific
					terms. You will get exactly what you need; whether your plants getting enough light in this place or not?{' '}
				</p>
				<p>
					If you care about your environmental footprint and want to eat organic vegetables from your backyard or
					rooftop, this is your tool. Have you ever wondered how to predict sunlight in your rooftop, backyard, or
					balcony? We provide inexpensive services for anyone who wants to see the share on reducing CO2 emission by
					being part of the urban gardener’s community around the world.
				</p>
				<p>
					If you are a professional and want to invest for your next season and making decision about the most
					beneficial plan in your greenhouse or vertical farm, this is your tool. You can model your very detailed
					space, specify your lighting system, and define spectral light requirements for your particular crops, then we
					provide you a detailed report. You get information about annual cost estimations for electricity, control of
					supplemental lighting, and the shading usage for various scenarios through many “What if” evaluations.
				</p>
			</>
		),
	},
];
const HowItWorksPage = () => {
	const { t } = useTranslation();
	return (
		<div className="container" style={{ padding: '2rem' }}>
			{contents.map((c, i) => {
				return (
					<div style={styles.summary} key={i}>
						<details>
							<summary>{c.title(t)}</summary>
							<div className="container" style={{ paddingTop: '1rem' }}>
								{c.content(t)}
							</div>
						</details>
					</div>
				);
			})}

			<div style={{ marginBottom: '4rem' }}></div>
		</div>
	);
};

export default HowItWorksPage;
