import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

const CarouselContaier = ({ children }) => {
	return (
		<div
			className="d-flex justify-content-center align-items-center"
			style={{
				backgroundColor: '#FFF',
				padding: '2rem 0.5rem 2rem 0.5rem',
				fontFamily: 'PT Sans',
				color: '#4F4F4F',
				textAlign: 'center',
				minHeight: '35vh',
				fontSize: isMobile ? '1.5rem' : '3rem',
			}}
		>
			{children}
		</div>
	);
};
const CarouselNextIcon = () => {
	return (
		<div style={{ margin: '1rem', width: '30%' }}>
			<svg viewBox="0 0 40.6 88.8">
				<title>arrow-thin-right</title>
				<path
					d="M1,0a1,1,0,0,1,.7.3L40.3,43.7a1,1,0,0,1,0,1.3L1.7,88.5A1,1,0,1,1,.3,87.2l38-42.8L.3,1.7A1,1,0,0,1,.3.3Z"
					fill="#4F4F4F"
				></path>
			</svg>
		</div>
	);
};
const CarouselPrevIcon = () => {
	return (
		<div style={{ margin: '1rem', width: '30%' }}>
			<svg viewBox="0 0 40.6 88.8">
				<title>arrow-thin-left</title>
				<path
					d="M39.6,88.8a1,1,0,0,1-.7-.3L.3,45.1a1,1,0,0,1,0-1.3L38.9.3a1,1,0,1,1,1.5,1.3L2.3,44.4l38,42.7a1,1,0,0,1-.1,1.4Z"
					fill="#4F4F4F"
				></path>
			</svg>
		</div>
	);
};
const carouselItemsContent = [
	'carousel_text_one',
	'carousel_text_two',
	'carousel_text_three',
	'carousel_text_four',
	'carousel_text_five',
	'carousel_text_six',
];

const CarouselMaker = () => {
	const { t } = useTranslation();
	return (
		<Carousel indicators={false} nextIcon={<CarouselNextIcon />} prevIcon={<CarouselPrevIcon />}>
			{carouselItemsContent.map((item, index) => (
				<Carousel.Item key={index}>
					<CarouselContaier>
						<div style={{ maxWidth: '80%', margin: 'auto' }}>
							<h3>{t('carousel_heading')}</h3>
							<p>{t(item)}</p>
						</div>
					</CarouselContaier>
				</Carousel.Item>
			))}
		</Carousel>
	);
};

export default CarouselMaker;
