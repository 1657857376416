import React, { useState } from 'react';
import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

const PrettoSlider = withStyles({
	root: {
		height: 1,
		color: '#C8C8C8',
	},
	thumb: {
		height: 9,
		width: 9,
		backgroundColor: '#4F4F4F',
		marginTop: 0,
		'&:focus, &:hover, &$active': {
			boxShadow: 'inherit',
		},
	},
	active: {},
	track: {
		color: '#70AD47',
		height: 5,
		marginTop: 2,
		borderRadius: 4,
	},
	rail: {
		height: 1,
		marginTop: 4,
		borderRadius: 4,
	},
})(Slider);

const PSlider = ({ min, max, step, value, onChange, label, style, fineTune }) => {
	const defaultStep = 0.0000001;

	const [val, setVal] = useState();

	const finishUpdate = e => {
		let amount = e.target.value;
		setVal();
		if (!amount || amount.match(/^-?\d{1,}(\.\d{0,4})?$/)) {
			onChange(Math.max(Math.min(parseFloat(amount), max), min));
		} else {
			onChange(value);
		}
	};

	return (
		<div style={{ ...(style || { width: '300px' }) }}>
			<div
				style={{
					position: 'relative',
					display: 'flex',
					marginBottom: '-20px',
				}}
			>
				<label
					style={{
						position: 'absolute',
						right: fineTune ? '2em' : '0.5em',
						fontFamily: 'PT Sans',
						color: '#828282',
					}}
				>
					{label}
				</label>
				<div style={{ position: 'relative', width: '100%' }}>
					<input
						id={'standard-basic' + label}
						style={{
							position: 'relative',
							width: '3em',
							left: '40%',
							border: 'none',
							margin: 0,
							backgroundColor: 'transparent',
						}}
						value={val !== undefined ? val : value.toFixed(2)}
						onChange={e => setVal(e.target.value)}
						onClick={() => setVal(value.toFixed(2))}
						onBlur={finishUpdate}
						onKeyDown={e => {
							if (e.key === 'Enter') {
								finishUpdate(e);
							}
						}}
					/>
				</div>
			</div>
			<div style={{ width: '100%', display: 'flex' }}>
				{fineTune ? (
					<button
						className="btn btn-link"
						style={{ textDecoration: 'none', color: '#828282' }}
						onClick={() => onChange(value - fineTune)}
					>
						{'-'}
					</button>
				) : (
					<></>
				)}
				<PrettoSlider
					style={{
						marginTop: '0.25em',
						width: 'inherit',
						marginLeft: '0.5em',
						marginRight: '0.25em',
					}}
					type="range"
					onChange={(e, val) => {
						onChange(val);
					}}
					min={min}
					max={max}
					step={step || defaultStep}
					defaultValue={value}
					value={value}
				/>
				{fineTune ? (
					<button
						className="btn btn-link"
						style={{ textDecoration: 'none', color: '#70AD47' }}
						onClick={() => onChange(value + fineTune)}
					>
						{'+'}
					</button>
				) : (
					<></>
				)}
			</div>
		</div>
	);
};

export default PSlider;
