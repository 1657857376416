import React from 'react';
import Expans from './expansion-panel';
import { useTranslation } from 'react-i18next';
import icon from '../../../assets/images/icons/general.svg';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import Slider from '../../ui-elements/slider';

const RoomPanel = ({
	currentSimulation: {
		width,
		height,
		depth,
		elevation,
		hasRoom,
		wallReflectivity,
		ceilingReflectivity,
		floorReflectivity,
	},
	handleRoomChange,
}) => {
	const { t } = useTranslation();
	return (
		<Expans title={t('room_label')} icon={icon} id="room">
			<FormControlLabel
				control={
					<Checkbox
						id="hasRoom-modeler-control"
						checked={hasRoom}
						color="primary"
						onChange={() => handleRoomChange('hasRoom', !hasRoom)}
						name="checkedA"
					/>
				}
				label={t('contains_room')}
			/>

			<Slider
				label={t('roomPanel_altitude_label')}
				value={elevation}
				onChange={value => handleRoomChange('elevation', value)}
				min={0}
				max={100}
				step={1}
			/>
			{hasRoom ? (
				<>
					<Slider
						label={t('roomPanel_width_label')}
						value={width}
						onChange={value => handleRoomChange('width', value)}
						min={2}
						max={15}
						step={0.5}
					/>
					<Slider
						label={t('roomPanel_depth_label')}
						value={depth}
						onChange={value => handleRoomChange('depth', value)}
						min={2}
						max={15}
						step={0.5}
					/>
					<Slider
						label={t('roomPanel_height_label')}
						value={height}
						onChange={value => handleRoomChange('height', value)}
						min={2}
						max={15}
						step={0.5}
					/>
					<Slider
						label={t('roomPanel_wallReflectivity_label')}
						value={wallReflectivity}
						onChange={value => handleRoomChange('wallReflectivity', value)}
						min={0.05}
						max={0.95}
					/>
					<Slider
						label={t('roomPanel_ceilingReflectivity_label')}
						value={ceilingReflectivity}
						onChange={value => handleRoomChange('ceilingReflectivity', value)}
						min={0.05}
						max={0.95}
					/>
					<Slider
						label={t('roomPanel_floorReflectivity_label')}
						value={floorReflectivity}
						onChange={value => handleRoomChange('floorReflectivity', value)}
						min={0.05}
						max={0.95}
					/>
				</>
			) : (
				<></>
			)}
		</Expans>
	);
};

export default RoomPanel;
