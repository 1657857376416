import React from 'react';
import { useTranslation } from 'react-i18next';
import { NativeSelect } from '@material-ui/core';
import Slider from '../../ui-elements/slider';

const Luminary = ({ luminaries, setLuminaries, selectedId, setSelectedId, workPlanes }) => {
	const { t } = useTranslation();
	if (selectedId === undefined) {
		return <></>;
	}
	const luminary = luminaries.find(l => l.id === selectedId);
	if (!luminary) return <></>;
	const workPlane = workPlanes.find(w => w.id === luminary.wpId);
	const min1 = Math.min(workPlane.position1[0], workPlane.position2[0]);
	const max1 = Math.max(workPlane.position1[0], workPlane.position2[0]);
	const min2 = Math.min(workPlane.position1[1], workPlane.position2[1]);
	const max2 = Math.max(workPlane.position1[1], workPlane.position2[1]);
	if (!luminary) {
		return <></>;
	}
	return (
		<div style={{ display: 'flex', flexDirection: 'column', padding: '2rem' }}>
			{workPlanes.length > 1 ? (
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<label htmlFor="luminary-select" style={{ marginRight: '2rem' }}>
						{t('report_select_workplane_button_label')}
					</label>
					<NativeSelect
						value={luminary.wpId}
						onChange={event => {
							setLuminaries([
								...luminaries.filter(f => f.id !== selectedId),
								{ ...luminary, wpId: parseInt(event.target.value), position: [0, 0] },
							]);
						}}
						inputProps={{
							name: 'workplane-select',
							id: 'workplane-select',
						}}
					>
						{workPlanes.map(w => (
							<option key={w.id} value={w.id}>
								{w.id}
							</option>
						))}
					</NativeSelect>
				</div>
			) : (
				<></>
			)}
			<Slider
				value={luminary.position[0]}
				onChange={v =>
					setLuminaries([
						...luminaries.filter(f => f.id !== selectedId),
						{ ...luminary, position: [v, luminary.position[1]] },
					])
				}
				name={'width'}
				label={t('report_luminary_x_button_label')}
				min={workPlane.face === 'H' ? min1 : min2}
				max={workPlane.face === 'H' ? max1 : max2}
			/>
			<Slider
				value={luminary.position[1]}
				onChange={v =>
					setLuminaries([
						...luminaries.filter(f => f.id !== selectedId),
						{ ...luminary, position: [luminary.position[0], v] },
					])
				}
				name={'depth'}
				label={t('report_luminary_y_button_label')}
				min={workPlane.face === 'H' ? min2 : min1}
				max={workPlane.face === 'H' ? max2 : max1}
			/>
			<Slider
				value={luminary.ppfd}
				onChange={v => setLuminaries([...luminaries.filter(f => f.id !== selectedId), { ...luminary, ppfd: v }])}
				name={'ppfd'}
				label={t('report_luminary_ppfd_button_label')}
				min={0}
				max={500}
				step={0.1}
			/>
			<Slider
				value={luminary.diameter}
				onChange={v => setLuminaries([...luminaries.filter(f => f.id !== selectedId), { ...luminary, diameter: v }])}
				name={'diameter'}
				label={t('report_luminary_diameter_button_label')}
				min={0.25}
				max={8}
				step={0.1}
			/>
			<button
				className="btn btn-dark"
				style={{ marginLeft: '2em', marginTop: '1em', width: '15rem' }}
				onClick={() => {
					const t = [...luminaries.filter(f => f.id !== selectedId)];
					setLuminaries(t);
					setSelectedId(t.length > 0 ? t[0].id : undefined);
				}}
			>
				{t('report_remove_luminary_button_label')}
			</button>
		</div>
	);
};

export default Luminary;
