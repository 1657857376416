import React from 'react';
import { useTranslation } from 'react-i18next';

const WeatherCondition = () => {
	const { t } = useTranslation();
	return (
		<>
			<p>{t('weather_condition_label')}</p>
		</>
	);
};

export default WeatherCondition;
