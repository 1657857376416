import React from 'react';
import { Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';

function Alert(props) {
	return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export function UpdateAlert({ newVersionAvailable, updateServiceWorker }) {
	const { t } = useTranslation();

	return (
		<Snackbar open={newVersionAvailable} onClose={() => {}}>
			<Alert severity="success">
				<Button style={{ color: 'white' }} onClick={updateServiceWorker}>
					{t('update_alert')}
				</Button>
			</Alert>
		</Snackbar>
	);
}
