import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WizardTemplateSetter } from '../wizard-templates';
import RadioImage from '../radio-image';
import WizardSection from './wizard-section';
import RadioButtonMaker from '../radio-button';
import templates from '../../pages/templatesList';
import defaultContext from '../../../defaultContext';
import general from '../../../assets/images/icons/general.svg';
import windows from '../../../assets/images/icons/windows.svg';
import materialIcon from '../../../assets/images/icons/materialIcon.svg';
import locationIcon from '../../../assets/images/icons/locationIcon.png';
import orientationIcon from '../../../assets/images/wizard/orientation.svg';
import ref1Icon from '../../../assets/images/wizard/Ref1.svg';
import ref2Icon from '../../../assets/images/wizard/Ref2.svg';
import ref3Icon from '../../../assets/images/wizard/Ref3.svg';
import tr1Icon from '../../../assets/images/wizard/Trn1.svg';
import tr2Icon from '../../../assets/images/wizard/Trn2.svg';
import tr3Icon from '../../../assets/images/wizard/Trn3.svg';
import wwr1 from '../../../assets/images/wizard/WWR1.svg';
import wwr2 from '../../../assets/images/wizard/WWR2.svg';
import wwr3 from '../../../assets/images/wizard/WWR3.svg';
import leafIcon from '../../../assets/images/icons/leaf.svg';
import dimentionGuide from '../../../assets/images/wizard/dimentionGuide.png';
import windowsGuide from '../../../assets/images/wizard/windowsGuide.png';
import { updateCurrentSimulationBase, handleArrayEntityChangeBase, runTemplateModifications } from '../utils';
import Slider from '../slider';
import Map from './map';
import { values, find, includes, isEmpty, cloneDeep } from 'lodash';
import { Autocomplete } from '@material-ui/lab';
import plantsShortList from '../plants/plants-short-list';
import { TextField } from '@material-ui/core';
import { getPlant } from '../../../services/plants';
import { useHistory } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import ModalCarousel from '../../ui-elements/modal-carousel';

const windowsSideInputs = [
	{
		id: 1,
		label: 'geometry_window_face_front_label',
		key: 'F',
	},
	{
		id: 2,
		label: 'geometry_window_face_left_label',
		key: 'L',
	},
	{
		id: 3,
		label: 'geometry_window_face_right_label',
		key: 'R',
	},
	{
		id: 4,
		label: 'geometry_window_face_top_label',
		key: 'T',
	},
];

const buildingOrientation = [
	{ id: 1, label: 'north_label', key: 0 },
	{ id: 2, label: 'east_label', key: 90 },
	{ id: 3, label: 'south_label', key: 180 },
	{ id: 4, label: 'west_label', key: 270 },
];

const transmissivityOptions = [
	{ id: 0, value: 0.3, image: tr1Icon },
	{ id: 1, value: 0.5, image: tr2Icon },
	{ id: 2, value: 0.8, image: tr3Icon },
];
const wwrOptions = [
	{ id: 0, value: 0.3, image: wwr1 },
	{ id: 1, value: 0.5, image: wwr2 },
	{ id: 2, value: 0.8, image: wwr3 },
];

const reflectivityOptions = [
	{ id: 0, value: 0.25, image: ref1Icon },
	{ id: 1, value: 0.5, image: ref2Icon },
	{ id: 2, value: 0.75, image: ref3Icon },
];

const FreePlanWizard = () => {
	const { t } = useTranslation();
	const history = useHistory();

	const { currentSimulation, setCurrentSimulation, setCurrentSimulationName } = useContext(defaultContext);
	const [template, setTemplate] = useState(values(templates)[0].id);
	const [worldMap, setWorldMap] = useState(4);
	const [transmissivityOptionId, setTransmissivityOptionId] = useState(2);
	const [reflectivityOptionId, setReflectivityOptionId] = useState(2);
	const [wwrOptionId, setWwrOptionId] = useState(2);

	const options = plantsShortList;

	const handlePlantChange = async (value, name) => {
		let plant = await getPlant(value);
		if (plant) {
			updateCurrentSimulation('plants', [{ id: plant.id, name, quantity: 1, dli: plant.dli }]);
		}
	};

	const handleWwrChange = v => {
		setWwrOptionId(v);
		const windowRatio = wwrOptions.find(f => f.id === v).value;
		handleArrayEntityChangeBase('templateInputs', 0, 'val', windowRatio, currentSimulation, setCurrentSimulation);
	};

	const handleTransmissivityChange = v => {
		setTransmissivityOptionId(v);
		const tr = transmissivityOptions.find(f => f.id === v).value;
		updateCurrentSimulation(
			'windows',
			currentSimulation.windows.map(w => {
				return { ...w, transmissivity: tr };
			}),
		);
	};

	const handleReflectivityChange = v => {
		setReflectivityOptionId(v);
		const re = transmissivityOptions.find(f => f.id === v).value;
		setCurrentSimulation({
			...currentSimulation,
			floorReflectivity: re,
			ceilingReflectivity: re,
			wallReflectivity: re,
		});
	};

	const handleWorldMapClick = v => {
		setWorldMap(v);
		const lats = [67.82, 61.18, 49.52, 41.8, 35.41, 24.428, 10.6, 1.37, -12, -18.8, -33.95, -43.49];
		const longs = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
		setCurrentSimulation({ ...currentSimulation, lat: lats[v], long: longs[v] });
	};

	const handleFinish = () => {
		const { func, templateInputs, ...rest } = currentSimulation;
		const id =
			`${rest.id}-${rest.width}-${rest.height}-${rest.depth}-${rest.lat}-` +
			`${rest.hasRoom ? rest.windows[0].face : 'N'}-${wwrOptionId}-${transmissivityOptionId}-${reflectivityOptionId}-` +
			`${rest.orientation}`;
		setCurrentSimulationName(id);
		setCurrentSimulation({
			...rest,
			id,
		});
		history.push('/free-report');
	};

	useEffect(() => {
		let t = cloneDeep(find(templates, t => t.id === template));
		t.width = Math.min(Math.max(t.width, 3), 6);
		t.depth = Math.min(Math.max(t.depth, 3), 6);
		t.height = Math.min(Math.max(t.height, 3), 6);

		if (t.windows && t.windows.length > 0) {
			t.windows[0].transmissivity = transmissivityOptions[2].value;
		}
		if (template === 'room' && t.templateInputs && t.templateInputs.length > 0) {
			t.templateInputs[0].val = wwrOptions[2].value;
			t = runTemplateModifications(t);
		}
		setCurrentSimulation({
			...t,
			lat: 35.41,
			long: 0,
			plants: [{ dli: [2, 16], quantity: 1, id: 'adiantum fragrans', name: 'Maidenhair Fern' }],
			floorReflectivity: reflectivityOptions[2].value,
			ceilingReflectivity: reflectivityOptions[2].value,
			wallReflectivity: reflectivityOptions[2].value,
		});
	}, [template, setCurrentSimulation]);

	const updateCurrentSimulation = (key, value) => {
		updateCurrentSimulationBase({ [key]: value }, currentSimulation, setCurrentSimulation);
	};

	if (!currentSimulation) {
		return <></>;
	}

	return (
		<div style={{ backgroundColor: '#F2F2F2' }}>
			<ModalCarousel />

			<WizardTemplateSetter
				optionId={template}
				style={{ backgroundColor: 'white' }}
				setOptionId={setTemplate}
				free={true}
			/>
			<WizardSection style={{ backgroundColor: 'white' }} image={general} title={t('dimensions_label') + ':'}>
				<div className="container">
					<div className="row">
						<div className="col-md-8">
							<Slider
								style={{ width: '80%' }}
								onChange={v => updateCurrentSimulation('width', v)}
								value={currentSimulation.width}
								min={3}
								max={6}
								step={0.5}
								label={t('width_in_meter_label')}
							/>
							<Slider
								style={{ width: '80%' }}
								onChange={v => updateCurrentSimulation('depth', v)}
								value={currentSimulation.depth}
								min={3}
								max={6}
								step={0.5}
								label={t('depth_in_meter_label')}
							/>
							<Slider
								style={{ width: '80%' }}
								onChange={v => updateCurrentSimulation('height', v)}
								value={currentSimulation.height}
								min={3}
								max={6}
								step={0.5}
								label={t('height_in_meter_label')}
							/>
						</div>
						<div className="col-md-4">
							<img src={dimentionGuide} alt="dimention guide" />
						</div>
					</div>
				</div>
			</WizardSection>
			{template === 'room' ? (
				<WizardSection
					image={windows}
					style={{ backgroundColor: 'white' }}
					title={t('geometry_window_face_label') + ':'}
				>
					<div className="container">
						<div className="row">
							<div className="col-md-8">
								<RadioButtonMaker
									inputsContent={windowsSideInputs}
									style={{ width: '60%', display: 'flex', flexDirection: 'column' }}
									value={(currentSimulation.windows[0] || {}).face}
									name={'free-wizrd-window-face'}
									onChange={v => updateCurrentSimulation('windows', [{ ...currentSimulation.windows[0], face: v }])}
								/>
							</div>
							<div className="col md-4">
								<img src={windowsGuide} alt="windows guide" />
							</div>
						</div>
						<div className="row">
							<div className="col-12 pl-0">
								<p style={{ marginBottom: '0' }}>{t('wwr')}</p>
								<RadioImage
									onClick={e => handleWwrChange(e)}
									checked={wwrOptionId}
									name="wwr"
									alt={t('wwr_option_aria_label')}
									options={wwrOptions}
									style={{ width: '5em', marginRight: '1em' }}
								/>
							</div>
						</div>
					</div>
				</WizardSection>
			) : (
				<></>
			)}
			<WizardSection image={materialIcon} style={{ backgroundColor: 'white' }} title={t('material_label') + ':'}>
				{currentSimulation.windows.length > 0 ? (
					<>
						<p style={{ marginBottom: '0' }}>{t('window_opacity')}</p>
						<RadioImage
							onClick={e => handleTransmissivityChange(e)}
							checked={transmissivityOptionId}
							name="transmissivity"
							options={transmissivityOptions}
							style={{ width: '5em', marginRight: '1em' }}
						/>
					</>
				) : (
					<></>
				)}
				<p style={{ marginBottom: '0' }}>{t('wall_reflectivity')}</p>
				<RadioImage
					onClick={e => handleReflectivityChange(e)}
					checked={reflectivityOptionId}
					name="reflectivity"
					options={reflectivityOptions}
					style={{ width: '5em', marginRight: '1em' }}
				/>
			</WizardSection>
			{template !== 'greenhouse' ? (
				<WizardSection
					image={orientationIcon}
					style={{ backgroundColor: 'white' }}
					title={t('orientation_label') + ':'}
				>
					<div>
						<RadioButtonMaker
							inputsContent={buildingOrientation}
							style={{ width: '60%', display: 'flex', flexDirection: 'column', paddingLeft: isMobile ? '1rem' : '' }}
							value={currentSimulation.orientation}
							name={'free-wizrd-building-rotation'}
							onChange={v => updateCurrentSimulation('orientation', parseInt(v))}
						/>
					</div>
				</WizardSection>
			) : (
				<></>
			)}
			<WizardSection image={locationIcon} style={{ backgroundColor: 'white' }} title={t('location_label') + ':'}>
				<Map width={400} height={200} value={worldMap} onChange={handleWorldMapClick} />
			</WizardSection>
			<WizardSection image={leafIcon} style={{ backgroundColor: 'white' }} title={t('free_wizard_plant') + ':'}>
				<Autocomplete
					defaultValue={options[0]}
					id="combo-box-1"
					options={options}
					onChange={(event, value) => handlePlantChange(value[2], value[1])}
					getOptionSelected={(option, value) => includes(option[0], value[0]) || includes(option[1], value[1])}
					getOptionLabel={option => (isEmpty(option[1]) ? option[0] : option[1])}
					style={{ width: 300 }}
					renderInput={params => <TextField {...params} label="Name" variant="outlined" />}
				/>
				<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
					{' '}
					<button className="btn btn-dark" onClick={handleFinish}>
						{t('wizard_save_and_preview_button')}
					</button>
				</div>
			</WizardSection>
		</div>
	);
};
export default FreePlanWizard;
