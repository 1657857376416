import React, { useRef, useEffect, useState } from 'react';
import worldmap from '../../../assets/images/wizard/worldmap.svg';

const Map = ({ value, onChange, width, height }) => {
	const [actualHeight, setActualHeight] = useState();
	const ref = useRef();
	useEffect(() => {
		const context = ref.current.getContext('2d');
		setActualHeight(context.canvas.clientHeight);
		context.globalAlpha = 1;

		const image = new Image();
		image.onload = () => {
			context.clearRect(0, 0, context.canvas.width, context.canvas.height);
			context.drawImage(image, 0, 0, context.canvas.width, context.canvas.height);
			const step = context.canvas.height / 12;
			for (let i = 0; i < context.canvas.height; i += 2 * step) {
				context.globalAlpha = 0.2;
				context.fillStyle = '#121212';
				context.fillRect(0, i, context.canvas.width, step);
			}
			if (value !== undefined) {
				context.globalAlpha = 0.2;
				context.fillStyle = '#00ff00';
				context.fillRect(0, step * value, context.canvas.width, step);
			}
		};
		image.src = worldmap;
	}, [value]);
	return (
		<>
			<canvas
				ref={ref}
				width={width}
				height={height}
				style={{ maxWidth: '100%' }}
				onClick={e => {
					var rect = ref.current.getBoundingClientRect();
					onChange(parseInt((e.clientY - rect.top) / (actualHeight / 12)));
				}}
			/>
		</>
	);
};

export default Map;
