import React, { useState, Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty, map, slice, filter } from 'lodash';
import { Canvas } from 'react-three-fiber';
import Room from '../../room-builder/room';
import Window from '../../room-builder/window';
import Block from '../../room-builder/block';
import MiscObject from '../../room-builder/misc-object';

import { countOF } from '../helpers';
import { rgbf, colorGradient } from '../../utils';
import { Selection } from './Selection';
import { Plane } from './Plane';
import { CameraController } from './CameraController';
import { Legend } from './Legend';

const GridReport = ({
	sensors,
	gridCameraPosition,
	style,
	onselect,
	pickedSensor,
	selectedSensor,
	currentSimulation: { containsRoom, width, depth, height, elevation, windows, resolution, hasRoom, blocks, objects },
	plantDli,
	dliType,
	range,
	window,
}) => {
	const { t } = useTranslation();
	const [showObjects, setShowObjects] = useState(true);

	if (!plantDli) {
		return <></>;
	}

	let percentage = {};
	if (!isEmpty(sensors)) {
		map(sensors, (sensor, key) => {
			percentage[key] =
				countOF(slice(window ? sensor.dlis[window] : sensor.dlisAll, range[0], range[1]), dliType, plantDli) /
				(range[1] - range[0]);
		});
	}
	const [x, y] = gridCameraPosition;

	return (
		<div style={style}>
			{isEmpty(sensors) ? (
				<></>
			) : (
				<>
					<Canvas camera={{ position: [0, 10, 0] }} onCreated={({ camera }) => camera.lookAt(0, -1, 0)}>
						<CameraController x={x} y={y} />
						<ambientLight intensity={2} />
						<pointLight position={[40, 40, 40]} />
						<Suspense fallback={null}>
							<group>
								{hasRoom ? <Room width={width} depth={depth} height={height} alt={elevation} /> : <></>}
								{map(windows, (window, index) => (
									<Window
										key={index}
										alt={elevation}
										roomWidth={width}
										roomDepth={depth}
										roomHeight={height}
										width={window.width}
										height={window.height}
										position={window.position}
										face={window.face}
										splitDirection={window.splitDirection}
										splitNum={window.splitNum}
									/>
								))}
								{map(blocks, (block, index) => (
									<Block key={index} alt={elevation} block={block} />
								))}
								{map(sensors, (sensor, key) =>
									sensor.wpId > 1000 ? (
										<></>
									) : (
										<Plane
											key={key}
											x={sensor.position[0]}
											y={sensor.position[1]}
											z={sensor.position[2]}
											r={resolution}
											n={sensor.normal}
											rotation={sensor.rotation}
											c={colorGradient(
												Math.min((percentage[key] * 4) / 3, 1), //we are showing 75% as fulfilled
												rgbf(200, 200, 200),
												{ 0: rgbf(55, 94, 197), 1: rgbf(85, 161, 87), 2: rgbf(255, 83, 83) }[dliType],
											)}
											onClick={() => onselect(sensor)}
										/>
									),
								)}
								{map(objects, (object, index) => (
									<MiscObject
										key={index}
										showObjects={showObjects}
										onselect={onselect}
										object={object}
										sensors={filter(
											map(sensors, (s, k) =>
												s.wpId > 1000
													? [
															...s.position,
															colorGradient(
																percentage[k],
																rgbf(200, 200, 200),
																{ 0: rgbf(55, 94, 197), 1: rgbf(85, 161, 87), 2: rgbf(255, 83, 83) }[dliType],
															),
															() => {
																console.log(s)
																onselect(s)
															},
													  ]
													: null,
											),
										)}
									/>
								))}
								{isEmpty(pickedSensor) ? (
									<></>
								) : (
									<Selection
										x={pickedSensor.position[0]}
										y={pickedSensor.position[1]}
										z={pickedSensor.position[2]}
										r={resolution}
										rotation={pickedSensor.rotation}
										n={pickedSensor.normal}
										c={'#000000'}
									/>
								)}
								{isEmpty(selectedSensor) ? (
									<></>
								) : (
									<Selection
										x={selectedSensor.position[0]}
										y={selectedSensor.position[1]}
										z={selectedSensor.position[2]}
										r={resolution}
										rotation={selectedSensor.rotation}
										n={selectedSensor.normal}
										c={'#751515'}
									/>
								)}
							</group>
						</Suspense>
					</Canvas>
					{objects && objects.length > 0 ? (
						<button
							className="btn btn-dark"
							style={{ position: 'absolute', bottom: '2rem', right: '2rem', zIndex: 1000 }}
							onClick={() => setShowObjects(!showObjects)}
						>
							{showObjects ? 'Hide Objects' : 'Show Objects'}
						</button>
					) : (
						<></>
					)}
					<Legend
						labels={[t('detail_grid_low'), t('detail_grid_mid'), t('detail_grid_high')]}
						colors={[[200, 200, 200], { 0: [55, 94, 197], 1: [85, 161, 87], 2: [255, 83, 83] }[dliType]]}
					/>
				</>
			)}
		</div>
	);
};

export default GridReport;
