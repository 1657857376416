import React, { useRef, useMemo } from 'react';
import { BoxBufferGeometry } from 'three';

const Box = ({ width, depth, height, alt }) => {
	const mesh = useRef();

	const geom = useMemo(() => new BoxBufferGeometry(depth, height, width), [depth, height, width]);
	if (width === 0 || height === 0 || depth === 0) {
		return <></>;
	}
	return (
		<mesh
			// castShadow
			ref={mesh}
			position={[0, alt + height / 2, 0]}
		>
			<lineSegments>
				<edgesGeometry attach="geometry" args={[geom]} />
				<lineBasicMaterial color="black" width={0.1} attach="material" />
			</lineSegments>
			{/* <boxBufferGeometry attach="geometry" args={[depth, height, width]} />
			<meshBasicMaterial attach="material" opacity={0.2} transparent={true} color={'#000000'} /> */}
		</mesh>
	);
};

export default Box;
