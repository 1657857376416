import React from 'react';

const AdvancedWizardSection = ({ children, style }) => {
	return (
		<div className="pt-2 pb-2 ml-4" style={{ ...style }}>
			<div>{children}</div>
		</div>
	);
};

export default AdvancedWizardSection;
