import React from 'react';
import {
	LineChart,
	Line,
	XAxis,
	YAxis,
	CartesianGrid,
	Tooltip,
	Legend,
	ReferenceArea,
	ResponsiveContainer,
} from 'recharts';

const SimpleLineChart = ({
	series1,
	series2,
	label1,
	label2,
	chartLabel,
	yLabel,
	range,
	min,
	max,
	dli,
	shoWRangeOnly,
	style,
	step,
}) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column' }}>
			<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '1em' }}>
				<h3>{chartLabel}</h3>
			</div>
			<div style={style}>
				<ResponsiveContainer>
					<LineChart
						data={series1.map((s, i) => {
							return { name: i, [label1]: s, [label2]: series2 ? series2[i] : undefined };
						})}
					>
						<XAxis
							dataKey="name"
							tick={{ marginLeft: '10px' }}
							ticks={[0, 32, 60, 91, 121, 152, 183, 214, 244, 275, 305, 336]
								.filter(f => (shoWRangeOnly ? f >= range[0] : true))
								.map(f => (shoWRangeOnly ? f - range[0] : f))}
							tickFormatter={i => {
								return {
									0: 'Jan',
									32: 'Feb',
									60: 'Mar',
									91: 'Apr',
									121: 'May',
									152: 'Jun',
									183: 'Jul',
									214: 'Aug',
									244: 'Sep',
									275: 'Oct',
									305: 'Nov',
									336: 'Dec',
								}[i + (shoWRangeOnly ? range[0] : 0)];
							}}
						/>
						<YAxis domain={[min, max]} label={{ value: yLabel, angle: -90, position: 'center' }} />
						<CartesianGrid strokeDasharray="5 5" />
						<Tooltip />
						<Legend />
						{range[0] !== 0 || range[1] !== 365 ? (
							<ReferenceArea x1={range[0] + 1} x2={range[1] - 1} y1={min} y2={max} fill="#d0d0FF" strokeOpacity={0.3} />
						) : (
							<></>
						)}
						{dli ? <ReferenceArea x1={1} x2={364} y1={dli[0]} y2={dli[1]} fill="#B3F7CA" strokeOpacity={0.3} /> : <></>}
						<Line type={step ? 'stepAfter' : 'basis'} dataKey={label1} stroke="#000000" dot={false} />
						<Line type={step ? 'stepAfter' : 'basis'} dataKey={label2} stroke="#751515" dot={false} />
					</LineChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
};

export default SimpleLineChart;
