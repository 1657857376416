import React, { useRef, useMemo } from 'react';
import { PlaneGeometry, DoubleSide } from 'three';

const Box = ({
	id,
	width,
	height,
	alt,
	face,
	roomWidth,
	roomDepth,
	roomHeight,
	position,
	transmissivity,
	activeId,
	setActiveId,
}) => {
	const mesh = useRef();

	let rotation;
	let translateX = 0;
	let translateZ = 0;
	let translateY = 0;
	if (face === 'F' || face === 'B') {
		rotation = [0, Math.PI / 2, 0];
		translateX = roomDepth / 2;
		translateZ = roomWidth / 2 - width / 2 - position[0];
		translateY = position[1] + height / 2;
		if (face === 'B') {
			translateX *= -1;
			translateZ *= -1;
		}
	} else if (face === 'L' || face === 'R') {
		rotation = [0, 0, 0];
		translateZ = roomWidth / 2;
		translateX = -roomDepth / 2 + width / 2 + position[0];
		translateY = position[1] + height / 2;
		if (face === 'R') {
			translateZ *= -1;
			translateX *= -1;
		}
	} else {
		rotation = [Math.PI / 2, 0, 0];
		translateX = position[0] - roomDepth / 2 + width / 2;
		translateZ = position[1] - roomWidth / 2 + height / 2;
		translateY = roomHeight;
	}

	const geom = useMemo(() => new PlaneGeometry(width, height), [width, height]);
	if (width === 0 || height === 0) {
		return <></>;
	}
	return (
		<mesh
			ref={mesh}
			position={[translateX, translateY + alt, translateZ]}
			rotation={rotation}
			onPointerDown={e => {
				if (setActiveId) setActiveId(`windows_${id}`);
			}}
			// onPointerOver={e => setHover(true)}
			// onPointerOut={e => setHover(false)}
		>
			<lineSegments>
				<edgesGeometry attach="geometry" args={[geom]} />
				<lineBasicMaterial color="black" width={0.1} attach="material" />
			</lineSegments>
			<planeGeometry attach="geometry" args={[width, height]} />
			<meshBasicMaterial
				attach="material"
				opacity={(1.5 - transmissivity) * 0.5 || 0.1}
				transparent={true}
				side={DoubleSide}
				color={activeId === `windows_${id}` ? '#550000' : '#000055'}
			/>
		</mesh>
	);
};

export default Box;
