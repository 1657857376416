import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-bootstrap';
import ModalComponent from './modal';
import Checkbox from '@material-ui/core/Checkbox';
import sliderOne from '../../assets/images/slider/sliderOne.png';
import sliderTwo from '../../assets/images/slider/sliderTwo.png';
import sliderThree from '../../assets/images/slider/sliderThree.png';
import sliderFour from '../../assets/images/slider/sliderFour.png';
import sliderFive from '../../assets/images/slider/sliderFive.png';
import sliderSix from '../../assets/images/slider/sliderSix.png';
import sliderSeven from '../../assets/images/slider/sliderSeven.png';
import { setCookie, getCookie } from '../../services/cookie';

const carouselItemsContent = [sliderOne, sliderTwo, sliderThree, sliderFour, sliderFive, sliderSix, sliderSeven];

const ModalCarousel = () => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [index, setIndex] = useState(0);

	useEffect(() => {
		if (!getCookie('help')) {
			setOpen(true);
		}
	}, []);
	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex);
	};
	return (
		<ModalComponent onClose={() => setOpen(false)} open={open}>
			<Carousel interval={null} activeIndex={index} onSelect={handleSelect}>
				{carouselItemsContent.map((i, index) => (
					<Carousel.Item key={index}>
						<img className="d-block w-100" src={i} alt="First slide" />
						<Carousel.Caption></Carousel.Caption>
					</Carousel.Item>
				))}
			</Carousel>
			<div style={{ paddingTop: '1rem', display: 'flex', justifyContent: 'space-between' }}>
				<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
					<Checkbox
						disableRipple={true}
						id="remember-me"
						style={{ paddingLeft: '.3rem', color: '#C2D1D9' }}
						onChange={() => setCookie('help', true)}
					/>
					<label htmlFor="remember-me" style={{ margin: 0 }}>
						{t('no_show_text')}
					</label>
				</div>
				<button className="btn btn-dark" onClick={() => setOpen(false)}>
					{t('close_button_label')}
				</button>
			</div>
		</ModalComponent>
	);
};

export default ModalCarousel;
