import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setCookie, getCookie } from '../../../services/cookie';
import Checkbox from '@material-ui/core/Checkbox';

const styles = {
	disclaimerContainer: {
		display: 'flex',
		justifyContent: 'center',
		justifyItems: 'center',
		backgroundColor: 'grey',
		color: 'white',
		paddingBottom: '5.5rem',
		paddingTop: '2.5rem',
		position: 'fixed',
		bottom: 0,
		left: 0,
		opacity: 0.9,
	},
	close: {
		position: 'absolute',
		right: 0,
	},
	closeBtn: {
		margin: 'auto'
	}
};

export function Disclaimer({ show, setShow }) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	useEffect(() => {
		if (!getCookie('disclaimer')) {
			setOpen(true);
		}
	}, []);
	return show && open ? (
		<div className="row" style={styles.disclaimerContainer}>
			<div className="col-md-2"></div>
			<div className="col-md-8">
				{/* <input type="checkbox" id="dashboard-disclaimer" />
				<label htmlFor="dashboard-disclaimer">{t('no_show_text')}</label> */}
				
				<h3 style={{ paddingLeft: '2rem', marginBottom: '1rem' }}>Disclaimer</h3>
				<ul>
					<li>
						AiPlantCare assumes neither warranty, nor guarantee nor any other liability of any kind for the contents and
						correctness of the provided data. The data has been generated with highest diligence but may in reality not
						represent the complete possible variation range of all environmental parameters in TMY data. Therefore, in
						certain cases a deviation between the real data and the predicted results could occur.
					</li>
					<li>
						AiPlantCare reserves the right to undertake technical changes of the components without further notification
						which could lead to changes in the provided data. AiPlantCare assumes no liability of any kind for the loss
						of data or any other damage resulting from the usage of the provided data.
					</li>
					<li>
						The user agrees to this disclaimer and user agreement with the download or usage of the provided files.
					</li>
				</ul>
				<div style={styles.close}>
					<Checkbox
						disableRipple={true}
						id="dashboard-disclaimer"
						style={{ paddingLeft: '.3rem', color: '#C2D1D9' }}
						onChange={() => setCookie('disclaimer', true)}
					/>
					<label htmlFor="dashboard-disclaimer" style={{ margin: 0 }}>
						{t('no_show_text')}
					</label>
					<button className="btn" style={styles.closeBtn} onClick={() => setShow(false)}>
						x
					</button>
				</div>
			</div>
			<div className="col-md-2"></div>
		</div>
	) : (
		<></>
	);
}
