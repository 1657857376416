import { useTranslation } from 'react-i18next';
import free from '../assets/images/pricing/free.png';
import basic from '../assets/images/pricing/basic.png';
import deluxe from '../assets/images/pricing/advanced.png';
import advanced from '../assets/images/pricing/deluxe.png';
import payg from '../assets/images/pricing/payg.png';

export function usePricingFeatures() {
	const { t } = useTranslation();

	const priceFeaturesContent = [
		{
			id: 0,
			title: t('free_plan_title'),
			price: t('free_plan_price'),
			priceYearly: t('advanced_plan_price_yearly'),
			description: t('free_plan_description'),
			features: t('free_plan_features').split('|'),
			image: free,
		},
		{
			id: 1,
			title: t('basic_plan_title'),
			price: t('basic_plan_price'),
			priceYearly: t('basic_plan_price_yearly'),
			description: t('basic_plan_description'),
			features: t('basic_plan_features').split('|'),
			image: basic,
		},
		{
			id: 2,
			title: t('advanced_plan_title'),
			price: t('advanced_plan_price'),
			priceYearly: t('advanced_plan_price_yearly'),
			description: t('advanced_plan_description'),
			features: t('advanced_plan_features').split('|'),
			image: advanced,
		},
		{
			id: 3,
			title: t('deluxe_plan_title'),
			price: t('deluxe_plan_price'),
			priceYearly: t('deluxe_plan_price_yearly'),
			description: t('deluxe_plan_description'),
			features: t('deluxe_plan_features').split('|'),
			image: deluxe,
		},
		{
			id: 4,
			title: t('payg_plan_title'),
			price: t('payg_plan_price'),
			priceYearly: t('payg_plan_price_yearly'),
			description: t('payg_plan_description'),
			features: t('payg_plan_features').split('|'),
			image: payg,
		},
	];

	return priceFeaturesContent;
}
