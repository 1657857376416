import React, { useContext, useState } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepConnector from '@material-ui/core/StepConnector';
import { isMobile } from 'react-device-detect';
import advancedWizardContext from './advance-wizard-context';
import { withStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import DoneIcon from '@material-ui/icons/Done';
import { useTranslation } from 'react-i18next';

import { sortBy, map, filter, find, last, findLast } from 'lodash';
import ModalComponent from '../modal';

const styles = {
	root: {
		maxWidth: 400,
		flexGrow: 1,
		backgroundColor: 'transparent',
	},
	dotActive: {
		backgroundColor: '#00ff00',
	},
};

function DotsMobileStepper({ steps, activeStep, classes }) {
	return (
		<MobileStepper
			style={{ display: 'flex', justifyContent: 'center' }}
			variant="dots"
			steps={steps.length}
			position="static"
			activeStep={activeStep}
			classes={classes}
		/>
	);
}

const MyMobileStepper = withStyles(styles)(DotsMobileStepper);

function MyStepper({ allSteps, setStep }) {
	allSteps = allSteps.filter(f => f.active);
	const {
		nextFunc,
		redrawEntity,
		handleRemoveEntity,
		setMarks,
		handleNext,
		handleFinish,
		nextStep,
		currentSimulation,
		setCurrentSimulation,
	} = useContext(advancedWizardContext);
	const { t } = useTranslation();
	allSteps = sortBy(allSteps, f => f.index);
	const [activeStep, setActiveStep] = useState(0);
	const [mainStep, setMainStep] = useState(0);
	const [completed, setCompleted] = useState({});
	const [open, setOpen] = useState(false);
	const steps = map(
		filter(allSteps, f => f.main),
		g => g.label,
	);

	const stepsIndices = map(
		filter(allSteps, f => f.main),
		g => g.index,
	);

	const currentStep = allSteps.find(f => f.index === activeStep);

	function getStepContent(stepIndex) {
		const step = allSteps.find(f => f.index === stepIndex);
		return step ? step.component : <></>;
	}

	const totalSteps = () => {
		return last(allSteps).index;
	};

	const completedSteps = () => {
		return Object.keys(completed).length;
	};

	const isLastStep = () => {
		return activeStep >= totalSteps();
	};

	const allStepsCompleted = () => {
		return completedSteps() === allSteps.length;
	};

	handleNext.current = () => {
		let newActiveStep =
			currentStep.nextStepIdFunc(currentSimulation) ||
			(isLastStep() && !allStepsCompleted() ? steps.findIndex((step, i) => !(i in completed)) : activeStep + 1);

		let customNextStep = undefined;
		if (nextStep.current) {
			customNextStep = nextStep.current;
			nextStep.current = undefined;
		}

		newActiveStep = Math.max(
			newActiveStep,
			allSteps.find(f => f.index >= newActiveStep).index,
			(allSteps.find(f => f.id === customNextStep) || { index: -Infinity }).index,
		);

		if (currentStep.hasCustomNextFunc) {
			nextFunc.current();
			nextFunc.current = () => {};
		}

		setStepsRoutine(newActiveStep);
	};

	function setStepsRoutine(step) {
		const prevMainStep = findLast(stepsIndices, f => f <= step);
		setActiveStep(step);
		setMainStep(stepsIndices.findIndex(f => f === prevMainStep));
		setStep(find(allSteps, f => f.index === step).id);
	}

	const handleBack = () => {
		setStepsRoutine(stepsIndices[mainStep - 1]);
	};
	const handleStep = step => {
		setStepsRoutine(stepsIndices[step]);
	};
	const handleSkip = () => {
		const n = allSteps.find(f => f.category !== currentStep.category && f.index > currentStep.index).index;
		const p = currentStep.skipStepIdFunc ? currentStep.skipStepIdFunc(currentSimulation) : n;
		setStepsRoutine(p);
	};
	const handleRedraw = () => {
		setMarks([]);
		handleRemoveEntity(redrawEntity.current);

		setStepsRoutine(allSteps.find(f => f.category === currentStep.category).index);
	};
	const handleAddMore = () => {
		setMarks([]);
		setStepsRoutine(allSteps.find(f => f.category === currentStep.category).index);
	};

	const handleComplete = () => {
		setCompleted({ ...completed, [mainStep]: true });
		handleNext.current();
	};

	const handleReset = () => {
		setMarks([]);
		setCompleted({});
		setCurrentSimulation();
		setMainStep(0);
		setActiveStep(0);
		setStep(0);
	};
	const ColorlibConnector = withStyles({
		alternativeLabel: {
			top: 12,
		},
		active: {
			'& $line': {
				backgroundColor: '#BDBDBD',
			},
		},
		completed: {
			'& $line': {
				backgroundColor: '#479344',
			},
		},
		line: {
			height: 3,
			border: 0,
			backgroundColor: '#BDBDBD',
			borderRadius: 1,
		},
	})(StepConnector);

	const MyButton = ({ onclick, completed, label, index, activeStep }) => {
		let addClass = '';
		let style = {
			height: '2em',
			width: '2em',
			marginTop: '0.5em',
			backgroundColor: '#BDBDBD',
			borderColor: '#BDBDBD',
		};
		if (index === activeStep) {
			addClass = 'btn-primary';
			style.backgroundColor = '#277344';
			style.borderColor = '#277344';
		} else {
			if (completed) {
				addClass = 'btn-dark';
				style.backgroundColor = '#479344';
				style.borderColor = '#479344';
			} else {
				addClass = 'btn-outline-dark';
			}
		}
		return (
			<div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
				<button style={style} className={'btn rounded-pill ' + addClass} disabled={index === 0} onClick={onclick}>
					<p style={{ marginTop: '-0.2em', marginLeft: '-0.1em' }}>
						{completed ? <DoneIcon style={{ marginTop: '-0.2em', marginLeft: '-0.3em' }} /> : index}
					</p>
				</button>
				<p style={{ marginTop: '0.75em', marginLeft: '0.5em', fontSize: '1em' }}>{label}</p>
			</div>
		);
	};
	return (
		<>
			<ModalComponent onClose={() => setOpen(false)} open={open}>
				{currentStep.help}
			</ModalComponent>
			<div style={activeStep === 1 ? { zIndex: 15 } : { position: 'absolute', bottom: '5em', zIndex: 15 }}>
				{getStepContent(activeStep)}
			</div>
			<div style={{ zIndex: 20, position: 'absolute', top: '3em', width: '90%', marginLeft: '5%', marginRight: '5%' }}>
				{isMobile ? (
					<MyMobileStepper steps={steps} activeStep={mainStep} />
				) : (
					<Stepper style={{ backgroundColor: 'transparent' }} activeStep={activeStep} connector={<ColorlibConnector />}>
						{steps.map((label, index) => (
							<Step key={label}>
								<MyButton
									onclick={() => handleStep(index)}
									completed={completed[index]}
									label={label}
									index={index}
									activeStep={mainStep}
								/>
							</Step>
						))}
					</Stepper>
				)}
			</div>

			<div style={{ position: 'absolute', bottom: 0, zIndex: 10 }}>
				{isLastStep() ? (
					<div>
						<button className="btn btn-dark" style={{ margin: '1em' }} onClick={handleReset}>
							{t('reset_button_label')}
						</button>
						<button className="btn btn-dark" style={{ margin: '1em' }} onClick={handleFinish}>
							{t('save_button_label')}
						</button>
					</div>
				) : (
					<div
						style={{
							margin: '1em',
							display: 'flex',
							flexDirection: 'row',
						}}
					>
						{activeStep > 1 ? (
							<button
								className="btn btn-dark"
								onClick={handleBack}
								style={{
									margin: '1em',
								}}
							>
								{t('back_button_label')}
							</button>
						) : (
							<></>
						)}
						{currentStep.skipStepIdFunc && currentStep.index > 4 ? (
							<button className="btn btn-dark" onClick={handleSkip} style={{ margin: '1em' }}>
								{t('skip_button_label')}
							</button>
						) : (
							<></>
						)}

						{currentStep.disableRedraw && currentStep.disableRedraw(currentSimulation) ? (
							<></>
						) : (
							<button className="btn btn-dark" onClick={handleRedraw} style={{ margin: '1em' }}>
								{t('redraw_button_label')}
							</button>
						)}
						{currentStep.disableNext ? (
							<></>
						) : (
							<button className="btn btn-dark" onClick={handleComplete} style={{ margin: '1em' }}>
								{completedSteps() === totalSteps() - 1 ? t('finish_label') : currentStep.next || 'Next'}
							</button>
						)}
						{find(allSteps, f => f.index > currentStep.index)?.category !== currentStep.category &&
						currentStep.index > 5 ? (
							<button className="btn btn-dark" onClick={handleAddMore} style={{ margin: '1em' }}>
								{t('add_more_label')}
							</button>
						) : (
							<></>
						)}
						{currentStep.help ? (
							<button className="btn btn-outline rounded-pill" onClick={() => setOpen(true)}>
								?
							</button>
						) : (
							<></>
						)}
					</div>
				)}
			</div>
		</>
	);
}

export default MyStepper;
