import React, { useEffect, useRef } from 'react';
import { useThree, extend } from 'react-three-fiber';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
extend({ OrbitControls });

export function CameraController({ x, y, setCanvasId }) {
	const {
		camera,
		gl: { domElement },
	} = useThree();
	const controls = useRef();
	useEffect(() => {
		controls.current.update();
	});

	return (
		<>
			<orbitControls ref={controls} args={[camera, domElement]} />
		</>
	);
}
