import React from 'react';
import { useTranslation } from 'react-i18next';
import CookieConsent from 'react-cookie-consent';

export function Cookie() {
	const { t } = useTranslation();

	return (
		<CookieConsent style={{ zIndex: 10000 }} buttonText={t('gdpr_understand')}>
			{t('gdpr_text')}
		</CookieConsent>
	);
}
