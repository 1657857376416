import React, { useState, useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import 'mapbox-gl/dist/mapbox-gl.css';

const MapSelector = ({ setLatLong, lat, long, styles }) => {
	const [mapInitialized, setMapInitialized] = useState(null);
	const mapContainer = useRef(null);
	const marker = useRef(null);
	const center = useRef(null);

	useEffect(() => {
		mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;
		const initializeMap = ({ setMapInitialized, mapContainer }) => {
			const map = new mapboxgl.Map({
				container: mapContainer.current,
				style: 'mapbox://styles/mapbox/light-v10',
				zoom: 15,
				center: [long, lat],
			});

			var geoCoder = new MapboxGeocoder({
				accessToken: mapboxgl.accessToken,
				mapboxgl: mapboxgl,
				marker: false,
			});

			map.addControl(geoCoder, 'bottom-right');

			map.on('mousemove', function (e) {});
			map.on('click', function (e) {
				setLatLong(e.lngLat.lng, e.lngLat.lat);
			});

			marker.current = new mapboxgl.Marker().setLngLat([long, lat]).addTo(map);
			center.current = (lat, long) => {
				map.flyTo({ center: [long, lat] });
			};

			setMapInitialized(true);
		};
		if (!mapInitialized) {
			initializeMap({ setMapInitialized, mapContainer });
		}
	}, [mapInitialized, lat, long, setLatLong]);

	useEffect(() => {
		if (marker && marker.current && long && lat) {
			marker.current.setLngLat([long, lat]);
			center.current(lat, long);
		}
	}, [lat, long]);
	return <div className="full-screen" ref={el => (mapContainer.current = el)} style={styles} />;
};

export default MapSelector;
