import React, { useRef } from 'react';
import { isFunction } from 'lodash';

export default props => {
	const mesh = useRef();

	return (
		<mesh
			{...props}
			ref={mesh}
			onClick={e => {
				if (props.onMapClick && isFunction(props.onMapClick.current)) {
					props.onMapClick.current(e.point.x, -e.point.z);
				}
			}}
		>
			<gridHelper attach="geometry" args={[1000, 1000, '#cccccc', '#dddddd']} position={[0, -0.01, 0]} />
		</mesh>
		// 	<mesh  rotation={[-Math.PI/2,0,0]} position={[0, -0.01, 0]} {...props}
		// 	ref={mesh}
		// 	receiveShadow>
		//     <planeBufferGeometry attach="geometry" args={[1000, 1000]} />
		//     <meshPhongMaterial attach="material" color="#272727" />
		//   </mesh>
	);
};
