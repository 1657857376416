export default [
	{
		name: 'house_plants',
		plants: [
			['Aglaonema commutatum', 'Chinese evergreen', 2866467],
			['Scindapsus pictus', 'Satin Pothos', 2873745],
			['Scindapsus pictus', ' Jade Satin Silver Pothos', 2873745],
			['Dracaena fragrans', 'fragrans Cintho', 5304583],
			['Dracaena reflexa', 'Pleomele Anita', 5304436],
			['Hedera helix', ' Variegata English Ivy', 8351737],
			['Philodendron hederaceum', ' Brasil', 8335693],
			['Sansevieria zeylanica', 'Mother-in-laws Tongue', 7870689],
			['Scindapsus treubii', ' Moonlight', 2873707],
			['Zamioculcas zamiifolia', 'ZZ Plant', 2869014],
			['Chamaedorea seifrizii', 'Bamboo Palm', 2734708],
			['philodendron', 'Green Congo', 2861312],
			['Anthurium andraeanum', ' Rainbow Champion', 7768208],
			['Monstera deliciosa', 'Swiss Cheese Plant', 2868241],
			['Epipremnum aureum', 'Devils Ivy', 2868323],
			['Begonia acetosa', 'Begonia', 4157591],
			['Coleus scutellarioides', 'coleus', 8594529],
			['Caladium Bicolor', 'heart of Jesus', 2868390],
			['Aglaonema modestum', ' Variegatum', 8562689],
			['Dracaena deremensis', 'Dragon Tree Janet Craig', 7925681],
		],
	},
	{
		name: 'purifier_plants',
		plants: [
			['Chamaedorea seifrizii', 'Bamboo Palm', 2734708],
			['Dracaena marginata', 'Madagascar Dragon Tree', 7592779],
			['Ficus benjamina', 'Weeping fig', 5361932],
			['Hedera helix', ' Variegata English Ivy', 8351737],
			['Nephrolepis exaltata', 'Boston Fern', 2650927],
			['Chlorophytum comosum', 'Spider Plant', 7742047],
			['Sansevieria zeylanica', 'Mother-in-laws Tongue', 7870689],
			['Aloe Vera', 'Aloe Vera', 2777724],
		],
	},
	{
		name: 'Cacti_Succulents',
		plants: [
			['Aloe Vera', 'Aloe Vera', 2777724],
			['Beaucarnea recurvata', 'Pony Tail Palm', 2768035],
			['Bryophyllum daigremontianum', 'Mother of Thousands', 5362080],
			['Curio rowleyanus', ' String of Pearls', 3149649],
			['Echeveria Shaviana', 'Mexican Hens and Chicks', 5362052],
			['Epiphyllum anguliger', 'Fishbone cactus', 7673570],
			['Epiphyllum oxypetalum', ' Queen-of-the-Night Cactus', 5384031],
			['Euphorbia milii', ' Crown of Thorns', 7713085],
			['Euphorbia tirucalli', ' Pencil cactus', 8130960],
			['Faucaria tigrina', ' Tiger Jaws', 3703460],
			['Kalanchoe tomentosa', ' Panda plant', 4198346],
			['Lepismium cruciforme', ' Hurricane Cactus', 7279966],
			['Mammillaria longimamma', ' Nipple Cactus', 7284614],
			['Myrtillocactus geometrizans', 'Booby Cactus', 7279778],
			['Opuntia microdasys', 'Bunny Ear Cactus', 9819787],
			['Pseudorhipsalis ramulosa', 'Red Mistletoe Cactus', 7283547],
			['Rhipsalis salicornioides', 'Dancing Bones Cactus', 7280609],
			['Rhipsalis baccifera', ' Mistletoe Cactus', 3084036],
			['Rhipsalis burchellii', 'Mistletoe Cactus', 3947598],
			['Rhipsalis mesembryanthemoides', 'Clumpy mistletoe cactus', 7484915],
			['Sansevieria trifasciata', 'Snake Plant', 2770610],
			['Sansevieria zeylanica', 'Mother-in-laws Tongue', 7870689],
			['Schlumbergera gaertneri', 'Easter Cactus', 8506587],
			['Schlumbergera truncata', 'Thanksgiving Cactus', 5383983],
			['Sedum burrito', 'Burros Tail', 5690519],
			['Yucca Recurvifolia', 'Yucca', 2775677],
		],
	},
	{
		name: 'edible_plants',
		plants: [
			['Lactuca virosa x Lactuca quercina', 'Lettuce', 10104589],
			['Cucumis sativus', 'Cucumber ', 2874569],
			['Capsicum annuum', 'Capsicum', 2932944],
			['Solanum melongena', 'Eggplant', 8347557],
			['Lycopersicon esculentum', 'Tomatoes', 9608998],
			['Fragaria', 'Strawberries', 3029779],
			['brassica oleracea', 'Cabbage', 8789577],
			['Spinacia', 'Spinach', 3083647],
			['Glycine max', 'Soybean', 5359660],
		],
	},
	{
		name: 'green_wall',
		plants: [
			['Aeschynanthus', 'Lipstick Plant', 6365037],
			['Codiaeum variegatum', 'Croton Petra', 3071476],
			['Davallia griffithiana', ' Rabbits Foot Fern', 7911152],
			['Dracaena deremensis', 'Dragon Tree Janet Craig', 7925681],
			['Epipremnum aureum', 'Devils Ivy / Pothos', 2868323],
			['Nephrolepis exaltata', 'Boston Fern or Sword Fern', 2650927],
			['philodendron', 'Green Congo', 2861312],
			['Spathiphyllum', ' Silver Streak Peace Lily', 2869649],
			['STEPHANOTIS', 'Wedding vine', 7311880],
		],
	},
	{
		name: 'flowering_plants',
		plants: [
			['Gloriosa rothschildiana', 'Flame Lily', 2740054],
			['Hibiscus', 'Rose of China', 3152542],
			['Ludisia discolor', ' Jewel Orchid', 2784179],
			['Spathiphyllum', ' Silver Streak Peace Lily', 2869649],
			['Streptocarpus ionanthus', 'Saintpaulia ionantha African violets', 6710927],
			['STEPHANOTIS', 'Madagascar jasmine or Wedding vine', 7311880],
			['Dianthus caryophyllus', 'Carnation ', 3085420],
			['GARDENIA', 'Gardenia', 2894320],
			['HYDRANGEA', 'Hydrangea', 2985983],
			['Helianthus', 'Sunflower', 3119134],
			['Viola tricolor', 'Pansy', 5331347],
			['Narcissus', 'Daffodils', 2858200],
			['Myosotis', 'Forget-me-not', 2925668],
			['Tagetes', 'Marigold', 3088468],
			['Paeonia', 'Peony', 3083483],
			['PETUNIA', 'Petunia', 2928858],
			['Matthiola incana', 'Stock', 5377378],
			['Viola', 'Violet', 2874237],
			['Rosa', 'Rose', 8395064],
			['Bellis perennis', 'Daisy', 3117424],
			['Tulipa', 'Tulip', 2750913],
			['Syringa', 'Lilac', 3172235],
		],
	},
];
