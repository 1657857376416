import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../assets/images/logo/logo.png';
import PricingFeatures from '../ui-elements/price/pricingFeatures';

const styles = {
	logo: {
		heigh: '5.812rem',
		width: '5.812rem',
		margin: '2.5rem',
	},
	mainTitle: {
		color: '#000',
		fontSize: '2.812rem',
	},
};

const PricingPage = () => {
	const { t } = useTranslation();
	return (
		<div className="container min-vh-100 ">
			<div className="row align-items-center" style={{ marginTop: '50px' }}>
				<div className="container">
					<div className="row" style={{display:'flex', alignItems:'center'}}>
						<div className="col-md-3 d-flex justify-content-md-end justify-content-center">
							<img src={logo} style={styles.logo} alt="logo" />
						</div>
						<div className="col-md-9 d-flex justify-content-md-start align-items-center">
							<h3 className="text-center" style={styles.mainTitle}>
								{t('our_plans_title_label')}
							</h3>
						</div>
					</div>
				</div>
			</div>
			<div className="row mt-2">
				<div className="col-12 ">
					<p style={{ fontSize: '1.5rem' }} className="text-center">
						{t('our_plans_text')}
					</p>
				</div>
			</div>
			<PricingFeatures />
			<div className="row ">
				<div style={{ marginBottom: '6em' }}></div>
			</div>
		</div>
	);
};

export default PricingPage;
