import React from 'react';

export function Legend({ labels, colors, overrideStyle }) {
	const points = { 2: [0, 100], 3: [0, 50, 100] };
	const gradient = colors.map((f, i, l) => `rgba(${f[0]},${f[1]},${f[2]}) ${points[l.length][i]}%`).join(', ');
	return (
		<div
			style={
				overrideStyle
					? {}
					: {
							marginLeft: '30px',
							marginTop: '-70px',
					  }
			}
		>
			<div
				style={{
					width: '200px',
					height: '20px',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
				}}
			>
				{labels.map(l => (
					<p key={l}>{l}</p>
				))}
			</div>
			<div
				style={{
					width: '200px',
					height: '20px',
					display: 'flex',
					flexDirection: 'row',
					backgroundImage: `linear-gradient(90deg, ${gradient})`,
				}}
			>
				<div
					style={{
						border: '2px solid',
						width: '50%',
					}}
				></div>
				<div
					style={{
						borderStyle: 'solid solid solid hidden',
						borderWidth: '2px',
						width: '50%',
					}}
				></div>
			</div>
		</div>
	);
}
