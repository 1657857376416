import React, { useState, useEffect } from 'react';
import './App.css';
import Root from './components/root';
import ContextProvider from './ContextProvider';
import * as serviceWorker from './serviceWorker';
import { UpdateAlert } from './UpdateAlert';
import { Cookie } from './Cookie';

function App() {
	const [newVersionAvailable, setNewVersionAvailable] = useState(false);
	const [waitingWorker, setWaitingWorker] = useState({});

	useEffect(() => {
		serviceWorker.register({
			onSuccess: () => {},
			onUpdate: onServiceWorkerUpdate,
		});
	}, []);

	const onServiceWorkerUpdate = registration => {
		setWaitingWorker(registration && registration.waiting);
		setNewVersionAvailable(true);
	};

	const updateServiceWorker = () => {
		waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });
		setNewVersionAvailable(false);
		window.location.reload();
	};

	return (
		<ContextProvider>
			<Root />
			<UpdateAlert updateServiceWorker={updateServiceWorker} newVersionAvailable={newVersionAvailable} />
			<Cookie />
		</ContextProvider>
	);
}

export default App;
